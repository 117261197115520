
// Vue
import {computed, defineComponent} from "vue";

// Tipos
import {Pago} from "@/typings/store/plugins/easyFirestore/pagos";

export default defineComponent({
  name: "ResultadoError",
  props: {
    pago: {
      type: Object as () => Pago | null,
    },
  },
  setup(props) {
    // Computed

    const tituloError = computed(() => {
      const code = props.pago?.resultado?.response_code;
      const errorPago = props.pago?.error;
      if (code) {
        return "Transacción rechazada";
      } else if (errorPago) {
        return "¡Algo salió mal!";
      }
      return "";
    });

    const error = computed(() => {
      const code = props.pago?.resultado?.response_code;
      const errorPago = props.pago?.error;
      if (code && code < 0) {
        switch (code) {
          case -1:
            return "Es posible que haya un error en el ingreso de datos de la transacción. ¡Vuelve a intentarlo!";
          case -2:
            return "Se produjo un fallo al procesar la transacción. ¡Vuelve a intentarlo!";
          case -3:
            return "Se produjo un error en la transacción. ¡Vuelve a intentarlo!";
          case -4:
            return "Es posible que el emisor (banco) de la tarjeta haya rechazado la transacción. ¡Vuelve a intentarlo!";
          case -5:
            return "¡Transacción con riesgo de posible fraude! Comunícate con tu banco en caso de cualquier duda o problema.";
        }
      } else if (errorPago) {
        switch (errorPago) {
          case "02":
            return "Lo lamentamos, se acabó el tiempo para realizar la transacción en Webpay. ¡Vuelve a intentarlo!";
          case "06":
            return "Lo lamentamos, la transacción fue abortada.\nSi deseas volver a intentarlo presiona Volver a Carrito.";
          default:
            return "Lo lamentamos, ocurrió un error con el formulario de Webpay. ¡Vuelve a intentarlo!";
        }
      }
      return "";
    });

    return {
      // Computed
      error,
      tituloError,
    };
  },
});
