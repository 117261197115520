
// Vue
import {defineComponent} from "vue";
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "PorQueContratar",
  components: {SvgIcon},
  setup() {
    // Data

    const razones = [
      "Creación y formalización de empresa.",
      "Contabilidad.",
      "Consultoría de gestión empresarial.",
      "Postulación a fondos, beneficios públicos y búsqueda de financiamiento bancario.",
      "Asesoría contable, laboral, financiera y tributaria.",
    ];

    return {
      //Data
      razones,
    };
  },
});
