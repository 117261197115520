
// Vue
import {defineComponent} from "vue";

// Tipos
import {ItemTablaInscripciones} from "@/typings/components/inscripciones/tablaInscripciones";

// Componentes
import PageTextField from "@/components/custom/PageTextField.vue";

export default defineComponent({
  name: "InformacionInscripcion",
  components: {
    PageTextField
  },
  props: {
    inscripcion: {
      required: true,
      type: Object as () => ItemTablaInscripciones
    }
  }
});
