import {RouteConfig} from "vue-router";
import VistaCuenta from "@/views/VistaCuenta.vue";

const cuenta: RouteConfig = {
  path: "/cuenta",
  name: "cuenta",
  component: VistaCuenta
};

export default cuenta;
