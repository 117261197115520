
// Decoradores
import {Vue, Component} from "vue-property-decorator";

// Vuex
import {mapGetters} from "vuex";

// Helpers
import routeGuard from "@/helpers/routeGuard";

// Tipos
import {Pago} from "@/typings/store/plugins/easyFirestore/pagos";
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";

// Componentes
import ViewTitle from "@/components/custom/ViewTitle.vue";
import Footer from "@/components/appCorePagina/Footer.vue";
import TablaHistorial from "@/components/historial/TablaHistorial.vue";
import DialogoDetalleHistorial from "@/components/historial/DialogoDetalleHistorial.vue";

@Component({
  components: {
    Footer,
    ViewTitle,
    TablaHistorial,
    DialogoDetalleHistorial
  },
  computed: mapGetters({
    getUsuario: "usuario/get",
    getArrayPagos: "pagos/getArray",
    getRutasUsuario: "usuario/getRutas"
  }),
  beforeRouteEnter(_to, _from, next) {
    next(routeGuard);
  }
})
export default class VistaHistorial extends Vue {
  created(): void {
    if (!routeGuard(this)) return;
  }

  dialogoDetalle: {
    model: boolean;
    pago: Pago | null;
  } = {
    model: false,
    pago: null
  };

  get usuario(): Usuario | null {
    return this.getUsuario;
  }

  get pagos(): Pago[] {
    if (!this.usuario) return [];
    return this.getArrayPagos.filter(pago => {
      const flagEstado = pago.estado === "finalizado";
      const status = pago.resultado?.status ?? "FAILED";
      const flagError = !pago.error && !pago.resultado?.error;
      const flagUsuario = pago.cliente.id === this.usuario?.id;
      const flagResultado = status !== "FAILED" && status !== "NULLIFIED" && status !== "PARTIALLY_NULLIFIED";
      return flagUsuario && flagEstado && flagError && flagResultado;
    });
  }

  abrirDialogoDetalle(pago: Pago): void {
    this.dialogoDetalle = {
      pago,
      model: true
    };
  }

  cerrarDialogoDetalle(): void {
    this.dialogoDetalle = {
      model: false,
      pago: null
    };
  }

}
