
// Decoradores
import {Vue, Component} from "vue-property-decorator";

// Helpers
import routeGuard from "@/helpers/routeGuard";
import Calculadora from "@/helpers/Calculadora";

// Vuex
import {mapGetters} from "vuex";

// Tipos
import {DateGroup} from "@/typings/vista/monitoreo";
import {Curso, Cursos} from "@/typings/store/plugins/easyFirestore/cursos";
import {Inscripcion} from "@/typings/store/plugins/easyFirestore/inscripciones";
import {TipoUsuario, Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";

// Componentes
import EstadoCursos from "@/components/monitoreo/EstadoCursos.vue";
import SelectPeriodo from "@/components/monitoreo/SelectPeriodo.vue";
import GraficoVentas from "@/components/monitoreo/GraficoVentas.vue";
import TotalUsuarios from "@/components/monitoreo/TotalUsuarios.vue";
import GraficoUsuarios from "@/components/monitoreo/GraficoUsuarios.vue";
import CursosMonitoreo from "@/components/monitoreo/CursosMonitoreo.vue";
import GraficoComisiones from "@/components/monitoreo/GraficoComisiones.vue";
import GraficoEstadoCursos from "@/components/monitoreo/GraficoEstadoCursos.vue";
import RecaudacionGanancias from "@/components/monitoreo/RecaudacionGanancias.vue";
import TablaPromocionesUsadas from "@/components/monitoreo/TablaPromocionesUsadas.vue";


@Component({
  components: {
    EstadoCursos,
    TotalUsuarios,
    GraficoVentas,
    SelectPeriodo,
    CursosMonitoreo,
    GraficoUsuarios,
    GraficoComisiones,
    GraficoEstadoCursos,
    RecaudacionGanancias,
    TablaPromocionesUsadas
  },
  computed: mapGetters({
    getCursos: "cursos/get",
    getUsuario: "usuario/get",
    getArrayUsuarios: "usuarios/getArray",
    getArrayInscripciones: "inscripciones/getArray"
  }),
  beforeRouteEnter(_to, _from, next) {
    next(routeGuard);
  }
})
export default class VistaMonitoreo extends Vue {
  created(): void {
    if (!routeGuard(this)) return;
  }

  periodo = 0;

  get fechas(): Date[] {
    const inicio = new Date();
    const fin = new Date();
    fin.setHours(23, 59, 59, 999);
    switch (this.periodo) {
      case 0:
        inicio.setDate(inicio.getDate() - 6);
        break;
      case 1:
        inicio.setMonth(inicio.getMonth() - 1);
        break;
      case 2:
        inicio.setFullYear(inicio.getFullYear() - 1);
        break;
    }
    inicio.setHours(0, 0, 0, 0);
    return [inicio, fin];
  }

  get dateGroups(): DateGroup[] {
    const now = new Date();
    const dateGroups: DateGroup[] = [];
    if (this.periodo === 0) {
      for (let i = 6; i >= 0; i--) {
        const inicio = new Date();
        inicio.setDate(now.getDate() - i);
        inicio.setHours(0, 0, 0, 0);
        const fin = new Date(inicio);
        fin.setHours(23, 59, 59, 999);
        const key = inicio.format("DDD");
        dateGroups.push({
          id: key,
          end: fin,
          start: inicio
        });
      }
    } else if (this.periodo === 1) {
      for (let i = 28; i >= 0; i -= 2) {
        const inicio = new Date();
        inicio.setDate(now.getDate() - (i + 1));
        inicio.setHours(0, 0, 0, 0);
        const fin = new Date();
        fin.setDate(now.getDate() - i);
        fin.setHours(23, 59, 59, 999);
        const key = `${inicio.getDate()}-${fin.getDate()}\n${fin.format("MMM")}`;
        dateGroups.push({
          id: key,
          end: fin,
          start: inicio
        });
      }
    } else if (this.periodo === 2) {
      for (let i = 11; i >= 0; i--) {
        const inicio = new Date();
        inicio.setMonth(now.getMonth() - i);
        inicio.setDate(1);
        inicio.setHours(0, 0, 0, 0);
        const fin = new Date();
        fin.setMonth(now.getMonth() - (i - 1));
        fin.setDate(-1);
        fin.setHours(23, 59, 59, 999);
        const key = inicio.format("MMM");
        dateGroups.push({
          id: key,
          end: fin,
          start: inicio
        });
      }
    }
    return dateGroups;
  }

  get usuario(): Usuario | null {
    return this.getUsuario;
  }

  get tipoUsuario(): TipoUsuario | null {
    return this.usuario?.tipo ?? null;
  }

  get cursos(): Cursos {
    return this.getCursos;
  }

  get arrayCursos(): Curso[] {
    return Object.values(this.cursos).filter(_curso => {
      const fecha = new Date(_curso.inicio);
      const flagRelator = this.usuario?.id && !!_curso.relatores[this.usuario.id];
      const flagFecha = fecha.isBetween(this.fechas[0], this.fechas[1]);
      return flagRelator && flagFecha;
    });
  }

  get inscripciones(): Inscripcion[] {
    return this.getArrayInscripciones.filter(_inscripcion => {
      const fecha = new Date(_inscripcion.fecha);
      return fecha.isBetween(this.fechas[0], this.fechas[1]);
    });
  }

  get usuarios(): Usuario[] {
    return this.getArrayUsuarios.filter(_usuario => {
      const fecha = new Date(_usuario.fecha);
      return fecha.isBetween(this.fechas[0], this.fechas[1]);
    });
  }

  get calculadora(): Calculadora {
    return new Calculadora(this.inscripciones, this.cursos);
  }

  get classGraficoVentas(): { [key: string]: boolean } {
    return {
      'pt-6': true,
      'pr-lg-6': this.periodo !== 0,
      'px-lg-6 pt-lg-0': this.periodo === 0
    };
  }

  get classCursosMonitoreo(): { [key: string]: boolean } {
    return {
      'pt-lg-0 pt-6': true,
      'px-lg-6': this.tipoUsuario === "admin",
      'pr-lg-6': this.tipoUsuario !== "admin"
    };
  }
}
