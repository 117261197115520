
// Composition
import {computed, defineComponent, reactive, toRef} from "vue";

// Router
import router from "@/router";

// Tipos
import {Carrito} from "@/typings/store/plugins/easyFirestore/carritos";

// Componentes
import IconButton from "@/components/custom/IconButton.vue";

export default defineComponent({
  name: "BotonCarrito",
  components: {
    IconButton,
  },
  props: {
    carrito: {
      type: Object as () => Carrito,
    },
  },
  emits: [
    "click",
  ],
  setup(props, ctx) {
    // Data

    const location = toRef(reactive(router), "currentRoute");

    // Computed

    const cantidad = computed(() => {
      if (!props.carrito) return 0;
      return Object.values(props.carrito.cursos).length;
    });

    const iconColor = computed(() => location.value.name === "carrito" ? "accent" : "#EDF6FA");

    // Emits

    const onClick = () => {
      ctx.emit("click");
    };

    return {
      // Computed
      cantidad,
      iconColor,
      // Emits
      onClick,
    };
  },
});
