//tipos
import { GetterTree } from "vuex";
import { State } from "@/typings/store";
import {
  Pago,
  Pagos,
  StatePagos,
} from "@/typings/store/plugins/easyFirestore/pagos";
import { env } from "@/helpers/env";

const getters: GetterTree<StatePagos, State> = {
  get(_state, _getters) {
    return (_getters["getArray"] as Array<Pago>).reduce((docs, doc) => {
      if (!doc?.id) {
        console.error("doc.id == undefined => ", doc);
        return docs;
      }
      docs[doc.id] = doc;
      return docs;
    }, {} as Pagos);
  },
  getArray(_state) {
    return Object.values(_state.all).map((doc) => {
      return JSON.parse(JSON.stringify(doc)) as Pago;
    });
  },
  getArrayValidos(_state, _getters) {
    return (_getters["getArray"] as Array<Pago>).filter((item) => {
      return item.resultado?.response_code === 0;
    });
  },
};

const pagos = {
  firestorePath: `${env}/v1/pagos`,
  firestoreRefType: "collection",
  moduleName: "pagos",
  statePropName: "all",
  namespaced: true,
  serverChange: {
    convertTimestamps: {
      created_at: "%convertTimestamp%", // default
      updated_at: "%convertTimestamp%", // default
    },
  },
  state: {},
  getters,
  mutations: {},
  actions: {},
};

export default pagos;
