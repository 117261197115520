import * as pdfMakeLib from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";

interface VFS {
  [key: string]: string;
}

interface PDFMakeTemp {
  vfs: VFS;
}

const pdfMakeTemp = pdfMakeLib as unknown as PDFMakeTemp;
pdfMakeTemp.vfs = pdfFonts.pdfMake.vfs;

export const pdfMake = pdfMakeLib;
