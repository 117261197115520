import {ActionTree, GetterTree, MutationTree} from "vuex";

//tipos
import {State} from "@/typings/store";
import {StateRutas} from "@/typings/store/modules/rutas";

const state: StateRutas = {
  all: {
    ["usuarios"]: {
      number: 1,
      show: true,
      id: "usuarios",
      text: "usuarios",
      to: {name: "usuarios"}
    },
    ["cursos"]: {
      number: 2,
      show: true,
      id: "cursos",
      text: "cursos",
      to: {name: "cursos"}
    },
    ["agenda"]: {
      number: 3,
      show: true,
      id: "agenda",
      text: "agenda",
      to: {name: "agenda"}
    },
    ["inscripciones"]: {
      number: 4,
      show: true,
      id: "inscripciones",
      text: "inscripciones",
      to: {name: "inscripciones"}
    },
    ["pagos"]: {
      number: 5,
      show: true,
      id: "pagos",
      text: "pagos",
      to: {name: "pagos"}
    },
    ["promociones"]: {
      number: 6,
      show: true,
      id: "promociones",
      text: "promociones",
      to: {name: "promociones"}
    },
    ["monitoreo"]: {
      number: 7,
      show: true,
      id: "monitoreo",
      text: "monitoreo",
      to: {name: "monitoreo"}
    },
    ["contactos"]: {
      number: 8,
      show: true,
      id: "contactos",
      text: "contactos",
      to: {name: "contactos"}
    }
  }
};

const getters: GetterTree<StateRutas, State> = {
  get(_state) {
    return _state.all;
  },
  getArray(_state) {
    return Object.values(_state.all);
  }
};

const mutations: MutationTree<StateRutas> = {};

const actions: ActionTree<StateRutas, State> = {};

const rutas = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

export default rutas;
