
// Vue
import {computed, defineComponent, ref} from "vue";

// Vuex
import Store from "@/store";

// Vuetify
import vuetify from "@/plugins/vuetify";

// Router
import router from "@/router";

// Types
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";

// Componentes
import FormularioRegistro from "@/components/login/FormularioRegistro.vue";
import DialogoMensajeLogin from "@/components/login/DialogoMensajeLogin.vue";
import FormularioActivarCuenta from "@/components/login/FormularioActivarCuenta.vue";
import FormularioIniciarSesion from "@/components/login/FormularioIniciarSesion.vue";
import FormularioRecuperarClave from "@/components/login/FormularioRecuperarClave.vue";

function routeGuardLogin() {
  const usuario = Store.getters["usuario/get"];
  if (usuario) {
    const fallbacks: { [key: string]: string } = {
      "admin": "usuarios",
      "cliente": "inicio",
      "relator": "cursos"
    };
    const fallback = fallbacks[usuario.tipo];
    router.replace({name: fallback});
    return false;
  }
  return true;
}

export default defineComponent({
  name: "VistaLogin",
  beforeRouteEnter(_to, _from, next) {
    next(routeGuardLogin);
  },
  created() {
    if (!routeGuardLogin(this)) return;
  },
  components: {
    FormularioRegistro,
    DialogoMensajeLogin,
    FormularioIniciarSesion,
    FormularioActivarCuenta,
    FormularioRecuperarClave
  },
  setup() {
    // Vuex

    const actions = {
      signInUsuario: "usuario/signIn",
      signUpUsuario: "usuario/signUp",
      activarUsuario: "usuario/activar",
      signInUsuarioGoogle: "usuario/signInGoogle",
      signInUsuarioFacebook: "usuario/signInFacebook",
      recuperarPasswordUsuario: "usuario/recuperarPassword"
    };

    // Data

    const errorCode = ref("");
    const cargando = ref(false);
    const emailActivar = ref("");
    const formulario = ref<1 | 2 | 3 | 4>(1);
    const dialogoMensaje = ref({
      titulo: "",
      mensaje: "",
      model: false
    });

    // Computed

    const mobile = computed(() => vuetify.framework.breakpoint.mdAndDown);

    const errorLogin = computed(() => {
      switch (errorCode.value) {
        case "auth/invalid-email":
          return "El correo no es válido";
        case "auth/user-disabled":
          return "El usuario está deshabilitado";
        case "auth/user-not-found":
          return "El usuario no existe";
        case "auth/wrong-password":
          return "La contraseña es incorrecta";
        case "auth/weak-password":
          return "La contraseña es débil";
        case "auth/popup-blocked":
          return "El popup está bloqueado";
        case "auth/email-already-in-use":
          return "El correo ya está en uso";
        case "auth/operation-not-allowed":
          return "La operación no está permitida";
        case "auth/popup-closed-by-user":
          return "El popup fue cerrado";
        case "auth/cancelled-popup-request":
          return "La solicitud de inicio de sesión se ha cancelado";
        case "auth/account-exists-with-different-credential":
          return "El usuario ya existe con una cuenta diferente";
        case "auth/too-many-requests":
          return "Ha ocurrido un error, por favor inténtelo más tarde.";
        case "auth/passwords-not-equal":
          return "Las contraseñas no coinciden";
        default:
          if (errorCode.value === "") return "";
          return "error desconocido " + errorCode.value;
      }
    });

    // Methods

    const onClickCerrar = () => {
      router.back();
    };

    const iniciarSesion = async ({email, password}: { email: string; password: string }) => {
      errorCode.value = "";
      try {
        await Store.dispatch(actions.signInUsuario, {email, password});
      } catch (error) {
        if (error.code === "auth/first-time-login") {
          formulario.value = 3;
          emailActivar.value = email;
        } else {
          errorCode.value = error.code;
        }
      }
    };

    const iniciarSesionGoogle = async () => {
      errorCode.value = "";
      try {
        await Store.dispatch(actions.signInUsuarioGoogle);
      } catch (error) {
        errorCode.value = error.code;
      }
    };

    const iniciarSesionFacebook = async () => {
      errorCode.value = "";
      try {
        await Store.dispatch(actions.signInUsuarioFacebook);
      } catch (error) {
        errorCode.value = error.code;
      }
    };

    const registrarUsuario = async ({usuario, password}: { usuario: Usuario; password: string }) => {
      cargando.value = true;
      errorCode.value = "";
      try {
        await Store.dispatch(actions.signUpUsuario, {usuario, password});
      } catch (error) {
        errorCode.value = error.code;
        cargando.value = false;
      }
    };

    const activarCuenta = async ({password, confirmar}: { password: string; confirmar: string }) => {
      errorCode.value = "";
      cargando.value = true;
      if (password !== confirmar) {
        errorCode.value = "auth/passwords-not-equal";
        cargando.value = false;
        return;
      }
      try {
        await Store.dispatch(actions.activarUsuario, {email: emailActivar.value, password});
        dialogoMensaje.value = {
          model: true,
          titulo: "¡Cuanta activada con éxito!",
          mensaje: "Ya puedes disfrutar de todos los cursos disponibles en nuesta plataforma."
        };
      } catch (error) {
        cargando.value = false;
        errorCode.value = error.code;
      }
    };

    const recuperarCuenta = async (email: string) => {
      errorCode.value = "";
      cargando.value = true;
      try {
        await Store.dispatch(actions.recuperarPasswordUsuario, email);
        dialogoMensaje.value = {
          model: true,
          titulo: "¡Casi listo...!",
          mensaje: "Revisa tu correo con los pasos a seguir para recuperar tu contraseña."
        };
        formulario.value = 1;
        cargando.value = false;
      } catch (error) {
        cargando.value = false;
        errorCode.value = error.code;
      }
    };

    return {
      // Data
      cargando,
      errorCode,
      formulario,
      emailActivar,
      dialogoMensaje,
      // Computed
      mobile,
      errorLogin,
      // Methods
      activarCuenta,
      onClickCerrar,
      iniciarSesion,
      recuperarCuenta,
      registrarUsuario,
      iniciarSesionGoogle,
      iniciarSesionFacebook
    };
  }
});
