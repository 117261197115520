
// Decoradores
import {Component, Emit, Prop, Vue} from 'vue-property-decorator';

// Tipos
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";
import {Notificacion} from '@/typings/store/plugins/easyFirestore/notificaciones';
import {NotificacionesLeidas} from '@/typings/store/plugins/easyFirestore/notificacionesLeidas';

// Componentes
import SvgIcon from '@/components/custom/SvgIcon.vue';
import ItemNotificacion from "@/components/notificaciones/ItemNotificacion.vue";

@Component({
  components: {
    ItemNotificacion,
    SvgIcon,
  },
})
export default class TablaNotificaciones extends Vue {
  @Prop() readonly usuario!: Usuario | null;
  @Prop() readonly notificaciones!: Notificacion[];
  @Prop() readonly notificacionesLeidas!: NotificacionesLeidas;

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  @Emit("click:marcar-leida")
  onClickMarcarLeida(notificacion: Notificacion): Notificacion {
    return notificacion;
  }

  clickNotificacion(notificacion: Notificacion): void {
    this.onClickMarcarLeida(notificacion);
    if (notificacion.tipo === "curso-creado" || notificacion.tipo === "curso-publicado" || notificacion.tipo === "curso-comprado") {
      if (!notificacion.curso?.id) return;
      const id = notificacion.curso.id;
      if (this.usuario?.tipo !== "cliente") {
        this.$router.push({
          name: "cursos",
          params: {id},
        });
      } else {
        this.$router.push({
          name: 'informacionCompleta',
          params: {id},
        });
      }
    } else if (notificacion.tipo === "contacto-recibido") {
      this.$goTo("contactos");
    } else {
      this.$goTo("agenda");
    }
  }
}
