
// Decoradores
import {Component, Prop, Vue} from "vue-property-decorator";

// Helpers
import {toCurrency} from "@/helpers/number";

// Tipos
import {Curso, Cursos} from "@/typings/store/plugins/easyFirestore/cursos";
import {Inscripcion} from "@/typings/store/plugins/easyFirestore/inscripciones";
import {TipoUsuario, Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

interface Item {
  id: string;
  total: number;
  cupos: number;
  nombre: string;
  cantidad: number;
}

@Component({
  components: {
    SvgIcon,
  },
})
export default class CursosMonitoreo extends Vue {
  @Prop() readonly cursos!: Cursos;
  @Prop() readonly usuario!: Usuario | null;
  @Prop() readonly inscripciones!: Inscripcion[];
  @Prop() readonly tipoUsuario!: TipoUsuario | null;

  toCurrency = toCurrency;
  orderBy: "cantidad" | "total" = "cantidad";

  get titulo(): string {
    if (this.tipoUsuario === "admin") return "Cursos";
    return "Cursos con más inscritos";
  }

  get items(): Item[] {
    const items: { [key: string]: Item } = {};
    for (const inscripcion of this.inscripciones) {
      const promocion = inscripcion.pago.promocion;
      for (const id in inscripcion.pago.cursos) {
        const curso = this.cursos[id];
        if (!curso || !this.tieneRelator(curso)) continue;
        if (!items[id]) {
          items[id] = {
            id,
            total: 0,
            cantidad: 0,
            cupos: curso.cupos,
            nombre: curso.nombre,
          };
        }
        const descuento = (curso.precio * (promocion?.descuento?.toInt() ?? 0)) / 100;
        items[id].total += curso.precio - descuento;
        items[id].cantidad++;
      }
    }
    return Object.values(items).sort((_itemA, _itemB) => {
      if (this.orderBy === "cantidad") {
        return _itemB.cantidad - _itemA.cantidad;
      } else {
        return _itemB.total - _itemA.total;
      }
    }).slice(0, 5);
  }

  tieneRelator(curso: Curso): boolean {
    if (this.usuario?.tipo === "admin") return true;
    if (!this.usuario) return false;
    return !!curso.relatores[this.usuario.id];
  }
}
