import { Store } from "vuex";
import { State } from "@/typings/store";
import { Usuario } from "@/typings/store/plugins/easyFirestore/usuarios";
import modulos from "./modulos";

const cerrarCanalesBD = async (
  store: Store<State>,
  usuario: Usuario
): Promise<void> => {
  const promesas = modulos.map((modulo) => {
    const valido = modulo.permisos.every((permiso) => {
      return usuario.permisos[permiso];
    });
    if (valido) {
      return store.dispatch(modulo.nombre + "/closeDBChannel");
    }
    return null;
  });
  await Promise.all(promesas);
};

export default cerrarCanalesBD;
