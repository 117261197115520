
// Vue
import {defineComponent} from "vue";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "InicioRapido",
  components: {
    SvgIcon
  },
  emits: [
    "click:google",
    "click:facebook"
  ],
  setup(_props, ctx) {
    // Emits

    const onClickGoogle = () => {
      ctx.emit("click:google");
    };

    const onClickFacebook = () => {
      ctx.emit("click:facebook");
    };

    return {
      // Emits
      onClickGoogle,
      onClickFacebook
    };
  }
});
