
// Vue
import {computed, defineComponent} from "vue";

// Vuetify
import vuetify from "@/plugins/vuetify";

// Router
import router from "@/router";

// Tipos
import {ProviderId} from "@/typings/store/modules/usuario";
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "BotonIniciarSesion",
  components: {
    SvgIcon
  },
  props: {
    usuario: {
      default: null,
      type: Object as () => Usuario | null
    },
    providerId: {
      default: null,
      type: String as () => ProviderId | null
    },
    editando: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    "click:cerrar-sesion",
    "click:iniciar-sesion"
  ],
  setup(props, ctx) {
    // Computed

    const mobile = computed(() => vuetify.framework.breakpoint.mdAndDown);

    const tipoUsuario = computed(() => props.usuario?.tipo ?? null);

    const texto = computed(() => props.usuario?.nombre?.capitalizeFirstLetter() ?? "Iniciar sesión");

    const imagen = computed(() => props.usuario?.perfil?.imagen?.url ?? null);

    const disabled = computed(() => !props.usuario || mobile.value || props.usuario?.tipo === 'admin' || props.editando);

    // Methods

    const clickIniciarSesion = () => {
      if (props.editando) return;
      if (!props.usuario || mobile.value) {
        onClickIniciarSesion();
      } else if (props.usuario?.tipo === 'admin') {
        router.push({name: 'usuarios'});
      }
    };

    // Emits

    const onClickIniciarSesion = () => {
      ctx.emit("click:iniciar-sesion");
    };

    const onClickCerrarSesion = () => {
      ctx.emit("click:cerrar-sesion");
    };

    return {
      // Computed
      texto,
      mobile,
      imagen,
      disabled,
      tipoUsuario,
      // Methods
      clickIniciarSesion,
      // Emits
      onClickCerrarSesion,
      onClickIniciarSesion
    };
  }
});
