
// Vue
import {computed, defineComponent} from "vue";

// Tipos
import {Notificacion} from "@/typings/store/plugins/easyFirestore/notificaciones";
import {NotificacionesLeidas} from "@/typings/store/plugins/easyFirestore/notificacionesLeidas";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "ItemNotificacion",
  components: {SvgIcon},
  props: {
    notificacion: {
      required: true,
      type: Object as () => Notificacion,
    },
    notificacionesLeidas: {
      default: null,
      type: Object as () => NotificacionesLeidas | null,
    },
  },
  emits: [
    "click:marcar-leida",
  ],
  setup(props, ctx) {
    // Computed

    const icono = computed(() => {
      const tipo = props.notificacion.tipo;
      if (tipo === "curso-creado" || tipo === "curso-publicado") return "fal-video";
      if (tipo === "clase-reprogramada") return "fal-clock";
      if (tipo === "recordatorio-clase") return "fal-circle-play";
      if (tipo === "curso-comprado") return "fal-graduation-cap";
      return "fal-id-badge";
    });

    const noLeida = computed(() => {
      if (!props.notificacion.id || !props.notificacionesLeidas) return true;
      return !props.notificacionesLeidas[props.notificacion.id];
    });

    // Emits

    const onClickMarcarLeida = () => {
      ctx.emit("click:marcar-leida", props.notificacion);
    };

    return {
      // Computed
      icono,
      noLeida,
      // Emits
      onClickMarcarLeida,
    };
  },
});
