
// Vue
import {computed, defineComponent} from "vue";

// Componentes
import ViewTitle from "@/components/custom/ViewTitle.vue";
import TextButton from "@/components/custom/TextButton.vue";

// Tipos
import {TipoUsuario} from "@/typings/store/plugins/easyFirestore/usuarios";
import vuetify from "@/plugins/vuetify";

export default defineComponent({
  name: "TituloPerfil",
  components: {
    ViewTitle,
    TextButton,
  },
  props: {
    tipoUsuario: {
      required: true,
      type: String as () => TipoUsuario,
    },
    buttonDisabled: {
      type: Boolean,
      required: true,
    },
    cargando: {
      type: Boolean,
      required: true,
    },
    editando: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    "click:editar",
  ],
  setup(_props, ctx) {
    // Computed

    const mobile = computed(() => vuetify.framework.breakpoint.mdAndDown);

    // Emits

    const onClickEditar = () => {
      ctx.emit("click:editar");
    };

    return {
      // Computed
      mobile,
      // Emits
      onClickEditar,
    };
  },
});
