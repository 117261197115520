// vuex
import { GetterTree } from "vuex";
// env
import { env } from "@/helpers/env";
//tipos
import { State } from "@/typings/store";
import {
  Notificacion,
  Notificaciones,
  StateNotificaciones,
} from "@/typings/store/plugins/easyFirestore/notificaciones";
import { NotificacionesLeidas } from "@/typings/store/plugins/easyFirestore/notificacionesLeidas";

const getters: GetterTree<StateNotificaciones, State> = {
  get(_state, _getters) {
    return (_getters["getArray"] as Array<Notificacion>).reduce((docs, doc) => {
      if (!doc?.id) {
        console.error("doc.id == undefined => ", doc);
        return docs;
      }
      docs[doc.id] = doc;
      return docs;
    }, {} as Notificaciones);
  },
  getArray(_state) {
    return Object.values(_state.all).map((doc) => {
      return JSON.parse(JSON.stringify(doc)) as Notificacion;
    });
  },
  getNoLeidos(_state, _getters, _rootState, _rootGetters) {
    const notificacionesLeidas = _rootGetters[
      "notificacionesLeidas/get"
    ] as NotificacionesLeidas;
    const arrayNotificaciones = _getters["getArray"] as Array<Notificacion>;
    return arrayNotificaciones.filter((notificacion) => {
      if (!notificacion?.id) {
        console.error("notificacion.id == undefined => ", notificacion);
        return false;
      }
      return !notificacionesLeidas[notificacion.id];
    });
  },
};

const notificaciones = {
  firestorePath: `${env}/v1/notificaciones`,
  firestoreRefType: "collection",
  moduleName: "notificaciones",
  statePropName: "all",
  namespaced: true,
  serverChange: {
    convertTimestamps: {
      created_at: "%convertTimestamp%", // default
      updated_at: "%convertTimestamp%", // default
    },
  },
  state: {},
  getters,
  mutations: {},
  actions: {},
};

export default notificaciones;
