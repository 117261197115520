
// Decoradores
import {Component, Emit, Prop, VModel, Vue, Watch} from "vue-property-decorator";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon
  }
})
export default class MessageDialog extends Vue {
  @VModel() model!: boolean;
  @Prop() readonly message!: string;

  @Watch("model")
  onModelChange(value: boolean): void {
    if (value) {
      window.setTimeout(() => {
        this.onClickCerrar();
      }, 3000);
    }
  }

  @Emit("click:cerrar")
  onClickCerrar(): void {
    this.model = false;
  }
}
