import Vue from "vue";
import VueRouter, {RouteConfig} from "vue-router";
// modulos
import pagos from "./pagos";
import cursos from "./cursos";
import perfil from "./perfil";
import usuarios from "./usuarios";
import monitoreo from "./monitoreo";
import contactos from "./contactos";
import agenda from "@/router/agenda";
import inicio from "@/router/inicio";
import cuenta from "@/router/cuenta";
import carrito from "@/router/carrito";
import promociones from "./promociones";
import reproductor from "./reproductor";
import misCursos from "@/router/misCursos";
import relatores from "@/router/relatores";
import historial from "@/router/historial";
import inscripciones from "./inscripciones";
import notificaciones from "./notificaciones";
import quienesSomos from "@/router/quienesSomos";
import cursosDisponibles from "./cursosDisponibles";
import resultadoCompra from "@/router/resultadoCompra";
import informacionCompleta from "./informacionCompleta";
import terminosCondiciones from "@/router/terminosCondiciones";
import iniciarSesion from "@/router/iniciarSesion";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  pagos,
  cursos,
  perfil,
  agenda,
  cuenta,
  inicio,
  carrito,
  usuarios,
  historial,
  relatores,
  misCursos,
  monitoreo,
  contactos,
  promociones,
  reproductor,
  quienesSomos,
  iniciarSesion,
  inscripciones,
  notificaciones,
  resultadoCompra,
  cursosDisponibles,
  informacionCompleta,
  terminosCondiciones
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
