import { RouteConfig } from "vue-router";
import Notificaciones from "@/views/VistaNotificaciones.vue";

const notificaciones: RouteConfig = {
    path: "/notificaciones",
    name: "notificaciones",
    component: Notificaciones,
};

export default notificaciones;
