
// Vue
import {computed, defineComponent, ref} from "vue";

// Router

import router from "@/router";

// Tipos
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import TextButton from "@/components/custom/TextButton.vue";
import PageTextField from "@/components/custom/PageTextField.vue";

export default defineComponent({
  name: "FormularioRegistro",
  components: {
    SvgIcon,
    TextButton,
    PageTextField
  },
  props: {
    error: {
      type: String,
      required: true
    },
    mobile: {
      type: Boolean
    },
    cargando: {
      type: Boolean
    }
  },
  emits: [
    "click:cerrar",
    "click:registrar",
    "click:iniciar-sesion"
  ],
  setup(_props, ctx) {
    // Data

    const email = ref("");
    const nombre = ref("");
    const password = ref("");
    const showingPassword = ref(false);

    // Computed

    const buttonDisabled = computed(() => !email.value || !password.value || !nombre.value);

    const inputTypePassword = computed(() => showingPassword.value ? "text" : "password");

    const appendIconPassword = computed(() => showingPassword.value ? "far-eye-slash" : "far-eye");

    // Methods

    const irTerminosYCondiciones = () => {
      const currentRoute = router.currentRoute;
      if (currentRoute.name !== "terminosCondiciones") {
        router.push({name: "terminosCondiciones"});
      }
      onClickCerrar();
    };

    const clickShowPassword = () => {
      showingPassword.value = !showingPassword.value;
    };

    const registrarse = () => {
      const usuario: Usuario = {
        id: "",
        uid: "",
        rut: "",
        email: email.value,
        nombre: nombre.value,
        perfil: {
          ciudad: null,
          imagen: null,
          telefono: null,
          profesion: null,
          antecedentes: null
        },
        tipo: "cliente",
        fecha: new Date().toISOString(),
        permisos: {
          perfil: true,
          inicio: true,
          cuenta: true,
          agenda: true,
          carrito: true,
          misCursos: true,
          historial: true,
          relatores: true,
          reproductor: true,
          quienesSomos: true,
          notificaciones: true,
          resultadoCompra: true,
          cursosDisponibles: true,
          informacionCompleta: true,
          terminosCondiciones: true
        },
        estado: "libre",
        token: null
      };
      onClickRegistrar(usuario);
    };

    // Emits

    const onClickRegistrar = (usuario: Usuario) => {
      ctx.emit("click:registrar", {
        usuario,
        password: password.value
      });
    };

    const onClickIniciarSesion = () => {
      ctx.emit("click:iniciar-sesion");
    };

    const onClickCerrar = () => {
      ctx.emit("click:cerrar");
    };

    return {
      // Data
      email,
      nombre,
      password,
      showingPassword,
      // Computed
      buttonDisabled,
      inputTypePassword,
      appendIconPassword,
      // Methods
      registrarse,
      clickShowPassword,
      irTerminosYCondiciones,
      // Emits
      onClickCerrar,
      onClickRegistrar,
      onClickIniciarSesion
    };
  }
});
