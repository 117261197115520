
// Vue
import {computed, defineComponent} from "vue";

// Vuetify
import vuetify from "@/plugins/vuetify";

// Componentes
import TextButton from "@/components/custom/TextButton.vue";

export default defineComponent({
  name: "ConfirmationDialog",
  components: {
    TextButton
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  },
  emits: [
    "click:cancel",
    "click:confirm"
  ],
  setup(props, ctx) {
    // VModel

    const model = computed({
      get() {
        return props.value;
      },
      set(value: boolean) {
        ctx.emit("input", value);
      }
    });

    // Computed

    const mobile = computed(() => vuetify.framework.breakpoint.mdAndDown);

    // Emits

    const onClickConfirm = () => {
      ctx.emit("click:confirm");
    };

    const onClickCancel = () => {
      ctx.emit("click:cancel");
    };

    return {
      // VModel
      model,
      // Computed
      mobile,
      // Emits
      onClickCancel,
      onClickConfirm
    };
  }
});
