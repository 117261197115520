
// Decoradores
import {Component, Vue} from "vue-property-decorator";

// Helpers
import {toFormatString} from "@/helpers/date";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import IconButton from "@/components/custom/IconButton.vue";

@Component({
  components: {
    IconButton,
    SvgIcon
  }
})
export default class Footer extends Vue {

  get year(): string {
    return toFormatString(new Date(), "YYYY");
  }

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }
}
