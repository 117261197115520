
// Vue
import {computed, defineComponent, reactive, ref, toRef} from "vue";

// Router
import router from "@/router";

// Vuetify
import vuetify from "@/plugins/vuetify";

// Tipos
import {Notificacion} from "@/typings/store/plugins/easyFirestore/notificaciones";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import IconButton from "@/components/custom/IconButton.vue";
import ItemNotificacion from "@/components/notificaciones/ItemNotificacion.vue";

export default defineComponent({
  name: "MenuNotificaciones",
  components: {
    SvgIcon,
    IconButton,
    ItemNotificacion,
  },
  props: {
    items: {
      required: true,
      type: Array as () => Notificacion[],
    },
    cliente: {
      type: Boolean,
    },
    type: {
      required: true,
      type: String as () => "app" | "page",
    },
  },
  emits: [
    "click:notificacion",
    "click:marcar-todas-leidas",
  ],
  setup(props, ctx) {
    // Data

    const menu = ref(false);
    const location = toRef(reactive(router), "currentRoute");

    // Computed

    const mobile = computed(() => vuetify.framework.breakpoint.mdAndDown);

    const iconColor = computed(() => {
      if (props.type === "app") {
        return location.value.name === "notificaciones" ? "#4E99FF" : "accent";
      } else {
        return location.value.name === "notificaciones" ? "accent" : "#EDF6FA";
      }
    });

    const iconSize = computed(() => props.type === "app" ? '1.5rem' : '1.875rem');

    const color = computed(() => {
      if (!props.items) return "transparent";
      return props.items.length > 0 ? "alternative" : "transparent";
    });

    const listaVacia = computed(() => !props.items || props.items.length === 0);

    // Methods

    const irNotificaciones = () => {
      if (!listaVacia.value) return;
      router.push({name: "notificaciones"});
    };

    // Emits

    const onClickNotificacion = (notificacion: Notificacion) => {
      menu.value = false;
      ctx.emit("click:notificacion", notificacion);
    };

    const onClickMarcarTodasLeidas = () => {
      menu.value = false;
      ctx.emit("click:marcar-todas-leidas");
    };

    return {
      // Data
      menu,
      location,
      // Computed
      color,
      mobile,
      iconSize,
      iconColor,
      listaVacia,
      // Methods
      irNotificaciones,
      // Emits
      onClickNotificacion,
      onClickMarcarTodasLeidas,
    };
  },
});
