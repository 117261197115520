
// Decoradores
import {Component, Prop, Vue} from "vue-property-decorator";

// Helpers
import {toCurrency} from "@/helpers/number";

// Tipos
import {DataTableHeader} from "vuetify";
import {Pago} from "@/typings/store/plugins/easyFirestore/pagos";
import {Cursos} from "@/typings/store/plugins/easyFirestore/cursos";
import {Inscripcion} from "@/typings/store/plugins/easyFirestore/inscripciones";

interface ItemHelper {
  id: string;
  usada: number;
  total: number;
  nombre: string;
}

interface Item {
  id: string;
  usada: number;
  total: string;
  numero: number;
  nombre: string;
}

@Component
export default class TablaPromocionesUsadas extends Vue {
  @Prop() readonly cursos!: Cursos;
  @Prop() readonly inscripciones!: Inscripcion[];

  get headers(): DataTableHeader[] {
    return [{
      text: "",
      value: "numero"
    }, {
      value: "nombre",
      text: "Promoción"
    }, {
      value: "usada",
      text: "Veces usada"
    }, {
      text: "Total",
      value: "total"
    }];
  }

  get promociones(): Item[] {
    const promociones: { [id: string]: ItemHelper } = {};
    for (const inscripcion of this.inscripciones) {
      const promocion = inscripcion.pago.promocion;
      if (!promocion?.id) continue;
      const totalPago = this.calcularTotalPago(inscripcion.pago);
      const descuento = (promocion.descuento.toInt() * totalPago) / 100;
      if (!promociones[promocion.id]) {
        const data: ItemHelper = {
          usada: 0,
          total: 0,
          id: promocion.id,
          nombre: promocion.nombre
        };
        this.$set(promociones, promocion.id, data);
      }
      promociones[promocion.id].usada++;
      promociones[promocion.id].total += descuento;
    }
    const ordenadas = Object.values(promociones).sort((_promocionA, _promocionB) => _promocionB.usada - _promocionA.usada).slice(0, 5);
    return ordenadas.map((_promocion, index) => ({
      numero: index + 1,
      id: _promocion.id,
      usada: _promocion.usada,
      nombre: _promocion.nombre,
      total: toCurrency(_promocion.total)
    }));
  }

  calcularTotalPago(pago: Pago): number {
    let total = 0;
    for (const id in pago.cursos) {
      const curso = this.cursos[id];
      total += curso.precio;
    }
    return total;
  }

}
