
// Decoradores
import {Component, Emit, Prop, Vue} from "vue-property-decorator";

// Vuex
import {mapActions, mapGetters} from "vuex";

// Tipos
import {Ruta} from "@/typings/store/modules/rutas";
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";
import {Notificacion} from "@/typings/store/plugins/easyFirestore/notificaciones";
import {NotificacionesLeidas, NotificacionLeida} from "@/typings/store/plugins/easyFirestore/notificacionesLeidas";

// Componentes
import LogoApp from "@/components/custom/LogoApp.vue";
import AccionesAppBar from "./appBar/AccionesAppBar.vue";
import NavegacionAppBar from "./appBar/NavegacionAppBar.vue";
import MenuMovil from "@/components/appCore/appBar/MenuMovil.vue";

@Component({
  components: {
    LogoApp,
    MenuMovil,
    AccionesAppBar,
    NavegacionAppBar,
  },
  methods: mapActions({
    setNotificacionLeida: "notificacionesLeidas/set",
  }),
  computed: mapGetters({
    getNotificacionesLeidas: "notificacionesLeidas/get",
  }),
})
export default class AppBar extends Vue {
  @Prop() readonly usuario!: Usuario | null;
  @Prop() readonly rutasUsuario!: Array<Ruta>;
  @Prop() readonly notificacionesPendientes!: Notificacion[];

  rutasSubmenu: Ruta[] = [{
    id: "perfil",
    text: "Perfil",
    to: {
      name: "perfil",
    },
    show: true,
  }, {
    id: "notificaciones",
    text: "Notificaciones",
    to: {
      name: "notificaciones",
    },
    show: true,
  }];

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get rutas(): Array<Ruta> {
    return this.rutasUsuario.filter((ruta) => ruta.show);
  }

  get notificacionesLeidas(): NotificacionesLeidas {
    return this.getNotificacionesLeidas;
  }

  get imagenAvatar(): string {
    const imagen = this.usuario?.perfil.imagen?.url;
    if (!imagen) return "";
    return imagen;
  }

  get nombreUsuario(): string {
    const nombre = this.usuario?.nombre;
    if (!nombre) return "Anónimo";
    return nombre;
  }

  get tipoUsuario(): "clientes" | "admins" | "relatores" {
    if (!this.usuario) return "clientes";
    switch (this.usuario.tipo) {
      case "admin":
        return "admins";
      case "relator":
        return "relatores";
      default:
        return "clientes";
    }
  }

  get itemsNotificacionesPendientes(): Notificacion[] {
    if (!this.notificacionesPendientes) return [];
    return this.notificacionesPendientes.filter(_notificacion => {
      if (!this.usuario) return false;
      const flagTodos = _notificacion.topico === "todos";
      const flagTipo = _notificacion.topico === this.tipoUsuario;
      const flagNoAutor = _notificacion.autor?.id !== this.usuario.id;
      const flagUsuarios = !!_notificacion.usuarios?.[this.usuario.id];
      return flagNoAutor && (flagTodos || flagTipo || flagUsuarios);
    }).sort((a, b) => b.fecha.toDate().getTime() - a.fecha.toDate().getTime()).slice(0, 5);
  }

  @Emit("click:sign-out")
  onClickSignOut(): void {
    return;
  }

  clickNotificacion(notificacion: Notificacion): void {
    this.marcarLeida(notificacion);
    if (notificacion.tipo === "curso-creado" || notificacion.tipo === "curso-publicado" || notificacion.tipo === "curso-comprado") {
      if (!notificacion.curso?.id) return;
      const id = notificacion.curso.id;
      if (this.usuario?.tipo !== "cliente") {
        this.$router.push({
          name: "cursos",
          params: {id},
        });
      } else {
        this.$router.push({
          name: 'informacionCompleta',
          params: {id},
        });
      }
    } else if (notificacion.tipo === "contacto-recibido") {
      this.$goTo("contactos");
    } else {
      this.$goTo("agenda");
    }
  }

  marcarLeida(notificacion: Notificacion): void {
    if (!notificacion.id) return;
    if (this.notificacionesLeidas[notificacion.id]) return;
    const notificacionLeida: NotificacionLeida = {
      id: notificacion.id,
      fecha: new Date().toISOString(),
    };
    this.setNotificacionLeida(notificacionLeida);
  }

  marcarTodasLeidas(): void {
    for (const notificacion of this.itemsNotificacionesPendientes) {
      this.marcarLeida(notificacion);
    }
  }
}
