import { render, staticRenderFns } from "./ResultadoError.vue?vue&type=template&id=2f8bcff4&scoped=true&"
import script from "./ResultadoError.vue?vue&type=script&lang=ts&"
export * from "./ResultadoError.vue?vue&type=script&lang=ts&"
import style0 from "./ResultadoError.vue?vue&type=style&index=0&id=2f8bcff4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f8bcff4",
  null
  
)

export default component.exports