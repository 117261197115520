
// Decoradores
import {Component, Emit, Prop, Vue} from 'vue-property-decorator';

// Tipos
import {DataTableHeader} from "vuetify";
import {Usuario} from '@/typings/store/plugins/easyFirestore/usuarios';
import {ItemTablaUsuarios} from '@/typings/components/usuarios/tabla';

// Componentes
import MenuButton from "@/components/custom/MenuButton.vue";
import IconButton from "@/components/custom/IconButton.vue";
import StatusCircle from "@/components/custom/StatusCircle.vue";
import PageTableItem from "@/components/custom/PageTableItem.vue";
import DataTable from "@/components/custom/DataTable.vue";

@Component({
  components: {
    DataTable,
    IconButton,
    MenuButton,
    StatusCircle,
    PageTableItem
  }
})
export default class TablaUsuarios extends Vue {
  @Prop() readonly buscar!: string;
  @Prop() readonly usuarios!: Array<Usuario>;

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get headers(): DataTableHeader[] {
    if (!this.mobile) {
      return [{
        text: "",
        value: "estado",
        sortable: false
      }, {
        text: "Nombre",
        value: "nombre",
        cellClass: "text-capitalize"
      }, {
        text: "RUT",
        value: "rut",
        sort: (a, b) => {
          if (a === "-") return -1;
          if (b === "-") return 1;
          const [rutA] = a.split("-");
          const [rutB] = b.split("-");
          return rutA.toInt() - rutB.toInt();
        }
      }, {
        text: "Tipo",
        value: "tipo"
      }, {
        text: "E-mail",
        value: "email"
      }, {
        text: "Fecha creación",
        value: "fechaCreacion",
        sort: (a, b) => {
          const fechaA = a.toDate();
          const fechaB = b.toDate();
          return fechaA.getTime() - fechaB.getTime();
        }
      }, {
        text: "",
        sortable: false,
        value: "acciones",
        cellClass: "justify-end"
      }];
    }
    return [{
      text: "",
      value: "estado",
      sortable: false
    }, {
      text: "Nombre",
      value: "nombre",
      cellClass: "text-capitalize"
    }, {
      text: "Tipo",
      value: "tipo"
    }, {
      text: "",
      sortable: false,
      value: "acciones",
      cellClass: "justify-end"
    }];
  }

  get items(): ItemTablaUsuarios[] {
    return this.usuarios.map(usuario => {
      const id = usuario.id;
      const email = usuario.email;
      const nombre = usuario.nombre;
      const estado = usuario.estado;
      const rut = usuario.rut?.toRUT() || "-";
      const tipo = usuario.tipo.capitalizeFirstLetter();
      const fechaCreacion = usuario.fecha.toDate().format("DD/MM/YYYY");
      return {
        id,
        rut,
        tipo,
        email,
        nombre,
        estado,
        fechaCreacion,
        data: usuario
      };
    });
  }

  get templateColumns(): string {
    if (this.mobile) {
      return "2rem 1fr 1fr 2.25rem";
    } else {
      return "2rem 1fr 1fr 1fr 1fr 1fr 0.75fr";
    }
  }

  get iconSize(): string {
    return this.mobile ? "1.875rem" : "1.25rem";
  }

  @Emit("click:informacion")
  onClickInformacion({data}: ItemTablaUsuarios): Usuario {
    return data;
  }

  @Emit("click:editar")
  onClickEditar({data}: ItemTablaUsuarios): Usuario {
    return data;
  }

  @Emit("click:cambiar-estado")
  onClickCambiarEstado({data}: ItemTablaUsuarios): Usuario {
    return data;
  }

  @Emit("click:eliminar")
  onClickEliminar({data}: ItemTablaUsuarios): Usuario {
    return data;
  }

  getTextoEstado({data}: ItemTablaUsuarios): string {
    if (!data.uid) return "Inactivo";
    return data.estado === "libre" ? "Habilitado" : "Deshabilitado";
  }

  getColorEstado({data}: ItemTablaUsuarios): string {
    if (!data.uid) return "#707070";
    return data.estado === "libre" ? "#279B18" : "#BD4F6C";
  }

}
