
// Decoradores
import {Component, Emit, Prop, Vue} from "vue-property-decorator";

// Tipos
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";

@Component
export default class CardRelator extends Vue {
  @Prop() readonly relator!: Usuario;

  get imagen(): string {
    return this.relator.perfil?.imagen?.url || "";
  }

  get nombre(): string {
    return this.relator.nombre || "";
  }

  get profesion(): string {
    return this.relator.perfil?.profesion || "Sin profesión";
  }

  @Emit("click")
  onClick(): void {
    return;
  }
}
