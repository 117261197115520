
// Decoradores
import {Component, Prop, Vue} from "vue-property-decorator";

// Tipos
import {Carrito, CursoCarrito} from "@/typings/store/plugins/easyFirestore/carritos";

@Component
export default class ResumenCarrito extends Vue {
  @Prop() readonly carrito!: Carrito | null;


  get cursos(): Array<CursoCarrito> {
    if (!this.carrito) return [];
    return Object.values(this.carrito.cursos);
  }

  get subtotal(): number {
    let total = 0;
    for (const cursoCarrito of this.cursos) {
      total += cursoCarrito.curso.precioRef ?? cursoCarrito.curso.precio;
    }
    return total;
  }

  get descuentoCursos(): number {
    let total = 0;
    for (const cursoCarrito of this.cursos) {
      if (!cursoCarrito.curso.precioRef) continue;
      total += cursoCarrito.curso.precioRef - cursoCarrito.curso.precio;
    }
    return total;
  }

  get descuentoPromocion(): number {
    if (!this.carrito?.promocion?.descuento) return 0;
    const promocion = this.carrito.promocion;
    const tipo = promocion.descuento.endsWith("%") ? "%" : "$";
    const descuento = promocion.descuento.toInt();
    if (tipo === "%") {
      return (this.subtotal * descuento) / 100;
    } else {
      return promocion.descuento.toInt();
    }
  }

  get codigoDescuento(): string {
    return this.carrito?.promocion?.codigo ?? "-";
  }

  get total(): number {
    let total = 0;
    for (const cursoCarrito of this.cursos) {
      total += cursoCarrito.curso.precio;
    }
    return total - this.descuentoPromocion;
  }

}
