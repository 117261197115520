
// Vue
import {computed, defineComponent} from "vue";

// Vuetify
import vuetify from "@/plugins/vuetify";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "TextButton",
  components: {
    SvgIcon
  },
  props: {
    height: {
      type: String
    },
    mobileHeight: {
      type: String
    },
    width: {
      type: String
    },
    mobileWidth: {
      type: String
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "primary"
    },
    textColor: {
      type: String,
      default: "white"
    }
  },
  emits: [
    "click"
  ],
  setup(props, ctx) {
    // Computed

    const textButtonStyle = computed(() => {
      const width = innerWidth.value;
      const height = innerheight.value;
      const color = theme.value[props.textColor] || props.textColor;
      const backgroundColor = !props.disabled ? theme.value[props.color] || props.color : "#EBEBEB" ;
      return {
        width,
        color,
        height,
        backgroundColor
      };
    });

    const textButtonClass = computed(() => ({
      'text-button__container': true,
      'text-button__container--disabled': props.disabled
    }));

    const theme = computed(() => vuetify.framework.theme.themes.light);

    const mobile = computed(() => vuetify.framework.breakpoint.mdAndDown);

    const innerheight = computed(() => {
      const iHeight = props.height || '3rem';
      if (props.mobileHeight && mobile.value) return props.mobileHeight;
      return iHeight;
    });

    const innerWidth = computed(() => {
      const iWidth = props.width || 'auto';
      if (props.mobileWidth && mobile.value) return props.mobileWidth;
      return iWidth;
    });

    // Emits

    const onClick = () => {
      return ctx.emit("click");
    };

    return {
      // Computed
      textButtonStyle,
      textButtonClass,
      // Emits
      onClick
    };
  }
});
