
// Decoradores
import {Vue, Component} from 'vue-property-decorator';

// Vuex
import {mapActions, mapGetters} from 'vuex';

// Helpers
import routeGuard from "@/helpers/routeGuard";

// Tipos
import {Contacto} from '@/typings/store/plugins/easyFirestore/contactos';
import {TituloContactosData} from "@/typings/components/contactos/tituloContactos";

// Componentes
import MessageDialog from "@/components/custom/MessageDialog.vue";
import TablaContactos from "@/components/contactos/TablaContactos.vue";
import TituloContactos from "@/components/contactos/TituloContactos.vue";
import FiltrosContactos from "@/components/contactos/FiltrosContactos.vue";
import DialogoFiltrosContactos from "@/components/contactos/DialogoFiltrosContactos.vue";
import NoData from "@/components/custom/NoData.vue";

@Component({
  components: {
    NoData,
    MessageDialog,
    TablaContactos,
    TituloContactos,
    FiltrosContactos,
    DialogoFiltrosContactos
  },
  computed: mapGetters({
    getUsuario: 'usuario/get',
    getArrayContactos: 'contactos/getArray'
  }),
  methods: mapActions({
    deleteContacto: 'contactos/delete'
  }),
  beforeRouteEnter(_to, _from, next) {
    next(routeGuard);
  }
})
export default class VistaContactos extends Vue {
  created(): void {
    if (!routeGuard(this)) return;
  }

  filtroAsunto = "";
  dialogoFiltros = false;
  dataTitulo: TituloContactosData = {
    ordenar: 0,
    buscar: ""
  };
  dialogoMensaje = {
    mensaje: "",
    model: false
  };

  get disabled(): boolean {
    return this.contactos.length === 0 && !this.buscar && !this.filtroAsunto;
  }

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get buscar(): string {
    return this.dataTitulo.buscar?.toLowerCase() ?? "";
  }

  get contactos(): Contacto[] {
    return this.getArrayContactos.filter(_contacto => {
      const datos = [
        _contacto.email.toLowerCase(),
        _contacto.nombre.toLowerCase(),
        _contacto.asunto.toLowerCase(),
        _contacto.mensaje.toLowerCase(),
        _contacto.telefono.toLowerCase()
      ];
      const flagFiltro = this.filtroAsunto ? _contacto.asunto === this.filtroAsunto : true;
      const flagBuscar = this.buscar ? datos.some(dato => dato.includes(this.buscar)) : true;
      return flagFiltro && flagBuscar;
    }).sort((_contactoA, _contactoB) => {
      const fechaA = new Date(_contactoA.fecha).getTime();
      const fechaB = new Date(_contactoB.fecha).getTime();
      return (fechaB - fechaA) * this.dataTitulo.ordenar;
    });
  }

  mostrarMensaje(mensaje: string): void {
    this.dialogoMensaje = {
      mensaje,
      model: true
    };
  }

  copiar({texto, nombre}: { texto: string; nombre: string }): void {
    navigator.clipboard.writeText(texto);
    this.mostrarMensaje(`${nombre} copiado al portapapeles`);
  }

  aplicarFiltros(filtroAsunto: string): void {
    this.filtroAsunto = filtroAsunto;
  }
}
