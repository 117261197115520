
// Vue
import {defineComponent} from "vue";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import IconButton from "@/components/custom/IconButton.vue";

export default defineComponent({
  name: "SignOutButton",
  components: {
    SvgIcon,
    IconButton,
  },
  emits: [
    "click",
  ],
  setup(_props, ctx) {
    // Emits

    const onClick = () => {
      ctx.emit("click");
    };

    return {
      // Emits
      onClick,
    };
  },
});
