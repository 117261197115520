
// Decoradores
import {Component, Emit, Prop, Vue} from "vue-property-decorator";

// Tipos
import {DataTableHeader} from "vuetify";
import {ItemTablaInscripciones} from "@/typings/components/inscripciones/tablaInscripciones";

// Componentes
import DataTable from "@/components/custom/DataTable.vue";
import IconButton from "@/components/custom/IconButton.vue";
import PageTableItem from "@/components/custom/PageTableItem.vue";

@Component({
  components: {
    DataTable,
    IconButton,
    PageTableItem
  }
})
export default class TablaInscripciones extends Vue {
  @Prop() readonly buscar!: string;
  @Prop() readonly inscripciones!: ItemTablaInscripciones[];

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get headers(): DataTableHeader[] {
    const headers: DataTableHeader[] = [{
      text: "Curso",
      value: "curso"
    }, {
      text: "Fecha",
      value: "fecha"
    }];
    if (!this.mobile) {
      headers.push(...[
        {
          text: "Nombre alumno",
          value: "alumno"
        }, {
          text: "Correo",
          value: "correo"
        }
      ]);
    }
    headers.push({
      text: "",
      sortable: false,
      value: "acciones"
    });
    return headers;
  }

  get templateColumns(): string {
    if (this.mobile) return "1fr 1fr 30px";
    return "repeat(4, 1fr) 30px";
  }

  @Emit("click:informacion")
  onClickInformacion(item: ItemTablaInscripciones): ItemTablaInscripciones {
    return item;
  }
}
