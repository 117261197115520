
// Decoradores
import {Component, Emit, Prop, Vue} from "vue-property-decorator";

// Tipos
import {DataTableHeader} from "vuetify";
import {Promocion} from "@/typings/store/plugins/easyFirestore/promociones";
import {ItemTablaPromociones} from "@/typings/components/promociones/tablaPromociones";

// Componentes
import MenuButton from "@/components/custom/MenuButton.vue";
import IconButton from "@/components/custom/IconButton.vue";
import StatusCircle from "@/components/custom/StatusCircle.vue";
import PageTableItem from "@/components/custom/PageTableItem.vue";
import DataTable from "@/components/custom/DataTable.vue";

@Component({
  components: {
    DataTable,
    IconButton,
    MenuButton,
    StatusCircle,
    PageTableItem,
  },
})
export default class TablaPromociones extends Vue {
  @Prop() readonly buscar!: string;
  @Prop() readonly promociones!: Promocion[];

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get headers(): DataTableHeader[] {
    const headers: DataTableHeader[] = [{
      text: "",
      value: "estado",
      sortable: false,
    }, {
      text: "Nombre",
      value: "nombre",
    }];
    if (!this.mobile) {
      headers.push(...[{
        text: "Código",
        value: "codigo",
      }, {
        align: "end",
        text: "Descuento",
        value: "descuento",
        cellClass: "justify-end",
      }, {
        text: "Inicio",
        value: "inicio",
      }, {
        text: "Fin",
        value: "fin",
      }]);
    } else {
      headers.push({
        text: "Dcto.",
        value: "descuento",
      });
    }
    headers.push({
      text: "",
      sortable: false,
      value: "acciones",
      cellClass: "justify-end",
    });
    return headers;
  }

  get items(): ItemTablaPromociones[] {
    return this.promociones.map(_promocion => {
      const id = _promocion.id ?? "";
      const nombre = _promocion.nombre;
      const codigo = _promocion.codigo;
      const estado = this.getEstadoPromocion(_promocion);
      const fin = new Date(_promocion.fin).format("DD/MM/YYYY");
      const inicio = new Date(_promocion.inicio).format("DD/MM/YYYY");
      const descuentoCurrency = _promocion.descuento.toInt().toCurrency("$");
      const descuento = _promocion.descuento.endsWith("%") ? _promocion.descuento : descuentoCurrency;
      return {
        id,
        fin,
        estado,
        nombre,
        codigo,
        inicio,
        descuento,
        data: _promocion,
      };
    });
  }

  get templateColumns(): string {
    if (this.mobile) return "2rem 1fr 1fr 2.25rem";
    return "2rem repeat(5, 1fr) 0.75fr";
  }

  get iconSize(): string {
    return this.mobile ? "1.875rem" : "1.25rem";
  }

  @Emit("click:informacion")
  onClickInformacion({data}: ItemTablaPromociones): Promocion {
    return data;
  }

  @Emit("click:editar")
  onClickEditar({data}: ItemTablaPromociones): Promocion {
    return data;
  }

  @Emit("click:eliminar")
  onClickEliminar({data}: ItemTablaPromociones): Promocion {
    return data;
  }

  getEstadoPromocion(promocion: Promocion): string {
    const now = new Date();
    const fin = new Date(promocion.fin);
    const inicio = new Date(promocion.inicio);
    if (now.isBetween(inicio, fin)) return "activa";
    if (now < inicio) return "pendiente";
    return "finalizada";
  }

  getColor({estado}: ItemTablaPromociones): string {
    if (estado === "activa") return "#279B18";
    if (estado === "pendiente") return "#FBC02D";
    return "#707070";
  }
}
