import { RouteConfig } from "vue-router";
import QuienesSomos from "@/views/QuienesSomos.vue";

const quienesSomos: RouteConfig = {
  path: "/quienesSomos",
  name: "quienesSomos",
  component: QuienesSomos,
};

export default quienesSomos;
