
// Vue
import {computed, defineComponent} from "vue";

// Vuetify
import vuetify from "@/plugins/vuetify";

// Componentes
import IconButton from "@/components/custom/IconButton.vue";

export default defineComponent({
  name: "ViewTitle",
  components: {
    IconButton
  },
  props: {
    title: {
      type: String,
      required: true
    },
    hideBack: {
      type: Boolean,
      default: false
    },
    alwaysShowTitle: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    "click:back"
  ],
  setup(_props, ctx) {
    // Computed

    const mobile = computed(() => vuetify.framework.breakpoint.mdAndDown);

    const iconSize = computed(() => mobile.value ? "1.625rem" : "1.5rem");

    // Methods

    const hasSlot = () => {
      return !!ctx.slots.default;
    };

    // Emits

    const onClickBack = () => {
      ctx.emit("click:back");
    };

    return {
      // Computed
      mobile,
      iconSize,
      // Methods
      hasSlot,
      // Emits
      onClickBack
    };
  }
});
