
// Decoradores
import {Component, Prop, Vue} from "vue-property-decorator";

// Tipos
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";

@Component
export default class InformacionRelator extends Vue {
  @Prop() readonly relator!: Usuario;

  created(): void {
    window.scrollTo(0, 0);
  }

  get imagen(): string {
    return this.relator.perfil?.imagen?.url || "";
  }

  get nombre(): string {
    return this.relator.nombre || "";
  }

  get profesion(): string {
    return this.relator.perfil?.profesion || "";
  }

  get antecedentes(): string {
    return this.relator.perfil?.antecedentes || "";
  }
}
