
// Decoradores
import {Component, Emit, Prop, VModel, Vue} from "vue-property-decorator";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import ViewTitle from "@/components/custom/ViewTitle.vue";
import SearchTextField from "@/components/custom/SearchTextField.vue";

@Component({
  components: {
    SvgIcon,
    ViewTitle,
    SearchTextField
  }
})
export default class TituloInscripciones extends Vue {
  @VModel() buscar!: string;

  @Prop() readonly disabled!: boolean;
  @Prop() readonly mostrarInformacion!: boolean;

  buscando = false;

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get titulo(): string {
    return this.mostrarInformacion ? "Información inscripción" : "Inscripciones";
  }

  get hideBack(): boolean {
    return !this.mostrarInformacion && !this.buscando;
  }

  get mostrarBuscar(): boolean {
    if (this.mobile) return this.buscando;
    return !this.mostrarInformacion;
  }

  get mostrarBotonMovil(): boolean {
    return this.mobile && !this.mostrarInformacion && !this.buscando;
  }

  @Emit("click:back")
  onClickBack(): void {
    return;
  }

  @Emit("click:filtrar")
  onClickFiltrar(): void {
    return;
  }

  clickBack(): void {
    if (this.buscando) {
      this.buscando = false;
    } else {
      this.onClickBack();
    }
  }
}
