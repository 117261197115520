import { GetterTree, MutationTree, ActionTree } from "vuex";
// firebase
import { Firebase } from "@/config/firebase";
// helpers
import {env} from "@/helpers/env";
//tipos
import { State } from "@/typings/store/index";
import {
  Archivo,
  BlobArchivo,
  DataURLArchivo,
  StateArchivos,
} from "@/typings/store/plugins/easyFirestore/archivos";

const refBase = `${env}/v1`;

const state: StateArchivos = {
  all: {},
};

const getters: GetterTree<StateArchivos, State> = {
  get(_state) {
    return _state.all;
  },
  getArray(_state) {
    return Object.values(_state.all);
  },
};

const mutations: MutationTree<StateArchivos> = {};

const actions: ActionTree<StateArchivos, State> = {
  async setBlob(ctx, data: BlobArchivo): Promise<string> {
    if (!data.file) {
      return "";
    }
    const ref = `${refBase}/${data.ref}`.replaceAll("//", "/");
    const storage = Firebase.storage();
    const storageRef = storage.ref();
    const imagenRef = storageRef.child(ref);
    await imagenRef
      .put(data.file)
      .then()
      .catch((err) => {
        console.log(err);
      });
    const url = await imagenRef.getDownloadURL().catch((err) => {
      console.log(err);
      return "";
    });
    const doc: Archivo = {
      ref: data.ref,
      nombre: data.nombre,
      url: url,
      fecha: data.fecha ?? new Date().toISOString(),
    };
    return ctx.dispatch("set", doc);
  },
  async setDataURL(ctx, data: DataURLArchivo): Promise<string> {
    if (!data.file) {
      return "";
    }
    const ref = `${refBase}/${data.ref}`.replaceAll("//", "/");
    const storage = Firebase.storage();
    const storageRef = storage.ref();
    const imagenRef = storageRef.child(ref);
    await imagenRef
      .putString(data.file, "data_url")
      .then()
      .catch((err) => {
        console.log(err);
      });
    const url = await imagenRef.getDownloadURL().catch((err) => {
      console.log(err);
      return "";
    });
    const doc: Archivo = {
      ref: data.ref,
      nombre: data.nombre,
      url: url,
      fecha: data.fecha ?? new Date().toISOString(),
    };
    return ctx.dispatch("set", doc);
  },
};

const archivos = {
  firestorePath: `${env}/v1/archivos`,
  firestoreRefType: "collection",
  moduleName: "archivos",
  statePropName: "all",
  namespaced: true,
  serverChange: {
    convertTimestamps: {
      created_at: "%convertTimestamp%", // default
      updated_at: "%convertTimestamp%", // default
    },
  },
  state,
  getters,
  mutations,
  actions,
};

export default archivos;
