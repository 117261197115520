
// Vue
import {computed, defineComponent} from "vue";

// Helpers
import {toCurrency} from "@/helpers/number";

// Tipos
import {Pago} from "@/typings/store/plugins/easyFirestore/pagos";

export default defineComponent({
  name: "ResumenDetallePago",
  props: {
    pagoSeleccionado: {
      required: true,
      type: Object as () => Pago | null
    }
  },
  setup(props) {
    const cursos = computed(() => props.pagoSeleccionado ? props.pagoSeleccionado.cursos : {});

    const subtotal = computed(() => {
      const suma = Object.values(cursos.value).reduce((acc, cur) => acc + cur.precio, 0);
      return toCurrency(suma);
    });

    const descuento = computed(() => {
      const promocion = props.pagoSeleccionado?.promocion;
      if (!promocion?.descuento) return "$0";
      const tipo = promocion.descuento.endsWith("%") ? "%" : "$";
      const descuentoPromocion = promocion.descuento.toInt();
      if (tipo === "%") {
        return toCurrency((subtotal.value.toInt() * descuentoPromocion) / 100);
      } else {
        return toCurrency(descuentoPromocion);
      }
    });

    const total = computed(() => toCurrency(props.pagoSeleccionado?.monto ?? 0));

    return {
      // Computed
      total,
      cursos,
      subtotal,
      descuento
    };
  }
});
