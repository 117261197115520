import { RouteConfig } from "vue-router";
import Monitoreo from "@/views/VistaMonitoreo.vue";

const monitoreo: RouteConfig = {
  path: "/monitoreo",
  name: "monitoreo",
  component: Monitoreo,
};

export default monitoreo;
