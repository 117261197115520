
// Vue
import {computed, defineComponent, ref} from "vue";

// Componentes
import PageFilter from "@/components/custom/PageFilter.vue";
import TextButton from "@/components/custom/TextButton.vue";
import ResetButton from "@/components/custom/ResetButton.vue";
import FiltersContainer from "@/components/custom/FiltersContainer.vue";

export default defineComponent({
  name: "FiltrosContactos",
  components: {
    PageFilter,
    TextButton,
    ResetButton,
    FiltersContainer,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "input",
    "click:aplicar",
    "click:resetear",
  ],
  setup(props, ctx) {
    // VModel

    const model = computed({
      get() {
        return props.value;
      },
      set(value) {
        ctx.emit("input", value);
      },
    });

    // Data

    const filtroAsunto = ref(props.value ? [props.value] : []);
    const asuntos = [{
      text: "Proponer nuevo curso",
      value: "Proponer nuevo curso",
    }, {
      text: "Problemas con mi cuenta",
      value: "Problemas con mi cuenta",
    }];

    // Emits

    const onClickAplicar = () => {
      model.value = filtroAsunto.value[0] ?? "";
      ctx.emit("click:aplicar");
    };

    const onClickResetear = () => {
      model.value = "";
      filtroAsunto.value = [];
      ctx.emit("click:resetear");
    };

    return {
      // VModel
      model,
      // Data
      asuntos,
      filtroAsunto,
      // Emits
      onClickAplicar,
      onClickResetear,
    };
  },
});
