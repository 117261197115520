
// Vue
import {computed, defineComponent, ref} from "vue";

// Router

import router from "@/router";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import TextButton from "@/components/custom/TextButton.vue";
import PageTextField from "@/components/custom/PageTextField.vue";
import InicioRapido from "@/components/login/formularioIniciarSesion/InicioRapido.vue";

export default defineComponent({
  name: "FormularioIniciarSesion",
  components: {
    SvgIcon,
    TextButton,
    InicioRapido,
    PageTextField
  },
  props: {
    error: {
      type: String,
      required: true
    },
    mobile: {
      type: Boolean,
      required: true
    },
    cargando: {
      type: Boolean,
      required: true
    }
  },
  emits: [
    "click:cerrar",
    "click:olvido-clave",
    "click:crear-cuenta",
    "click:iniciar-sesion",
    "click:iniciar-sesion-google",
    "click:iniciar-sesion-facebook"
  ],
  setup(props, ctx) {
    // Data

    const email = ref("");
    const password = ref("");
    const showingPassword = ref(false);

    // Computed

    const buttonDisabled = computed(() => !email.value || !password.value);

    const labelPassword = computed(() => !props.mobile ? "Contraseña" : "");

    const labelEmail = computed(() => !props.mobile ? "Correo electrónico" : "");

    const inputTypePassword = computed(() => showingPassword.value ? "text" : "password");

    const appendIconPassword = computed(() => showingPassword.value ? "far-eye-slash" : "far-eye");

    // Methods

    const irTerminosYCondiciones = () => {
      const currentRoute = router.currentRoute;
      if (currentRoute.name !== "terminosCondiciones") {
        router.push({name: "terminosCondiciones"});
      }
    };

    const clickShowPassword = () => {
      showingPassword.value = !showingPassword.value;
    };

    // Emits

    const onClickCerrar = () => {
      ctx.emit("click:cerrar");
    };

    const onClickCrearCuenta = () => {
      ctx.emit("click:crear-cuenta");
    };

    const onClickIniciarSesionGoogle = () => {
      ctx.emit("click:iniciar-sesion-google");
    };

    const onClickIniciarSesionFacebook = () => {
      ctx.emit("click:iniciar-sesion-facebook");
    };

    const onClickIniciarSesion = () => {
      if (buttonDisabled.value) return;
      ctx.emit("click:iniciar-sesion", {
        email: email.value,
        password: password.value
      });
    };

    const onClickOlvidoClave = () => {
      ctx.emit("click:olvido-clave");
    };

    return {
      // Data
      email,
      password,
      showingPassword,
      // Computed
      labelEmail,
      labelPassword,
      buttonDisabled,
      inputTypePassword,
      appendIconPassword,
      // Methods
      clickShowPassword,
      irTerminosYCondiciones,
      // Emits
      onClickCerrar,
      onClickOlvidoClave,
      onClickCrearCuenta,
      onClickIniciarSesion,
      onClickIniciarSesionGoogle,
      onClickIniciarSesionFacebook
    };
  }
});
