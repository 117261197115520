import { render, staticRenderFns } from "./FormularioCategoria.vue?vue&type=template&id=902be21e&scoped=true&"
import script from "./FormularioCategoria.vue?vue&type=script&lang=ts&"
export * from "./FormularioCategoria.vue?vue&type=script&lang=ts&"
import style0 from "./FormularioCategoria.vue?vue&type=style&index=0&id=902be21e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "902be21e",
  null
  
)

export default component.exports