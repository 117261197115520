export {};

declare global {
  interface String {
    toDate(): Date;

    toRUT(): string;

    toInt(): number;

    validateRUT(): boolean;

    validateEmail(): boolean;

    capitalizeFirstLetter(): string;

    reverse(): string;
  }

  interface Array<T> {
    at(index: number): T | undefined;
  }

  interface Number {
    toCurrency(symbol?: string): string;
  }

  interface Date {
    format(format: string): string;

    isBefore(date: Date): boolean;

    isAfter(date: Date): boolean;

    isBetween(start: Date, end: Date): boolean;

    isToday(): boolean;

    isBeforeToday(): boolean;

    isAfterToday(): boolean;

    getWeek(): Array<Date>;

    toTimeAgo(): string;
  }
}

if (!Array.prototype.at) {
  Array.prototype.at = function (index: number) {
    const o = Object(this);
    const len = o.length >>> 0;
    const relativeIndex = index >> 0;
    const k = relativeIndex >= 0 ? relativeIndex : len + relativeIndex;
    return k < 0 || k >= len ? undefined : o[k];
  };
}

Number.prototype.toCurrency = function (symbol?: string) {
  if (!isNaN(Number(this))) {
    const [value] = this.toString().split(".");
    const isNegative = value.includes("-");
    const reverse = value.replaceAll("-", "").reverse();
    const matchResult = reverse.match(/.{1,3}/g);
    const negative = isNegative ? "-" : '';
    if (matchResult) {
      const result = matchResult.join(".").reverse();
      return `${negative}${symbol ?? ""}${result}`;
    }
  }
  return this.toString();
};

String.prototype.reverse = function () {
  let result = "";
  for (let i = this.length - 1; i >= 0; i--) {
    result += this[i];
  }
  return result;
};

String.prototype.toInt = function () {
  return parseInt(String(this).replace(/\D/g, ""));
};

String.prototype.toDate = function () {
  const isoRegexUTC = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(((\.\d+)*Z)|(\+\d{2}:\d{2}))$/;
  const isoRegexNoUTC = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d+$/;
  const stringThis = String(this);
  if (isoRegexNoUTC.test(stringThis) || isoRegexUTC.test(stringThis)) {
    return new Date(stringThis);
  }
  const dateRegex = /\d{2,4}[-/]\d{2}[-/]\d{2,4}/g;
  const hourRegex = /\d{2}:\d{2}(:\d{2})*/g;
  const dateMatch = stringThis.match(dateRegex);
  const hourMatch = stringThis.match(hourRegex);
  let dateString = dateMatch ? dateMatch[0] : "";
  const hourString = hourMatch ? hourMatch[0] : "00:00:00";
  if (/\d{2}[-/]\d{2}[-/]\d{4}/g.test(dateString)) {
    dateString = dateString.split(/[/-]/).reverse().join("/");
  }
  dateString = dateString.replace(/-/g, "/");
  try {
    return new Date(`${dateString} ${hourString}`);
  } catch (err) {
    console.error(err);
    return new Date();
  }
};

String.prototype.capitalizeFirstLetter = function () {
  if (String(this)) {
    return `${this[0].toUpperCase()}${this.slice(1, this.length)}`;
  }
  return "";
};

String.prototype.validateRUT = function () {
  const rutRegExp = /^\d{7,8}-[\dkK]$/;
  const rut = this.replace(/\./g, "").toLowerCase();
  if (rutRegExp.test(rut)) {
    const rutArray = rut.split("-");
    const cuerpo = rutArray[0];
    const digitoVerificador = rutArray[1];
    let suma = 0;
    let multiplicador = 2;
    for (let i = cuerpo.length - 1; i >= 0; i--) {
      if (multiplicador > 7) multiplicador = 2;
      suma += parseInt(cuerpo[i]) * multiplicador;
      multiplicador++;
    }
    const result = 11 - (suma % 11);
    let digitoReal = result.toString();
    if (result === 11) digitoReal = "0";
    if (result === 10) digitoReal = "k";
    return digitoReal === digitoVerificador;
  } else {
    return false;
  }
};

String.prototype.validateEmail = function () {
  const EmailRegEx = /[a-zñ][\w-.]*@[a-zñ][\w-.]*\.[a-zñ]+/g;
  return EmailRegEx.test(String(this));
};

String.prototype.toRUT = function () {
  const rutRegExp = /^\d{7,8}-[\dkK]$/;
  if (rutRegExp.test(String(this))) {
    const reverse: string = this.split("-")[0].split("").reverse().join("");
    const rutReverse: RegExpMatchArray | null = reverse.match(/.{1,3}/g);
    if (rutReverse) {
      return `${rutReverse.join(".").split("").reverse().join("")}-${this.split("-")[1]}`;
    }
  }
  return "";
};

Date.prototype.isAfter = function (date: Date) {
  return this.getTime() > date.getTime();
};

Date.prototype.isBefore = function (date: Date) {
  return this.getTime() < date.getTime();
};

Date.prototype.isBetween = function (start: Date, end: Date) {
  return this.getTime() >= start.getTime() && this.getTime() <= end.getTime();
};

const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre"
];
const days = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado"
];

Date.prototype.format = function (format: string) {
  if (this.toString().toLowerCase() !== "invalid date") {
    let fechaReturn = format;
    fechaReturn = fechaReturn.replaceAll("DDDD", days[this.getDay() === 7 ? 0 : this.getDay()]);
    fechaReturn = fechaReturn.replaceAll("DDD", days[this.getDay() === 7 ? 0 : this.getDay()].substring(0, 3));
    fechaReturn = fechaReturn.replaceAll("DD", this.getDate().toString().padStart(2, "0"));
    fechaReturn = fechaReturn.replaceAll("MMMM", months[this.getMonth()]);
    fechaReturn = fechaReturn.replaceAll("MMM", months[this.getMonth()].substring(0, 3));
    fechaReturn = fechaReturn.replaceAll("MM", (this.getMonth() + 1).toString().padStart(2, "0"));
    fechaReturn = fechaReturn.replaceAll("YYYY", this.getFullYear().toString());
    fechaReturn = fechaReturn.replaceAll("YY", this.getFullYear().toString().substring(2, 4));
    fechaReturn = fechaReturn.replaceAll("HH", this.getHours().toString().padStart(2, "0"));
    fechaReturn = fechaReturn.replaceAll("mm", this.getMinutes().toString().padStart(2, "0"));
    fechaReturn = fechaReturn.replaceAll("ss", this.getSeconds().toString().padStart(2, "0"));
    fechaReturn = fechaReturn.replaceAll("tt", this.getHours() >= 12 ? "PM" : "AM");
    return fechaReturn;
  }
  return "Invalid Date";
};

Date.prototype.isToday = function () {
  const today00 = new Date();
  today00.setHours(0, 0, 0, 0);
  const today23 = new Date();
  today23.setHours(23, 59, 59, 999);
  return this.isBetween(today00, today23);
};

Date.prototype.isBeforeToday = function () {
  const today00 = new Date();
  today00.setHours(0, 0, 0, 0);
  return this.isBefore(today00);
};

Date.prototype.isAfterToday = function () {
  const today23 = new Date();
  today23.setHours(23, 59, 59, 999);
  return this.isAfter(today23);
};

Date.prototype.getWeek = function () {
  const firstWeekDay = new Date(this);
  firstWeekDay.setDate(this.getDate() - this.getDay() + 1);
  const week = [];
  for (let i = 0; i < 7; i++) {
    const day = new Date(firstWeekDay);
    day.setDate(day.getDate() + i);
    week.push(day);
  }
  return week;
};

function isYesterday(date: Date): boolean {
  const yesterday00 = new Date();
  yesterday00.setDate(yesterday00.getDate() - 1);
  yesterday00.setHours(0, 0, 0, 0);
  const yesterday23 = new Date();
  yesterday23.setDate(yesterday23.getDate() - 1);
  yesterday23.setHours(23, 59, 59, 999);
  return date.isBetween(yesterday00, yesterday23);
}

function checkWeek(date: Date): boolean {
  const now = new Date();
  now.setHours(23, 59, 59, 999);
  const firstWeekDay = new Date();
  firstWeekDay.setDate(now.getDate() - 7);
  firstWeekDay.setHours(0, 0, 0, 0);
  return date.isBetween(firstWeekDay, now);
}

Date.prototype.toTimeAgo = function () {
  const now = new Date();
  const seconds = Math.round((now.getTime() - this.getTime()) / 1000);
  if (seconds < 60) {
    const timeAgo = seconds === 1 ? "1 segundo" : `${seconds} segundos`;
    return `Hace ${timeAgo}`;
  }
  const minutes = Math.round(seconds / 60);
  if (minutes < 60) {
    const timeAgo = minutes === 1 ? "1 minuto" : `${minutes} minutos`;
    return `Hace ${timeAgo}`;
  }
  const hours = Math.round(minutes / 60);
  if (this.isToday()) {
    const timeAgo = hours === 1 ? "1 hora" : `${hours} horas`;
    return `Hace ${timeAgo}`;
  }
  if (isYesterday(this)) return `Ayer`;
  if (checkWeek(this)) return `Hace ${Math.round(hours / 24)} días`;
  return this.format("DD de MMMM");
};
