
// Decoradores
import {Component, Emit, Prop, Vue} from "vue-property-decorator";

// Tipos
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";
import {FiltrosCursosClienteType} from "@/typings/components/cursosCliente/filtrosCursosCliente";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import FiltrosCursos from "@/components/cursosCliente/FiltrosCursosCliente.vue";

@Component({
  components: {
    SvgIcon,
    FiltrosCursos
  }
})
export default class DialogoFiltrosCursosCliente extends Vue {
  @Prop() readonly categorias!: Array<string>;
  @Prop() readonly relatores!: Array<Usuario>;
  @Prop() readonly filtros!: FiltrosCursosClienteType;

  created(): void {
    this.filtrosInternos = {
      relatores: this.filtros.relatores.slice(),
      categorias: this.filtros.categorias.slice(),
      rangoPrecio: this.filtros.rangoPrecio?.slice() ?? null
    };
  }

  filtrosInternos: FiltrosCursosClienteType = {
    relatores: [],
    categorias: [],
    rangoPrecio: []
  };

  @Emit("click:close")
  onClickClose(): void {
    return;
  }

  @Emit("click:aplicar")
  onClickAplicar(): FiltrosCursosClienteType {
    return this.filtrosInternos;
  }
}
