
// Decoradores
import {Component, Emit, Prop, VModel, Vue, Watch} from "vue-property-decorator";

// Tipos
import {DataFiltrosCursos} from "@/typings/components/cursos/filtros";
import {TipoUsuario, Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";
import {CategoriaCurso} from "@/typings/store/plugins/easyFirestore/categoriasCurso";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import DialogTitle from "@/components/custom/DialogTitle.vue";
import FiltrosCursos from "@/components/cursos/FiltrosCursos.vue";

@Component({
  components: {
    SvgIcon,
    DialogTitle,
    FiltrosCursos
  }
})
export default class DialogoFiltrosCursos extends Vue {
  @VModel() model!: boolean;

  @Prop() readonly relatores!: Usuario[];
  @Prop() readonly filtros!: DataFiltrosCursos;
  @Prop() readonly categorias!: CategoriaCurso[];
  @Prop() readonly tipoUsuario!: TipoUsuario | null;

  filtrosInternos: DataFiltrosCursos = {
    estado: [],
    relator: [],
    categoria: []
  };

  @Watch("model", {immediate: true})
  onModelChange(val: boolean): void {
    if (val) {
      this.filtrosInternos = {
        estado: this.filtros.estado.slice(),
        relator: this.filtros.relator.slice(),
        categoria: this.filtros.categoria.slice()
      };
    }
  }

  @Emit("click:cerrar")
  onClickCerrar(): void {
    this.model = false;
  }

  @Emit("click:aplicar")
  onClickAplicar(): DataFiltrosCursos {
    this.model = false;
    return this.filtrosInternos;
  }
}
