import { ActionTree, GetterTree, MutationTree } from "vuex";
import { State } from "@/typings/store";
import { StateCargando } from "@/typings/store/modules/cargando";

const state: StateCargando = {
  cargando: true,
};

const getters: GetterTree<StateCargando, State> = {
  get(state) {
    return state.cargando;
  },
};

const mutations: MutationTree<StateCargando> = {
  set(state, data: boolean) {
    state.cargando = data;
  },
};

const actions: ActionTree<StateCargando, State> = {
  set(ctx, data: boolean) {
    ctx.commit("set", data);
  },
};

const cargando = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default cargando;
