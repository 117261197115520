//tipos
import { GetterTree } from "vuex";
import { State } from "@/typings/store";
import { StateRegiones } from "@/typings/store/plugins/easyFirestore/regiones";
import {env} from "@/helpers/env";

const getters: GetterTree<StateRegiones, State> = {
  get(state) {
    return state.all;
  },
  getArray(state) {
    return Object.values(state.all);
  },
};

const regiones = {
  firestorePath: `${env}/v1/regiones`,
  firestoreRefType: "collection",
  moduleName: "regiones",
  statePropName: "all",
  namespaced: true,
  serverChange: {
    convertTimestamps: {
      created_at: "%convertTimestamp%", // default
      updated_at: "%convertTimestamp%", // default
    },
  },
  state: {},
  getters,
  mutations: {},
  actions: {},
};

export default regiones;
