
// Decoradores
import {Component, Emit, Prop, Vue} from "vue-property-decorator";

// Tipos
import {DataTableHeader} from "vuetify";
import {ItemTablaCursos} from "@/typings/components/cursos/tabla";
import {Curso} from "@/typings/store/plugins/easyFirestore/cursos";
import {Sesion} from "@/typings/store/plugins/easyFirestore/sesiones";
import {TipoUsuario} from "@/typings/store/plugins/easyFirestore/usuarios";
import {Inscripcion} from "@/typings/store/plugins/easyFirestore/inscripciones";

// Componentes
import DataTable from "@/components/custom/DataTable.vue";
import IconButton from "@/components/custom/IconButton.vue";
import MenuButton from "@/components/custom/MenuButton.vue";
import StatusCircle from "@/components/custom/StatusCircle.vue";

@Component({
  components: {
    DataTable,
    MenuButton,
    IconButton,
    StatusCircle
  }
})
export default class TablaCursos extends Vue {
  @Prop() readonly buscar!: string;
  @Prop() readonly cursos!: Curso[];
  @Prop() readonly sesiones!: Sesion[];
  @Prop() readonly inscripciones!: Inscripcion[];
  @Prop() readonly tipoUsuario!: TipoUsuario | null;

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get headers(): DataTableHeader[] {
    const headers: DataTableHeader[] = [{
      text: "",
      value: "estado",
      sortable: false
    }, {
      text: "Nombre",
      value: "nombre"
    }, {
      text: "Categoría",
      value: "categoria"
    }];
    if (!this.mobile) {
      headers.push(...[{
        text: "Relatores",
        value: "relatores"
      }, {
        align: "end",
        text: "Clases",
        value: "clases",
        cellClass: "justify-end"
      }, {
        align: "end",
        text: "Cupos",
        value: "cupos",
        cellClass: "justify-end"
      }, {
        text: "Inicio",
        value: "inicio",
        sort: this.sortByDate
      }]);
      if (this.tipoUsuario === "admin") {
        headers.push({
          align: "end",
          text: "Precio",
          value: "precio",
          cellClass: "justify-end",
          sort: (a: string, b: string) => {
            return a.toInt() - b.toInt();
          }
        });
      }
    }
    headers.push({
      text: "",
      sortable: false,
      value: "acciones"
    });
    return headers;
  }

  get iconSize(): string {
    return this.mobile ? "1.875rem" : "1.25rem";
  }

  get templateColumns(): string {
    if (this.mobile) {
      return "2rem 1fr 1fr 2.25rem";
    } else {
      if (this.tipoUsuario === "admin") return "2rem 1fr 1fr 1fr 1fr 1fr 1fr 1fr 0.75fr";
      return "2rem 1fr 1fr 1fr 1fr 1fr 1fr 0.75fr";
    }
  }

  get items(): ItemTablaCursos[] {
    return this.cursos.map(curso => {
      const id = curso.id ?? "";
      const cupos = curso.cupos;
      const estado = curso.estado;
      const nombre = curso.nombre;
      const categoria = curso.categoria.nombre;
      const clases = this.getSesionesCurso(id);
      const relatores = this.getRelatoresCurso(curso);
      const fin = curso.fin.toDate().format("DD/MM/YYYY");
      const inicio = curso.inicio.toDate().format("DD/MM/YYYY");
      const precio = (curso.precioRef ?? curso.precio).toCurrency("$");
      return {
        id,
        fin,
        cupos,
        nombre,
        estado,
        inicio,
        clases,
        precio,
        categoria,
        relatores,
        data: curso
      };
    });
  }

  @Emit("click:editar")
  onClickEditar({data}: ItemTablaCursos): Curso {
    return data;
  }

  @Emit("click:eliminar")
  onClickEliminar({data}: ItemTablaCursos): Curso {
    return data;
  }

  @Emit("click:informacion")
  onClickInformacion({data}: ItemTablaCursos): Curso {
    return data;
  }

  @Emit("click:duplicar")
  onClickDuplicar({data}: ItemTablaCursos): Curso {
    return data;
  }

  sortByDate(a: string, b: string): number {
    const fechaA = a.toDate();
    const fechaB = b.toDate();
    return fechaA.getTime() - fechaB.getTime();
  }

  getSesionesCurso(id: string): number {
    return this.sesiones.filter(sesion => sesion.curso.id === id).length;
  }

  getRelatoresCurso(curso: Curso): string {
    const array = Object.values(curso.relatores);
    return array.map(relator => relator.nombre).join(", ");
  }

  getColorEstado({estado}: ItemTablaCursos): string {
    if (estado === "publicado") return "#279B18";
    if (estado === "pendiente") return "#C2A473";
    return "#707070";
  }

  mostrarEliminar({id}: ItemTablaCursos): boolean {
    const tipoAdmin = this.tipoUsuario === "admin";
    const noVendido = !this.inscripciones.some(_inscripcion => !!_inscripcion.pago.cursos[id]);
    return tipoAdmin && noVendido;
  }
}
