
// Decoradores
import {Component, Emit, Prop, Vue} from "vue-property-decorator";

// Helpers
import routeGuard from "@/helpers/routeGuard";

// Vuex
import {mapActions, mapGetters} from "vuex";

// Tipos
import {Cupo} from "@/typings/store/plugins/easyFirestore/cupos";
import {Curso} from "@/typings/store/plugins/easyFirestore/cursos";
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";
import {Contacto} from "@/typings/store/plugins/easyFirestore/contactos";
import {Pagina, Razon} from "@/typings/store/plugins/easyFirestore/pagina";
import {Testimonio} from "@/typings/store/plugins/easyFirestore/testimonios";
import {Notificacion} from "@/typings/store/plugins/easyFirestore/notificaciones";
import {CategoriaCurso} from "@/typings/store/plugins/easyFirestore/categoriasCurso";
import {DataEditarTestimonios} from "@/typings/components/testimonios/editarTestimonios";

// Componentes
import Footer from "@/components/appCorePagina/Footer.vue";
import PorQueInicio from "@/components/inicio/PorQueInicio.vue";
import AreasInteres from "@/components/inicio/AreasInteres.vue";
import ContactoInicio from "@/components/inicio/ContactoInicio.vue";
import RelatoresInicio from "@/components/inicio/RelatoresInicio.vue";
import PorQueContratar from "@/components/inicio/PorQueContratar.vue";
import CursosDestacados from "@/components/inicio/CursosDestacados.vue";
import CarouselPromociones from "@/components/inicio/CarouselPromociones.vue";
import ComponenteTestimonios from "@/components/inicio/ComponenteTestimonios.vue";

@Component({
  beforeRouteEnter(_to, _from, next) {
    next(routeGuard);
  },
  components: {
    Footer,
    PorQueInicio,
    AreasInteres,
    ContactoInicio,
    PorQueContratar,
    RelatoresInicio,
    CursosDestacados,
    CarouselPromociones,
    ComponenteTestimonios,
  },
  methods: mapActions({
    setPago: "pagos/set",
    setPagina: "pagina/set",
    setContacto: "contactos/set",
    setTestimonio: "testimonios/set",
    setBlobArchivo: "archivos/setBlob",
    setNotificacion: "notificaciones/set",
  }),
  computed: mapGetters({
    getPagina: "pagina/get",
    getUsuario: "usuario/get",
    getArchivos: "archivos/get",
    getArrayCupos: "cupos/getArray",
    getArrayCursos: "cursos/getArray",
    getRutasUsuario: "usuario/getRutas",
    getArrayUsuarios: "usuarios/getArray",
    getArrayTestimonios: "testimonios/getArray",
    getArrayInscripciones: "inscripciones/getArray",
    getArrayCategoriasCursos: "categoriasCurso/getArray",
  }),
})
export default class VistaInicio extends Vue {
  @Prop() readonly editando!: boolean;

  created(): void {
    if (!routeGuard(this)) return;
    window.scrollTo(0, 0);
  }

  nuevoBanner: File | null = null;

  get pagina(): Pagina {
    return this.getPagina;
  }

  get usuario(): Usuario | null {
    return this.getUsuario;
  }

  get arrayCursos(): Array<Curso> {
    return this.getArrayCursos;
  }

  get arrayCategorias(): Array<CategoriaCurso> {
    return this.getArrayCategoriasCursos.sort((categoriaA,categoriaB)=>{
      return categoriaA.nombre.localeCompare(categoriaB.nombre)
    });
  }

  get arrayCupos(): Array<Cupo> {
    return this.getArrayCupos;
  }

  get arrayRelatores(): Array<Usuario> {
    return this.getArrayUsuarios.filter((_usuario) => {
      const estaActivo = !!_usuario.uid;
      const esRelator = _usuario.tipo === "relator";
      return esRelator && estaActivo;
    }).sort((_relatorA, _relatorB) => {
      return _relatorA.nombre.localeCompare(_relatorB.nombre);
    });
  }

  get arrayTestimonios(): Array<Testimonio> {
    return this.getArrayTestimonios;
  }

  @Emit("click:iniciar-sesion")
  onClickIniciarSesion(): void {
    return;
  }

  clickEnviarContacto(contacto: Contacto): void {
    const notificacion: Notificacion = {
      curso: null,
      usuarios: null,
      enviada: false,
      topico: "admins",
      autor: this.usuario,
      tipo: "contacto-recibido",
      titulo: "Solicitud de contacto",
      fecha: new Date().toISOString(),
      contenido: `${contacto.nombre.capitalizeFirstLetter()} quiere contactarse.`,
    };
    this.setNotificacion(notificacion);
    this.setContacto(contacto);
  }

  guardarRazones(razones: Razon[]): void {
    for (const razon of razones) {
      this.setPagina(razon);
    }
  }

  async cambiarBanner(): Promise<void> {
    if (!this.nuevoBanner) return;
    const archivoId = await this.setBlobArchivo({
      file: this.nuevoBanner,
      nombre: "background-banner",
      fecha: new Date().toISOString(),
      ref: `pagina/background-banner`,
    });
    const archivo = this.getArchivos[archivoId];
    if (archivo) {
      await this.setPagina({
        foto: archivo,
        id: "backgroundBanner",
      });
    }
    this.nuevoBanner = null;
  }

  async guardarTestimonios(data: DataEditarTestimonios): Promise<void> {
    const promises: Promise<string>[] = [];
    for (const testimonio of data.testimonios) {
      if (!testimonio.id) continue;
      const foto = data.fotos[testimonio.id];
      if (foto) {
        const archivoId = await this.setBlobArchivo({
          file: foto,
          fecha: new Date().toISOString(),
          nombre: testimonio.foto.nombre ?? testimonio.id,
          ref: testimonio.foto.ref ?? `testimonios/${testimonio.id}/foto`,
        });
        testimonio.foto = this.getArchivos[archivoId];
      }
      promises.push(this.setTestimonio(testimonio));
    }
    await Promise.all(promises);
  }
}
