
// Decoradores
import {Component, Emit, Prop, VModel, Vue, Watch} from "vue-property-decorator";

// Tipos
import {Curso} from "@/typings/store/plugins/easyFirestore/cursos";
import {FiltrosInscripcionesData} from "@/typings/components/inscripciones/filtrosInscripciones";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import DialogTitle from "@/components/custom/DialogTitle.vue";
import FiltrosInscripciones from "@/components/inscripciones/FiltrosInscripciones.vue";

@Component({
  components: {
    SvgIcon,
    DialogTitle,
    FiltrosInscripciones
  }
})
export default class DialogoFiltrosInscripciones extends Vue {
  @VModel() model!: boolean;

  @Prop() readonly filtros!: FiltrosInscripcionesData;
  @Prop() readonly cursos!: Curso[];

  filtrosInternos: FiltrosInscripcionesData = {
    fecha: [],
    cursos: [],
    fechaSeleccionada: ""
  };

  @Watch("model")
  onModelChange(val: boolean): void {
    if (val) {
      this.filtrosInternos = {
        fecha: this.filtros.fecha,
        cursos: this.filtros.cursos.slice(),
        fechaSeleccionada: this.filtros.fechaSeleccionada
      };
    }
  }

  @Emit("click:cerrar")
  onClickCerrar(): void {
    this.model = false;
  }

  @Emit("click:aplicar")
  onClickAplicar(): FiltrosInscripcionesData {
    this.model = false;
    return this.filtrosInternos;
  }
}
