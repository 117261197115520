export const env = process.env.NODE_ENV === "production" ? "prod" : "dev";
export const firebaseAppId = process.env.VUE_APP_FIREBASE_APP_ID;
export const firebaseApiKey = process.env.VUE_APP_FIREBASE_API_KEY;
export const firebaseProjectId = process.env.VUE_APP_FIREBASE_PROJECT_ID;
export const firebaseAuthDomain = process.env.VUE_APP_FIREBASE_AUTH_DOMAIN;
export const firebaseStorageBucket = process.env.VUE_APP_FIREBASE_STORAGE_BUCKET;
export const firebaseMeasurementId = process.env.VUE_APP_FIREBASE_MEASUREMENT_ID;
export const firebaseMessagingSenderId = process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID;

export default {
  env,
  firebaseAppId,
  firebaseApiKey,
  firebaseProjectId,
  firebaseAuthDomain,
  firebaseStorageBucket,
  firebaseMeasurementId,
  firebaseMessagingSenderId
};
