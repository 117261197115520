
// Decoradores
import {Vue, Component} from "vue-property-decorator";

// Vuex
import {mapActions, mapGetters} from "vuex";

// Helpers
import routeGuard from "@/helpers/routeGuard";

// Tipos
import {FiltroPromocionType} from "@/typings/components/promociones/filtrosPromociones";
import {Promocion, PromocionPatch} from "@/typings/store/plugins/easyFirestore/promociones";
import {FormularioPromocionData} from "@/typings/components/promociones/formularioPromocion";

// Componentes
import MessageDialog from "@/components/custom/MessageDialog.vue";
import ConfirmationDialog from "@/components/custom/ConfirmationDialog.vue";
import TablaPromociones from "@/components/promociones/TablaPromociones.vue";
import TituloPromociones from "@/components/promociones/TituloPromociones.vue";
import FiltrosPromociones from "@/components/promociones/FiltrosPromociones.vue";
import FormularioPromocion from "@/components/promociones/FormularioPromocion.vue";
import DialogoFiltrosPromociones from "@/components/promociones/DialogoFiltrosPromociones.vue";
import NoData from "@/components/custom/NoData.vue";

@Component({
  components: {
    NoData,
    MessageDialog,
    TablaPromociones,
    TituloPromociones,
    ConfirmationDialog,
    FiltrosPromociones,
    FormularioPromocion,
    DialogoFiltrosPromociones,
  },
  beforeRouteEnter(_to, _from, next) {
    next(routeGuard);
  },
  computed: mapGetters({
    getUsuario: "usuario/get",
    getArrayPromociones: "promociones/getArray",
  }),
  methods: mapActions({
    setPromocion: "promociones/set",
    deletePromocion: "promociones/delete",
  }),
})
export default class VistaPromociones extends Vue {
  created(): void {
    if (!routeGuard(this)) return;
  }

  buscar = "";
  cargando = false;
  dialogoFiltros = false;
  mostrarFormulario = false;
  mostrarInformacion = false;
  dialogoConfirmacion = false;
  filtroEstado: FiltroPromocionType = null;
  promocionSeleccionada: Promocion | null = null;
  dialogoMensaje = {
    mensaje: "",
    model: false,
  };
  formulario: FormularioPromocionData = {
    fin: "",
    nombre: "",
    inicio: "",
    codigo: "",
    descuento: "",
  };

  get disabled(): boolean {
    return this.promociones.length === 0 && !this.filtroEstado;
  }

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get promociones(): Promocion[] {
    return this.getArrayPromociones.filter(_promocion => {
      const estado = this.getEstadoPromocion(_promocion);
      return this.filtroEstado ? this.filtroEstado === estado : true;
    }).sort((_promocionA, _promocionB) => {
      const fechaA = new Date(_promocionA.inicio);
      const fechaB = new Date(_promocionB.inicio);
      return fechaB.getTime() - fechaA.getTime();
    });
  }

  get titulo(): string {
    if (!this.mostrarFormulario && !this.mostrarInformacion) return "Promociones";
    if (this.mostrarInformacion) return "Información de Promoción";
    if (this.promocionSeleccionada) return "Editar Promoción";
    return "Crear Promoción";
  }

  get textoBoton(): string {
    if (!this.mostrarFormulario) return "AÑADIR PROMOCIÓN";
    if (this.promocionSeleccionada) return "GUARDAR";
    return "CREAR";
  }

  get buttonDisabled(): boolean {
    const regexDescuento = /^\d{1,2}%$/g;
    const flags = [
      !this.formulario.fin,
      !this.formulario.nombre,
      !this.formulario.codigo,
      !this.formulario.inicio,
      !this.formulario.descuento,
      this.formulario.codigo.length < 8,
      this.formulario.codigo.length > 15,
      this.formulario.nombre.length > 30,
      !regexDescuento.test(this.formulario.descuento),
    ];
    return flags.some(_flag => _flag) && this.mostrarFormulario;
  }

  get mensajeConfirmacion(): string {
    if (!this.promocionSeleccionada) return "";
    const nombre = this.promocionSeleccionada.nombre.capitalizeFirstLetter();
    return `¿Seguro que desea eliminar la promoción "${nombre}"?`;
  }

  getEstadoPromocion(promocion: Promocion): string {
    const now = new Date();
    const fin = new Date(promocion.fin);
    const inicio = new Date(promocion.inicio);
    if (now.isBetween(inicio, fin)) return "activa";
    if (now < inicio) return "pendiente";
    return "finalizada";
  }

  aplicarFiltros(filtro: FiltroPromocionType): void {
    this.filtroEstado = filtro;
  }

  limpiar(): void {
    this.mostrarFormulario = false;
    this.mostrarInformacion = false;
    this.dialogoConfirmacion = false;
    this.promocionSeleccionada = null;
    this.formulario = {
      fin: "",
      nombre: "",
      inicio: "",
      codigo: "",
      descuento: "",
    };
  }

  clickAgregar(): void {
    if (!this.mostrarFormulario) {
      this.mostrarFormulario = true;
    } else {
      this.guardarPromocion();
    }
  }

  clickEditar(promocion: Promocion): void {
    this.mostrarFormulario = true;
    this.promocionSeleccionada = promocion;
  }

  clickInformacion(promocion: Promocion): void {
    this.mostrarInformacion = true;
    this.promocionSeleccionada = promocion;
  }

  clickEliminar(promocion: Promocion): void {
    this.promocionSeleccionada = promocion;
    this.dialogoConfirmacion = true;
  }

  cerrarDialogoConfirmacion(): void {
    this.dialogoConfirmacion = false;
    this.promocionSeleccionada = null;
  }

  mostrarMensaje(mensaje: string): void {
    this.dialogoMensaje = {
      mensaje,
      model: true,
    };
  }

  obtenerPromocion(): PromocionPatch {
    const inicio = this.formulario.inicio.toDate();
    inicio.setHours(0, 0, 0, 0);
    const fin = this.formulario.fin.toDate();
    fin.setHours(23, 59, 59, 999);
    const promocion: PromocionPatch = {
      fin: fin.toISOString(),
      inicio: inicio.toISOString(),
      nombre: this.formulario.nombre,
      codigo: this.formulario.codigo,
      descuento: this.formulario.descuento,
    };
    if (this.promocionSeleccionada) promocion.id = this.promocionSeleccionada.id;
    return promocion;
  }

  async guardarPromocion(): Promise<void> {
    this.cargando = true;
    const promocion = this.obtenerPromocion();
    await this.setPromocion(promocion);
    this.cargando = false;
    let mensaje = `Promoción "${promocion.nombre?.capitalizeFirstLetter()}" creada exitosamente.`;
    if (this.promocionSeleccionada) mensaje = "¡Cambios guardados exitosamente!";
    this.mostrarMensaje(mensaje);
    this.limpiar();
  }

  eliminarPromocion(): void {
    if (!this.promocionSeleccionada?.id) return;
    this.deletePromocion(this.promocionSeleccionada.id);
    const nombre = this.promocionSeleccionada.nombre.capitalizeFirstLetter();
    this.mostrarMensaje(`Promoción "${nombre}" eliminada exitosamente.`);
    this.limpiar();
  }
}
