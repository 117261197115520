
// Decoradores
import {Vue, Component} from 'vue-property-decorator';

// Vuex
import {mapActions, mapGetters} from 'vuex';

// Helpers
import routeGuard from "@/helpers/routeGuard";

// Tipos
import {Curso} from '@/typings/store/plugins/easyFirestore/cursos';
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";
import {TituloCursosClienteData} from "@/typings/components/cursosCliente/tituloCursosCliente";
import {FiltrosCursosClienteType} from "@/typings/components/cursosCliente/filtrosCursosCliente";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import CardCurso from '@/components/cursos/CardCurso.vue';
import ViewTitle from "@/components/custom/ViewTitle.vue";
import Footer from "@/components/appCorePagina/Footer.vue";
import IconButton from "@/components/custom/IconButton.vue";
import SinCursos from "@/components/cursosCliente/SinCursos.vue";
import SearchTextField from "@/components/custom/SearchTextField.vue";
import TituloCursosCliente from "@/components/cursosCliente/TituloCursosCliente.vue";
import CursosOrdenarPor from "@/components/cursosCliente/OrdenarPorCursosCliente.vue";
import FiltrosCursosCliente from "@/components/cursosCliente/FiltrosCursosCliente.vue";
import CursosDialogoFiltros from "@/components/cursosCliente/DialogoFiltrosCursosCliente.vue";

@Component({
  beforeRouteEnter(_to, _from, next) {
    next(routeGuard);
  },
  computed: mapGetters({
    getCursos: 'cursos/get',
    getUsuario: 'usuario/get',
    getRutasUsuario: "usuario/getRutas",
    getArrayInscripciones: 'inscripciones/getArray'
  }),
  methods: mapActions({
    setPago: 'pagos/set',
    setCarrito: 'carritos/set'
  }),
  components: {
    Footer,
    SvgIcon,
    ViewTitle,
    CardCurso,
    SinCursos,
    IconButton,
    SearchTextField,
    CursosOrdenarPor,
    TituloCursosCliente,
    FiltrosCursosCliente,
    CursosDialogoFiltros
  }
})
export default class VistaMisCursos extends Vue {
  created(): void {
    if (!routeGuard(this)) return;
    window.scrollTo(0, 0);
  }

  dataTitulo: TituloCursosClienteData = {
    buscar: "",
    ordenar: null
  };
  dialogoFiltros = false;
  filtros: FiltrosCursosClienteType = {
    relatores: [],
    categorias: [],
    rangoPrecio: []
  };

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get cursos(): Array<Curso> {
    const result: Array<Curso> = [];
    for (const inscripcion of this.getArrayInscripciones) {
      if (inscripcion.cliente.id === this.getUsuario?.id) {
        for (const id in inscripcion.pago.cursos) {
          const cursoReal = this.getCursos[id];
          if (cursoReal && cursoReal.estado === "publicado") result.push(JSON.parse(JSON.stringify(cursoReal)));
        }
      }
    }
    return result;
  }

  get arrayCursos(): Array<Curso> {
    return this.cursos.filter(curso => {
      const buscar = this.dataTitulo.buscar.toLowerCase();
      const nombre = curso.nombre.toLowerCase();
      const categoria = curso.categoria.nombre.toLowerCase();
      const flagBuscar = buscar ? nombre.includes(buscar) || categoria.includes(buscar) : true;
      const flagCategorias = this.filtros.categorias.length > 0 ? this.filtros.categorias.includes(curso.categoria.nombre) : true;
      const flagRelatores = this.filtros.relatores.length > 0 ? this.filtros.relatores.some(r => !!curso.relatores[r]) : true;
      return flagCategorias && flagBuscar && flagRelatores;
    }).sort((a, b) => {
      if (this.dataTitulo.ordenar === "fecha") {
        const fechaInicioA = a.inicio.toDate();
        const fechaInicioB = b.inicio.toDate();
        return fechaInicioA.getTime() - fechaInicioB.getTime();
      } else if (this.dataTitulo.ordenar === "nombre") {
        return a.nombre.localeCompare(b.nombre);
      }
      return 0;
    });
  }

  get arrayCategorias(): Array<string> {
    const result: Array<string> = [];
    for (const curso of this.cursos) {
      if (!result.includes(curso.categoria.nombre)) {
        result.push(curso.categoria.nombre);
      }
    }
    return result;
  }

  get arrayRelatores(): Array<Usuario> {
    const result: Array<Usuario> = [];
    for (const curso of this.cursos) {
      const relatores = Object.values(curso.relatores);
      for (const relator of relatores) {
        if (!result.some(r => r.id === relator.id)) {
          result.push(relator);
        }
      }
    }
    return result;
  }

  aplicarFiltros(filtros: FiltrosCursosClienteType): void {
    this.filtros = {
      categorias: filtros.categorias.slice(),
      rangoPrecio: filtros.rangoPrecio?.slice() ?? null,
      relatores: filtros.relatores.slice()
    };
    this.dialogoFiltros = false;
  }
}
