
// Vue
import {computed, defineComponent} from "vue";

// Vuetify
import vuetify from "@/plugins/vuetify";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "OrdernarPorNotificaciones",
  components: {
    SvgIcon,
  },
  props: {
    value: {
      required: true,
      type: Number as () => -1 | 0 | 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "input",
  ],
  setup(props, ctx) {
    // VModel

    const model = computed({
      get() {
        if (props.value === -1) return "Más antiguas";
        if (props.value === 1) return "Más recientes";
        return "Seleccione";
      },
      set(value) {
        if (value === "antiguas") {
          if (props.value === -1) {
            ctx.emit("input", 0);
          } else {
            ctx.emit("input", -1);
          }
        } else if (value === "recientes") {
          if (props.value === 1) {
            ctx.emit("input", 0);
          } else {
            ctx.emit("input", 1);
          }
        }
      },
    });

    // Data

    const items = [{
      value: "antiguas",
      text: "Más antiguas",
    }, {
      value: "recientes",
      text: "Más recientes",
    }];

    // Computed

    const mobile = computed(() => vuetify.framework.breakpoint.mdAndDown);

    return {
      // VModel
      model,
      // Data
      items,
      // Computed
      mobile,
    };
  },
});
