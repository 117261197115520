
// Vue
import {defineComponent} from "vue";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "VistaCargando",
  components: {
    SvgIcon
  }
});
