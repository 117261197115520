
// Vue
import {computed, defineComponent} from "vue";

// Tipos
import {Pago} from "@/typings/store/plugins/easyFirestore/pagos";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import TextButton from "@/components/custom/TextButton.vue";

export default defineComponent({
  name: "ResultadoExito",
  components: {
    SvgIcon,
    TextButton,
  },
  props: {
    pago: {
      type: Object as () => Pago | null,
    },
  },
  setup(props) {
    // Data

    const nombreComercio = "UEPYM";
    const metodos = {
      VD: "Débito",
      VN: "Crédito",
      VC: "Crédito",
      SI: "Crédito",
      S2: "Crédito",
      NC: "Crédito",
      VP: "Prepago",
    };

    // Computed

    const monto = computed(() => props.pago?.monto?.toCurrency("$") ?? "");

    const codigoAutorizacion = computed(() => props.pago?.resultado?.authorization_code ?? "");

    const fecha = computed(() => props.pago?.resultado?.transaction_date?.toDate().format("DD/MM/YYYY") ?? "");

    const tipoPago = computed(() => {
      if (!props.pago?.resultado) return "";
      return metodos[props.pago.resultado.payment_type_code] ?? "";
    });

    const cantidadCuotas = computed(() => props.pago?.resultado?.installments_amount ?? "");

    const montoCuota = computed(() => props.pago?.resultado?.installments_amount ?? "");

    const cuatroUltimos = computed(() => props.pago?.resultado?.card_detail?.card_number ?? "");

    const productos = computed(() => {
      const cursos: string[] = [];
      for (const id in props.pago?.cursos) {
        const curso = props.pago?.cursos[id];
        cursos.push(curso.nombre);
      }
      return cursos.join("\n");
    });

    return {
      // Data
      metodos,
      nombreComercio,
      // Computed
      monto,
      fecha,
      tipoPago,
      productos,
      montoCuota,
      cuatroUltimos,
      cantidadCuotas,
      codigoAutorizacion,
    };
  },
});
