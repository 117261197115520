
// Decoradores
import {Component, Emit, Prop, Vue} from "vue-property-decorator";

// helpers
import {toCurrency} from "@/helpers/number";
import {metodoPago} from "@/helpers/pagos";

// Tipos
import {DataTableHeader} from "vuetify";
import {ItemTablaPagos} from "@/typings/components/pagos/tabla";
import {Pago} from "@/typings/store/plugins/easyFirestore/pagos";

// Componentes
import IconButton from "@/components/custom/IconButton.vue";
import DataTable from "@/components/custom/DataTable.vue";
import {capitalize} from "@/helpers/string";

@Component({
  components: {
    DataTable,
    IconButton,
  },
})
export default class TablaPagos extends Vue {
  @Prop() readonly buscar!: string;
  @Prop() readonly arrayPagos!: Array<Pago>;

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get headers(): DataTableHeader[] {
    if (this.mobile) {
      return [{
        text: "Fecha",
        value: "fecha",
      }, {
        align: "end",
        text: "Monto",
        value: "monto",
        cellClass: "justify-end",
      }, {
        text: "",
        value: "acciones",
        sortable: false,
      }];
    }
    return [{
      text: "Fecha",
      value: "fecha",
    }, {
      text: "Hora",
      value: "hora",
    }, {
      text: "Nombre",
      value: "nombre",
    }, {
      text: "Método de pago",
      value: "metodoPago",
    }, {
      align: "end",
      text: "Monto",
      value: "monto",
      cellClass: "justify-end",
      sort: (a: string, b: string) => {
        return a.toInt() - b.toInt();
      }
    }, {
      text: "",
      value: "acciones",
      sortable: false,
    }];
  }

  get items(): ItemTablaPagos[] {
    return this.arrayPagos.map((pago) => {
      const data = pago;
      const id = pago.id || "";
      const monto = toCurrency(pago.monto ?? 0);
      const nombre = capitalize(pago.cliente.nombre);
      const _metodoPago = capitalize(metodoPago(pago));
      const fechaIso = pago.resultado?.transaction_date || pago.fecha;
      const hora = new Date(fechaIso).toLocaleTimeString();
      const fecha = new Date(fechaIso).format("DD/MM/YYYY");
      return {
        id,
        hora,
        data,
        fecha,
        monto,
        nombre,
        metodoPago: _metodoPago,
      };
    });
  }

  get templateColumns(): string {
    if (this.mobile) {
      return "1fr 1fr 1.25rem";
    } else {
      return "1fr 1fr 1fr 1fr 1fr 1.25rem";
    }
  }

  get buttonIcon(): string {
    return this.mobile ? "fas-chevron-right" : "far-info-circle";
  }

  @Emit("click:informacion")
  onClickInformacion({data}: ItemTablaPagos): Pago {
    return data;
  }
}
