export const fechaLocal = (fecha: string | Date = new Date()): string => {
  const _date = new Date(fecha);
  return (
    _date.getFullYear().toString() +
    "-" +
    (_date.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    _date.getDate().toString().padStart(2, "0")
  );
};

export const toDate = (fecha: string | Date | null): Date | null => {
  if (fecha === null) return null;
  let _date = new Date(fecha);
  if (typeof fecha === "string" && !fecha.endsWith("Z")) {
    _date = new Date(fecha.replaceAll("-", "/"));
  }
  if (!isDate(_date)) {
    return null;
  }
  return _date;
};

export const horaLocal = (fecha = new Date()): string => {
  return (
    fecha.getHours().toString().padStart(2, "0") +
    ":" +
    fecha.getMinutes().toString().padStart(2, "0") +
    ":" +
    fecha.getSeconds().toString().padStart(2, "0")
  );
};

export const horaLocalCorta = (fecha = new Date()): string => {
  return (
    fecha.getHours().toString().padStart(2, "0") +
    ":" +
    fecha.getMinutes().toString().padStart(2, "0")
  );
};

export const meses = (
  fechaInicio = new Date(),
  fechaFin = new Date()
): number => {
  return (
    (fechaFin.valueOf() - fechaInicio.valueOf()) /
    (1000 * 60 * 60 * 24 * 30.4375)
  );
};

export const isDate = (value: string | Date): boolean => {
  const prueba = new Date(value);
  return prueba.toString() !== "Invalid Date";
};

export const toFormatString = (date: Date, format: string): string => {
  if (date.toString().toLowerCase() === "invalid date") return "";
  let fechaReturn = format;
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre"
  ];
  const days = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado"
  ];
  const getMonth = date.getMonth();

  const getYear = date.getFullYear().toString();
  const getAMPM = date.getHours() >= 12 ? "PM" : "AM";
  const getDay = date.getDay() === 7 ? 0 : date.getDay();
  const getDate = date.getDate().toString().padStart(2, "0");
  const getHours = date.getHours().toString().padStart(2, "0");
  const getMinutes = date.getMinutes().toString().padStart(2, "0");
  const getSeconds = date.getSeconds().toString().padStart(2, "0");
  const getMonthString = (getMonth + 1).toString().padStart(2, "0");
  fechaReturn = fechaReturn.replace("DDDD", days[getDay]);
  fechaReturn = fechaReturn.replace("DDD", days[getDay].substring(0, 3));
  fechaReturn = fechaReturn.replace("DD", getDate);
  fechaReturn = fechaReturn.replace("MMMM", months[getMonth]);
  fechaReturn = fechaReturn.replace("MMM", months[getMonth].substring(0, 3));
  fechaReturn = fechaReturn.replace("MM", getMonthString);
  fechaReturn = fechaReturn.replace("YYYY", getYear);
  fechaReturn = fechaReturn.replace("YY", getYear.substring(2, 4));
  fechaReturn = fechaReturn.replace("HH", getHours);
  fechaReturn = fechaReturn.replace("mm", getMinutes);
  fechaReturn = fechaReturn.replace("ss", getSeconds);
  fechaReturn = fechaReturn.replace("tt", getAMPM);
  return fechaReturn;
};
