
// Decoradores
import {Component, Emit, Prop, VModel, Vue} from "vue-property-decorator";

// Tipos
import {Promocion} from "@/typings/store/plugins/easyFirestore/promociones";
import {FormularioPromocionData} from "@/typings/components/promociones/formularioPromocion";

// Componentes
import TextButton from "@/components/custom/TextButton.vue";
import PageTextField from "@/components/custom/PageTextField.vue";
import PageDatePicker from "@/components/custom/PageDatePicker.vue";

@Component({
  components: {
    TextButton,
    PageTextField,
    PageDatePicker,
  },
})
export default class FormularioPromocion extends Vue {
  @VModel() model!: FormularioPromocionData;

  @Prop() readonly cargando!: boolean;
  @Prop() readonly readonly!: boolean;
  @Prop() readonly promocion!: Promocion | null;

  created(): void {
    if (!this.promocion) return;
    this.model = {
      fin: this.promocion.fin,
      nombre: this.promocion.nombre,
      codigo: this.promocion.codigo,
      inicio: this.promocion.inicio,
      descuento: this.promocion.descuento,
    };
  }

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get textoBoton(): string {
    if (this.promocion) return "GUARDAR";
    return "CREAR";
  }

  @Emit("click:agregar")
  onClickAgregar(): void {
    return;
  }

  allowedDatesInicio(fecha: string): boolean {
    const now = new Date();
    const date = fecha.toDate();
    now.setHours(0, 0, 0, 0);
    return date.getTime() >= now.getTime();
  }

  allowedDatesFin(fecha: string): boolean {
    if (!this.model.inicio) return false;
    const date = fecha.toDate();
    const inicio = this.model.inicio.toDate();
    return date.isAfter(inicio);
  }
}
