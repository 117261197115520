import { render, staticRenderFns } from "./FiltrosNotificaciones.vue?vue&type=template&id=ae5a9c56&scoped=true&"
import script from "./FiltrosNotificaciones.vue?vue&type=script&lang=ts&"
export * from "./FiltrosNotificaciones.vue?vue&type=script&lang=ts&"
import style0 from "./FiltrosNotificaciones.vue?vue&type=style&index=0&id=ae5a9c56&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae5a9c56",
  null
  
)

export default component.exports