
// Vue
import {computed, defineComponent, ref} from "vue";

// Vuetify
import vuetify from "@/plugins/vuetify";

// Router
import router from "@/router";

// Types
import {CategoriaCurso} from "@/typings/store/plugins/easyFirestore/categoriasCurso";

// Components
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "AreasInteres",
  components: {SvgIcon},
  props: {
    categorias: {
      required: true,
      type: Array as () => CategoriaCurso[],
    },
  },
  setup() {
    // Data

    const cards = ref<HTMLDivElement | null>(null);

    // Computed

    const mobile = computed(() => vuetify.framework.breakpoint.mdAndDown);

    // Methods

    const clickCard = (categoria: CategoriaCurso) => {
      router.push({
        name: "cursosDisponibles",
        params: {
          categoria: categoria.nombre,
        },
      });
    };

    const styleCard = (categoria: CategoriaCurso) => {
      if (!categoria.imagen?.url) return "";
      return {
        'background-image': `url(${categoria.imagen?.url})`,
      };
    };

    const moveScroll = (direction: number) => {
      if (!cards.value) return;
      const scrollLeft = cards.value.scrollLeft;
      const width = Math.ceil(((cards.value.offsetWidth - 96) / 5) + 24) * direction;
      cards.value.scrollTo({
        top: 0,
        behavior: "smooth",
        left: scrollLeft + width,
      });
    };

    return {
      // Data
      cards,
      // Computed
      mobile,
      // Methods
      styleCard,
      clickCard,
      moveScroll,
    };
  },
});
