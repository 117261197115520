import {Modulos} from "@/typings/store/plugins/easyFirestore/iniciar/modulos";

// si permisos = [] todos pueden acceder; value de parametro obtiene su valor del objeto Usuario (id = usuario.id)
const modulos: Modulos = [
  {nombre: "inscripciones", permisos: [], parametros: [], where: []},
  {nombre: "pagos", permisos: [], parametros: [], where: []},
  {nombre: "notificaciones", permisos: [], parametros: [], where: []},
  {nombre: "carritos", permisos: [], parametros: [], where: []},
  {nombre: "videosVistos", permisos: [], parametros: [], where: []},
  {nombre: "promocionesUsuario", permisos: [], parametros: [], where: []},
  {nombre: "encuestas", permisos: [], parametros: [], where: []},
  {nombre: "notificacionesLeidas", permisos: [], parametros: [], where: []},
  {nombre: "archivos", permisos: [], parametros: [], where: []},
];

export default modulos;
