
// Decoradores
import {Component, Prop, Vue} from "vue-property-decorator";

// Helpers
import {toCurrency} from "@/helpers/number";
import Calculadora from "@/helpers/Calculadora";

@Component
export default class RecaudacionGanancias extends Vue {
  @Prop() readonly calculadora!: Calculadora;

  get recaudacion(): string {
    return toCurrency(this.calculadora.recaudacion);
  }

  get ganancias(): string {
    return toCurrency(this.calculadora.ganancias);
  }
}
