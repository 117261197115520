
// Decoradores
import {Component, Prop, VModel, Vue} from "vue-property-decorator";

// Tipos
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon
  }
})
export default class ImagenUsuario extends Vue {
  @VModel() file!: File | null;
  @Prop() readonly usuario!: Usuario | null;
  @Prop() readonly readonly!: boolean;

  get url(): string {
    if (this.file) return URL.createObjectURL(this.file);
    return this.usuario?.perfil?.imagen?.url ?? "";
  }

  clickCambiar(): void {
    const fileInput = this.$refs.fileInput as HTMLInputElement;
    fileInput.click();
  }

  onFileChange(e: InputEvent): void {
    const fileInput = e.target as HTMLInputElement;
    this.file = fileInput.files?.[0] ?? null;
  }
}
