
// Decoradores
import {Component, Emit, Prop, Vue} from "vue-property-decorator";

// Tipos
import {Notificacion} from "@/typings/store/plugins/easyFirestore/notificaciones";

// Componentes
import SignOutButton from "@/components/custom/SignOutButton.vue";
import AvatarAccionesAppBar from "./accionesAppBar/AvatarAccionesAppBar.vue";
import MenuNotificaciones from "@/components/appCore/appBar/accionesAppBar/MenuNotificaciones.vue";

@Component({
  components: {
    SignOutButton,
    MenuNotificaciones,
    AvatarAccionesAppBar,
  },
})
export default class AccionesAppBar extends Vue {
  @Prop() readonly imagenAvatar?: string;
  @Prop() readonly nombreUsuario?: string;
  @Prop() readonly itemsNotificacionesPendientes!: Notificacion[];

  @Emit("click:avatar")
  onClickAvatar(): void {
    return;
  }

  @Emit("click:sign-out")
  onClickSignOut(): void {
    return;
  }

  @Emit("click:notificacion")
  onClickNotificacion(notificacion: Notificacion): Notificacion {
    return notificacion;
  }

  @Emit("click:marcar-todas-leidas")
  onClickMarcarTodasLeidas(): void {
    return;
  }
}
