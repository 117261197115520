
// Vue
import {computed, defineComponent} from "vue";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import vuetify from "@/plugins/vuetify";
import PageSelect from "@/components/custom/PageSelect.vue";

export default defineComponent({
  name: "BotonDescargarPagos",
  components: {
    PageSelect,
    SvgIcon
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(_props,ctx) {
    // Data

    const items = [{
      value: "pdf",
      text: "Informe PDF"
    }, {
      value: "excel",
      text: "Informe Excel"
    }];

    // Computed

    const mobile = computed(() => vuetify.framework.breakpoint.mdAndDown);

    const handleclick=({value}:{value:"pdf" | "excel"})=> {
      if (value==="pdf"){
        ctx.emit("click:pdf")
      } else {ctx.emit("click:excel")}
    }

    return {
      // Data
      items,
      // Computed
      mobile,
      // Emit
      handleclick
    };
  }
});
