
// Decoradores
import {Component, Prop, Vue} from "vue-property-decorator";

// Tipos
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";
import {Inscripcion} from "@/typings/store/plugins/easyFirestore/inscripciones";

@Component
export default class TotalUsuarios extends Vue {
  @Prop() readonly usuarios!: Usuario[];
  @Prop() readonly usuario!: Usuario | null;
  @Prop() readonly inscripciones!: Inscripcion[];

  get inscritos(): number {
    if (this.usuario?.tipo === "admin") return this.inscripciones.length;
    return this.inscripciones.filter(_inscripcion => {
      const cursos = Object.values(_inscripcion.pago.cursos);
      return cursos.some(_curso => this.usuario && !!_curso.relatores[this.usuario.id]);
    }).length;
  }

  get clientes(): number {
    return this.usuarios.filter(_usuario => _usuario.tipo === "cliente").length;
  }

  get relatores(): number {
    return this.usuarios.filter(_usuario => _usuario.tipo === "relator" && _usuario.uid).length;
  }

  get items(): { text: string; value: number }[] {
    const items = [{
      text: "Total inscritos",
      value: this.inscritos,
    }, {
      text: "Clientes registrados",
      value: this.clientes,
    }];
    if (this.usuario?.tipo === "admin") {
      items.push({
        text: "Relatores registrados",
        value: this.relatores,
      });
    }
    return items;
  }
}
