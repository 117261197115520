
// Decoradores
import {Component, Prop, Vue} from "vue-property-decorator";

// Google Charts
import {GChart} from 'vue-google-charts/legacy';
import {GoogleChartOptions} from "vue-google-charts/dist/types";

// Tipos
import {DateGroup} from "@/typings/vista/monitoreo";
import {Inscripcion} from "@/typings/store/plugins/easyFirestore/inscripciones";
import {TipoUsuario, Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";

interface DataItem {
  [key: string]: {
    clientes: number;
    relatores: number;
    inscritos: number;
  };
}

@Component({
  components: {
    GChart
  }
})
export default class GraficoUsuarios extends Vue {
  @Prop() readonly usuarios!: Usuario[];
  @Prop() readonly usuario!: Usuario | null;
  @Prop() readonly dateGroups!: DateGroup[];
  @Prop() readonly inscripciones!: Inscripcion[];
  @Prop() readonly tipoUsuario!: TipoUsuario | null;

  get items(): DataItem {
    const items: DataItem = {};
    for (const {id} of this.dateGroups) {
      const item = {
        clientes: 0,
        relatores: 0,
        inscritos: 0
      };
      this.$set(items, id, item);
    }
    for (const usuario of this.usuarios) {
      const key = this.getDateGroupId(usuario.fecha);
      if (!key) continue;
      if (usuario.tipo === "cliente") items[key].clientes++;
      if (usuario.tipo === "relator") items[key].relatores++;
    }
    for (const inscripcion of this.inscripciones) {
      const key = this.getDateGroupId(inscripcion.fecha);
      if (!key || !this.tieneRelator(inscripcion)) continue;
      items[key].inscritos++;
    }
    return items;
  }

  get chartData(): (string | number)[][] {
    const chartData: (string | number)[][] = [
      ["Día", "Clientes", "Inscritos"]
    ];
    if (this.tipoUsuario === "admin") {
      chartData[0].push("Relatores");
    }
    for (const [key, value] of Object.entries(this.items)) {
      const newData = [key, value.clientes, value.inscritos];
      if (this.tipoUsuario === "admin") {
        newData.push(value.relatores);
      }
      chartData.push(newData);
    }
    return chartData;
  }

  get chartOptions(): GoogleChartOptions {
    return {
      isStacked: true,
      series: {
        0: {
          color: this.$vuetify.theme.themes.light.dark as string
        },
        1: {
          color:"#4E99FF"
        },
        2: {
          color: this.$vuetify.theme.themes.light.accent as string
        }
      },
      hAxis: {
        textStyle: {
          color: '#707070',
          fontName: 'SF UI Display'
        }
      },
      vAxis: {
        textStyle: {
          color: '#707070',
          fontName: 'SF UI Display'
        }
      }
    };
  }

  getDateGroupId(fecha: string): string | null {
    const date = new Date(fecha);
    return this.dateGroups.find(({start, end}) => date.isBetween(start, end))?.id ?? null;
  }

  tieneRelator(inscripcion: Inscripcion): boolean {
    if (this.usuario?.tipo === "admin") return true;
    const cursos = Object.values(inscripcion.pago.cursos);
    const idUsuario = this.usuario?.id;
    return idUsuario ? cursos.some(_curso => !!_curso.relatores[idUsuario]) : true;
  }
}
