
// Decorades
import {Component, VModel, Vue} from "vue-property-decorator";

// Tipos
import {TituloNotificacionesData} from "@/typings/components/notificaciones/tituloNotificaciones";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import ViewTitle from "@/components/custom/ViewTitle.vue";
import SearchTextField from "@/components/custom/SearchTextField.vue";
import OrdenarPorNotificaciones from "@/components/notificaciones/OrdenarPorNotificaciones.vue";

@Component({
  components: {
    SvgIcon,
    ViewTitle,
    SearchTextField,
    OrdenarPorNotificaciones,
  },
})
export default class TituloNotificaciones extends Vue {
  @VModel() model!: TituloNotificacionesData;

  buscar = "";
  buscando = false;

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get mostrarBuscar(): boolean {
    if (this.mobile) return this.buscando;
    return true;
  }
}
