
// Decoradores
import {Component, Emit, Vue, Watch} from 'vue-property-decorator';

// Helpers
import routeGuard from "@/helpers/routeGuard";

// Vuex
import {mapActions, mapGetters} from "vuex";

// Tipos
import {Cupo} from "@/typings/store/plugins/easyFirestore/cupos";
import {Curso} from "@/typings/store/plugins/easyFirestore/cursos";
import {Sesion} from "@/typings/store/plugins/easyFirestore/sesiones";
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";
import {Carrito, CursoCarrito} from "@/typings/store/plugins/easyFirestore/carritos";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import UsersList from "@/components/custom/UsersList.vue";
import Footer from "@/components/appCorePagina/Footer.vue";
import IconButton from "@/components/custom/IconButton.vue";
import TextButton from "@/components/custom/TextButton.vue";
import DialogoAgregado from "@/components/informacionCurso/DialogoAgregado.vue";
import MessageDialog from "@/components/custom/MessageDialog.vue";
import DialogoValidando from "@/components/informacionCurso/DialogoValidando.vue";

@Component({
  beforeRouteEnter(_to, _from, next) {
    next(routeGuard);
  },
  components: {
    DialogoValidando,
    Footer,
    SvgIcon,
    UsersList,
    TextButton,
    IconButton,
    MessageDialog,
    DialogoAgregado
  },
  methods: mapActions({
    setCupo: "cupos/set",
    setCarrito: "carritos/set",
    deleteCarrito: "carritos/delete"
  }),
  computed: mapGetters({
    getCursos: "cursos/get",
    getUsuario: "usuario/get",
    getUsuarios: "usuarios/get",
    getArrayCupos: "cupos/getArray",
    getCarrito: "usuario/getCarrito",
    getRutasUsuario: "usuario/getRutas",
    getArraySesiones: "sesiones/getArray",
    getArrayInscripciones: "inscripciones/getArray",
    getArraySesionesProgramadas: "sesionesProgramadas/getArray"
  })
})
export default class VistaInformacionCurso extends Vue {
  created(): void {
    if (!routeGuard(this)) return;
    window.scrollTo(0, 0);
  }

  cargando = false;
  timeoutId: number | null = null;
  dialogoAgregado = {
    texto: "",
    model: false
  };
  dialogoMensaje = {
    mensaje: "",
    model: false
  };

  get carrito(): Carrito | null {
    return this.getCarrito;
  }

  get enElCarrito(): boolean {
    if (!this.carrito || !this.curso.id) return false;
    return Object.keys(this.carrito.cursos).includes(this.curso.id);
  }

  get comprado(): boolean {
    return this.getArrayInscripciones.some(inscripcion => {
      if (this.getUsuario?.id !== inscripcion.cliente.id) return false;
      const cursos = Object.values(inscripcion.pago.cursos);
      return cursos.some(curso => curso.id === this.curso?.id);
    });
  }

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get curso(): Curso {
    return this.getCursos[this.$route.params.id];
  }

  get nombreCurso(): string {
    return this.curso?.nombre ?? "";
  }

  get imagenCurso(): string {
    return this.curso?.imagen?.url ?? "";
  }

  get precioRef(): string {
    return this.curso.precioRef?.toCurrency("$") || '';
  }

  get valorCurso(): string {
    const valor = this.curso?.precio?.toCurrency("$") ?? "";
    return `${this.precioRef ? '- ' : ''}${valor}`;
  }

  get resumenCurso(): string {
    return this.curso?.resumen?.capitalizeFirstLetter() ?? "";
  }

  get descripcionCurso(): string {
    return this.curso?.descripcion?.capitalizeFirstLetter() ?? "";
  }

  get entregaCertificado(): string {
    return this.curso?.certificado ? "SÍ" : "NO";
  }

  get relatoresCurso(): Usuario[] {
    const relatores: Usuario[] = [];
    const relatoresCurso = Object.values(this.curso?.relatores ?? {});
    for (const relator of relatoresCurso) {
      const usuario = this.getUsuarios[relator.id] ?? relator;
      relatores.push(usuario);
    }
    return relatores;
  }

  get fechaInicioCurso(): string {
    return this.curso?.inicio?.toDate()?.format("DD/MM/YYYY") ?? "";
  }

  get fechaFinCurso(): string {
    return this.curso?.fin?.toDate()?.format("DD/MM/YYYY") ?? "";
  }

  get tipoCurso(): string {
    const grabada = this.sesionesCurso.every(sesion => sesion.tipo === "grabada");
    const enVivo = this.sesionesCurso.every(sesion => sesion.tipo === "en-vivo");
    if (grabada) return "Grabada";
    if (enVivo) return "Online";
    return "Mixto";
  }

  get sesionesCurso(): Sesion[] {
    return this.getArraySesiones.filter(sesion => sesion.curso.id === this.curso?.id).sort((a, b) => a.numero - b.numero);
  }

  get cuposCurso(): Cupo[] {
    return this.getArrayCupos.filter(_cupo => {
      const flagNoUsuario = !_cupo.usuario;
      const flagDisponible = _cupo.estado === "libre";
      const flagCurso = _cupo.curso.id === this.curso.id;
      return flagNoUsuario && flagDisponible && flagCurso;
    });
  }

  get arrayCuposCurso(): Cupo[] {
    return this.getArrayCupos.filter(_cupo => _cupo.curso.id === this.curso.id);
  }

  @Emit("iniciar-sesion")
  onIniciarSesion(): void {
    return;
  }

  @Watch("arrayCuposCurso")
  onGetArrayCuposChange(val: Cupo[]): void {
    if (this.timeoutId) {
      window.clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
    if (!this.cargando) return;
    const cupoUsado = val.find(_cupo => _cupo.usuario?.id === this.getUsuario?.id);
    if (cupoUsado) {
      const nombre = cupoUsado.curso.nombre.capitalizeFirstLetter();
      this.dialogoAgregado = {
        model: true,
        texto: `El curso ${nombre} ha sido añadido al carrito.`
      };
    } else {
      this.deleteCarrito(`${this.getUsuario?.id}.cursos.${this.curso.id}`);
      this.dialogoMensaje = {
        model: true,
        mensaje: "Desafortunadamente, este curso no tiene cupos disponibles."
      };
    }
    this.cargando = false;
  }

  verMas(id: string): void {
    this.$router.push({name: 'relatores', params: {id}});
  }

  mostrarVerClase(sesion: Sesion): boolean {
    if (!sesion.fecha) return false;
    const fechaInicio = sesion.fecha.toDate();
    const fechaFin = sesion.fecha.toDate();
    fechaFin.setMinutes(fechaFin.getMinutes() + sesion.duracion);
    return new Date().isBetween(fechaInicio, fechaFin);
  }

  mostrarIrReproductor(sesion: Sesion): boolean {
    return sesion.tipo === "grabada" || sesion.resubida;
  }

  verClase(sesion: Sesion): void {
    window.open(sesion.link, "_blank");
  }

  irReproductor(sesion: Sesion): void {
    if (!sesion.id) return;
    this.$router.push({name: 'reproductor', params: {sid: sesion.id}});
  }

  clickLoQuiero(): void {
    if (!this.curso.id) return;
    if (!this.getUsuario) {
      this.onIniciarSesion();
      return;
    }
    const carritoClone = this.carrito ? JSON.parse(JSON.stringify(this.carrito)) : null;
    const carrito: Carrito = carritoClone ?? {
      cursos: {},
      id: this.getUsuario.id,
      estado: "editado",
      promocion: null,
      usuario: this.getUsuario
    };
    const cursoCarrito: CursoCarrito = {
      id: this.curso.id,
      curso: JSON.parse(JSON.stringify(this.curso)),
      estado: "pendiente"
    };
    carrito.estado = "editado";
    this.$set(carrito.cursos, this.curso.id, cursoCarrito);
    this.setCarrito(carrito);
    this.cargando = true;
    this.timeoutId = window.setTimeout(() => {
      this.cargando = false;
      this.deleteCarrito(`${this.getUsuario?.id}.cursos.${this.curso.id}`);
      this.dialogoMensaje = {
        model: true,
        mensaje: "Ha ocurrido un error, intente nuevamente."
      };
    }, 10000);
  }

  getFechaSesion(sesion: Sesion): string {
    const fechaSesion = sesion.fecha?.toDate();
    if(!fechaSesion) return "";
    const now = Date.now();
    if(now > fechaSesion.getTime() + (sesion.duracion * 60000)) return "Finalizada";
    return fechaSesion.format("DD/MM/YYYY a partir de las HH:mm");
  }

  getNombreRelator(sesion: Sesion): string {
    if (!sesion.relator) return "Sin relator";
    const relator = this.getUsuarios[sesion.relator.id];
    return relator?.nombre ?? "";
  }

  getDuracionClase({duracion}: Sesion): string {
    return `${duracion} ${duracion === 1 ? "minuto" : "minutos"}`;
  }
}
