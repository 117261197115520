
// Decoradores
import {Vue, Component} from 'vue-property-decorator';

// Vuex
import {mapActions, mapGetters} from 'vuex';

// Helpers
import routeGuard from "@/helpers/routeGuard";

// Tipos
import {Cupo} from "@/typings/store/plugins/easyFirestore/cupos";
import {Curso} from '@/typings/store/plugins/easyFirestore/cursos';
import {TituloCursosClienteData} from "@/typings/components/cursosCliente/tituloCursosCliente";
import {FiltrosCursosClienteType} from '@/typings/components/cursosCliente/filtrosCursosCliente';

// Componentes
import CardCurso from '@/components/cursos/CardCurso.vue';
import ViewTitle from "@/components/custom/ViewTitle.vue";
import Footer from "@/components/appCorePagina/Footer.vue";
import TituloCursosCliente from "@/components/cursosCliente/TituloCursosCliente.vue";
import FiltrosCursosCliente from "@/components/cursosCliente/FiltrosCursosCliente.vue";
import DialogoFiltrosCursosCliente from "@/components/cursosCliente/DialogoFiltrosCursosCliente.vue";

@Component({
  beforeRouteEnter(_to, _from, next) {
    next(routeGuard);
  },
  computed: mapGetters({
    getUsuario: "usuario/get",
    getArrayCupos: 'cupos/getArray',
    getArrayCursos: 'cursos/getArray',
    getRutasUsuario: "usuario/getRutas",
    getArrayInscripciones: 'inscripciones/getArray',
  }),
  methods: mapActions({
    setPago: 'pagos/set',
    setCarrito: 'carritos/set',
  }),
  components: {
    Footer,
    ViewTitle,
    CardCurso,
    TituloCursosCliente,
    FiltrosCursosCliente,
    DialogoFiltrosCursosCliente,
  },
})
export default class VistaCursosDisponibles extends Vue {
  created(): void {
    if (!routeGuard(this)) return;
    if (this.$route.params?.categoria) {
      this.filtros.categorias.push(this.$route.params.categoria);
    }
    window.scrollTo(0, 0);
  }

  dialogoFiltros = false;
  dataTitulo: TituloCursosClienteData = {
    buscar: '',
    ordenar: null,
  };
  rangosPrecio: { [key: string]: number[] } = {
    0: [0, 100000],
    1: [101000, 200000],
    2: [201000, 300000],
    3: [301000, 999999999],
  };
  filtros: FiltrosCursosClienteType = {
    relatores: [],
    categorias: [],
    rangoPrecio: [],
  };

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get arrayCursos(): Array<Curso> {
    const now00 = new Date();
    now00.setHours(0, 0, 0, 0);
    return this.getArrayCursos.filter(curso => {
      const flagCuposInicio = (this.cuposCurso(curso) > 0 || curso.inicio.toDate() >= now00);
      const flags = [
        flagCuposInicio,
        this.buscarCurso(curso),
        !this.cursoComprado(curso),
        curso.estado === "publicado",
        this.filtrarCategoria(curso),
        this.filtrarRangoPrecio(curso),
      ];
      return flags.every(flag => flag);
    }).sort((a, b) => {
      if (this.dataTitulo.ordenar === "fecha") {
        const fechaInicioA = a.inicio.toDate();
        const fechaInicioB = b.inicio.toDate();
        return fechaInicioA.getTime() - fechaInicioB.getTime();
      } else if (this.dataTitulo.ordenar === "nombre") {
        return a.nombre.localeCompare(b.nombre);
      }
      return 0;
    });
  }

  get cupos(): Cupo[] {
    return this.getArrayCupos;
  }

  get arrayCategorias(): Array<string> {
    const result: Array<string> = [];
    for (const curso of this.getArrayCursos) {
      if (curso.estado === "publicado" && !result.includes(curso.categoria.nombre)) {
        result.push(curso.categoria.nombre);
      }
    }
    return result;
  }

  cursoComprado(curso: Curso): boolean {
    return this.getArrayInscripciones.some(inscripcion => {
      if (this.getUsuario?.id !== inscripcion.cliente.id) return false;
      const cursos = Object.values(inscripcion.pago.cursos);
      return cursos.some(cursoInscrito => cursoInscrito.id === curso.id);
    });
  }

  buscarCurso(curso: Curso): boolean {
    if (!this.dataTitulo.buscar) return true;
    const buscar = this.dataTitulo.buscar.toLowerCase();
    const nombre = curso.nombre.toLowerCase();
    const categoria = curso.categoria.nombre.toLowerCase();
    return nombre.includes(buscar) || categoria.includes(buscar);
  }

  filtrarCategoria(curso: Curso): boolean {
    return this.filtros.categorias.length === 0 || this.filtros.categorias.includes(curso.categoria.nombre);
  }

  filtrarRangoPrecio(curso: Curso): boolean {
    if (this.filtros.rangoPrecio.length === 0) return true;
    const rango = this.rangosPrecio[this.filtros.rangoPrecio[0]];
    return curso.precio >= rango[0] && curso.precio <= rango[1];
  }

  cuposCurso(curso: Curso): number {
    return this.cupos.filter(_cupo => {
      const flagNoUsuario = !_cupo.usuario;
      const flagDisponible = _cupo.estado === "libre";
      const flagCurso = _cupo.curso.id === curso.id;
      return flagNoUsuario && flagDisponible && flagCurso;
    }).length;
  }

  aplicarFiltros(filtros: FiltrosCursosClienteType): void {
    this.filtros = {
      categorias: filtros.categorias.slice(),
      rangoPrecio: filtros.rangoPrecio?.slice() ?? null,
      relatores: filtros.relatores.slice(),
    };
    this.dialogoFiltros = false;
  }
}
