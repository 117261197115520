
// Vue
import {computed, defineComponent, ref} from "vue";

// Components
import SvgIcon from "@/components/custom/SvgIcon.vue";
import TextButton from "@/components/custom/TextButton.vue";

export default defineComponent({
  name: "FormImagePicker",
  components: {
    SvgIcon,
    TextButton,
  },
  props: {
    value: {
      type: File,
    },
    preview: {
      type: String,
    },
  },
  emits: [
    "input",
  ],
  setup(props, ctx) {
    // VModel

    const model = computed({
      get() {
        return props.value;
      },
      set(value) {
        ctx.emit("input", value);
      },
    });

    // Data

    const fileInput = ref<HTMLInputElement | null>(null);

    // Computed

    const url = computed(() => {
      if (props.value) return URL.createObjectURL(props.value);
      if (props.preview) return props.preview;
      return "";
    });

    const containerStyle = computed(() => ({
      'background-image': `url(${url.value})`,
    }));

    // Methods

    const clickInput = () => {
      if (fileInput.value) fileInput.value.click();
    };

    const onFileInputChange = (ev: InputEvent) => {
      const target = ev.target as HTMLInputElement;
      const file = target.files[0];
      if (file) model.value = file;
    };

    return {
      // VModel
      model,
      // Data
      fileInput,
      // Computed
      url,
      containerStyle,
      // Methods
      clickInput,
      onFileInputChange,
    };
  },
});
