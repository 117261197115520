
// Vue
import {computed, defineComponent} from "vue";

// Vuetify
import vuetify from "@/plugins/vuetify";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "SearchTextField",
  components: {
    SvgIcon
  },
  props: {
    value: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean
    }
  },
  emits: [
    "input",
    "click:clear",
    "keydown:enter"
  ],
  setup(props, ctx) {
    // VModel

    const search = computed({
      get() {
        return props.value;
      },
      set(value: string) {
        ctx.emit("input", value);
      }
    });

    // Computed

    const mobile = computed(() => vuetify.framework.breakpoint.mdAndDown);

    const appendIconColor = computed(() => mobile.value ? 'dark' : 'accent');

    const iconsSize = computed(() => mobile.value ? '1.5rem' : '1rem');

    // Methods

    const clickClear = () => {
      search.value = "";
      ctx.emit("click:clear");
    };

    // Emits

    const onKeydownEnter = () => {
      ctx.emit("keydown:enter");
    };

    return {
      // VModel
      search,
      // Computed
      mobile,
      iconsSize,
      appendIconColor,
      // Methods
      clickClear,
      // Emits
      onKeydownEnter
    };
  }
});
