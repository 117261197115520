
// Decoradores
import {Component, Emit, Prop, VModel, Vue, Watch} from "vue-property-decorator";

// Tipos
import {FiltrosUsuarioType} from "@/typings/components/usuarios/filtros";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import DialogTitle from "@/components/custom/DialogTitle.vue";
import FiltrosUsuarios from "@/components/usuarios/FiltrosUsuarios.vue";

@Component({
  components: {
    SvgIcon,
    DialogTitle,
    FiltrosUsuarios
  }
})
export default class DialogoFiltrosUsuarios extends Vue {
  @VModel() model!: boolean;

  @Prop() readonly filtros!: FiltrosUsuarioType;

  filtrosInternos: FiltrosUsuarioType = {
    tipo: [],
    estado: []
  };

  @Watch("model")
  onModelChange(val: boolean): void {
    if (val) {
      this.filtrosInternos = {
        tipo: this.filtros.tipo.slice(),
        estado: this.filtros.estado.slice()
      };
    }
  }

  @Emit("click:cerrar")
  onClickCerrar(): void {
    this.model = false;
  }

  @Emit("click:aplicar")
  onClickAplicar(): FiltrosUsuarioType {
    this.model = false;
    return this.filtrosInternos;
  }
}
