
// Vue
import {computed, defineComponent, ref} from "vue";

// Tipos
import {DataScopeProps, DataTableHeader} from "vuetify";

export default defineComponent({
  name: "DataTable",
  props: {
    items: {
      type: Array,
      required: true
    },
    headers: {
      type: Array as () => DataTableHeader[],
      required: true
    },
    templateColumns: {
      type: String
    },
    search: {
      type: String
    }
  },
  setup(props, ctx) {
    // Data

    const currentPage = ref(1);

    // Computed

    const slots = computed(() => {
      const result: string[] = [];
      for (const header of props.headers) {
        if (checkSlot(header.value)) {
          result.push(header.value);
        }
      }
      return result;
    });

    const tableStyle = computed(() => {
      return {
        '--grid-template-columns': props.templateColumns ?? `repeat(${props.headers.length}, 1fr)`
      };
    });

    const tableClass = computed(() => {
      return {
        'data-table': true,
        'no-data': props.items.length === 0
      };
    });

    // Methods

    const changePage = (page: number) => {
      currentPage.value = page;
    };

    const previousPage = ({pagination}: DataScopeProps) => {
      if (pagination.page > 1) {
        currentPage.value = pagination.page - 1;
      }
    };

    const nextPage = ({pagination}: DataScopeProps) => {
      if (pagination.page < pagination.pageCount) {
        currentPage.value = pagination.page + 1;
      }
    };

    const checkSlot = (name: string) => {
      return ctx.slots[name] !== undefined;
    };

    return {
      // Data
      currentPage,
      // Computed
      slots,
      tableClass,
      tableStyle,
      // Methods
      nextPage,
      changePage,
      previousPage
    };
  }
});
