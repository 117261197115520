
// Decoradores
import { Component, Emit, Prop, VModel, Vue } from "vue-property-decorator";

// Tipos
import { FiltrosPagoType } from "@/typings/components/pagos/filtros";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import FiltrosPagos from "@/components/pagos/FiltrosPagos.vue";
import DialogTitle from "@/components/custom/DialogTitle.vue";

@Component({
  components: {
    DialogTitle,
    SvgIcon,
    FiltrosPagos,
  },
})
export default class DialogoFiltrosPagos extends Vue {
  @VModel() model!: boolean;

  @Prop() readonly filtros!: FiltrosPagoType;

  created(): void {
    this.filtrosInternos = {
      fecha: this.filtros.fecha.slice(),
      tipo: this.filtros.tipo.slice(),
      fechaSeleccionada: this.filtros.fechaSeleccionada,
    };
  }

  filtrosInternos: FiltrosPagoType = {
    tipo: [],
    fecha: [],
    fechaSeleccionada: "",
  };

  @Emit("click:cerrar")
  onClickCerrar(): void {
    this.model = false;
  }

  @Emit("click:aplicar")
  onClickAplicar(): FiltrosPagoType {
    this.model = false;
    return this.filtrosInternos;
  }
}
