import router from "@/router";
import { Curso } from "@/typings/store/plugins/easyFirestore/cursos";

declare module "vue/types/vue" {
  interface Vue {
    $goTo(path: string): Promise<void>;

    $goBack(): void;

    $calculatePercentage(curso: Curso): number;
  }
}

export default {
  methods: {
    async $goTo(path: string): Promise<void> {
      const activeRoute = router.currentRoute.name;
      if (activeRoute === path) return;
      await router.push({ name: path });
    },
    $goBack(): void {
      router.back();
    },
    $calculatePercentage(curso: Curso): number {
      if (!curso.precioRef) return 0;
      return 100 - Math.floor((curso.precio * 100) / curso.precioRef);
    },
  },
};
