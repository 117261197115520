
// Vue
import {computed, defineComponent} from "vue";

// Helpers
import {toFormatString} from "@/helpers/date";

// Vuetify
import vuetify from "@/plugins/vuetify";

// Tipos
import {Sesion} from "@/typings/store/plugins/easyFirestore/sesiones";
import {TipoUsuario} from "@/typings/store/plugins/easyFirestore/usuarios";

export default defineComponent({
  name: "EventoCalendario",
  props: {
    sesion: {
      required: true,
      type: Object as () => Sesion,
    },
    fecha: {
      type: Date,
      required: true,
    },
    tipoUsuario: {
      required: true,
      type: String as () => TipoUsuario,
    },
  },
  emits: [
    "click",
    "click:agregar-link",
  ],
  setup(props, ctx) {
    // Computed

    const url = computed(() => props.sesion.link);

    const nombre = computed(() => props.sesion.nombre);

    const mobile = computed(() => vuetify.framework.breakpoint.mdAndDown);

    const classContainer = computed(() => {
      return {
        'evento-calendario__container': true,
        'evento-calendario__container--comenzo': comenzo.value,
        'evento-calendario__container--finalizo': finalizo.value,
      };
    });

    const hora = computed(() => {
      if (!props.sesion.fecha) return "00:00 - 00:00";
      const fechaInicio = new Date(props.sesion.fecha);
      const fechaFin = new Date(props.sesion.fecha);
      fechaFin.setMinutes(fechaFin.getMinutes() + props.sesion.duracion);
      const fechaInicioString = toFormatString(fechaInicio, "HH:mm");
      const fechaFinString = toFormatString(fechaFin, "HH:mm");
      return `${fechaInicioString} - ${fechaFinString}`;
    });

    const comienzaEn = computed(() => {
      if (!props.sesion.fecha) return "";
      const now = props.fecha.getTime();
      const fechaInicio = new Date(props.sesion.fecha).getTime();
      const diferencia = Math.ceil((fechaInicio - now) / 60000);
      if (diferencia < 0) return "Finalizada";
      return minutosToString(diferencia);
    });

    const comenzo = computed(() => {
      if (!props.sesion.fecha) return false;
      const now = props.fecha.getTime();
      const fechaInicio = new Date(props.sesion.fecha).getTime() - (5 * 60 * 1000);
      const fechaFin = new Date(props.sesion.fecha);
      fechaFin.setMinutes(fechaFin.getMinutes() + props.sesion.duracion);
      return fechaInicio < now && now < fechaFin.getTime();
    });

    const finalizo = computed(() => {
      if (!props.sesion.fecha) return false;
      const now = props.fecha.getTime();
      const fechaFin = new Date(props.sesion.fecha);
      fechaFin.setMinutes(fechaFin.getMinutes() + props.sesion.duracion);
      return fechaFin.getTime() < now;
    });

    const mostrarComienzaEn = computed(() => {
      return !comenzo.value && ((!finalizo.value && mobile.value) || (finalizo.value && props.tipoUsuario === "cliente"));
    });

    // Methods

    const minutosToString = (minutos: number) => {
      if (minutos >= (24 * 60)) {
        const dias = Math.ceil(minutos / (24 * 60));
        const palabra = dias > 1 ? "días" : "día";
        return `Comienza en ${dias} ${palabra}`;
      } else if (minutos < 60) {
        const palabra = minutos === 1 ? "minuto" : "minutos";
        return `Comienza en ${minutos} ${palabra}`;
      } else {
        const horas = Math.floor(minutos / 60);
        const palabra = horas === 1 ? "hora" : "horas";
        return `Comienza en ${horas} ${palabra}`;
      }
    };

    // Emits

    const onClick = () => {
      ctx.emit("click", props.sesion);
    };

    const onClickAgregarLink = () => {
      ctx.emit("click:agregar-link", props.sesion);
    };

    return {
      // Computed
      url,
      hora,
      mobile,
      nombre,
      comenzo,
      finalizo,
      comienzaEn,
      classContainer,
      mostrarComienzaEn,
      // Emits
      onClick,
      onClickAgregarLink,
    };
  },
});
