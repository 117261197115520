import { RouteConfig } from "vue-router";
import VistaInicio from "@/views/VistaInicio.vue";

const inicio: RouteConfig = {
  path: "/",
  name: "inicio",
  component: VistaInicio
};

export default inicio;
