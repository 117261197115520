
// Decoradores
import {Component, Emit, Prop, VModel, Vue} from "vue-property-decorator";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import Checkbox from "@/components/custom/Checkbox.vue";

@Component({
  components: {
    SvgIcon,
    Checkbox
  }
})
export default class PageSelect extends Vue {
  @VModel() model!: unknown;

  @Prop() readonly label!: string;
  @Prop() readonly placeholder!: string;
  @Prop() readonly textMultiple!: string;
  @Prop() readonly items!: Array<unknown>;
  @Prop() readonly errorMessages!: Array<string>;
  @Prop({type: Boolean}) readonly readonly!: boolean;
  @Prop({type: Boolean}) readonly multiple!: boolean;
  @Prop({default: "text"}) readonly itemText!: string;
  @Prop({default: "value"}) readonly itemValue!: string;
  @Prop({type: Boolean}) readonly returnObject!: boolean;
  @Prop({default: "initial"}) textCase!: "initial" | "lowercase" | "uppercase" | "capitalize";

  get textClass(): string {
    return `text-overflow-ellipsis pa-0 font-size-14 dark--text text-${this.textCase}`;
  }

  get textListClass(): string {
    return `text-overflow-ellipsis pa-0 font-size-14 dark--text text-${this.textCase}`;
  }

  @Emit("change")
  onChange(value: unknown): unknown {
    return value;
  }

  @Emit("input")
  onInput(value: unknown): unknown {
    return value;
  }

  @Emit("right-click")
  onRightClick(item: unknown): unknown {
    return item;
  }

  hasRightClickListener(): boolean {
    return !!this.$listeners["right-click"];
  }

  getText(item: { [key: string]: string } | string): string {
    if (typeof item === "object") {
      if (item[this.itemText]) {
        return item[this.itemText];
      }
      return "";
    }
    return JSON.stringify(item);
  }
}
