
// Decoradores
import {Vue, Component} from "vue-property-decorator";

// Vuex
import {mapActions, mapGetters} from "vuex";

// Helpers
import routeGuard from "@/helpers/routeGuard";

// Tipos
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";
import {Notificacion} from "@/typings/store/plugins/easyFirestore/notificaciones";
import {TituloNotificacionesData} from "@/typings/components/notificaciones/tituloNotificaciones";
import {NotificacionesLeidas, NotificacionLeida} from "@/typings/store/plugins/easyFirestore/notificacionesLeidas";

// componentes
import NoData from "@/components/custom/NoData.vue";
import TablaNotificaciones from "@/components/notificaciones/TablaNotificaciones.vue";
import TituloNotificaciones from "@/components/notificaciones/TituloNotificaciones.vue";
import FiltrosNotificaciones from "@/components/notificaciones/FiltrosNotificaciones.vue";

@Component({
  components: {
    NoData,
    TablaNotificaciones,
    TituloNotificaciones,
    FiltrosNotificaciones,
  },
  beforeRouteEnter(_to, _from, next) {
    next(routeGuard);
  },
  computed: mapGetters({
    getUsuario: "usuario/get",
    getArrayNotificaciones: "notificaciones/getArray",
    getNotificacionesLeidas: "notificacionesLeidas/get",
  }),
  methods: mapActions({
    setNotificacionLeida: "notificacionesLeidas/set",
  }),
})
export default class VistaNotificaciones extends Vue {
  created(): void {
    if (!routeGuard(this)) return;
  }

  mostrarTodas = true;
  dataTitulo: TituloNotificacionesData = {
    buscar: "",
    ordenar: 1,
  };

  get usuario(): Usuario | null {
    return this.getUsuario;
  }

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get tipoUsuario(): "clientes" | "admins" | "relatores" {
    if (!this.usuario) return "clientes";
    switch (this.usuario.tipo) {
      case "admin":
        return "admins";
      case "relator":
        return "relatores";
      default:
        return "clientes";
    }
  }

  get notificaciones(): Notificacion[] {
    const buscar = this.dataTitulo.buscar.toLowerCase();
    return this.getArrayNotificaciones.filter(_notificacion => {
      if (!this.usuario || !_notificacion.id) return false;
      const titulo = _notificacion.titulo.toLowerCase();
      const contenido = _notificacion.contenido.toLowerCase();
      const flagTodos = _notificacion.topico === "todos";
      const flagTipo = _notificacion.topico === this.tipoUsuario;
      const flagNoAutor = _notificacion.autor?.id !== this.usuario.id;
      const flagUsuarios = !!_notificacion.usuarios?.[this.usuario.id];
      const flagBuscar = buscar ? titulo.includes(buscar) || contenido.includes(buscar) : true;
      const flagFiltro = this.mostrarTodas ? true : !this.notificacionesLeidas[_notificacion.id];
      return flagFiltro && flagNoAutor && flagBuscar && (flagTodos || flagTipo || flagUsuarios);
    }).sort((a, b) => (b.fecha.toDate().getTime() - a.fecha.toDate().getTime()) * this.dataTitulo.ordenar);
  }

  get notificacionesLeidas(): NotificacionesLeidas {
    return this.getNotificacionesLeidas;
  }

  marcarLeida(notificacion: Notificacion): void {
    if (!notificacion.id) return;
    if (this.notificacionesLeidas[notificacion.id]) return;
    const notificacionLeida: NotificacionLeida = {
      id: notificacion.id,
      fecha: new Date().toISOString(),
    };
    this.setNotificacionLeida(notificacionLeida);
  }

  marcarTodasLeidas(): void {
    for (const notificacion of this.notificaciones) {
      this.marcarLeida(notificacion);
    }
  }

}
