
// Vue
import {computed, defineComponent} from "vue";

// Tipos
import {Pago} from "@/typings/store/plugins/easyFirestore/pagos";

// Componentes
import PageDivider from "@/components/custom/PageDivider.vue";
import PageTextField from "@/components/custom/PageTextField.vue";
import InfoDetallePago from "@/components/pagos/detalle/InfoDetallePago.vue";
import CursosDetallePago from "@/components/pagos/detalle/CursosDetallePago.vue";
import ResumenDetallePago from "@/components/pagos/detalle/ResumenDetallePago.vue";
import vuetify from "@/plugins/vuetify";

export default defineComponent({
  name: "DetallePago",
  components: {
    PageDivider,
    PageTextField,
    InfoDetallePago,
    CursosDetallePago,
    ResumenDetallePago,
  },
  props: {
    pagoSeleccionado: {
      required: true,
      type: Object as () => Pago,
    },
  },
  setup() {
    // Computed

    const mobile = computed(() => vuetify.framework.breakpoint.mdAndDown);

    return {
      // Computed
      mobile,
    };
  },
});
