
// Decoradores
import {Vue, Component} from "vue-property-decorator";

// Helpers
import {pdfMake} from "@/helpers/pdfMake";
import {ExcelJS} from "@/helpers/excelJS";
import {metodoPago} from "@/helpers/pagos";
import routeGuard from "@/helpers/routeGuard";
import Calculadora from "@/helpers/Calculadora";

// Vuex
import {mapGetters} from "vuex";

// Tipos
import {TDocumentDefinitions} from "pdfmake/interfaces";
import {Pago} from "@/typings/store/plugins/easyFirestore/pagos";
import {FiltrosPagoType} from "@/typings/components/pagos/filtros";
import {Cursos} from "@/typings/store/plugins/easyFirestore/cursos";
import {Region} from "@/typings/store/plugins/easyFirestore/regiones";
import {Archivos} from "@/typings/store/plugins/easyFirestore/archivos";
import {Inscripcion} from "@/typings/store/plugins/easyFirestore/inscripciones";

// Componentes
import NoData from "@/components/custom/NoData.vue";
import ViewTitle from "@/components/custom/ViewTitle.vue";
import TablaPagos from "@/components/pagos/TablaPagos.vue";
import DetallePago from "@/components/pagos/DetallePago.vue";
import TituloPagos from "@/components/pagos/TituloPagos.vue";
import FiltrosPagos from "@/components/pagos/FiltrosPagos.vue";
import ResumenPagos from "@/components/pagos/ResumenPagos.vue";
import ExpansionPanel from "@/components/custom/ExpansionPanel.vue";
import DialogoFiltrosPagos from "@/components/pagos/DialogoFiltrosPagos.vue";

@Component({
  beforeRouteEnter(_to, _from, next) {
    next(routeGuard);
  },
  components: {
    NoData,
    ViewTitle,
    TablaPagos,
    TituloPagos,
    DetallePago,
    FiltrosPagos,
    ResumenPagos,
    ExpansionPanel,
    DialogoFiltrosPagos
  },
  computed: mapGetters({
    getPagos: "pagos/get",
    getCursos: "cursos/get",
    getUsuario: "usuario/get",
    getArchivos: "archivos/get",
    getArrayPagos: "pagos/getArray",
    getPermisosPago: "pago/getPermisos",
    getArrayRegiones: "regiones/getArray",
    getArrayCiudades: "ciudades/getArray",
    getArrayInscripciones: "inscripciones/getArray"
  })
})
export default class VistaPagos extends Vue {
  created(): void {
    if (!routeGuard(this)) return;
  }

  buscar = "";
  dialogoFiltros = false;
  mostrarInformacion = false;
  pagoSeleccionado: Pago | null = null;

  filtros: FiltrosPagoType = {
    tipo: [],
    fecha: [],
    fechaSeleccionada: ""
  };

  get disabled(): boolean {
    return this.inscripciones.length === 0 && !this.fechaInicio && !this.fechaFin && !this.filtroTipo;
  }

  get fechaInicio(): Date | null {
    if (!this.filtros.fecha[0]) return null;
    return new Date(this.filtros.fecha[0]);
  }

  get fechaFin(): Date | null {
    if (!this.filtros.fecha[1]) return null;
    return new Date(this.filtros.fecha[1]);
  }

  get filtroTipo(): string[] | null {
    return this.filtros.tipo.length > 0 ? this.filtros.tipo : null;
  }

  get inscripciones(): Inscripcion[] {
    return this.getArrayInscripciones.filter(_inscripcion => {
      const fecha = new Date(_inscripcion.fecha);
      const flagFecha = this.fechaInicio && this.fechaFin ? fecha.isBetween(this.fechaInicio, this.fechaFin) : true;
      const metodoInscripcion = metodoPago(_inscripcion.pago);
      const flagMetodo = this.filtroTipo ? this.filtroTipo.includes(metodoInscripcion) : true;
      return flagFecha && flagMetodo;
    });
  }

  get cursos(): Cursos {
    return this.getCursos;
  }

  get calculadora(): Calculadora {
    return new Calculadora(this.inscripciones, this.cursos);
  }

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get title(): string {
    if (this.mostrarInformacion) return `Detalle de pago`;
    return "Pagos";
  }

  get arrayPagosValidos(): Pago[] {
    const pagos: Pago[] = [];
    for (const inscripcion of this.inscripciones) {
      pagos.push(inscripcion.pago);
    }
    return pagos;
  }

  get regiones(): Region[] {
    return this.getArrayRegiones;
  }

  get archivos(): Archivos {
    return this.getArchivos;
  }

  get headersInforme(): string[] {
    return ["Fecha", "Hora", "Nombre", "Método de pago", "Monto"];
  }

  get itemsInforme(): Array<string[]> {
    return this.arrayPagosValidos.map((pago) => {
      const fechaIso = pago.resultado?.transaction_date || pago.fecha;
      const fecha = new Date(fechaIso).toLocaleDateString();
      const hora = new Date(fechaIso).toLocaleTimeString();
      const nombre = pago.cliente.nombre;
      const _metodoPago = metodoPago(pago);
      const monto = pago.monto?.toString() || "";
      return [fecha, hora, nombre, _metodoPago, monto];
    });
  }

  clickBack(): void {
    this.mostrarInformacion = false;
    this.pagoSeleccionado = null;
  }

  clickInformacion(pago: Pago): void {
    this.mostrarInformacion = true;
    this.pagoSeleccionado = pago;
  }

  aplicarFiltros(filtros: FiltrosPagoType): void {
    this.filtros = {
      tipo: filtros.tipo.slice(),
      fecha: filtros.fecha.slice(),
      fechaSeleccionada: filtros.fechaSeleccionada
    };
  }

  generarInformePDF(): void {
    const docDefinition: TDocumentDefinitions = {
      pageSize: "LETTER",
      pageOrientation: "landscape",
      pageMargins: [40, 60, 40, 60],
      content: [
        {
          table: {
            widths: ["*", "*", "*", "*", "*"],
            body: [this.headersInforme.slice(), ...this.itemsInforme]
          }
        }
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true
        }
      }
    };
    pdfMake.createPdf(docDefinition).download();
  }

  async generarInformeExcel(): Promise<void> {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Pagos");
    worksheet.columns = this.headersInforme.map((header) => {
      return {
        header: header,
        key: header.toLowerCase(),
        width: header.length * 4
      };
    });
    worksheet.addRows(this.itemsInforme);
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {type: "application/xlsx"});
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "Reporte" + new Date().format("DD-MM-YYYY") + ".xlsx";
    link.click();
  }
}
