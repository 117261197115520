
// Decoradores
import {Component, Emit, Prop, VModel, Vue} from "vue-property-decorator";

// Tipos
import {FiltroPromocionType} from "@/typings/components/promociones/filtrosPromociones";

// Componentes
import PageFilter from "@/components/custom/PageFilter.vue";
import TextButton from "@/components/custom/TextButton.vue";
import ResetButton from "@/components/custom/ResetButton.vue";
import FiltersContainer from "@/components/custom/FiltersContainer.vue";

@Component({
  components: {
    TextButton,
    PageFilter,
    ResetButton,
    FiltersContainer,
  },
})
export default class FiltrosPromociones extends Vue {
  @VModel() model!: FiltroPromocionType;

  @Prop() readonly disabled!: boolean;
  @Prop({type: Boolean}) readonly dialog!: boolean;

  created(): void {
    if (this.model) this.filtro = [this.model];
  }

  filtro: ("pendiente" | "activa" | "finalizada")[] = [];

  itemsEstado = [{
    text: "Activa",
    value: "activa",
  }, {
    text: "Finalizada",
    value: "finalizada",
  }, {
    text: "Pendiente",
    value: "pendiente",
  }];

  @Emit("click:aplicar")
  onClickAplicar(): void {
    this.model = this.filtro[0];
  }

  @Emit("click:resetear")
  onClickResetear(): void {
    this.filtro = [];
    this.model = null;
  }
}
