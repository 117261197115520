
// Decoradores
import {Component, Emit, Prop, VModel, Vue, Watch} from 'vue-property-decorator';

// Tipos
import {Curso} from '@/typings/store/plugins/easyFirestore/cursos';
import {BackgroundBanner, Pagina} from "@/typings/store/plugins/easyFirestore/pagina";

// Componentes
import TextButton from "@/components/custom/TextButton.vue";

@Component({
  components: {
    TextButton
  }
})
export default class CarouselPromociones extends Vue {
  @VModel() file!: File | null;

  @Prop() readonly editando!: boolean;
  @Prop() readonly cursos!: Array<Curso>;
  @Prop() readonly pagina!: Pagina | null;

  get backgroundBanner(): BackgroundBanner | null {
    return this.pagina?.backgroundBanner ?? null;
  }

  get url(): string {
    if (this.file) return URL.createObjectURL(this.file);
    return this.backgroundBanner?.foto.url ?? "";
  }

  get cursosFiltrados(): Array<Curso> {
    return this.cursos.filter(curso => curso.precioRef);
  }

  get fileInput(): HTMLInputElement {
    return this.$refs.fileInput as HTMLInputElement;
  }

  @Emit("cambiar-banner")
  onCambiarBanner(): void {
    return;
  }

  @Watch("editando")
  onEditandoChange(val: boolean): void {
    if (!val) this.onCambiarBanner();
  }

  verCurso(curso: Curso): void {
    const id = curso.id ?? '';
    if (this.$route.name !== 'informacionCompleta' || this.$route.params.id !== id) {
      this.$router.push({
        name: 'informacionCompleta',
        params: {id: id}
      });
    }
  }

  calcularDescuento(curso: Curso): number {
    const precioRef = curso.precioRef ?? 0;
    const precio = curso.precio ?? 0;
    return Math.round((precioRef - precio) * 100 / precioRef);
  }

  getRelatores(curso: Curso): string {
    const relatores = Object.values(curso.relatores).map(relator => relator.nombre);
    return relatores.join(', ');
  }

  clickFileInput(): void {
    this.fileInput.click();
  }

  onFileInputChange(): void {
    const file = this.fileInput.files?.[0];
    if (file) this.file = file;
  }
}
