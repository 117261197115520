
// Decoradores
import {Component, Emit, Prop, VModel, Vue} from "vue-property-decorator";

// Tipos
import {FormularioCuentaType} from "@/typings/components/cuenta/formularioCuenta";

// Componentes
import TextButton from "@/components/custom/TextButton.vue";
import PageTextField from "@/components/custom/PageTextField.vue";

@Component({
  components: {
    TextButton,
    PageTextField
  }
})
export default class FormularioCuenta extends Vue {
  @VModel() form!: FormularioCuentaType;
  @Prop() readonly error!: string;
  @Prop() readonly cargando!: boolean;
  @Prop() readonly erroresClaveNueva!: string[];
  @Prop() readonly erroresClaveActual!: string[];

  showingClaveNueva = false;
  showingClaveActual = false;

  get claveActual(): string {
    return this.form.claveActual;
  }

  set claveActual(value: string) {
    this.form.claveActual = value;
  }

  get claveNueva(): string {
    return this.form.claveNueva;
  }

  set claveNueva(value: string) {
    this.form.claveNueva = value;
  }

  @Emit("click:actualizar")
  onClickActualizar(): void {
    return;
  }
}
