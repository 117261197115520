// Tipos
import {GetterTree} from "vuex";
import {env} from "@/helpers/env";
import {State} from "@/typings/store";
import {StatePagina} from "@/typings/store/plugins/easyFirestore/pagina";

const getters: GetterTree<StatePagina, State> = {
  get(state) {
    return state.all;
  }
};

const pagina = {
  getters,
  state: {},
  actions: {},
  mutations: {},
  namespaced: true,
  moduleName: "pagina",
  statePropName: "all",
  firestoreRefType: "collection",
  firestorePath: `${env}/v1/pagina`,
  serverChange: {
    convertTimestamps: {
      created_at: "%convertTimestamp%", // default
      updated_at: "%convertTimestamp%" // default
    }
  }
};

export default pagina;
