
// Vue
import {computed, defineComponent, ref} from "vue";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "ExpansionPanel",
  components: {
    SvgIcon
  },
  props: {
    value: {
      type: Boolean,
      default: undefined
    },
    disabled: {
      type: Boolean
    }
  },
  emits: [
    "input"
  ],
  setup(props, ctx) {
    // VModel

    const model = computed({
      get() {
        if(props.value !== undefined) return props.value;
        return helperModel.value;
      },
      set(value) {
        if (props.disabled) return;
        helperModel.value = value;
        ctx.emit("input", value);
      }
    });

    // Data

    const helperModel = ref(false);

    // Computed

    const classIcon = computed(() => {
      return {
        'expansion-panel__icon': true,
        'expansion-panel__icon--open': model.value
      };
    });

    const classContent = computed(() => {
      return {
        'expansion-panel__content': true,
        'expansion-panel__content--open': model.value
      };
    });

    return {
      // Data
      model,
      // Computed
      classIcon,
      classContent
    };
  }
});
