
// Decoradores
import {Component, Prop, Vue} from "vue-property-decorator";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon
  }
})
export default class LogoApp extends Vue {
  @Prop() readonly mobile!: boolean;
}
