import { render, staticRenderFns } from "./FiltrosCursosCliente.vue?vue&type=template&id=ec4881be&scoped=true&"
import script from "./FiltrosCursosCliente.vue?vue&type=script&lang=ts&"
export * from "./FiltrosCursosCliente.vue?vue&type=script&lang=ts&"
import style0 from "./FiltrosCursosCliente.vue?vue&type=style&index=0&id=ec4881be&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec4881be",
  null
  
)

export default component.exports