
// Decoradores
import {Component, Emit, Prop, VModel, Vue} from "vue-property-decorator";

// Tipos
import {TituloContactosData} from "@/typings/components/contactos/tituloContactos";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import ViewTitle from "@/components/custom/ViewTitle.vue";
import SearchTextField from "@/components/custom/SearchTextField.vue";
import OrdenarPorContactos from "@/components/contactos/OrdenarPorContactos.vue";

@Component({
  components: {
    SvgIcon,
    ViewTitle,
    SearchTextField,
    OrdenarPorContactos,
  },
})
export default class TituloContactos extends Vue {
  @VModel() model!: TituloContactosData;

  @Prop() readonly disabled!: boolean;

  buscando = false;

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get mostrarBuscar(): boolean {
    return !this.mobile || this.buscando;
  }

  @Emit("click:filtrar")
  onClickFiltrar(): void {
    return;
  }
}
