
// Vue
import {computed, defineComponent} from "vue";
import vuetify from "@/plugins/vuetify";

export default defineComponent({
  name: "PageDivider",
  props: {
    color: {
      type: String,
      default: "#000000"
    },
    size: {
      type: String,
      default: "1px"
    },
    vertical: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    // Computed

    const stylePageDivider = computed(() => {
      const vTheme = vuetify.framework.theme.currentTheme;
      const backgroundColor = vTheme[props.color] ?? props.color;
      const width = props.vertical ? props.size : "100%";
      const height = props.vertical ? "100%" : props.size;
      return {
        width,
        height,
        backgroundColor
      };
    });

    return {
      // Computed
      stylePageDivider
    };
  }
});
