import {Curso, Cursos} from "@/typings/store/plugins/easyFirestore/cursos";
import {Inscripcion} from "@/typings/store/plugins/easyFirestore/inscripciones";
import {PaymentTypeCodeResultadoWebPay} from "@/typings/store/plugins/easyFirestore/pagos";

const CREDITO = ["VN", "VC", "SI", "S2", "NC"];
const DEBITO = ["VD"];
const PREPAGO = ["VP"];
const DESCUENTO_CREDITO = 0.0248;
const DESCUENTO_DEBITO = 0.0142;

class Calculadora {
  cursos: Cursos;
  inscripciones: Array<Inscripcion>;

  constructor(inscripciones: Array<Inscripcion>, cursos: Cursos) {
    this.cursos = cursos;
    this.inscripciones = inscripciones;
  }

  get comisionDebito(): number {
    return Calculadora.calcularComision(this.subtotalDebito, "VD");
  }

  get comisionCredito(): number {
    return Calculadora.calcularComision(this.subtotalCredito, "VN");
  }

  get comisionPrepago(): number {
    return Calculadora.calcularComision(this.subtotalPrepago, "VP");
  }

  get comisionRelatores(): number {
    let comision = 0;
    for (const inscripcion of this.inscripciones) {
      const cursos = inscripcion.pago.cursos;
      for (const id in cursos) {
        const curso = this.cursos[id];
        const descuento = Calculadora.calcularDescuentoCurso(curso, inscripcion);
        comision += Math.round(((curso.precio - descuento) * curso.comision) / 100);
      }
    }
    return comision;
  }

  get totalComisiones(): number {
    return this.comisionDebito + this.comisionCredito + this.comisionPrepago + this.comisionRelatores;
  }

  get recaudacion(): number {
    return this.subtotalCredito + this.subtotalDebito + this.subtotalPrepago;
  }

  get ganancias(): number {
    return this.recaudacion - this.totalComisiones;
  }

  get subtotalCredito(): number {
    let subtotal = 0;
    for (const inscripcion of this.inscripciones) {
      const paymentType = inscripcion.pago.resultado?.payment_type_code;
      if (paymentType && CREDITO.includes(paymentType)) {
        subtotal += inscripcion.pago.monto ?? 0;
      }
    }
    return subtotal;
  }

  get subtotalDebito(): number {
    let subtotal = 0;
    for (const inscripcion of this.inscripciones) {
      const paymentType = inscripcion.pago.resultado?.payment_type_code;
      if (paymentType && DEBITO.includes(paymentType)) {
        subtotal += inscripcion.pago.monto ?? 0;
      }
    }
    return subtotal;
  }

  get subtotalPrepago(): number {
    let subtotal = 0;
    for (const inscripcion of this.inscripciones) {
      const paymentType = inscripcion.pago.resultado?.payment_type_code;
      if (paymentType && PREPAGO.includes(paymentType)) {
        subtotal += inscripcion.pago.monto ?? 0;
      }
    }
    return subtotal;
  }

  static calcularComision(value: number, type: PaymentTypeCodeResultadoWebPay): number {
    let comision = 0;
    if (DEBITO.includes(type) || PREPAGO.includes(type)) {
      comision = Math.round(value * DESCUENTO_DEBITO);
    } else if (CREDITO.includes(type)) {
      comision = Math.round(value * DESCUENTO_CREDITO);
    }
    const iva = Math.round(comision * 0.19);
    return comision + iva;
  }

  static calcularDescuentoCurso(curso: Curso, inscripcion: Inscripcion): number {
    const promocion = inscripcion.pago.promocion;
    const descuentoPromocion = promocion?.descuento.toInt() ?? 0;
    const descuentoCurso = (descuentoPromocion * curso.precio) / 100;
    const comision = Calculadora.calcularComision(curso.precio, inscripcion.pago.resultado?.payment_type_code ?? "VD");
    return descuentoCurso + comision;
  }
}

export default Calculadora;
