
// Decoradores
import {Vue, Component} from "vue-property-decorator";

// Vuex
import {mapGetters} from "vuex";

// Helpers
import routeGuard from "@/helpers/routeGuard";

// Tipos
import {Curso} from "@/typings/store/plugins/easyFirestore/cursos";
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";
import {Inscripcion} from "@/typings/store/plugins/easyFirestore/inscripciones";
import {ItemTablaInscripciones} from "@/typings/components/inscripciones/tablaInscripciones";
import {FiltrosInscripcionesData} from "@/typings/components/inscripciones/filtrosInscripciones";

// Componentes
import NoData from "@/components/custom/NoData.vue";
import TablaInscripciones from "@/components/inscripciones/TablaInscripciones.vue";
import TituloInscripciones from "@/components/inscripciones/TituloInscripciones.vue";
import FiltrosInscripciones from "@/components/inscripciones/FiltrosInscripciones.vue";
import InformacionInscripcion from "@/components/inscripciones/InformacionInscripcion.vue";
import DialogoFiltrosInscripciones from "@/components/inscripciones/DialogoFiltrosInscripciones.vue";

@Component({
  components: {
    NoData,
    TablaInscripciones,
    TituloInscripciones,
    FiltrosInscripciones,
    InformacionInscripcion,
    DialogoFiltrosInscripciones
  },
  computed: mapGetters({
    getUsuario: "usuario/get",
    getArrayInscripciones: "inscripciones/getArray"
  }),
  beforeRouteEnter(_to, _from, next) {
    next(routeGuard);
  }
})
export default class VistaInscripciones extends Vue {
  created(): void {
    if (!routeGuard(this)) return;
  }

  buscar = "";
  dialogoFiltros = false;
  mostrarInformacion = false;
  inscripcionSeleccionada: ItemTablaInscripciones | null = null;
  filtros: FiltrosInscripcionesData = {
    fecha: [],
    cursos: [],
    fechaSeleccionada: ""
  };

  get usuario(): Usuario | null {
    return this.getUsuario;
  }

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get fechaInicio(): Date | null {
    if (!this.filtros.fecha[0]) return null;
    return new Date(this.filtros.fecha[0]);
  }

  get fechaFin(): Date | null {
    if (!this.filtros.fecha[1]) return null;
    return new Date(this.filtros.fecha[1]);
  }

  get filtrosCursos(): string[] | null {
    if (this.filtros.cursos.length === 0) return null;
    return this.filtros.cursos;
  }

  get disabled(): boolean {
    return this.items.length === 0 && !this.fechaInicio && !this.fechaFin && !this.filtrosCursos;
  }

  get items(): ItemTablaInscripciones[] {
    const items: ItemTablaInscripciones[] = [];
    for (const inscripcion of this.getArrayInscripciones) {
      if (!this.filtrarFecha(inscripcion)) continue;
      const cursos = Object.values(inscripcion.pago.cursos);
      for (const curso of cursos) {
        if (!this.filtrarCurso(curso)) continue;
        const item = this.generarItem(inscripcion, curso);
        items.push(item);
      }
    }
    return items.sort((_inscripcionA, _inscripcionB) => {
      const fechaA = _inscripcionA.fecha.toDate().getTime();
      const fechaB = _inscripcionB.fecha.toDate().getTime();
      return fechaB - fechaA;
    });
  }

  get cursos(): Curso[] {
    const cursos: Curso[] = [];
    for (const inscripcion of this.getArrayInscripciones) {
      const cursosInscripcion = Object.values(inscripcion.pago.cursos);
      for (const curso of cursosInscripcion) {
        if (!cursos.some(_curso => _curso.id === curso.id)) {
          cursos.push(curso);
        }
      }
    }
    return cursos.sort((_cursoA, _cursoB) => _cursoA.nombre.localeCompare(_cursoB.nombre));
  }

  generarItem(inscripcion: Inscripcion, curso: Curso): ItemTablaInscripciones {
    const nombreCurso = curso.nombre;
    const correo = inscripcion.cliente.email;
    const alumno = inscripcion.cliente.nombre;
    const id = `${curso.id}-${inscripcion.id}`;
    const telefono = inscripcion.cliente.perfil?.telefono ?? "-";
    const fecha = new Date(inscripcion.fecha).format("DD/MM/YYYY");
    const categoria = curso.categoria.nombre.capitalizeFirstLetter();
    return {
      id,
      fecha,
      correo,
      alumno,
      telefono,
      categoria,
      curso: nombreCurso
    };
  }

  filtrarFecha(inscripcion: Inscripcion): boolean {
    if (!this.fechaInicio || !this.fechaFin) return true;
    return new Date(inscripcion.fecha).isBetween(this.fechaInicio, this.fechaFin);
  }

  filtrarCurso(curso: Curso): boolean {
    const flagCurso = this.filtrosCursos?.includes(curso.id || "") ?? true;
    const flagRelator = !!this.usuario && (!!curso.relatores[this.usuario.id] || this.usuario.tipo === "admin");
    return flagCurso && flagRelator;
  }

  aplicarFiltros(filtros: FiltrosInscripcionesData): void {
    this.filtros = {
      fecha: filtros.fecha.slice(),
      cursos: filtros.cursos.slice(),
      fechaSeleccionada: filtros.fechaSeleccionada
    };
  }

  limpiar(): void {
    this.mostrarInformacion = false;
    this.inscripcionSeleccionada = null;
  }

  clickInformacion(inscripcion: ItemTablaInscripciones): void {
    this.mostrarInformacion = true;
    this.inscripcionSeleccionada = inscripcion;
  }
}
