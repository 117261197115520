
// Vue
import {computed, defineComponent} from "vue";

// Tipos
import {Pago} from "@/typings/store/plugins/easyFirestore/pagos";

// Componentes
import TextButton from "@/components/custom/TextButton.vue";

export default defineComponent({
  name: "TablaPagosPerfil",
  components: {
    TextButton,
  },
  props: {
    pagos: {
      required: true,
      type: Array as () => Pago[],
    },
  },
  setup(props) {
    // Computed

    const items = computed(() => {
      let result: { fecha: string; monto: string }[] = [];
      for (const pago of props.pagos) {
        const status = pago.resultado?.status ?? "FAILED";
        const flagResultado = !!pago.resultado;
        const flagEstado = pago.estado === "finalizado";
        const flagNoError = !pago.error && !pago.resultado?.error;
        const flagStatus = status !== "FAILED" && status !== "NULLIFIED" && status !== "PARTIALLY_NULLIFIED";
        if (flagResultado && flagEstado && flagNoError && flagStatus) {
          const fecha = pago.resultado?.transaction_date?.toDate()?.format("DD/MM/YYYY") ?? "";
          const monto = pago.monto?.toCurrency("$") ?? "";
          result.push({fecha, monto});
        }
      }
      result.sort((a, b) => {
        const aFecha = a.fecha.toDate();
        const bFecha = b.fecha.toDate();
        return bFecha.getTime() - aFecha.getTime();
      })
      return result.slice(0, 10);
    });

    return {
      // Computed
      items,
    };
  },
});
