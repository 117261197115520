
// Vue
import {defineComponent} from "vue";

// Tipos
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";

export default defineComponent({
  name: "UsersList",
  props: {
    users: {
      required: true,
      type: Array as () => Usuario[]
    }
  },
  emits: [
    "click:see-more"
  ],
  setup(_props, ctx) {
    // Methods

    const getImage = (user: Usuario) => {
      const imagen = user.perfil?.imagen?.url ?? "";
      return {
        'background-image': `url(${imagen})`
      };
    };

    const getProfesion = (user: Usuario) => {
      return user.perfil?.profesion ?? "";
    };

    // Emits

    const onClickSeeMore = (id: string) => {
      ctx.emit("click:see-more", id);
    };

    return {
      // Methods
      getImage,
      getProfesion,
      // Emits
      onClickSeeMore
    };
  }
});
