
// Vue
import {computed, defineComponent} from "vue";

// Vuetify
import vuetify from "@/plugins/vuetify";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "FilterDatePicker",
  components: {
    SvgIcon
  },
  props: {
    value: {
      default: "",
      type: [String, Array] as () => string | string[]
    },
    range: {
      default: false,
      type: Boolean
    },
    allowedDates: {
      type: Function as () => (date: string) => boolean
    },
    disabled: {
      type: Boolean,
      default: false
    },
    inactive: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    "input"
  ],
  setup(props, ctx) {
    // VModel

    const model = computed<string | string[]>({
      get() {
        return props.value;
      },
      set(value) {
        let date = value;
        if (Array.isArray(value)) {
          const first = value[0]?.toDate();
          const last = value[1]?.toDate();
          if (first && last && first.getTime() >= last.getTime()) {
            date = [last.format("YYYY-MM-DD"), first.format("YYYY-MM-DD")];
          }
        }
        ctx.emit("input", date);
      }
    });

    // Computed

    const mobile = computed(() => vuetify.framework.breakpoint.mdAndDown);

    const containerClass = computed(() => ({
      'filter-date-picker__container': true,
      'filter-date-picker__container--disabled': props.disabled
    }));

    const dateString = computed(() => {
      if (!model.value) return "Personalizar";
      if (Array.isArray(model.value)) {
        if (model.value.length === 0) return "Personalizar";
        return model.value.map((fecha) => fecha.toDate().format("DD/MM/YY")).join(" - ");
      }
      return model.value.toDate().format("DD/MM/YY");
    });

    // Emits

    const onClick = (ev: MouseEvent) => {
      if (props.disabled) return;
      if (props.inactive) ev.stopPropagation();
      ctx.emit("click", ev);
    };

    return {
      // VModel
      model,
      // Computed
      mobile,
      dateString,
      containerClass,
      // Emits
      onClick
    };
  }
});
