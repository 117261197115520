
// Decoradores
import {Component, Vue, Watch} from "vue-property-decorator";

// Vuex
import {mapActions, mapGetters} from "vuex";

// Validaciones
import {required} from "vuelidate/lib/validators";

// Helpers
import {env} from "@/helpers/env";
import routeGuard from "@/helpers/routeGuard";

// Tipos
import {Pago} from "@/typings/store/plugins/easyFirestore/pagos";
import {Cursos} from "@/typings/store/plugins/easyFirestore/cursos";
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";
import {Cupo, CupoPatch} from "@/typings/store/plugins/easyFirestore/cupos";
import {Promocion} from "@/typings/store/plugins/easyFirestore/promociones";
import {PromocionesUsuario} from "@/typings/store/plugins/easyFirestore/promocionesUsuario";
import {Carrito, CarritoPatch, CursoCarrito} from "@/typings/store/plugins/easyFirestore/carritos";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import ViewTitle from "@/components/custom/ViewTitle.vue";
import Footer from "@/components/appCorePagina/Footer.vue";
import IconButton from "@/components/custom/IconButton.vue";
import TextButton from "@/components/custom/TextButton.vue";
import BotonPagar from "@/components/carrito/BotonPagar.vue";
import TablaCarrito from "@/components/carrito/TablaCarrito.vue";
import MessageDialog from "@/components/custom/MessageDialog.vue";
import ResumenCarrito from "@/components/carrito/ResumenCarrito.vue";
import DescuentoCarrito from "@/components/carrito/DescuentoCarrito.vue";

@Component({
  beforeRouteEnter(_to, _from, next) {
    next(routeGuard);
  },
  components: {
    Footer,
    SvgIcon,
    ViewTitle,
    TextButton,
    BotonPagar,
    IconButton,
    TablaCarrito,
    MessageDialog,
    ResumenCarrito,
    DescuentoCarrito,
  },
  methods: mapActions({
    setCupo: "cupos/set",
    setPago: "pagos/set",
    setCarrito: "carritos/set",
    deleteCarrito: "carritos/delete"
  }),
  computed: mapGetters({
    getPagos: "pagos/get",
    getUsuario: "usuario/get",
    getArrayCupos: "cupos/getArray",
    getCarrito: "usuario/getCarrito",
    getRutasUsuario: "usuario/getRutas",
    getArrayPromociones: "promociones/getArray",
    getPromocionesUsuario: "promocionesUsuario/get"
  }),
  validations: {
    codigoPromocion: {required}
  }
})
export default class VistaCarrito extends Vue {
  created(): void {
    window.scrollTo(0, 0);
    if (!routeGuard(this)) return;
    this.validarPromocion();
  }

  cargando = false;
  idPagoActual = "";
  codigoInvalido = 0;
  codigoPromocion = "";
  dialogoMensaje = false;
  dialogoMensajeCupos: {
    model: boolean;
    mensaje: string;
    cursos: CursoCarrito[];
  } = {
    cursos: [],
    mensaje: "",
    model: false
  };

  get usuario(): Usuario | null {
    return this.getUsuario;
  }

  get pagoActual(): Pago | null {
    return this.getPagos[this.idPagoActual] ?? null;
  }

  get carrito(): Carrito | null {
    return this.getCarrito;
  }

  get vacio(): boolean {
    if (!this.carrito) return true;
    return Object.values(this.carrito.cursos).length === 0;
  }

  get formAction(): string {
    const subdomain = env === "dev" ? "webpay3gint" : "webpay3g";
    return `https://${subdomain}.transbank.cl/webpayserver/initTransaction`;
  }

  get promocionesUsuario(): PromocionesUsuario {
    return this.getPromocionesUsuario;
  }

  get erroresCodigoPromocion(): string[] {
    const errores: string[] = [];
    if (!this.$v.codigoPromocion.$dirty) return errores;
    if (this.codigoInvalido === 1) errores.push("El código ingresado no es válido");
    if (this.codigoInvalido === 2) errores.push("El codigo ya ha sido utilizado anteriormente");
    if (this.codigoInvalido === 3) errores.push("El código está vencido");
    if (!this.$v.codigoPromocion.required) errores.push("El código es requerido");
    return errores;
  }

  @Watch("pagoActual", {deep: true})
  onChangePagoActual(pago: Pago | null): void {
    if (pago?.url && pago?.token) {
      const formulario = this.$refs.formulario as HTMLFormElement;
      const input = formulario["token_ws"] as HTMLInputElement;
      input.value = pago.token;
      formulario.submit();
    } else if (pago !== null && pago.error) {
      this.cargando = false;
    }
  }

  validarPromocion(): void {
    const promocion = this.carrito?.promocion;
    const promocionReal = this.getArrayPromociones.find(p => p.id === promocion?.id);
    if (!promocion?.id || !promocionReal) return;
    const usada = !!this.promocionesUsuario[promocion.id];
    const fechaFin = promocionReal.fin.toDate();
    const fechaInicio = promocionReal.inicio.toDate();
    const valido = new Date().isBetween(fechaInicio, fechaFin);
    if (!valido || usada) {
      const id = this.carrito?.id;
      if (!id) return;
      const carrito: CarritoPatch = {
        id,
        promocion: null
      };
      this.setCarrito(carrito);
    }
  }

  cupoPorCurso(curso: CursoCarrito): Cupo | null {
    return this.getArrayCupos.find(_cupo => {
      const flagCurso = _cupo.curso.id === curso.id;
      const flagUsuario = _cupo.usuario?.id === this.usuario?.id;
      return flagCurso && flagUsuario;
    }) ?? null;
  }

  mensajeSinCupo(cursosSinCupo: CursoCarrito[], cantidadCursos: number): string {
    if (cursosSinCupo.length > 1) {
      return `${cursosSinCupo.length} de ${cantidadCursos} cursos no tienen cupos disponibles, por lo que se eliminarán del carrito de compras.`;
    }
    const nombre = cursosSinCupo[0].curso.nombre.capitalizeFirstLetter();
    return `El curso "${nombre}" no tiene cupos disponibles, por lo que se eliminará del carrito de compras.`;
  }

  validarCupos(): boolean {
    if (!this.carrito) return false;
    const cursosCarrito = Object.values(this.carrito.cursos);
    const cursosSinCupo: CursoCarrito[] = [];
    for (const cursoCarrito of cursosCarrito) {
      const cupo = this.cupoPorCurso(cursoCarrito);
      if (!cupo) {
        cursosSinCupo.push(cursoCarrito);
      } else if (cupo.fecha) {
        const fechaCupo = cupo.fecha.toDate();
        const diff = new Date().getTime() - fechaCupo.getTime();
        const minutos = diff / (1000 * 60);
        if (minutos > 55) cursosSinCupo.push(cursoCarrito);
      }
    }
    if (cursosSinCupo.length === 0) return true;
    const mensaje = this.mensajeSinCupo(cursosSinCupo, cursosCarrito.length);
    this.dialogoMensajeCupos = {
      mensaje,
      model: true,
      cursos: cursosSinCupo
    };
    return false;
  }

  obtenerCursos(): Cursos {
    if (!this.carrito) return {};
    const cursosCarrito = Object.values(this.carrito.cursos);
    const cursos: Cursos = {};
    for (const cursoCarrito of cursosCarrito) {
      if (cursoCarrito.id) {
        cursos[cursoCarrito.id] = JSON.parse(JSON.stringify(cursoCarrito.curso));
      }
    }
    return cursos;
  }

  cerrarMensajeCupos(): void {
    this.dialogoMensajeCupos = {
      cursos: [],
      mensaje: "",
      model: false
    };
  }

  async clickPagar(): Promise<void> {
    if (!this.carrito || !this.usuario) return;
    this.cargando = true;
    if (!this.validarCupos()) return;
    const cursos = this.obtenerCursos();
    const pago: Pago = {
      fecha: new Date().toISOString(),
      urlRetorno: location.origin + "/resultadoCompra",
      cursos: cursos,
      cliente: this.usuario,
      estado: "pendiente",
      url: null,
      error: null,
      monto: null,
      token: null,
      anulado: null,
      token_ws: null,
      resultado: null,
      promocion: this.carrito.promocion ?? null
    };
    this.idPagoActual = await this.setPago(pago);
  }

  async eliminarCurso(curso: CursoCarrito): Promise<void> {
    if (!this.carrito?.id) return;
    const carritoId = this.carrito.id;
    await this.deleteCarrito(`${carritoId}.cursos.${curso.id}`);
    const cupo = this.cupoPorCurso(curso);
    if (!cupo?.id) return;
    const cupoLibre: CupoPatch = {
      id: cupo.id,
      fecha: null,
      usuario: null,
      estado: "libre"
    };
    await this.setCupo(cupoLibre);
  }

  getPromocionByCodigo(codigo: string): Promocion | null {
    return this.getArrayPromociones.find((promocion) => promocion.codigo?.toLowerCase() === codigo.toLowerCase()) ?? null;
  }

  aplicarDescuento(): void {
    this.$v.$touch();
    this.codigoInvalido = 0;
    if (this.$v.$invalid || !this.carrito?.id) return;
    const promocion = this.getPromocionByCodigo(this.codigoPromocion);
    if (!promocion?.id) {
      this.codigoInvalido = 1;
      return;
    }
    if (this.promocionesUsuario[promocion.id]) {
      this.codigoInvalido = 2;
      return;
    }
    const fechaFin = promocion.fin.toDate();
    const fechaInicio = promocion.inicio.toDate();
    const valido = new Date().isBetween(fechaInicio, fechaFin);
    if (!valido) {
      this.codigoInvalido = 3;
      return;
    }
    const carrito: CarritoPatch = {
      promocion,
      id: this.carrito.id
    };
    this.$v.$reset();
    this.setCarrito(carrito);
    this.codigoPromocion = "";
    this.dialogoMensaje = true;
  }

  eliminarCursosSinCupo(): void {
    for (const curso of this.dialogoMensajeCupos.cursos) {
      this.eliminarCurso(curso);
    }
    this.cerrarMensajeCupos();
  }
}
