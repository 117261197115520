import {RouteConfig} from "vue-router";
import VistaHistorial from "@/views/VistaHistorial.vue";

const historial: RouteConfig = {
  name: "historial",
  path: "/historial",
  component: VistaHistorial
};

export default historial;
