import VuexEasyFirestore from "vuex-easy-firestore";

import {Firebase} from "@/config/firebase";

import pagos from "./pagos";
import cursos from "./cursos";
import ciudades from "./ciudades";
import regiones from "./regiones";
import sesiones from "./sesiones";
import usuarios from "./usuarios";
import carritos from "./carritos";
import archivos from "./archivos";
import encuestas from "./encuestas";
import promociones from "./promociones";
import inscripciones from "./inscripciones";
import notificaciones from "./notificaciones";
import categoriasCurso from "./categoriasCurso";
import cupos from "@/store/plugins/easyFirestore/cupos";
import contactos from "@/store/plugins/easyFirestore/contactos";
import testimonios from "@/store/plugins/easyFirestore/testimonios";
import videosVistos from "@/store/plugins/easyFirestore/videosVistos";
import promocionesUsuario from "@/store/plugins/easyFirestore/promocionesUsuario";
import notificacionesLeidas from "@/store/plugins/easyFirestore/notificacionesLeidas";
import pagina from "@/store/plugins/easyFirestore/pagina";

const easyFirestore = VuexEasyFirestore(
  [
    pagos,
    cupos,
    cursos,
    pagina,
    ciudades,
    regiones,
    sesiones,
    usuarios,
    carritos,
    archivos,
    encuestas,
    contactos,
    testimonios,
    promociones,
    videosVistos,
    inscripciones,
    notificaciones,
    categoriasCurso,
    promocionesUsuario,
    notificacionesLeidas,
  ],
  {
    logging: true,
    FirebaseDependency: Firebase
  }
);

export default easyFirestore;
