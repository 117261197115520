
// Vue
import {computed, defineComponent, ref, watch} from "vue";

export default defineComponent({
  name: "DialogoMensajeLogin",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    titulo: {
      type: String,
      required: true
    },
    mensaje: {
      type: String,
      required: true
    }
  },
  emits: [
    "input"
  ],
  setup(props, ctx) {
    // VModel

    const model = computed({
      get() {
        return props.value;
      },
      set(value: boolean) {
        ctx.emit("input", value);
      }
    });

    // Data

    const timeout = ref(null);

    // Methods

    const overlayClickHandler = (ev: MouseEvent) => {
      if (ev.target === ev.currentTarget) model.value = false;
    };

    watch(model, (value) => {
      if (value) {
        if (timeout.value) window.clearTimeout(timeout.value);
        timeout.value = window.setTimeout(() => {
          console.log("onMounted");
          model.value = false;
        }, 6000);
      } else {
        if (timeout.value) window.clearTimeout(timeout.value);
      }
    });

    return {
      // VModel
      model,
      // Methods
      overlayClickHandler
    };
  }
});
