
// Vue
import {computed, defineComponent} from "vue";

// Tipos
import {Curso} from "@/typings/store/plugins/easyFirestore/cursos";
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";

// Componentes
import TextButton from "@/components/custom/TextButton.vue";

export default defineComponent({
  name: "TablaCursosPerfil",
  components: {
    TextButton,
  },
  props: {
    cursos: {
      required: true,
      type: Array as () => Curso[],
    },
    usuario: {
      required: true,
      type: Object as () => Usuario,
    },
  },
  setup(props) {
    // Computed

    const items = computed(() => {
      const result: { nombre: string; fecha: string }[] = [];
      for (const curso of props.cursos) {
        const userId = props.usuario?.id ?? "";
        const flagEstado = curso.estado !== "pendiente";
        const flagUsuario = props.usuario.tipo === "admin" ? true : !!curso.relatores[userId];
        if (flagEstado && flagUsuario) {
          const fecha = curso.inicio.toDate().format("DD/MM/YYYY");
          const nombre = curso.nombre;
          result.push({nombre, fecha});
        }
      }
      result.sort((a, b) => {
        const aFecha = a.fecha.toDate();
        const bFecha = b.fecha.toDate();
        return bFecha.getTime() - aFecha.getTime();
      });
      return result.slice(0, 10);
    });

    return {
      // Computed
      items,
    };
  },
});
