
// Decoradores
import {Component, Vue, Watch} from "vue-property-decorator";

// Helpers
import {firebaseApiKey} from "@/helpers/env";

// Vuex
import {mapActions, mapGetters} from "vuex";

// Tipos
import {Curso} from "@/typings/store/plugins/easyFirestore/cursos";
import {Sesion} from "@/typings/store/plugins/easyFirestore/sesiones";
import {VideoVisto} from "@/typings/store/plugins/easyFirestore/videosVistos";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import Controles, {IControles} from "@/components/reproductor/Controles.vue";

@Component({
  components: {
    SvgIcon,
    Controles
  },
  computed: mapGetters({
    getCursos: "cursos/get",
    getSesiones: "sesiones/get"
  }),
  methods: mapActions({
    setVideoVisto: "videosVistos/set"
  })
})
export default class VistaReproductor extends Vue {
  created(): void {
    this.sid = this.$route.params.sid ?? "";
    if (!this.sid && this.$route.name !== "cursosDisponibles") {
      this.$router.push({
        name: "cursosDisponibles"
      });
    }
    this.dataControles.name = this.sesion.nombre;
  }

  mounted(): void {
    document.addEventListener("fullscreenchange", this.fullScreenChangeHandler);
  }

  sid = "";
  volume = 1;
  lastSecondSended = 0;
  timeoutId: number | null = null;
  info = {
    icon: "",
    text: "",
    model: false
  };
  dataControles: IControles = {
    name: "",
    duration: 0,
    muted: false,
    currentTime: 0,
    playing: false,
    fullscreen: false
  };

  get sesion(): Sesion {
    return this.getSesiones[this.sid];
  }

  get curso(): Curso {
    const id = this.sesion.curso.id ?? "";
    return this.getCursos[id];
  }

  get videoId(): string {
    if (this.sesion?.link) {
      const url = new URL(this.sesion.link);
      const pathSplit = url.pathname.split("/");
      return pathSplit.find((path) => path.length > 10) ?? "";
    }
    return "";
  }

  get video(): HTMLVideoElement {
    return this.$refs.video as HTMLVideoElement;
  }

  get videoContainer(): HTMLDivElement {
    return this.$refs.videoContainer as HTMLDivElement;
  }

  get infoStyle(): { [key: string]: string } {
    return {
      'opacity': this.info.model ? '1' : '0'
    };
  }

  get volumeIcon(): string {
    if (this.dataControles.muted) return "fas-volume-slash";
    if (this.volume > 0.66) {
      return "fas-volume-high";
    } else if (this.volume > 0.33) {
      return "fas-volume";
    } else if (this.volume >= 0.1) {
      return "fas-volume-low";
    } else {
      return "fas-volume-off";
    }
  }

  get volumeText(): string {
    return this.dataControles.muted ? "Silenciado" : `Volumen ${Math.round(this.volume * 100)}%`;
  }

  @Watch("videoId")
  onVideoIdChange(videoId: string): void {
    this.resetView();
    this.dataControles.playing = false;
    this.video.src = `https://www.googleapis.com/drive/v3/files/${videoId}?alt=media&key=${firebaseApiKey}`;
    this.video.load();
    this.info = {
      model: true,
      icon: "fas-sync-alt",
      text: "Cargando..."
    };
  }

  setVisto(): void {
    const videoVisto: VideoVisto = {
      id: this.sesion.id ?? "",
      tiempo: this.dataControles.currentTime,
      fecha: new Date().toISOString(),
      duracion: this.dataControles.duration
    };
    this.setVideoVisto(videoVisto);
  }

  showInfo(icon: string, text?: string): void {
    this.info = {
      icon,
      model: true,
      text: text ?? ""
    };
    if (this.timeoutId) window.clearTimeout(this.timeoutId);
    this.timeoutId = window.setTimeout(() => {
      this.info = {
        icon: "",
        text: "",
        model: false
      };
    }, 1000);
  }

  goBack(): void {
    const id = this.curso?.id ?? "";
    if (this.$route.name !== "informacionCompleta" || this.$route.params.id !== id) {
      this.$router.replace({
        name: "informacionCompleta",
        params: {
          id: id
        }
      });
    }
  }

  onEnded(): void {
    this.dataControles.playing = false;
  }

  onSeeked(): void {
    if (this.info.model && this.info.icon === "fas-sync-alt") {
      this.info = {
        icon: "",
        text: "",
        model: false
      };
    }
  }

  onLoadedDataVideo(): void {
    if (this.info.model) {
      this.info = {
        icon: "",
        text: "",
        model: false
      };
    }
    this.dataControles.duration = this.video?.duration ?? 0;
    if (this.$route.params.tiempo) {
      this.video.currentTime = parseFloat(this.$route.params.tiempo);
    }
  }

  onTimeUpdate(): void {
    this.dataControles.currentTime = this.video.currentTime;
    const res = Math.round(this.dataControles.currentTime % 30);
    const lastSecond = Math.round(this.dataControles.currentTime);
    if (res === 0 && lastSecond !== this.lastSecondSended) {
      this.lastSecondSended = lastSecond;
      this.setVisto();
    }
  }

  async playPause(): Promise<void> {
    if (this.dataControles.playing) {
      this.showInfo("far-pause-circle");
      this.video.pause();
      this.dataControles.playing = false;
    } else {
      this.dataControles.playing = true;
      this.showInfo("far-play-circle");
      try {
        await this.video.play();
      } catch (error) {
        this.dataControles.playing = false;
        this.showInfo("", "Error al cargar el video");
        console.error(error);
      }
    }
  }

  muteUnmute(): void {
    this.dataControles.muted = !this.dataControles.muted;
    this.showInfo(this.volumeIcon, this.volumeText);
    this.video.muted = this.dataControles.muted;
  }

  volumeUp(): void {
    if (this.volume > 0.9) return;
    if (this.dataControles.muted) this.muteUnmute();
    this.volume += 0.1;
    this.showInfo(this.volumeIcon, this.volumeText);
    this.video.volume = this.volume;
  }

  volumeDown(): void {
    if (this.volume < 0.1) return;
    if (this.dataControles.muted) this.muteUnmute();
    this.volume -= 0.1;
    this.showInfo(this.volumeIcon, this.volumeText);
    this.video.volume = this.volume;
  }

  backward5sec(): void {
    this.showInfo("fas-undo-alt", "-5 segundos");
    this.video.currentTime -= 5;
  }

  forward5sec(): void {
    this.showInfo("fas-redo-alt", "+5 segundos");
    this.video.currentTime += 5;
  }

  showFullscreen(): void {
    if (!this.dataControles.fullscreen) {
      this.showInfo("fas-expand-alt");
      this.videoContainer.requestFullscreen();
    } else {
      this.showInfo("fas-compress-alt");
      document.exitFullscreen();
    }
  }

  fullScreenChangeHandler(): void {
    this.dataControles.fullscreen = !!document.fullscreenElement;
  }

  setCurrentTime(time: number): void {
    this.info = {
      model: true,
      icon: "fas-sync-alt",
      text: "Cargando..."
    };
    this.video.currentTime = time;
  }

  resetView(): void {
    this.dataControles = {
      duration: 0,
      muted: false,
      playing: false,
      currentTime: 0,
      fullscreen: false,
      name: this.sesion.nombre
    };
    this.lastSecondSended = 0;
  }

  reload(): void {
    this.video.load();
    this.dataControles.playing = false;
    this.video.currentTime = this.dataControles.currentTime;
    this.showInfo("sync-alt", "Recargando...");
  }

  destroyed(): void {
    if (this.timeoutId) window.clearTimeout(this.timeoutId);
    document.removeEventListener("fullscreenchange", this.fullScreenChangeHandler);
    this.setVisto();
  }
}
