
// Decoradores
import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";

// Tipos
import {Testimonio, Testimonios} from "@/typings/store/plugins/easyFirestore/testimonios";
import {DataEditarTestimonios} from "@/typings/components/testimonios/editarTestimonios";

// Componentes
import ImagenTestimonio from "@/components/inicio/testimonios/ImagenTestimonio.vue";

type TestimonioKey = "comentario" | "informacion" | "profesion";

@Component({
  components: {
    ImagenTestimonio
  }
})
export default class ComponenteTestimonios extends Vue {
  @Prop() readonly editando!: boolean;
  @Prop() readonly testimonios!: Array<Testimonio>;

  created(): void {
    for (const testimonio of this.testimonios) {
      if (!testimonio.id) continue;
      this.$set(this.fotos, testimonio.id, null);
      this.$set(this.testimoniosPatch, testimonio.id, {...testimonio});
    }
  }

  testimoniosPatch: Testimonios = {};
  fotos: { [id: string]: File | null } = {};

  @Emit("set:testimonios")
  setTestimonios(): DataEditarTestimonios {
    return {
      fotos: this.fotos,
      testimonios: Object.values(this.testimoniosPatch)
    };
  }

  @Watch("editando")
  onEditandoChange(val: boolean): void {
    if (!val) this.setTestimonios();
  }

  inputHandler(ev: InputEvent, testimonio: Testimonio, key: TestimonioKey): void {
    const text = (ev.target as HTMLDivElement).innerText;
    if (!testimonio.id) return;
    const testimonioPatch = this.testimoniosPatch[testimonio.id];
    if (testimonioPatch && testimonioPatch[key]) {
      testimonioPatch[key] = text;
    }
  }

  keydownHandler(ev: KeyboardEvent, key: TestimonioKey): void {
    const text = (ev.target as HTMLDivElement).innerText;
    let maxLength = 0;
    switch (key) {
      case "comentario":
        maxLength = 168;
        break;
      case "informacion":
        maxLength = 34;
        break;
      case "profesion":
        maxLength = 34;
        break;
    }
    if (text.length > maxLength && ev.key.length === 1) {
      ev.preventDefault();
    }
  }

  getMaxLength(testimonio: Testimonio, key: TestimonioKey): string {
    if (!testimonio.id) return "";
    let maxLength = 0;
    switch (key) {
      case "comentario":
        maxLength = 169;
        break;
      case "informacion":
        maxLength = 35;
        break;
      case "profesion":
        maxLength = 35;
        break;
    }
    const _testimonio = this.testimoniosPatch[testimonio.id];
    if (!_testimonio) return "";
    const keyValue = _testimonio[key];
    if (!keyValue) return "";
    return `${keyValue.length}/${maxLength} caracteres`;
  }

}
