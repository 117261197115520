
// Vue
import {defineComponent} from "vue";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "IconButton",
  components: {
    SvgIcon,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    href: {
      default: null,
      type: String as () => string | null,
    },
    target: {
      default: null,
      type: String as () => string | null,
    },
    size: {
      type: String,
      default: "20px",
    },
  },
  emits: [
    "clikc",
  ],
  setup(_props, ctx) {
    // Emits

    const onClick = (event: MouseEvent) => {
      ctx.emit("click", event);
    };

    return {
      // Emits
      onClick,
    };
  },
});
