
// Vue
import {computed, defineComponent, ref, watch} from "vue";

// Helpers
import {toCurrency} from "@/helpers/number";
import Calculadora from "@/helpers/Calculadora";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import ExpansionPanel from "@/components/custom/ExpansionPanel.vue";
import vuetify from "@/plugins/vuetify";

export default defineComponent({
  name: "ResumenPagos",
  components: {
    SvgIcon,
    ExpansionPanel
  },
  props: {
    calculadora: {
      type: Object as () => Calculadora,
      required: true
    }
  },
  setup(props) {
    // Data

    const expansion = ref(false);

    // Computed

    const mobile = computed(() => vuetify.framework.breakpoint.mdAndDown);

    const totalPagosCredito = computed(() => props.calculadora.subtotalCredito);

    const totalPagosDebito = computed(() => props.calculadora.subtotalDebito);

    const totalPagosPrepago = computed(() => props.calculadora.subtotalPrepago);

    const totalPagos = computed(() => totalPagosCredito.value + totalPagosDebito.value + totalPagosPrepago.value);

    const totalComisionesRelatores = computed(() => props.calculadora.comisionRelatores);

    const totalComisionesCredito = computed(() => props.calculadora.comisionCredito);

    const totalComisionesDebito = computed(() => props.calculadora.comisionDebito);

    const totalComisionesPrepago = computed(() => props.calculadora.comisionPrepago);

    const totalComisionesWebpay = computed(() => totalComisionesCredito.value + totalComisionesDebito.value + totalComisionesPrepago.value);

    const totalComisiones = computed(() => totalComisionesWebpay.value + totalComisionesRelatores.value);

    const stringTotal = computed(() => toCurrency(props.calculadora.ganancias));

    // Watchs

    watch(mobile, (val: boolean) => {
      expansion.value = !val;
    }, {
      immediate: true
    });

    // Methods

    const tc = (val: number) => toCurrency(val);

    return {
      // Data
      expansion,
      // Computed
      mobile,
      totalPagos,
      stringTotal,
      totalComisiones,
      totalPagosDebito,
      totalPagosCredito,
      totalPagosPrepago,
      totalComisionesDebito,
      totalComisionesWebpay,
      totalComisionesCredito,
      totalComisionesPrepago,
      totalComisionesRelatores,
      // Methods
      tc
    };
  }
});
