
import {Component, Prop, Vue} from "vue-property-decorator";

// Google Charts
import {GChart} from "vue-google-charts/legacy";
import {GoogleChartOptions} from "vue-google-charts/dist/types";

// Tipos
import {DateGroup} from "@/typings/vista/monitoreo";
import {Inscripcion} from "@/typings/store/plugins/easyFirestore/inscripciones";

@Component({
  components: {
    GChart
  }
})
export default class GraficoVentas extends Vue {
  @Prop() readonly dateGroups!: DateGroup[];
  @Prop() readonly inscripciones!: Inscripcion[];

  tipoDebito = "VD";
  tipoPrepago = "VP";
  tiposCredito = ["VN", "VC", "SI", "S2", "NC"];

  get chartData(): (string | number)[][] {
    const data: { [key: string]: { prepago: number; credito: number; debito: number } } = {};
    for (const {id} of this.dateGroups) {
      if (!data[id]) this.$set(data, id, {prepago: 0, credito: 0, debito: 0});
    }
    for (const inscripcion of this.inscripciones) {
      const tipo = inscripcion.pago.resultado?.payment_type_code;
      const key = this.getDateGroupId(inscripcion.fecha);
      if (!key || !tipo) continue;
      if (tipo === this.tipoPrepago) data[key].prepago++;
      if (tipo === this.tipoDebito) data[key].debito++;
      if (this.tiposCredito.includes(tipo)) data[key].credito++;
    }
    const chartData: (string | number)[][] = [
      ["Día", "Prepago", "Débito", "Crédito"]
    ];
    for (const [key, values] of Object.entries(data)) {
      chartData.push([
        key,
        values.prepago,
        values.debito,
        values.credito
      ]);
    }
    return chartData;
  }

  get chartOptions(): GoogleChartOptions {
    return {
      curveType: 'function',
      series: {
        0: {
          color: this.$vuetify.theme.themes.light.dark as string
        },
        1: {
          color: "#4E99FF"
        },
        2: {
          color: this.$vuetify.theme.themes.light.accent as string
        }
      },
      hAxis: {
        textStyle: {
          color: '#707070',
          fontName: 'SF UI Display'
        }
      },
      vAxis: {
        textStyle: {
          color: '#707070',
          fontName: 'SF UI Display'
        }
      }
    };
  }

  getDateGroupId(fecha: string): string | null {
    const date = new Date(fecha);
    return this.dateGroups.find(({start, end}) => date.isBetween(start, end))?.id ?? null;
  }
}
