
// Decoradores
import {Component, Prop, VModel, Vue} from "vue-property-decorator";

@Component
export default class DialogoAgregado extends Vue {
  @VModel() model!: boolean;

  @Prop() readonly texto!: string;
}
