
// Decoradores
import {Component, Prop, VModel, Vue} from "vue-property-decorator";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon
  }
})
export default class OrdenarPorCursosCliente extends Vue {
  @VModel() model!: "fecha" | "nombre" | null;

  @Prop() readonly disabled!: boolean;

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get classesFecha(): string {
    let classes = "font-weight-medium sf-ui-display dark--text text-initial font-size-18 border-bottom";
    if (this.model === "fecha") classes += " alternative";
    return classes;
  }

  get classesNombre(): string {
    let classes = "font-weight-medium sf-ui-display dark--text text-initial font-size-18 border-bottom";
    if (this.model === "nombre") classes += " alternative";
    return classes;
  }

  seleccionar(valor: "fecha" | "nombre"): void {
    if (this.model !== valor) {
      this.model = valor;
    } else {
      this.model = null;
    }
  }
}
