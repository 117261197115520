
// Decoradores
import {Component, Emit, Prop, VModel, Vue, Watch} from "vue-property-decorator";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import FiltrosContactos from "@/components/contactos/FiltrosContactos.vue";

@Component({
  components: {
    SvgIcon,
    FiltrosContactos
  }
})
export default class DialogoFiltrosContactos extends Vue {
  @VModel() model!: boolean;

  @Prop() readonly filtroAsunto!: string;

  filtrosAsuntoInterno = "";

  @Watch("model")
  onModelChange(val: boolean): void {
    if (val) {
      this.filtrosAsuntoInterno = this.filtroAsunto;
    }
  }

  @Emit("click:cerrar")
  onClickCerrar(): void {
    this.model = false;
  }

  @Emit("click:aplicar")
  onClickAplicar(): string {
    this.model = false;
    return this.filtrosAsuntoInterno;
  }
}
