
// Vue
import {computed, defineComponent} from "vue";

// Router
import router from "@/router";

// Tipos
import {Usuario} from '@/typings/store/plugins/easyFirestore/usuarios';

// Componentes
import UsersList from "@/components/custom/UsersList.vue";
import TextButton from "@/components/custom/TextButton.vue";

export default defineComponent({
  name: "RelatoresInicio",
  components: {
    UsersList,
    TextButton
  },
  props: {
    relatores: {
      required: true,
      type: Array as () => Usuario[]
    }
  },
  setup(props) {
    // Computed

    const items = computed(() => props.relatores.slice(0, 6));

    // Methods

    const verMas = (id: string) => {
      router.push({name: 'relatores', params: {id}});
    };

    return {
      // Computed
      items,
      // Methods
      verMas
    };
  }
});
