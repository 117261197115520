
// Vue
import {computed, defineComponent} from "vue";

// Vuetify
import vuetify from "@/plugins/vuetify";

// Tipos
import {Region} from "@/typings/store/plugins/easyFirestore/regiones";
import {Ciudad} from "@/typings/store/plugins/easyFirestore/ciudades";
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";
import {FormularioPerfilType} from "@/typings/components/perfil/formularioPerfil";

// Componentes
import PageSelect from "@/components/custom/PageSelect.vue";
import TextButton from "@/components/custom/TextButton.vue";
import PageTextArea from "@/components/custom/PageTextArea.vue";
import PageTextField from "@/components/custom/PageTextField.vue";
import ImagenUsuario from "@/components/usuarios/formulario/ImagenUsuario.vue";

export default defineComponent({
  name: "FormularioPerfil",
  components: {
    PageSelect,
    TextButton,
    PageTextArea,
    PageTextField,
    ImagenUsuario,
  },
  props: {
    editando: {
      type: Boolean,
      required: true,
    },
    buttonDisabled: {
      type: Boolean,
      required: true,
    },
    cargando: {
      type: Boolean,
      required: true,
    },
    regiones: {
      required: true,
      type: Array as () => Region[],
    },
    ciudades: {
      required: true,
      type: Array as () => Ciudad[],
    },
    usuario: {
      required: true,
      type: Object as () => Usuario,
    },
    value: {
      required: true,
      type: Object as () => FormularioPerfilType,
    },
  },
  emits: [
    "input",
    "click:guardar",
  ],
  setup(props, ctx) {
    // VModel

    const form = computed({
      get() {
        return props.value;
      },
      set(value: FormularioPerfilType) {
        ctx.emit("input", value);
      },
    });

    // Computed

    const readonly = computed(() => !props.editando);

    const mobile = computed(() => vuetify.framework.breakpoint.mdAndDown);

    const tipoUsuario = computed(() => props.usuario.tipo);

    const correo = computed(() => props.usuario.email);

    const formClass = computed(() => ({
      "formulario__form": true,
      "formulario__form--relator": tipoUsuario.value === "relator",
    }));

    const containerClass = computed(() => ({
      "formulario__container": true,
      "formulario__container--cliente": tipoUsuario.value === "cliente",
    }));

    const file = computed({
      get() {
        return form.value.file;
      },
      set(value: File | null) {
        form.value.file = value;
      },
    });

    const nombre = computed({
      get() {
        return form.value.nombre;
      },
      set(value: string) {
        form.value.nombre = value;
      },
    });

    const profesion = computed({
      get() {
        return form.value.profesion;
      },
      set(value: string) {
        form.value.profesion = value;
      },
    });

    const rut = computed({
      get() {
        return form.value.rut;
      },
      set(value: string) {
        form.value.rut = value;
      },
    });

    const telefono = computed({
      get() {
        return form.value.telefono;
      },
      set(value: string) {
        form.value.telefono = value;
      },
    });

    const region = computed({
      get() {
        return form.value.region;
      },
      set(value: Region | null) {
        form.value.region = value;
      },
    });

    const ciudad = computed({
      get() {
        return form.value.ciudad;
      },
      set(value: Ciudad | null) {
        form.value.ciudad = value;
      },
    });

    const antecedentes = computed({
      get() {
        return form.value.antecedentes;
      },
      set(value: string) {
        form.value.antecedentes = value;
      },
    });

    // Emits

    const onClickGuardar = () => {
      ctx.emit("click:guardar");
    };

    return {
      // VModel
      form,
      // Computed
      rut,
      file,
      mobile,
      correo,
      nombre,
      region,
      ciudad,
      readonly,
      telefono,
      formClass,
      profesion,
      tipoUsuario,
      antecedentes,
      containerClass,
      // Emits
      onClickGuardar,
    };
  },
});
