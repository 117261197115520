import { RouteConfig } from "vue-router";
import ResultadoCompra from "@/views/VistaResultadoCompra.vue";

const resultadoCompra: RouteConfig = {
  path: "/resultadoCompra/:id",
  name: "resultadoCompra",
  component: ResultadoCompra
};

export default resultadoCompra;
