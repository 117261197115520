
// Vue
import {computed, defineComponent} from "vue";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "Checkbox",
  components: {
    SvgIcon
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    boxIcon: {
      type: String,
      default: "far-square"
    },
    color: {
      type: String,
      default: "black"
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    boxIconSize: {
      type: String,
      default: "24px"
    },
    checkedIcon: {
      type: String,
      default: "fas-check-square"
    },
    checkedIconSize: {
      type: String,
      default: "24px"
    }
  },
  emits: [
    "input",
    "change"
  ],
  setup(props, ctx) {
    // VModel

    const model = computed({
      get() {
        return props.value;
      },
      set(value) {
        ctx.emit("input", value);
        ctx.emit("change", value);
      }
    });

    // Computed

    const checkBoxClass = computed(() => {
      return {
        'opacity-3': props.disabled,
        'relative check-container': true,
        'cursor-pointer': !props.disabled
      };
    });

    // Methods

    const toggle = () => {
      if (props.readonly || props.disabled) return;
      model.value = !model.value;
    };

    return {
      // VModel
      model,
      // Computed
      checkBoxClass,
      // Methods
      toggle
    };
  }
});
