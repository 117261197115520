
// Vue
import {computed, defineComponent} from "vue";

export default defineComponent({
  name: "TimeInput",
  props: {
    value: {
      type: String,
      required: true
    },
    max: {
      type: String
    },
    min: {
      type: String
    },
    label: {
      type: String,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    "input",
    "change"
  ],
  setup(props, ctx) {
    // VModel

    const model = computed({
      get() {
        return props.value;
      },
      set(value) {
        ctx.emit("input", value);
      }
    });

    // Computed

    const classes = computed(() => {
      return {
        'readonly': props.readonly,
        'time-input__container': true
      };
    });

    // Emits

    const onChange = (event: Event) => {
      ctx.emit("change", event);
    };

    return {
      //VModel
      model,
      // Computed
      classes,
      // Emits
      onChange
    };
  }
});
