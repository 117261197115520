
// Decoradores
import {Component, Emit, Prop, VModel, Vue} from "vue-property-decorator";

// Tipos
import {FiltrosUsuarioType} from "@/typings/components/usuarios/filtros";
import {EstadoUsuario, TipoUsuario} from "@/typings/store/plugins/easyFirestore/usuarios";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import TextButton from "@/components/custom/TextButton.vue";
import PageFilter from "@/components/custom/PageFilter.vue";
import ResetButton from "@/components/custom/ResetButton.vue";
import FiltersContainer from "@/components/custom/FiltersContainer.vue";

@Component({
  components: {
    SvgIcon,
    PageFilter,
    TextButton,
    ResetButton,
    FiltersContainer
  }
})
export default class FiltrosUsuarios extends Vue {
  @VModel() model!: FiltrosUsuarioType;
  @Prop({type: Boolean}) readonly dialog!: boolean;

  created(): void {
    this.filtroTipoUsuario = this.model.tipo.slice();
    this.filtroEstadoUsuario = this.model.estado.slice();
  }

  filtroTipoUsuario: TipoUsuario[] = [];
  filtroEstadoUsuario: (EstadoUsuario | "inactivo")[] = [];

  itemsTipoUsuario = [{
    value: "admin",
    text: "Admin"
  }, {
    value: "cliente",
    text: "Cliente"
  }, {
    value: "relator",
    text: "Relator"
  }];

  itemsEstadoUsuario = [{
    value: "libre",
    text: "Habilitado"
  }, {
    value: "bloqueado",
    text: "Deshabilitado"
  }, {
    value: "inactivo",
    text: "Inactivo"
  }];

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  @Emit("click:aplicar")
  onClickAplicar(): void {
    this.model = {
      tipo: this.filtroTipoUsuario.slice(),
      estado: this.filtroEstadoUsuario.slice()
    };
  }

  @Emit("click:resetear")
  onClickResetear(): void {
    this.filtroTipoUsuario = [];
    this.filtroEstadoUsuario = [];
    this.model = {
      tipo: [],
      estado: []
    };
  }

}
