// vuex
import {ActionTree, GetterTree, MutationTree} from "vuex";
// firebase
import {Firebase} from "@/config/firebase";
// helpers
import {env} from "@/helpers/env";
import {idtify} from "@/helpers/string";
//tipos
import {State} from "@/typings/store";
import {Ruta, Rutas} from "@/typings/store/modules/rutas";
import {ProviderId, UsuarioState} from "@/typings/store/modules/usuario";
import {Usuarios, Usuario, TipoUsuario, Permisos} from "@/typings/store/plugins/easyFirestore/usuarios";
import {Carritos} from "@/typings/store/plugins/easyFirestore/carritos";

class AuthError extends Error {
  code: string;

  constructor(message: string, code: string) {
    super(message);
    this.code = code;
  }
}

const state: UsuarioState = {
  error: null,
  intentos: 0,
  usuario: null,
  primerInicio: false,
  providerId: null
};

const getters: GetterTree<UsuarioState, State> = {
  get(_state, _getters, _rootState, _rootGetters) {
    const usuarios = _rootGetters["usuarios/get"] as Usuarios;
    const id = _state.usuario?.id;
    return id ? usuarios[id] : null;
  },
  getProviderId(_state) {
    return _state.providerId;
  },
  getCarrito(_state, _getters, _rootState, _rootGetters) {
    const _usuario = _state.usuario;
    if (!_usuario?.id) return null;
    const carritos = _rootGetters["carritos/get"] as Carritos;
    return carritos[_usuario.id] ?? null;
  },
  getErrorSignIn(_state) {
    return _state.error;
  },
  getPrimerInicio(_state) {
    return _state.primerInicio;
  },
  getRutas(_state, _getters, _rootState, _rootGetters) {
    const _usuario = _getters["get"] as Usuario | null;
    const permisos = _usuario?.permisos ? _usuario.permisos : {};
    const rutas = _rootGetters["rutas/get"] as Rutas;
    const rutasVerdaderas: Ruta[] = [];
    for (const key in permisos) {
      if (rutas[key]) {
        rutasVerdaderas.push(rutas[key]);
      }
    }
    return rutasVerdaderas.sort((a, b) => {
      if (!a.number) return 1;
      if (!b.number) return -1;
      return a.number - b.number;
    });
  },
  getPermisos() {
    const permisos: { [key: string]: TipoUsuario[] } = {
      pagos: ["admin"],
      usuarios: ["admin"],
      carrito: ["cliente"],
      contactos: ["admin"],
      misCursos: ["cliente"],
      historial: ["cliente"],
      promociones: ["admin"],
      reproductor: ["cliente"],
      cursos: ["admin", "relator"],
      inicio: ["admin", "cliente"],
      resultadoCompra: ["cliente"],
      relatores: ["admin", "cliente"],
      monitoreo: ["admin", "relator"],
      quienesSomos: ["admin", "cliente"],
      inscripciones: ["relator", "admin"],
      agenda: ["cliente", "admin", "relator"],
      cuenta: ["cliente", "admin", "relator"],
      cursosDisponibles: ["admin", "cliente"],
      perfil: ["admin", "cliente", "relator"],
      informacionCompleta: ["admin", "cliente"],
      terminosCondiciones: ["admin", "cliente"],
      notificaciones: ["admin", "cliente", "relator"]
    };
    const resultado: { [key: string]: Permisos } = {};
    const tipos: TipoUsuario[] = ["admin", "cliente", "relator"];
    for (const tipo of tipos) {
      resultado[tipo] = {};
      for (const key in permisos) {
        if (permisos[key].includes(tipo)) {
          resultado[tipo][key] = true;
        }
      }
    }
    return resultado;
  }
};

const mutations: MutationTree<UsuarioState> = {
  set(_state, data: Usuario | null) {
    _state.usuario = data;
  },
  setError(_state, data: string | null) {
    _state.error = data;
  },
  setPrimerInicio(_state, data: boolean) {
    _state.primerInicio = data;
  },
  setIntentos(_state, data: number) {
    _state.intentos = data;
  },
  setProviderId(_state, data: ProviderId | null) {
    _state.providerId = data;
  }
};

const actions: ActionTree<UsuarioState, State> = {
  set(ctx, data: Usuario | null) {
    if (!data) {
      ctx.commit("set", null);
      return "";
    }
    ctx.commit("set", data);
    return data.id;
  },
  async signInGoogle() {
    const provider = new Firebase.auth.GoogleAuthProvider();
    await Firebase.auth().signInWithPopup(provider);
  },
  async signInFacebook() {
    const provider = new Firebase.auth.FacebookAuthProvider();
    await Firebase.auth().signInWithPopup(provider);
  },
  async signIn(ctx, {email, password}: { email: string; password: string }) {
    ctx.commit("setError", null);
    try {
      await Firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (error) {
      const id = idtify(email);
      try {
        await ctx.dispatch("usuarios/fetchById", id, {root: true});
      } catch (errorDispatch) {
        if (env === "dev") {
          console.error("Usuario no encontrado => ", errorDispatch);
        }
      }
      const usuarios = ctx.rootGetters["usuarios/get"] as Usuarios;
      switch (error.code) {
        case "auth/user-not-found":
          if (usuarios[id]) {
            if (usuarios[id].rut.toLowerCase() === password.toLowerCase()) {
              throw new AuthError("Primera vez", "auth/first-time-login");
            }
            throw new AuthError("La contraseña es incorrecta", "auth/wrong-password");
          }
          throw error;
        case "auth/wrong-password":
          ctx.commit("setIntentos", ctx.state.intentos + 1);
          throw error;
        default:
          throw error;
      }
    }
  },
  async signOut(ctx) {
    await Firebase.auth().signOut();
    ctx.commit("setError", null);
    ctx.commit("setPrimerInicio", false);
  },
  async recuperarPassword(ctx, email: string) {
    ctx.commit("setError", null);
    await Firebase.auth().sendPasswordResetEmail(email);
  },
  async activar(ctx, data: { email: string; password: string }) {
    ctx.commit("setError", null);
    await Firebase.auth().createUserWithEmailAndPassword(data.email, data.password);
  },
  async signUp(ctx, data: { usuario: Usuario, password: string }) {
    const usuarioCreado = data.usuario;
    const password = data.password;
    ctx.commit("setError", null);
    const userCredential = await Firebase.auth().createUserWithEmailAndPassword(usuarioCreado.email, password);
    const user = userCredential.user;
    if (user) {
      const usuarioClone = Object.assign({}, usuarioCreado);
      usuarioClone.id = user.uid;
      usuarioClone.uid = user.uid;
      await ctx.dispatch("usuarios/set", usuarioClone, {root: true});
    }
  },
  async updatePassword(_ctx, {currentPassword, newPassword}: { currentPassword: string; newPassword: string }) {
    const currentUser = await Firebase.auth().currentUser;
    if (!currentUser) throw new AuthError("No hay usuario", "auth/user-not-found");
    if (!currentUser.email) throw new AuthError("No hay email", "auth/email-not-found");
    const email = currentUser.email;
    const credential = Firebase.auth.EmailAuthProvider.credential(email, currentPassword);
    await currentUser.reauthenticateWithCredential(credential);
    await currentUser.updatePassword(newPassword);
  }
};

const usuario = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

export default usuario;
