
// Vue
import {computed, defineComponent, ref, watch} from "vue";

export default defineComponent({
  name: "SelectPeriodo",
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  emits: [
    "input",
  ],
  setup(props, ctx) {
    // VModel

    const selected = computed({
      get() {
        return props.value;
      },
      set(value: number) {
        ctx.emit("input", value);
      },
    });

    // Data

    const selectPeriodo = ref<HTMLDivElement | null>(null);
    const items = [{
      text: "Semana",
      value: 0,
    }, {
      text: "Mes",
      value: 1,
    }, {
      text: "Año",
      value: 2,
    }];

    // Watchers

    watch(selected, (value: number) => {
      if (selectPeriodo.value) {
        selectPeriodo.value.style.setProperty("--slide", value.toString());
      }
    });

    // Methods

    const getClasses = (value: number) => ({
      "select-periodo__item": true,
      "select-periodo__item--selected": selected.value === value,
    });

    const selectItem = ({value}: { value: number }) => {
      selected.value = value;
    };

    return {
      // VModel
      selected,
      // Data
      items,
      selectPeriodo,
      // Methods
      getClasses,
      selectItem,
    };
  },
});
