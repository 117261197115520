import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import es from "vuetify/src/locale/es";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        text: '#707070',
        dark: '#020000',
        card: '#F4F9FC',
        error: '#EA5143',
        accent: '#0A6FF9',
        primary: '#014CB2',
        overlay: '#333945',
        success: '#279B18',
        tertiary: '#EBEBEB',
        disabled: "#D1D1D1",
        'text-2': '#747C8D',
        secondary: '#001F44',
        'accent-2': '#033775',
        background: '#F7FAFC',
        'primary-2': '#62A8A8',
        alternative: '#F4F4F4',
        'tertiary-2': '#6B95C7',
        'secondary-2': '#04071C',
        'alternative-2': 'FAFAFA'
      }
    }
  },
  lang: {
    locales: {es},
    current: "es"
  }
});
