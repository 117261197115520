
// Decoradores
import {Component, Emit, Prop, VModel, Vue} from "vue-property-decorator";

// Tipos
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";
import {FiltrosCursosClienteType} from "@/typings/components/cursosCliente/filtrosCursosCliente";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import TextButton from "@/components/custom/TextButton.vue";
import ResetButton from "@/components/custom/ResetButton.vue";
import PageFilter, {PageFilterItem} from "@/components/custom/PageFilter.vue";

@Component({
  components: {
    SvgIcon,
    TextButton,
    PageFilter,
    ResetButton,
  },
})
export default class FiltrosCursosCliente extends Vue {
  @VModel() filtros!: FiltrosCursosClienteType;

  @Prop() readonly disabled!: boolean;
  @Prop() readonly categorias!: Array<string>;
  @Prop() readonly relatores!: Array<Usuario>;
  @Prop({type: Boolean}) readonly dialog!: boolean;

  created(): void {
    this.filtroRelatores = this.filtros.relatores.slice();
    this.filtroCategorias = this.filtros.categorias.slice();
    this.filtroRangoPrecio = this.filtros.rangoPrecio.slice();
  }

  filtroRelatores: Array<string> = [];
  filtroCategorias: Array<string> = [];
  filtroRangoPrecio: Array<string> = [];

  get misCursos(): boolean {
    return this.$route.name === "misCursos";
  }

  get classesTitle(): string {
    let classes = "uni-neue font-weight-heavy font-size-24 primary-2--text";
    if (this.disabled) classes += " opacity-3";
    return classes;
  }

  get itemsCategorias(): PageFilterItem[] {
    return this.categorias.map(categoria => ({
      value: categoria,
      text: categoria,
    }));
  }

  get itemsPrecio(): PageFilterItem[] {
    return [{
      text: "$0 - $100.000",
      value: "0",
    }, {
      text: "$101.000 - $200.000",
      value: "1",
    }, {
      text: "$201.000 - $300.000",
      value: "2",
    }, {
      text: "$301.000 - Más",
      value: "3",
    }];
  }

  get itemsRelatores(): PageFilterItem[] {
    return this.relatores.map(relator => ({
      value: relator.id,
      text: relator.nombre,
    }));
  }

  @Emit("click:aplicar")
  onClickAplicar(): void {
    this.filtros = {
      relatores: this.filtroRelatores.slice(),
      categorias: this.filtroCategorias.slice(),
      rangoPrecio: this.filtroRangoPrecio.slice(),
    };
  }

  @Emit("click:resetear")
  onClickResetear(): void {
    this.filtroRelatores = [];
    this.filtroCategorias = [];
    this.filtroRangoPrecio = [];
    this.filtros = {
      relatores: [],
      categorias: [],
      rangoPrecio: [],
    };
  }
}
