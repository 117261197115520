import {RouteConfig} from "vue-router";
import VistaLogin from "@/views/VistaLogin.vue";

const iniciarSesion: RouteConfig = {
  path: "/iniciarSesion",
  name: "iniciarSesion",
  component: VistaLogin
};

export default iniciarSesion;
