
// Vue
import {computed, defineComponent} from "vue";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "PageTextField",
  components: {
    SvgIcon
  },
  props: {
    value: {
      required: true,
      type: [String, Number]
    },
    label: {
      type: String
    },
    placeholder: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: "text"
    },
    appendIcon: {
      type: String,
      default: null
    },
    prependIcon: {
      type: String,
      default: null
    },
    appendIconColor: {
      type: String,
      default: "primary"
    },
    prependIconColor: {
      type: String,
      default: "primary"
    },
    readonly: {
      type: Boolean,
      default: false
    },
    inactive: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: Array,
      default: () => [] as string[]
    }
  },
  emits: [
    "input",
    "keydown",
    "click:append"
  ],
  setup(props, ctx) {
    // VModel

    const model = computed({
      get() {
        return props.value;
      },
      set(value) {
        ctx.emit("input", value);
      }
    });

    // Computed

    const classInput = computed(() => ({
      'page-text-field__input': true,
      'page-text-field__input--readonly': props.readonly
    }));

    // Emits

    const onClickAppend = () => {
      ctx.emit("click:append");
    };

    const onKeyDown = (event: KeyboardEvent) => {
      ctx.emit("keydown", event);
    };

    return {
      // VModel
      model,
      // Computed
      classInput,
      // Emits
      onKeyDown,
      onClickAppend
    };
  }
});
