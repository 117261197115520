import { RouteConfig } from "vue-router";
import CursosDisponibles from "@/views/VistaCursosDisponibles.vue";

const cursosDisponibles: RouteConfig = {
    path: "/cursosdisponibles",
    name: "cursosDisponibles",
    component: CursosDisponibles,
};

export default cursosDisponibles;
