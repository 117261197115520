
// Decoradores
import {Component, Emit, Prop, VModel, Vue} from "vue-property-decorator";

// Tipos
import {FiltrosPagoType} from "@/typings/components/pagos/filtros";

// Componentes
import Checkbox from "@/components/custom/Checkbox.vue";
import TextButton from "@/components/custom/TextButton.vue";
import PageFilter from "@/components/custom/PageFilter.vue";
import ResetButton from "@/components/custom/ResetButton.vue";
import FilterDatePicker from "@/components/custom/FilterDatePicker.vue";
import FiltersContainer from "@/components/custom/FiltersContainer.vue";

@Component({
  components: {
    Checkbox,
    PageFilter,
    TextButton,
    ResetButton,
    FilterDatePicker,
    FiltersContainer
  }
})
export default class FiltrosPagos extends Vue {
  @VModel() model!: FiltrosPagoType;

  @Prop() readonly disabled!: boolean;
  @Prop({type: Boolean}) readonly dialog!: boolean;

  created(): void {
    this.filtroTipoPago = this.model.tipo.slice();
    const filtroSeleccionado = this.model.fechaSeleccionada;
    if (filtroSeleccionado) {
      this.filtroFechaSeleccion = [filtroSeleccionado];
      if (filtroSeleccionado === "5") {
        this.fechaPersonalizada = this.model.fecha.map(fecha => fecha.format("YYYY-MM-DD"));
        this.checkBoxPersonalizar = true;
      }
    }
  }

  filtroTipoPago: string[] = [];
  fechaPersonalizada: string[] = [];
  filtroFechaSeleccion: string[] = [];
  itemsFecha = [{
    value: "1",
    text: "Últimas 24 horas"
  }, {
    value: "2",
    text: "Última semana"
  }, {
    value: "3",
    text: "Último mes"
  }, {
    value: "4",
    text: "Último año"
  }];
  tiposPago = [{
    value: "crédito",
    text: "Crédito"
  }, {
    value: "débito",
    text: "Débito"
  }, {
    value: "Prepago",
    text: "Prepago"
  }];

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get checkBoxPersonalizar(): boolean {
    return this.filtroFechaSeleccion[0] === "5";
  }

  set checkBoxPersonalizar(value: boolean) {
    if (value) {
      this.$set(this.filtroFechaSeleccion, 0, "5");
    } else {
      this.filtroFechaSeleccion = [];
    }
  }

  get fechaMostrar(): string[] {
    if (this.filtroFechaSeleccion[0] !== "5") return [];
    return this.fechaPersonalizada;
  }

  set fechaMostrar(value: string[]) {
    if (this.filtroFechaSeleccion[0] === "5") {
      this.fechaPersonalizada = value;
    }
  }

  get filtroFecha(): Date[] {
    if (!this.filtroFechaSeleccion.length) return [];
    let fechaInicio = new Date();
    let fechaFin = new Date();
    switch (this.filtroFechaSeleccion[0]) {
      case "1":
        fechaInicio.setHours(fechaInicio.getHours() - 24);
        break;
      case "2":
        fechaInicio.setDate(fechaInicio.getDate() - 6);
        fechaInicio.setHours(0, 0, 0, 0);
        break;
      case "3":
        fechaInicio.setMonth(fechaInicio.getMonth() - 1);
        fechaInicio.setHours(0, 0, 0, 0);
        break;
      case "4":
        fechaInicio.setFullYear(fechaInicio.getFullYear() - 1);
        fechaInicio.setHours(0, 0, 0, 0);
        break;
      case "5":
        fechaInicio = this.fechaPersonalizada[0].toDate();
        fechaInicio.setHours(0, 0, 0, 0);
        fechaFin = this.fechaPersonalizada[1].toDate();
        fechaFin.setHours(23, 59, 59, 999);
        break;
    }
    return [fechaInicio, fechaFin];
  }

  @Emit("click:resetear")
  onClickResetear(): void {
    this.filtroTipoPago = [];
    this.fechaPersonalizada = [];
    this.filtroFechaSeleccion = [];
    this.model = {
      tipo: [],
      fecha: [],
      fechaSeleccionada: ""
    };
  }

  @Emit("click:aplicar")
  onClickAplicar(): void {
    this.model = {
      fecha: this.filtroFecha,
      tipo: this.filtroTipoPago.slice(),
      fechaSeleccionada: this.filtroFechaSeleccion[0] ?? ""
    };
  }

  allowedDates(fecha: string): boolean {
    const now = new Date();
    const date = fecha.toDate();
    return date.getTime() <= now.getTime();
  }
}
