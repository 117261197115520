
// Decoradores
import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";

// Tipos
import {Pagina, Razon} from "@/typings/store/plugins/easyFirestore/pagina";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

interface Item {
  id: string;
  icon: string;
  title: string;
  description: string;
}

@Component({
  components: {
    SvgIcon
  }
})
export default class PorQueInicio extends Vue {
  @Prop() readonly pagina!: Pagina | null;
  @Prop() readonly editando!: boolean;

  razonesPatch: { [id: string]: Razon } = {};

  get items(): Item[] {
    return [{
      id: "aprendizajeAcelerado",
      icon: "ag-double-bolt",
      title: "Aprendizaje acelerado",
      description: this.pagina?.aprendizajeAcelerado?.texto ?? ""
    }, {
      id: "lifeCoaching",
      icon: "fas-chalkboard-teacher",
      title: "Life coaching",
      description: this.pagina?.lifeCoaching?.texto ?? ""
    }, {
      id: "perfeccionamiento",
      icon: "fas-check-double",
      title: "Perfeccionamiento",
      description: this.pagina?.perfeccionamiento?.texto ?? ""
    }, {
      id: "tiempo",
      icon: "fas-clock",
      title: "Tiempo",
      description: this.pagina?.tiempo?.texto ?? ""
    }];
  }

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  @Emit("set:razones")
  onGuardarRazones(): Razon[] {
    return Object.values(this.razonesPatch);
  }

  @Watch("editando")
  onEditandoChange(editando: boolean): void {
    if (!editando) this.onGuardarRazones();
  }

  @Watch("pagina", {immediate: true})
  onPaginaChange(pagina: Pagina | null): void {
    if (!pagina) return;
    this.$set(this.razonesPatch, "tiempo", {...pagina.tiempo});
    this.$set(this.razonesPatch, "lifeCoaching", {...pagina.lifeCoaching});
    this.$set(this.razonesPatch, "perfeccionamiento", {...pagina.perfeccionamiento});
    this.$set(this.razonesPatch, "aprendizajeAcelerado", {...pagina.aprendizajeAcelerado});
  }

  inputHandler(ev: InputEvent, id: string): void {
    const text = (ev.target as HTMLDivElement).innerText;
    const razonPatch = this.razonesPatch[id];
    if (razonPatch) razonPatch.texto = text;
  }

  keydownHandler(ev: KeyboardEvent): void {
    const text = (ev.target as HTMLDivElement).innerText;
    if (text.length > 345 && ev.key.length === 1) {
      ev.preventDefault();
    }
  }

  getMaxLength(id: string): string {
    const razonPatch = this.razonesPatch[id];
    if (!razonPatch?.texto) return "";
    return `${razonPatch.texto.length}/346 caracteres`;
  }
}
