
// Vue
import {computed, defineComponent} from "vue";

// Router

import router from "@/router";

// Tipos
import {Cupo} from "@/typings/store/plugins/easyFirestore/cupos";
import {Curso} from '@/typings/store/plugins/easyFirestore/cursos';
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";
import {Inscripcion} from "@/typings/store/plugins/easyFirestore/inscripciones";

// Helpers

import {toCurrency} from "@/helpers/number";
import {capitalize} from "@/helpers/string";
import {toFormatString} from "@/helpers/date";

// Componentes
import SvgIcon from '@/components/custom/SvgIcon.vue';
import TextButton from "@/components/custom/TextButton.vue";

export default defineComponent({
  name: "CardCurso",
  components: {
    SvgIcon,
    TextButton
  },
  props: {
    curso: {
      required: true,
      type: Object as () => Curso
    },
    cupos: {
      type: Array as () => Cupo[]
    },
    usuario: {
      type: Object as () => Usuario | null
    },
    inscripciones: {
      type: Array as () => Inscripcion[]
    }
  },
  setup(props) {
    // Data

    const nombre = props.curso.nombre;
    const precio = toCurrency(props.curso.precio);
    const categoria = props.curso.categoria.nombre;
    const imagenUrl = props.curso.imagen?.url ?? "";
    const precioRef = props.curso.precioRef ? toCurrency(props.curso.precioRef) : "";

    // Computed

    const comprado = computed(() => {
      if (!props.inscripciones) return false;
      return props.inscripciones.some(inscripcion => {
        if (props.usuario?.id !== inscripcion.cliente.id) return false;
        return !!inscripcion.pago.cursos[props.curso.id];
      });
    });

    const relator = computed(() => {
      const relatores = Object.values(props.curso.relatores);
      const nombreRelator = capitalize(relatores[0].nombre);
      return `Por ${nombreRelator}`;
    });

    const comienzo = computed(() => {
      const fecha = toFormatString(new Date(props.curso.inicio), "DD/MM/YYYY");
      return `Comienzo: ${fecha}`;
    });

    const cuposCurso = computed(() => {
      if (!props.cupos) return 'Sin cupos';
      const cupos = props.cupos.filter(_cupo => {
        const flagNoUsuario = !_cupo.usuario;
        const flagDisponible = _cupo.estado === "libre";
        const flagCurso = _cupo.curso.id === props.curso.id;
        return flagNoUsuario && flagDisponible && flagCurso;
      }).length;
      if (cupos === 0) return 'Sin cupos';
      return `${cupos} ${cupos === 1 ? 'cupo' : 'cupos'}`;
    });

    // Methods

    const clickVerCurso = () => {
      const id = props.curso.id;
      if (!id) return;
      const currentRoute = router.currentRoute;
      if (currentRoute.name !== 'informacionCompleta' || currentRoute.params.id !== id) {
        router.push({
          params: {id},
          name: 'informacionCompleta'
        });
      }
    };

    return {
      // Data
      nombre,
      precio,
      categoria,
      imagenUrl,
      precioRef,
      // Computed
      relator,
      comprado,
      comienzo,
      cuposCurso,
      // Methods
      clickVerCurso
    };
  }
});
