
// Decoradores
import {Component, Prop, VModel, Vue} from "vue-property-decorator";

// Tipos
import {Testimonio} from "@/typings/store/plugins/easyFirestore/testimonios";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon
  }
})
export default class ImagenTestimonio extends Vue {
  @VModel() file!: File | null;
  @Prop() readonly editando!: boolean;
  @Prop() readonly testimonio!: Testimonio | null;

  get url(): string {
    if (this.file) return URL.createObjectURL(this.file);
    return this.testimonio?.foto?.url ?? "";
  }

  clickCambiar(): void {
    const fileInput = this.$refs.fileInput as HTMLInputElement;
    fileInput.click();
  }

  onFileChange(e: InputEvent): void {
    const fileInput = e.target as HTMLInputElement;
    this.file = fileInput.files?.[0] ?? null;
  }

}
