
// Decoradores
import {Component, Emit, Prop, VModel, Vue, Watch} from "vue-property-decorator";

// Tipos
import {CategoriaCurso} from "@/typings/store/plugins/easyFirestore/categoriasCurso";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import TextButton from "@/components/custom/TextButton.vue";
import DialogTitle from "@/components/custom/DialogTitle.vue";
import PageTextField from "@/components/custom/PageTextField.vue";
import FormImagePicker from "@/components/custom/FormImagePicker.vue";

@Component({
  components: {
    SvgIcon,
    TextButton,
    DialogTitle,
    PageTextField,
    FormImagePicker,
  },
})
export default class FormularioCategoria extends Vue {
  @VModel() model!: boolean;

  @Prop() readonly categoria!: CategoriaCurso | null;

  nombre = "";
  preview = "";
  file: File | null = null;

  get buttonDisabled(): boolean {
    return !this.nombre;
  }

  @Emit("click:cerrar")
  onClickCerrar(): void {
    this.model = false;
  }

  @Emit("click:crear")
  onClickCrear(): { categoria: CategoriaCurso; imagen: File | null } {
    const categoria = Object.assign({}, this.categoria, {
      nombre: this.nombre,
    });
    return {
      categoria,
      imagen: this.file,
    };
  }

  @Watch("model", {immediate: true})
  onModelChanged(value: boolean): void {
    if (value && this.categoria) {
      this.nombre = this.categoria.nombre;
      this.preview = this.categoria.imagen?.url ?? "";
    } else {
      this.file = null;
      this.nombre = "";
      this.preview = "";
    }
  }
}
