
// Decoradores
import {Component, Emit, Prop, Vue} from 'vue-property-decorator';

// Tipos
import {Curso} from "@/typings/store/plugins/easyFirestore/cursos";
import {Sesion, SesionPatch} from "@/typings/store/plugins/easyFirestore/sesiones";
import {TipoUsuario, Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import TimeInput from "@/components/custom/TimeInput.vue";
import PageSelect from "@/components/custom/PageSelect.vue";
import TextButton from "@/components/custom/TextButton.vue";
import PageTextField from "@/components/custom/PageTextField.vue";
import PageDatePicker from "@/components/custom/PageDatePicker.vue";
import DialogTitle from "@/components/custom/DialogTitle.vue";

@Component({
  components: {
    DialogTitle,
    SvgIcon,
    TimeInput,
    TextButton,
    PageSelect,
    PageTextField,
    PageDatePicker,
  },
})
export default class FormularioAgenda extends Vue {
  @Prop() readonly cursos!: Curso[];
  @Prop() readonly sesiones!: Sesion[];
  @Prop() readonly usuario!: Usuario | null;
  @Prop() readonly fechaSeleccionada!: string | null;
  @Prop() readonly sesionSeleccionada!: Sesion | null;

  created(): void {
    if (this.fechaSeleccionada) {
      this.fecha = this.fechaSeleccionada;
    }
    if (!this.sesionSeleccionada) return;
    this.readonly = true;
    const inicio = this.sesionSeleccionada.fecha?.toDate();
    const fin = this.sesionSeleccionada.fecha?.toDate();
    if (fin) fin.setMinutes(fin.getMinutes() + this.sesionSeleccionada.duracion);
    this.sesion = this.sesionSeleccionada;
    this.link = this.sesionSeleccionada.link;
    this.curso = this.sesionSeleccionada.curso;
    this.horaFin = fin?.format("HH:mm") ?? "";
    this.fecha = inicio?.format("YYYY-MM-DD") ?? "";
    this.horaInicio = inicio?.format("HH:mm") ?? "";
  }

  link = "";
  fecha = "";
  readonly = false;
  validando = false;
  horaFin = "";
  horaInicio = "";
  curso: Curso | null = null;
  sesion: Sesion | null = null;

  get titulo(): string {
    let texto = "Programar";
    if (this.sesionSeleccionada && this.readonly) {
      texto = "Información";
    } else if (this.sesionSeleccionada) {
      texto = "Editar";
    }
    return `${texto} sesión`;
  }

  get erroresLink(): string[] {
    const errores: string[] = [];
    if (!this.validando) return errores;
    if (!this.validarLink(this.link)) errores.push("Link inválido");
    return errores;
  }

  get arrayCursos(): Curso[] {
    return this.cursos.filter(_curso => {
      return this.sesiones.some(_sesion => this.validarSesion(_sesion, _curso));
    });
  }

  get sesionesArray(): Sesion[] {
    return this.sesiones.filter(_sesion => this.validarSesion(_sesion, this.curso)).sort((a, b) => a.numero - b.numero);
  }

  get horasValidas(): boolean {
    const [horasInicio, minutosInicio] = this.horaInicio.split(":").map(Number);
    const inicio = new Date();
    inicio.setHours(horasInicio, minutosInicio);
    const [horasFin, minutosFin] = this.horaFin.split(":").map(Number);
    const fin = new Date();
    fin.setHours(horasFin, minutosFin);
    const minimo = new Date();
    minimo.setHours(14, 0);
    const maximo = new Date();
    maximo.setHours(23, 0);
    return fin.getTime() > inicio.getTime() && inicio.getTime() >= minimo.getTime() && fin.getTime() <= maximo.getTime();
  }

  get buttonDisabled(): boolean {
    if (this.readonly) return false;
    const flags = [
      !this.link,
      !this.fecha,
      !this.curso,
      !this.sesion,
      !this.horaFin,
      !this.horaInicio,
      !this.horasValidas,
    ];
    return flags.some(flag => flag);
  }

  get textoBoton(): string {
    let texto = "PROGRAMAR";
    if (this.sesionSeleccionada && this.readonly) {
      texto = "EDITAR";
    } else if (this.sesionSeleccionada) {
      texto = "GUARDAR";
    }
    return texto;
  }

  get tipoUsuario(): TipoUsuario | null {
    return this.usuario?.tipo ?? null;
  }

  @Emit("click:cerrar")
  onClickCerrar(): void {
    return;
  }

  @Emit("click:guardar")
  onClickGuardar(sesion: SesionPatch): SesionPatch {
    return sesion;
  }

  allowedDates(fecha: string): boolean {
    if (!this.curso) return false;
    const date = fecha.toDate();
    const inicio = new Date(this.curso.inicio);
    inicio.setHours(0, 0, 0, 0);
    const fin = new Date(this.curso.fin);
    fin.setHours(23, 59, 59, 999);
    return date.isBetween(inicio, fin) && date.getDay() > 0;
  }

  guardar(): void {
    if (this.sesionSeleccionada && this.readonly) {
      this.readonly = false;
    } else {
      this.validando = true;
      if (!this.sesion || !this.validarLink(this.link)) return;
      const inicio = `${this.fecha} ${this.horaInicio}`.toDate();
      const fin = `${this.fecha} ${this.horaFin}`.toDate();
      const duracionReal = Math.floor((fin.getTime() - inicio.getTime()) / 60000);
      const sesion: SesionPatch = {
        link: this.link,
        id: this.sesion.id,
        duracion: duracionReal,
        fecha: inicio.toISOString(),
      };
      this.onClickGuardar(sesion);
    }
  }

  validarLink(link: string): boolean {
    const regex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/g;
    return regex.test(link);
  }

  validarSesion(sesion: Sesion, curso: Curso | null): boolean {
    const flagTipo = sesion.tipo === "en-vivo";
    const flagCurso = sesion.curso.id === curso?.id;
    const flagNoProgramada = !sesion.fecha || this.sesionSeleccionada?.id === sesion.id;
    const flagRelator = this.usuario?.tipo === 'relator' ? sesion.relator?.id === this.usuario?.id : true;
    return flagCurso && flagRelator && flagTipo && flagNoProgramada;
  }
}
