
// Decoradores
import {Component, Emit, Prop, Vue} from "vue-property-decorator";

// Tipos
import {EventCalendar, vCalendar} from "@/typings/vuetify/vCalendar";
import {Sesion} from "@/typings/store/plugins/easyFirestore/sesiones";
import {Inscripcion} from "@/typings/store/plugins/easyFirestore/inscripciones";
import {TipoUsuario, Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";

// Componentes
import IconButton from "@/components/custom/IconButton.vue";
import EventoCalendario from "@/components/agenda/calendario/EventoCalendario.vue";

@Component({
  components: {
    IconButton,
    EventoCalendario,
  },
})
export default class CalendarioAgenda extends Vue {
  @Prop() readonly usuario!: Usuario;
  @Prop() readonly sesiones!: Sesion[];
  @Prop() readonly inscripciones!: Inscripcion[];
  @Prop() readonly tipoUsuario!: TipoUsuario | null;

  created(): void {
    this.fecha = new Date().format("YYYY-MM-DD");
  }

  fecha = "";
  firstInterval = 8;
  intervalCount = 14;
  weekdays = [1, 2, 3, 4, 5, 6];

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get intervals(): number[] {
    const result: number[] = [];
    const first = this.firstInterval + 1;
    const last = first + this.intervalCount;
    for (let i = first; i < last; i++) {
      result.push(i);
    }
    return result;
  }

  get headerClass(): { [key: string]: boolean } {
    return {
      "calendario__header": true,
      "calendario__header--cliente": this.tipoUsuario === "cliente",
    };
  }

  get numeroClases(): number {
    const week = this.fecha.toDate().getWeek();
    const monday = week[0];
    const saturday = week[week.length - 2];
    return this.events.filter(_event => _event.start.isBetween(monday, saturday)).length;
  }

  get numeroAsistentes(): number {
    const week = this.fecha.toDate().getWeek();
    const monday = week[0];
    const saturday = week[week.length - 2];
    const cursosIds: string[] = [];
    for (const sesion of this.sesiones) {
      const flagFecha = sesion.fecha && sesion.fecha.toDate().isBetween(monday, saturday);
      if (flagFecha && sesion.curso.id && !cursosIds.includes(sesion.curso.id)) {
        cursosIds.push(sesion.curso.id);
      }
    }
    return this.inscripciones.filter(_inscripcion => {
      const cursos = Object.values(_inscripcion.pago.cursos);
      const flagRelator = this.tipoUsuario === "admin" ? true : cursos.some(_curso => !!_curso.relatores[this.usuario.id]);
      const flagCurso = cursos.some(_curso => cursosIds.includes(_curso.id ?? ""));
      return flagCurso && flagRelator;
    }).length;
  }

  get titulo(): string {
    const fecha = this.fecha.toDate();
    if (!this.mobile) {
      const week = fecha.getWeek();
      const monday = week.at(0)?.format("DD/MM");
      const saturday = week.at(-2)?.format("DD/MM");
      return `Semana ${monday} - ${saturday}`;
    } else {
      return fecha.format("DD/MM");
    }
  }

  get events(): Array<EventCalendar> {
    const events: Array<EventCalendar> = [];
    for (const sesion of this.sesiones) {
      if (!sesion.fecha || !sesion.id) continue;
      const timed = true;
      const id = sesion.id;
      const name = sesion.nombre;
      const start = sesion.fecha.toDate();
      const number = sesion.numero.toString();
      const end = sesion.fecha.toDate();
      end.setMinutes(end.getMinutes() + sesion.duracion);
      const color = start.isToday() ? "secondary" : "primary";
      events.push({
        id,
        end,
        name,
        color,
        start,
        timed,
        sesion,
        number,
      });
    }
    return events;
  }

  get calendar(): vCalendar {
    return this.$refs.calendar as vCalendar;
  }

  @Emit("click:sesion")
  onClickSesion(sesion: Sesion): Sesion {
    return sesion;
  }

  @Emit("click:date")
  onClickDate({date}: { date: string }): string {
    return date;
  }

  @Emit("click:agregar-link")
  onClickAgregarLink({sesion}: EventCalendar): Sesion {
    return sesion;
  }

  calendarPrev(): void {
    if (!this.calendar) return;
    this.calendar.prev();
  }

  calendarNext(): void {
    if (!this.calendar) return;
    this.calendar.next();
  }

}
