
// Vue
import {computed, defineComponent, ref} from "vue";

// Componentes
import Checkbox from "@/components/custom/Checkbox.vue";

export interface PageFilterItem {
  text: string;
  value: string;
}

interface PageFilterItemInner {
  text: string;
  value: string;
  model: boolean;
}

export default defineComponent({
  name: "PageFilter",
  components: {
    Checkbox
  },
  props: {
    label: {
      type: String,
      required: true
    },
    items: {
      required: true,
      type: Array as () => PageFilterItem[]
    },
    value: {
      required: true,
      type: Array as () => string[]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    }
  },
  emits: [
    "input"
  ],
  setup(props, ctx) {
    // VModel

    const model = computed({
      get() {
        return props.value;
      },
      set(value) {
        ctx.emit("input", value);
      }
    });

    // Data

    const showingAll = ref(false);

    // Computed

    const containerClass = computed(() => ({
      "page-filter__container": true,
      "page-filter__container--disabled": props.disabled
    }));

    const boxIcon = computed(() => props.multiple ? 'far-square' : 'far-circle');

    const checkedIcon = computed(() => props.multiple ? 'fas-check-square' : 'fas-circle-dot');

    const innerItems = computed(() => {
      const items = props.items.map(item => ({
        text: item.text,
        value: item.value,
        model: model.value.includes(item.value)
      }));
      if (showingAll.value) return items;
      return items.slice(0, 5);
    });

    // Methods

    const handleClick = (item: PageFilterItemInner) => {
      if (props.disabled) return;
      const index = model.value.indexOf(item.value);
      if (index === -1) {
        if (props.multiple) {
          model.value.push(item.value);
        } else {
          model.value = [item.value];
        }
      } else {
        model.value.splice(index, 1);
      }
    };

    return {
      // VModel
      model,
      // Data
      showingAll,
      // Computed
      boxIcon,
      checkedIcon,
      containerClass,
      innerItems,
      // Methods
      handleClick
    };
  }
});
