
// Decoradores
import {Vue, Component} from "vue-property-decorator";

// Helpers
import {idtify} from "@/helpers/string";
import routeGuard from "@/helpers/routeGuard";

// Vuex
import {mapActions, mapGetters} from "vuex";

// Validaciones
import {required, email} from "vuelidate/lib/validators";

// Tipos
import {Region} from "@/typings/store/plugins/easyFirestore/regiones";
import {Ciudad} from "@/typings/store/plugins/easyFirestore/ciudades";
import {FiltrosUsuarioType} from "@/typings/components/usuarios/filtros";
import {DataFormularioUsuario} from "@/typings/components/usuarios/formulario";
import {Usuario, UsuarioPatch} from "@/typings/store/plugins/easyFirestore/usuarios";
import {Archivo, Archivos, BlobArchivo} from "@/typings/store/plugins/easyFirestore/archivos";

// Componentes
import MessageDialog from "@/components/custom/MessageDialog.vue";
import TablaUsuarios from "@/components/usuarios/TablaUsuarios.vue";
import TituloUsuarios from "@/components/usuarios/TituloUsuarios.vue";
import FiltrosUsuarios from "@/components/usuarios/FiltrosUsuarios.vue";
import FormularioUsuario from "@/components/usuarios/FormularioUsuario.vue";
import ConfirmationDialog from "@/components/custom/ConfirmationDialog.vue";
import DialogoFiltrosUsuarios from "@/components/usuarios/DialogoFiltrosUsuarios.vue";

@Component({
  beforeRouteEnter(_to, _from, next) {
    next(routeGuard);
  },
  components: {
    MessageDialog,
    TablaUsuarios,
    TituloUsuarios,
    FiltrosUsuarios,
    FormularioUsuario,
    ConfirmationDialog,
    DialogoFiltrosUsuarios
  },
  computed: mapGetters({
    getUsuario: "usuario/get",
    getArchivos: "archivos/get",
    getArrayUsuarios: "usuarios/getArray",
    getArrayRegiones: "regiones/getArray",
    getArrayCiudades: "ciudades/getArray",
    getPermisosUsuario: "usuario/getPermisos"
  }),
  methods: mapActions({
    setUsuario: "usuarios/set",
    deleteUsuario: "usuarios/delete",
    setBlobArchivo: "archivos/setBlob"
  }),
  validations: {
    formulario: {
      rut: {required},
      correo: {email}
    }
  }
})
export default class VistaUsuarios extends Vue {
  created(): void {
    if (!routeGuard(this)) return;
  }

  buscar = "";
  cargando = false;
  dialogoFiltros = false;
  mostrarFormulario = false;
  mostrarInformacion = false;
  dialogoConfirmacion = false;
  usuarioSeleccionado: Usuario | null = null;
  dialogoMensaje = {
    mensaje: "",
    model: false
  };
  filtros: FiltrosUsuarioType = {
    tipo: [],
    estado: []
  };
  formulario: DataFormularioUsuario = {
    rut: "",
    nombre: "",
    tipo: null,
    correo: "",
    imagen: null,
    ciudad: null,
    region: null,
    telefono: "",
    profesion: "",
    antecedentes: ""
  };

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get title(): string {
    const deUsuario = this.mobile ? '' : ' de usuario';
    if (this.mostrarFormulario) {
      return this.usuarioSeleccionado ? `Edición${deUsuario}` : `Formulario creación${deUsuario}`;
    }
    if (this.mostrarInformacion) return `Información${deUsuario}`;
    return 'Usuarios';
  }

  get textoBoton(): string {
    if (this.mostrarFormulario) {
      return this.usuarioSeleccionado ? "GUARDAR" : 'CREAR';
    }
    return "CREAR USUARIO";
  }

  get usuarios(): Usuario[] {
    const filtroTipo = this.filtros.tipo.length > 0 ? this.filtros.tipo : null;
    const filtroEstado = this.filtros.estado.length > 0 ? this.filtros.estado : null;
    return this.getArrayUsuarios.filter(_usuario => {
      const flagTipo = filtroTipo ? filtroTipo.includes(_usuario.tipo) : true;
      const flagEstado = filtroEstado ? filtroEstado.some(_estado => _estado === "inactivo" ? !_usuario.uid : _usuario.estado === _estado) : true;
      return flagTipo && flagEstado;
    });
  }

  get regiones(): Region[] {
    return this.getArrayRegiones;
  }

  get ciudades(): Ciudad[] {
    return this.getArrayCiudades.filter(ciudad => ciudad.region.id === this.formulario.region?.id);
  }

  get archivos(): Archivos {
    return this.getArchivos;
  }

  get erroresRut(): string[] {
    const errores: string[] = [];
    if (!this.$v.formulario?.rut?.$dirty) return errores;
    if (!this.$v.formulario?.rut?.required) errores.push('El RUT es requerido');
    if (!this.formulario.rut.validateRUT()) errores.push('El RUT no es válido');
    return errores;
  }

  get erroresCorreo(): string[] {
    const errores: string[] = [];
    if (!this.$v.formulario?.correo?.$dirty) return errores;
    if (!this.$v.formulario?.correo?.email) errores.push('El correo no es válido');
    return errores;
  }

  get buttonDisabled(): boolean {
    const flags = [
      !this.formulario.rut,
      !this.formulario.tipo,
      !this.formulario.nombre,
      !this.formulario.ciudad,
      !this.formulario.region,
      !this.formulario.correo,
      !this.formulario.telefono,
      !this.formulario.profesion
    ];
    if (this.formulario.tipo === "relator") {
      flags.push(!this.formulario.antecedentes);
    }
    return flags.some(flag => flag) && this.mostrarFormulario;
  }

  clickBack(): void {
    this.mostrarFormulario = false;
    this.mostrarInformacion = false;
    this.usuarioSeleccionado = null;
    this.formulario = {
      rut: "",
      nombre: "",
      tipo: null,
      correo: "",
      imagen: null,
      ciudad: null,
      region: null,
      telefono: "",
      profesion: "",
      antecedentes: ""
    };
  }

  limpiarFiltros(): void {
    this.buscar = '';
    this.filtros = {
      tipo: [],
      estado: []
    };
  }

  clickCrear(): void {
    this.limpiarFiltros();
    if (!this.mostrarFormulario) {
      this.mostrarFormulario = true;
    } else {
      this.crearUsuario();
    }
  }

  clickEliminar(usuario: Usuario): void {
    this.dialogoConfirmacion = true;
    this.usuarioSeleccionado = usuario;
  }

  clickEditar(usuario: Usuario): void {
    this.limpiarFiltros();
    this.mostrarFormulario = true;
    this.mostrarInformacion = false;
    this.usuarioSeleccionado = usuario;
  }

  clickInformacion(usuario: Usuario): void {
    this.limpiarFiltros();
    this.mostrarFormulario = false;
    this.mostrarInformacion = true;
    this.usuarioSeleccionado = usuario;
  }

  clickCambiarEstado(usuario: Usuario): void {
    const estado = usuario.estado === 'libre' ? 'bloqueado' : 'libre';
    const estadoMensaje = estado === 'libre' ? 'habilitado' : 'deshabilitado';
    const usuarioPatch: UsuarioPatch = {
      estado,
      id: usuario.id
    };
    this.setUsuario(usuarioPatch);
    this.mostrarMensaje(`¡Usuario ${estadoMensaje} con éxito!`);
  }

  mostrarMensaje(mensaje: string): void {
    this.dialogoMensaje = {
      mensaje,
      model: true
    };
  }

  cerrarDialogoConfirmacion(): void {
    this.usuarioSeleccionado = null;
    this.dialogoConfirmacion = false;
  }

  aplicarFiltros(filtros: FiltrosUsuarioType): void {
    this.filtros = {
      tipo: filtros.tipo.slice(),
      estado: filtros.estado.slice()
    };
  }

  async subirImagen(rut: string): Promise<Archivo | null> {
    if (!this.formulario.imagen) return null;
    const blobArchivo: BlobArchivo = {
      nombre: "perfil",
      file: this.formulario.imagen,
      fecha: new Date().toDateString(),
      ref: `usuarios/${rut}/imagen/perfil`
    };
    const archivoId = await this.setBlobArchivo(blobArchivo);
    return this.archivos[archivoId] ?? null;
  }

  obtenerUsuario(archivo: Archivo | null): Usuario | UsuarioPatch | null {
    if (!this.formulario.tipo) return null;
    if (this.usuarioSeleccionado) {
      const usuario: UsuarioPatch = {
        tipo: this.formulario.tipo,
        nombre: this.formulario.nombre,
        id: this.usuarioSeleccionado.id,
        permisos: this.getPermisosUsuario[this.formulario.tipo],
        perfil: {
          ciudad: this.formulario.ciudad,
          telefono: this.formulario.telefono,
          profesion: this.formulario.profesion,
          antecedentes: this.formulario.antecedentes || null
        }
      };
      if (archivo && usuario.perfil) usuario.perfil.imagen = archivo;
      return usuario;
    }
    return {
      uid: null,
      token: null,
      estado: "libre",
      rut: this.formulario.rut,
      tipo: this.formulario.tipo,
      email: this.formulario.correo,
      nombre: this.formulario.nombre,
      fecha: new Date().toISOString(),
      id: idtify(this.formulario.correo),
      permisos: this.getPermisosUsuario[this.formulario.tipo],
      perfil: {
        imagen: archivo,
        ciudad: this.formulario.ciudad,
        telefono: this.formulario.telefono,
        profesion: this.formulario.profesion,
        antecedentes: this.formulario.antecedentes || null
      }
    };
  }

  async crearUsuario(): Promise<void> {
    this.$v.$touch();
    if (this.$v.$invalid || !this.formulario.rut.validateRUT()) return;
    this.cargando = true;
    const archivo = await this.subirImagen(this.formulario.rut);
    const usuario = this.obtenerUsuario(archivo);
    if (!usuario) return;
    let mensaje = "¡Usuario creado con éxito!";
    if (this.usuarioSeleccionado) {
      mensaje = "¡Cambios guardados con éxito!";
      const id = this.usuarioSeleccionado.id;
      await this.deleteUsuario(`${id}.permisos`);
    }
    await this.setUsuario(usuario);
    this.cargando = false;
    this.mostrarMensaje(mensaje);
    this.clickBack();
  }

  eliminarUsuario(): void {
    if (!this.usuarioSeleccionado) return;
    this.deleteUsuario(this.usuarioSeleccionado.id);
    this.mostrarMensaje("¡Usuario eliminado con éxito!");
    this.usuarioSeleccionado = null;
  }
}
