
// Decoradores
import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class StatusCircle extends Vue {
  @Prop() readonly color!: string;

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get iconSize(): string {
    return this.mobile ? "1.5rem" : "1.25rem";
  }

  get iconSizeSmall(): string {
    return this.mobile ? "0.5rem" : "0.375rem";
  }

  get isRGB(): boolean {
    return this.color.startsWith("rgb");
  }

  get isHex(): boolean {
    return this.color.startsWith("#");
  }

  get colorWithOpacity(): string {
    let rgb;
    if (this.isRGB) {
      rgb = this.color.replace(/[^\d,]/, "");
    } else if (this.isHex) {
      rgb = this.hexToRgb(this.color);
    } else {
      const hex = (this.$vuetify.theme.themes.light[this.color] as string) ?? "#000000";
      rgb = this.hexToRgb(hex);
    }
    return `rgba(${rgb}, 0.4)`;
  }

  hexToRgb(hex: string): string {
    hex = hex.replace("#", "");
    if (hex.length === 3) {
      let [r, g, b] = hex.split("");
      return `${parseInt(r + r, 16)}, ${parseInt(g + g, 16)}, ${parseInt(b + b, 16)}`;
    }
    const rgbHex = hex.match(/.{1,2}/g);
    return rgbHex?.map(h => parseInt(h, 16)).join(", ") || "0, 0, 0";
  }
}
