
// Decoradores
import {Component, Prop, Vue} from "vue-property-decorator";

// Google charts
import {GChart} from "vue-google-charts/legacy";
import {GoogleChartOptions} from "vue-google-charts/dist/types";

// Tipos
import {Curso} from "@/typings/store/plugins/easyFirestore/cursos";

@Component({
  components: {
    GChart
  }
})
export default class GraficoEstadoCursos extends Vue {
  @Prop() readonly cursos!: Curso[];

  get pendientes(): number {
    return this.cursos.filter(_curso => _curso.estado === "pendiente").length;
  }

  get finalizados(): number {
    return this.cursos.filter(_curso => _curso.estado === "finalizado").length;
  }

  get publicados(): number {
    return this.cursos.filter(_curso => _curso.estado === "publicado").length;
  }

  get chartData(): (string | number)[][] {
    if (!this.pendientes && !this.finalizados && !this.publicados) return [];
    return [
      ["Tipo", "Cantidad"],
      ["Pendientes", this.pendientes],
      ["Finalizados", this.finalizados],
      ["Publicados", this.publicados]
    ];
  }

  get chartOptions(): GoogleChartOptions {
    return {
      colors: ["#4DB6AC", "#279B18", "#9747FF"],
      hAxis: {
        textStyle: {
          color: '#707070',
          fontName: 'SF UI Display'
        }
      },
      vAxis: {
        textStyle: {
          color: '#707070',
          fontName: 'SF UI Display'
        }
      }
    };
  }
}
