
// Vue
import {computed, defineComponent} from "vue";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "ResetButton",
  components: {
    SvgIcon
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    "click"
  ],
  setup(props, ctx) {
    // Computed

    const containerClass = computed(() => ({
      "reset-button__container": true,
      "reset-button__container--disabled": props.disabled
    }));

    // Emits

    const onClick = () => {
      ctx.emit("click");
    };

    return {
      // Computed
      containerClass,
      // Emits
      onClick
    };
  }
});
