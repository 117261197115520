
// Vue
import {computed, defineComponent, ref} from "vue";

// Router
import router from "@/router";

// Tipos
import {Ruta} from "@/typings/store/modules/rutas";
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import BotonIniciarSesion from "@/components/appCorePagina/appBar/BotonIniciarSesion.vue";

export default defineComponent({
  name: "MenuMovil",
  components: {
    SvgIcon,
    BotonIniciarSesion
  },
  props: {
    rutas: {
      required: true,
      type: Array as () => Ruta[]
    },
    rutasSubmenu: {
      required: true,
      type: Array as () => Ruta[]
    },
    usuario: {
      type: Object as () => Usuario | null
    },
    type: {
      required: true,
      type: String as () => "app" | "page"
    }
  },
  emits: [
    "click:cerrar-sesion",
    "click:iniciar-sesion"
  ],
  setup(props, ctx) {
    // Data

    const top = ref(0);
    const menu = ref(0);
    const menuMovilButton = ref<HTMLDivElement | null>(null);

    // Computed

    const styleContent = computed(() => ({
      'top': `${top.value}px`,
      'width': props.type === 'app' ? 'calc(91.7vw - 2rem)' : 'calc(100vw - 2rem)'
    }));

    const rutasFiltradas = computed(() => props.rutas.filter(ruta => ruta.show));

    const rutasSubmenuFiltradas = computed(() => props.rutasSubmenu.filter(ruta => ruta.show));

    // Methods

    const clickHandler = (path: string) => {
      menu.value = 0;
      const activeRoute = router.currentRoute.name;
      if (activeRoute === path) return;
      router.push({name: path});
    };

    const clickIniciarSesion = () => {
      if (props.usuario) {
        menu.value = 2;
      } else {
        onClickIniciarSesion();
      }
    };

    const onClickOverlay = (ev: MouseEvent) => {
      if (ev.target === ev.currentTarget) menu.value = 0;
    };

    const openMenu = () => {
      if (!menuMovilButton.value) return;
      const boundingClientRect = menuMovilButton.value.getBoundingClientRect();
      top.value = boundingClientRect.y + boundingClientRect.height;
      menu.value = 1;
    };

    // Emits

    const onClickCerrarSesion = () => {
      ctx.emit("click:cerrar-sesion");
    };

    const onClickIniciarSesion = () => {
      ctx.emit("click:iniciar-sesion");
    };

    return {
      // Data
      top,
      menu,
      menuMovilButton,
      // Computed
      styleContent,
      rutasFiltradas,
      rutasSubmenuFiltradas,
      // Methods
      openMenu,
      clickHandler,
      onClickOverlay,
      clickIniciarSesion,
      // Emits
      onClickCerrarSesion,
      onClickIniciarSesion
    };
  }
});
