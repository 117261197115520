
// Vue
import {computed, defineComponent, ref} from "vue";

// Vuetify
import vuetify from "@/plugins/vuetify";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import ViewTitle from "@/components/custom/ViewTitle.vue";
import SearchTextField from "@/components/custom/SearchTextField.vue";
import BotonDescargarPagos from "@/components/pagos/BotonDescargarPagos.vue";

export default defineComponent({
  name: "TituloPagos",
  components: {
    SvgIcon,
    ViewTitle,
    SearchTextField,
    BotonDescargarPagos
  },
  props: {
    value: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    mostrarInformacion: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    "input",
    "click:back",
    "click:pdf",
    "click:excel",
    "click:filtrar"
  ],
  setup(props, ctx) {
    // VModel

    const buscar = computed({
      get() {
        return props.value;
      },
      set(value: string) {
        ctx.emit("input", value);
      }
    });

    // Data

    const buscando = ref(false);

    // Computed

    const mobile = computed(() => vuetify.framework.breakpoint.mdAndDown);

    const mostrarBuscar = computed(() => {
      if (mobile.value) return buscando.value && !props.mostrarInformacion;
      return !props.mostrarInformacion;
    });

    const mostrarActions = computed(() => mobile.value && !props.mostrarInformacion && !buscando.value);

    // Methods

    const clickBack = () => {
      if (buscando.value && !props.mostrarInformacion) {
        buscando.value = false;
      } else {
        onClickBack();
      }
    };

    // Emits

    const onClickBack = () => {
      ctx.emit("click:back");
    };

    const onClickFiltrar = () => {
      ctx.emit("click:filtrar");
    };

    const onClickPDF = () => {
      ctx.emit("click:pdf");
    };

    const onClickExcel = () => {
      ctx.emit("click:excel");
    };

    return {
      // VModel
      buscar,
      // Data
      buscando,
      // Computed
      mobile,
      mostrarBuscar,
      mostrarActions,
      // Methods
      clickBack,
      // Emits
      onClickPDF,
      onClickBack,
      onClickExcel,
      onClickFiltrar
    };
  }
});
