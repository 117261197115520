
// Decoradores
import {Component, Emit, Prop, VModel, Vue} from "vue-property-decorator";

// Tipos
import {TituloCursosClienteData} from "@/typings/components/cursosCliente/tituloCursosCliente";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import ViewTitle from "@/components/custom/ViewTitle.vue";
import SearchTextField from "@/components/custom/SearchTextField.vue";
import OrdenarPorCursosCliente from "@/components/cursosCliente/OrdenarPorCursosCliente.vue";

@Component({
  components: {
    SvgIcon,
    ViewTitle,
    SearchTextField,
    OrdenarPorCursosCliente
  }
})
export default class TituloCursosCliente extends Vue {
  @VModel() model!: TituloCursosClienteData;

  @Prop() readonly titulo!: string;
  @Prop() readonly cantidadCursos!: number;

  buscando = false;

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get disabled(): boolean {
    return this.cantidadCursos === 0 && !this.model.buscar;
  }

  get mostrarBuscar(): boolean {
    if (this.mobile) return this.buscando;
    return true;
  }

  @Emit("click:filtrar")
  onClickFiltrar(): void {
    return;
  }

  clickBack(): void {
    if (this.buscando) {
      this.buscando = false;
    } else {
      this.$goBack();
    }
  }
}
