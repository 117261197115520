//tipos
import {GetterTree} from "vuex";
import {env} from "@/helpers/env";
import {State} from "@/typings/store";
import {StateTestimonios} from "@/typings/store/plugins/easyFirestore/testimonios";

const getters: GetterTree<StateTestimonios, State> = {
  get(state) {
    return state.all;
  },
  getArray(state) {
    return Object.values(state.all);
  }
};

const testimonios = {
  firestorePath: `${env}/v1/testimonios`,
  firestoreRefType: "collection",
  moduleName: "testimonios",
  statePropName: "all",
  namespaced: true,
  serverChange: {
    convertTimestamps: {
      created_at: "%convertTimestamp%", // default
      updated_at: "%convertTimestamp%" // default
    }
  },
  state: {},
  getters,
  mutations: {},
  actions: {}
};

export default testimonios;
