import { RouteConfig } from "vue-router";
import TerminosCondiciones from "@/views/VistaTerminosCondiciones.vue";

const terminosCondiciones: RouteConfig = {
  path: "/terminosCondiciones",
  name: "terminosCondiciones",
  component: TerminosCondiciones
};

export default terminosCondiciones;
