
// Vue
import {computed, defineComponent} from "vue";

// Router
import router from "@/router";

// Tipos
import {Cupo} from "@/typings/store/plugins/easyFirestore/cupos";
import {Curso} from "@/typings/store/plugins/easyFirestore/cursos";
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";

// Componentes
import CardCurso from "@/components/cursos/CardCurso.vue";
import TextButton from "@/components/custom/TextButton.vue";

export default defineComponent({
  name: "CursosDestacados",
  components: {
    CardCurso,
    TextButton
  },
  props: {
    usuario: {
      type: Object as () => Usuario | null
    },
    cupos: {
      required: true,
      type: Array as () => Cupo[]
    },
    cursos: {
      required: true,
      type: Array as () => Curso[]
    }
  },
  setup(props) {
    // Computed

    const cursosFiltrados = computed(() => {
      const now00 = new Date();
      now00.setHours(0, 0, 0, 0);
      return props.cursos.filter(curso => {
        const flagCupos = filtrarPorCupo(curso);
        const flagNoComprado = !cursoComprado(curso);
        const flagEstado = curso.estado === "publicado";
        const flagInicio = new Date(curso.inicio) >= now00;
        return flagCupos && flagInicio && flagNoComprado && flagEstado;
      }).slice(0, 6);
    });
    // Methods

    const clickVerTodos = () => {
      router.push("/cursosDisponibles");
    };

    const cursoComprado = (curso: Curso) => {
      if (!props.usuario) return false;
      return props.cupos.some(_cupo => {
        const flagCurso = _cupo.curso.id === curso.id;
        const flagEstado = _cupo.estado === "comprado";
        const flatUsuario = _cupo.usuario?.id === props.usuario?.id;
        return flagCurso && flagEstado && flatUsuario;
      });
    };

    const filtrarPorCupo = (curso: Curso) => {
      return props.cupos.filter(_cupo => {
        const flagCurso = _cupo.curso.id === curso.id;
        const flagDisponible = _cupo.estado === "libre";
        return flagDisponible && flagCurso;
      }).length > 0;
    };

    return {
      // Computed
      cursosFiltrados,
      // Methods
      clickVerTodos
    };
  }
});
