
// Decoradores
import {Component, Vue} from "vue-property-decorator";

// Vuex
import {mapGetters} from "vuex";

// Helpers
import routeGuard from "@/helpers/routeGuard";

// Tipos
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";

// Componentes
import ViewTitle from "@/components/custom/ViewTitle.vue";
import Footer from "@/components/appCorePagina/Footer.vue";
import CardRelator from "@/components/relatores/CardRelator.vue";
import InformacionRelator from "@/components/relatores/InformacionRelator.vue";

@Component({
  beforeRouteEnter(_to, _from, next) {
    next(routeGuard);
  },
  components: {
    Footer,
    ViewTitle,
    CardRelator,
    InformacionRelator
  },
  computed: mapGetters({
    getUsuario: "usuario/get",
    getRutasUsuario: "usuario/getRutas",
    getArrayUsuarios: "usuarios/getArray"
  })
})
export default class VistaRelatores extends Vue {
  created(): void {
    if (!routeGuard(this)) return;
    window.scrollTo(0, 0);
    const id = this.$route.params.id;
    if (!id) return;
    const relator = this.getArrayUsuarios.find(r => r.id === id);
    if (!relator) return;
    this.seleccionarRelator(relator);
  }

  relatorSeleccionado: Usuario | null = null;

  get arrayRelatores(): Array<Usuario> {
    return this.getArrayUsuarios.filter((_usuario) => {
      const estaActivo = !!_usuario.uid;
      const esRelator = _usuario.tipo === "relator";
      return esRelator && estaActivo;
    }).sort((_relatorA, _relatorB) => {
      return _relatorA.nombre.localeCompare(_relatorB.nombre);
    });
  }

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  calculateClasses(index: number): string {
    const classes: Array<string> = [];
    if (!this.mobile) {
      if (index % 3 === 0) classes.push("pr-6");
      if (index % 3 === 1) classes.push("px-6");
      if (index % 3 === 2) classes.push("pl-6");
      if (index > 2) classes.push("pt-12");
    } else if (index > 0) {
      classes.push("pt-7");
    }
    return classes.join(" ");
  }

  seleccionarRelator(relator: Usuario): void {
    this.relatorSeleccionado = relator;
  }

  goBack(): void {
    if (!this.relatorSeleccionado) {
      this.$goBack();
    } else {
      this.relatorSeleccionado = null;
    }
  }
}
