import { RouteConfig } from "vue-router";
import Perfil from "@/views/VistaPerfil.vue";

const perfil: RouteConfig = {
    path: "/perfil",
    name: "perfil",
    component: Perfil,
};

export default perfil;
