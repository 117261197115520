import { render, staticRenderFns } from "./FormularioUsuario.vue?vue&type=template&id=7120b014&scoped=true&"
import script from "./FormularioUsuario.vue?vue&type=script&lang=ts&"
export * from "./FormularioUsuario.vue?vue&type=script&lang=ts&"
import style0 from "./FormularioUsuario.vue?vue&type=style&index=0&id=7120b014&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7120b014",
  null
  
)

export default component.exports