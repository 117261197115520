
// Decoradores
import {Component, Emit, Prop, Vue} from 'vue-property-decorator';

// Vuex
import {mapActions, mapGetters} from "vuex";

// Tipos
import {Ruta} from "@/typings/store/modules/rutas";
import {ProviderId} from "@/typings/store/modules/usuario";
import {Usuario} from '@/typings/store/plugins/easyFirestore/usuarios';
import {Carrito} from "@/typings/store/plugins/easyFirestore/carritos";
import {Notificacion} from "@/typings/store/plugins/easyFirestore/notificaciones";
import {NotificacionesLeidas, NotificacionLeida} from "@/typings/store/plugins/easyFirestore/notificacionesLeidas";

// Componentes
import SvgIcon from '@/components/custom/SvgIcon.vue';
import IconButton from "@/components/custom/IconButton.vue";
import MenuMovil from "@/components/appCore/appBar/MenuMovil.vue";
import BotonCarrito from "@/components/appCorePagina/appBar/BotonCarrito.vue";
import BotonIniciarSesion from "@/components/appCorePagina/appBar/BotonIniciarSesion.vue";
import MenuNotificaciones from "@/components/appCore/appBar/accionesAppBar/MenuNotificaciones.vue";

@Component({
  components: {
    MenuMovil,
    SvgIcon,
    IconButton,
    BotonCarrito,
    MenuNotificaciones,
    BotonIniciarSesion
  },
  computed: mapGetters({
    getNotificacionesLeidas: "notificacionesLeidas/get"
  }),
  methods: mapActions({
    setNotificacionLeida: "notificacionesLeidas/set"
  })
})
export default class AppBarPagina extends Vue {
  @Prop() readonly editando!: boolean;
  @Prop() readonly usuario!: Usuario | null;
  @Prop() readonly carrito!: Carrito | null;
  @Prop() readonly providerId!: ProviderId | null;
  @Prop({type: Boolean}) readonly alternative!: boolean;
  @Prop() readonly notificacionesPendientes!: Array<Notificacion>;

  get rutas(): Ruta[] {
    const tipoUsuario = this.usuario?.tipo ?? null;
    const rutas = [{
      id: "quienesSomos",
      text: "Quiénes somos",
      to: {
        name: "quienesSomos"
      },
      show: true
    }, {
      id: "cursosDisponibles",
      text: "Cursos",
      to: {
        name: "cursosDisponibles"
      },
      show: true
    }, {
      id: "relatores",
      text: "Relatores",
      to: {
        name: "relatores"
      },
      show: true
    }, {
      id: "misCursos",
      text: "Mis cursos",
      to: {
        name: "misCursos"
      },
      show: tipoUsuario === "cliente"
    }, {
      id: "agenda",
      text: "Calendario",
      to: {
        name: "agenda"
      },
      show: tipoUsuario === "cliente"
    }];
    return rutas.filter(ruta => ruta.show);
  }

  get rutasSubmenu(): Ruta[] {
    return [{
      id: "perfil",
      text: "Perfil",
      to: {
        name: "perfil"
      },
      show: true
    }, {
      id: "cuenta",
      text: "Cuenta",
      to: {
        name: "cuenta"
      },
      show: this.providerId === 'password'
    }, {
      id: "notificaciones",
      text: "Notificaciones",
      to: {
        name: "notificaciones"
      },
      show: true
    }, {
      id: "historial",
      text: "Historial de compras",
      to: {
        name: "historial"
      },
      show: true
    }];
  }

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get activeRoute(): string {
    return this.$route.name ?? '';
  }

  get mostrarCarrito(): boolean {
    if (!this.usuario) return true;
    return this.usuario.tipo === 'cliente';
  }

  get mostrarNotificaciones(): boolean {
    if (!this.usuario) return false;
    return this.usuario.tipo === 'cliente' && !this.mobile;
  }

  get mostrarEditar(): boolean {
    if (!this.usuario) return false;
    return this.usuario.tipo === 'admin' && this.$route.name === "inicio" && !this.mobile;
  }

  get tipoUsuario(): "clientes" | "admins" | "relatores" {
    if (!this.usuario) return "clientes";
    switch (this.usuario.tipo) {
      case "admin":
        return "admins";
      case "relator":
        return "relatores";
      default:
        return "clientes";
    }
  }

  get itemsNotificaciones(): Array<Notificacion> {
    return this.notificacionesPendientes.filter(_notificacion => {
      if (!this.usuario) return false;
      const flagTodos = _notificacion.topico === "todos";
      const flagTipo = _notificacion.topico === this.tipoUsuario;
      const flagNoAutor = _notificacion.autor?.id !== this.usuario.id;
      const flagUsuarios = !!_notificacion.usuarios?.[this.usuario.id];
      return flagNoAutor && (flagTodos || flagTipo || flagUsuarios);
    }).sort((a, b) => b.fecha.toDate().getTime() - a.fecha.toDate().getTime()).slice(0, 5);
  }

  get notificacionesLeidas(): NotificacionesLeidas {
    return this.getNotificacionesLeidas;
  }

  @Emit("click:iniciar-sesion")
  onClickIniciarSesion(): void {
    return;
  }

  @Emit("click:cerrar-sesion")
  onClickCerrarSesion(): void {
    return;
  }

  @Emit("click:editar")
  onClickEditar(): void {
    return;
  }

  isActive(route: string): boolean {
    return this.activeRoute.toLowerCase() === route.toLowerCase();
  }

  irCarrito(): void {
    if (this.usuario) {
      this.$goTo('carrito');
    } else {
      this.onClickIniciarSesion();
    }
  }

  clickNotificacion(notificacion: Notificacion): void {
    this.marcarLeida(notificacion);
    if (notificacion.tipo === "curso-creado" || notificacion.tipo === "curso-publicado" || notificacion.tipo === "curso-comprado") {
      if (!notificacion.curso?.id) return;
      const id = notificacion.curso.id;
      if (this.usuario?.tipo !== "cliente") {
        this.$router.push({
          name: "cursos",
          params: {id}
        });
      } else {
        this.$router.push({
          name: 'informacionCompleta',
          params: {id}
        });
      }
    } else if (notificacion.tipo === "contacto-recibido") {
      this.$goTo("contactos");
    } else {
      this.$goTo("agenda");
    }
  }

  marcarLeida(notificacion: Notificacion): void {
    if (!notificacion.id) return;
    if (this.notificacionesLeidas[notificacion.id]) return;
    const notificacionLeida: NotificacionLeida = {
      id: notificacion.id,
      fecha: new Date().toISOString()
    };
    this.setNotificacionLeida(notificacionLeida);
  }

  marcarTodasLeidas(): void {
    for (const notificacion of this.itemsNotificaciones) {
      this.marcarLeida(notificacion);
    }
  }

  getClassRuta(ruta: Ruta): { [key: string]: boolean } {
    return {
      'button-disabled': this.editando,
      'app-bar-pagina__button-ruta': true,
      'app-bar-pagina__button-ruta--active': this.isActive(ruta.id)
    };
  }
}
