
// Vue
import {defineComponent, ref} from "vue";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import TextButton from "@/components/custom/TextButton.vue";
import PageTextField from "@/components/custom/PageTextField.vue";

export default defineComponent({
  name: "FormularioRecuperarClave",
  components: {
    SvgIcon,
    TextButton,
    PageTextField
  },
  props: {
    error: {
      type: String,
      required: true
    },
    cargando: {
      type: Boolean,
      required: true
    },
    mobile: {
      type: Boolean,
      required: true
    }
  },
  emits: [
    "click:cerrar",
    "click:recuperar"
  ],
  setup(_props, ctx) {
    // Data

    const emailRecuperar = ref("");

    // Methods

    const recuperar = () => {
      onClickRecuperar(emailRecuperar.value);
    };

    // Emits

    const onClickRecuperar = (email: string) => {
      ctx.emit("click:recuperar", email);
    };

    const onClickCerrar = () => {
      ctx.emit("click:cerrar");
    };

    return {
      // Data
      emailRecuperar,
      // Methods
      recuperar,
      // Emits
      onClickCerrar,
      onClickRecuperar
    };
  }
});
