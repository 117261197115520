
// Decoradores
import {Component, Prop, Vue} from "vue-property-decorator";

// Google charts
import {GChart} from "vue-google-charts/legacy";
import {GoogleChartOptions} from "vue-google-charts/dist/types";

// Helpers
import Calculadora from "@/helpers/Calculadora";

@Component({
  components: {
    GChart
  }
})
export default class GraficoComisiones extends Vue {
  @Prop() readonly calculadora!: Calculadora;

  get chartData(): (string | number)[][] {
    const comisionPrepago = this.calculadora.comisionPrepago;
    const comisionCredito = this.calculadora.comisionCredito;
    const comisionDebito = this.calculadora.comisionDebito;
    const comisionRelatores = this.calculadora.comisionRelatores;
    if (!comisionPrepago && !comisionCredito && !comisionDebito && !comisionRelatores) return [];
    return [
      ["Tipo", "Comisión"],
      ["Prepago", comisionPrepago],
      ["Crédito", comisionCredito],
      ["Débito", comisionDebito],
      ["Relatores", comisionRelatores]
    ];
  }

  get chartOptions(): GoogleChartOptions {
    return {
      colors: ["#4DB6AC", "#279B18", "#9747FF", "#FBC02D"],
      hAxis: {
        textStyle: {
          color: '#707070',
          fontName: 'SF UI Display'
        }
      },
      vAxis: {
        textStyle: {
          color: '#707070',
          fontName: 'SF UI Display'
        }
      }
    };
  }
}
