
// Decoradores
import {Component, Vue} from "vue-property-decorator";

// Vuex
import {mapGetters} from "vuex";

// Helpers
import routeGuard from "@/helpers/routeGuard";

// Componentes
import Footer from "@/components/appCorePagina/Footer.vue";
import ViewTitle from "@/components/custom/ViewTitle.vue";

@Component({
  beforeRouteEnter(_to, _from, next) {
    next(routeGuard);
  },
  components: {
    Footer,
    ViewTitle
  },
  computed: mapGetters({
    getUsuario: "usuario/get",
    getRutasUsuario: "usuario/getRutas"
  })
})
export default class QuienesSomos extends Vue {
  created(): void {
    if (!routeGuard(this)) return;
    window.scrollTo(0, 0);
  }
}
