import {Pago} from "@/typings/store/plugins/easyFirestore/pagos";

export const metodoPago = (pago: Pago): string => {
  const siglasMetodoPago = pago.resultado?.payment_type_code;
  if (siglasMetodoPago === "VP") {
    return "prepago";
  }
  if (siglasMetodoPago === "VD") {
    return "débito";
  }
  if (siglasMetodoPago === "VN" || siglasMetodoPago === "VC" || siglasMetodoPago === "SI" || siglasMetodoPago === "S2" || siglasMetodoPago === "NC") {
    return "crédito";
  }
  return "";
};
