import { render, staticRenderFns } from "./InformacionRelator.vue?vue&type=template&id=51fb8d94&scoped=true&"
import script from "./InformacionRelator.vue?vue&type=script&lang=ts&"
export * from "./InformacionRelator.vue?vue&type=script&lang=ts&"
import style0 from "./InformacionRelator.vue?vue&type=style&index=0&id=51fb8d94&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51fb8d94",
  null
  
)

export default component.exports