
// Vue
import {computed, defineComponent} from "vue";

// Vuetify
import vuetify from "@/plugins/vuetify";

// Componentes
import TextButton from "@/components/custom/TextButton.vue";
import ResetButton from "@/components/custom/ResetButton.vue";

export default defineComponent({
  name: "FiltersContainer",
  components: {
    TextButton,
    ResetButton,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "click:reset",
    "click:filter",
  ],
  setup(_props, ctx) {
    // Computed

    const mobile = computed(() => vuetify.framework.breakpoint.mdAndDown);

    // Emits

    const onClickFilter = () => {
      ctx.emit("click:filter");
    };

    const onClickReset = () => {
      ctx.emit("click:reset");
    };

    return {
      // Computed
      mobile,
      // Emits
      onClickReset,
      onClickFilter,
    };
  },
});
