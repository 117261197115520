import { RouteConfig } from "vue-router";
import VistaRelatores from "@/views/VistaRelatores.vue";

const relatores: RouteConfig = {
  path: "/relatores",
  name: "relatores",
  component: VistaRelatores,
};

export default relatores;
