
// Vue
import {defineComponent} from "vue";

// Componentes
import ViewTitle from "@/components/custom/ViewTitle.vue";
import TextButton from "@/components/custom/TextButton.vue";

export default defineComponent({
  name: "TituloAgenda",
  components: {
    ViewTitle,
    TextButton
  },
  props: {
    titulo: {
      type: String,
      required: true
    },
    isNotClient: {
      type: Boolean,
      required: true
    },
    mobile: {
      type: Boolean,
      required: true
    }
  },
  emits: [
    "click:back",
    "click:programar"
  ],
  setup(_props, ctx) {
    // Emits

    const onClickBack = () => {
      ctx.emit("click:back");
    };

    const onClickProgramar = () => {
      ctx.emit("click:programar");
    };

    return {
      // Emits
      onClickBack,
      onClickProgramar
    };
  }
});
