
// Decoradores
import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class PageTableItem extends Vue {
  @Prop({type: Boolean}) readonly disabled!: boolean;
  @Prop({default: "center"}) readonly align!: "left" | "center" | "right";
  @Prop({default: "initial"}) readonly textCase!: "capitalize" | "lowercase" | "uppercase" | "initial";

  get classes(): string {
    return `${this.disabled ? 'tertiary' : 'dark'}--text font-weight-light font-size-lg-20 font-size-16 poppins text-${this.textCase} text-${this.align}`;
  }
}
