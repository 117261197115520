
// Decoradores
import {Component, Emit, Prop, VModel, Vue} from "vue-property-decorator";

// Tipos
import {Region} from "@/typings/store/plugins/easyFirestore/regiones";
import {Ciudad} from "@/typings/store/plugins/easyFirestore/ciudades";
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";
import {DataFormularioUsuario} from "@/typings/components/usuarios/formulario";

// Componentes
import PageSelect from "@/components/custom/PageSelect.vue";
import TextButton from "@/components/custom/TextButton.vue";
import PageTextArea from "@/components/custom/PageTextArea.vue";
import PageTextField from "@/components/custom/PageTextField.vue";
import ImagenUsuario from "@/components/usuarios/formulario/ImagenUsuario.vue";

@Component({
  components: {
    TextButton,
    PageSelect,
    PageTextArea,
    PageTextField,
    ImagenUsuario
  }
})
export default class FormularioUsuario extends Vue {
  @VModel() form!: DataFormularioUsuario;

  @Prop() readonly readonly!: boolean;
  @Prop() readonly cargando!: boolean;
  @Prop() readonly regiones!: Region[];
  @Prop() readonly ciudades!: Ciudad[];
  @Prop() readonly erroresRut!: string[];
  @Prop() readonly erroresCorreo!: string[];
  @Prop() readonly buttonDisabled!: boolean;
  @Prop() readonly usuarioSeleccionado!: Usuario | null;

  created(): void {
    if (this.usuarioSeleccionado) {
      this.nombre = this.usuarioSeleccionado.nombre;
      this.rut = this.usuarioSeleccionado.rut;
      this.tipo = this.usuarioSeleccionado.tipo;
      this.correo = this.usuarioSeleccionado.email;
      this.ciudad = this.usuarioSeleccionado.perfil?.ciudad ?? null;
      this.telefono = this.usuarioSeleccionado.perfil?.telefono ?? "";
      this.profesion = this.usuarioSeleccionado.perfil?.profesion ?? "";
      this.region = this.usuarioSeleccionado.perfil?.ciudad?.region ?? null;
      this.antecedentes = this.usuarioSeleccionado.perfil?.antecedentes ?? "";
    }
  }

  get tiposUsuario(): { value: string; text: string }[] {
    const tipos = [{
      text: "Relator",
      value: "relator"
    }, {
      text: "Administrador",
      value: "admin"
    }];
    if (this.readonly) {
      tipos.push({
        text: "Cliente",
        value: "cliente"
      });
    }
    return tipos;
  }

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get formClass(): { [key: string]: boolean } {
    return {
      "formulario-usuario__form": true,
      "formulario-usuario__form--relator": this.tipo === "relator"
    };
  }

  get file(): File | null {
    return this.form.imagen;
  }

  set file(value: File | null) {
    this.form.imagen = value;
  }

  get nombre(): string {
    return this.form.nombre;
  }

  set nombre(value: string) {
    this.form.nombre = value;
  }

  get rut(): string {
    return this.form.rut;
  }

  set rut(value: string) {
    this.form.rut = value;
  }

  get tipo(): "admin" | "relator" | null {
    return this.form.tipo;
  }

  set tipo(value: "admin" | "relator" | null) {
    this.form.tipo = value;
  }

  get telefono(): string {
    return this.form.telefono;
  }

  set telefono(value: string) {
    this.form.telefono = value;
  }

  get correo(): string {
    return this.form.correo;
  }

  set correo(value: string) {
    this.form.correo = value;
  }

  get profesion(): string {
    return this.form.profesion;
  }

  set profesion(value: string) {
    this.form.profesion = value;
  }

  get region(): Region | null {
    return this.form.region;
  }

  set region(value: Region | null) {
    this.form.region = value;
  }

  get ciudad(): Ciudad | null {
    return this.form.ciudad;
  }

  set ciudad(value: Ciudad | null) {
    this.form.ciudad = value;
  }

  get antecedentes(): string {
    return this.form.antecedentes;
  }

  set antecedentes(value: string) {
    this.form.antecedentes = value;
  }

  @Emit("click:guardar")
  onClickGuardar(): void {
    return;
  }

}
