
// Vue
import {computed, defineComponent, onBeforeUnmount, ref} from "vue";

// Helpers
import {toFormatString} from "@/helpers/date";

// Tipos
import {Sesion} from "@/typings/store/plugins/easyFirestore/sesiones";
import {Inscripcion} from "@/typings/store/plugins/easyFirestore/inscripciones";
import {TipoUsuario, Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";

// Compontes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import EventoCalendario from "@/components/agenda/calendario/EventoCalendario.vue";

export default defineComponent({
  name: "CalendarioAgendaMovil",
  components: {
    EventoCalendario,
    SvgIcon
  },
  props: {
    sesiones: {
      required: true,
      type: Array as () => Sesion[]
    },
    tipoUsuario: {
      required: true,
      type: String as () => TipoUsuario
    },
    inscripciones: {
      required: true,
      type: Array as () => Inscripcion[]
    },
    usuario: {
      required: true,
      type: Object as () => Usuario
    }
  },
  emits: [
    "click:sesion",
    "click:agregar-link"
  ],
  setup(props, ctx) {
    // Data

    const fecha = ref(new Date());
    const fechaActual = ref(new Date());
    const intervalId = ref<number | null>(null);

    // Computed

    const fechaString = computed(() => toFormatString(fecha.value, "DD/MM"));

    const sesionesFiltradas = computed(() => {
      return props.sesiones.filter(sesion => {
        if (!sesion.fecha) return false;
        const fechaSesion = new Date(sesion.fecha);
        const inicio = new Date(fecha.value);
        inicio.setHours(0, 0, 0, 0);
        const fin = new Date(fecha.value);
        fin.setHours(23, 59, 59, 999);
        return fechaSesion >= inicio && fechaSesion <= fin;
      }).sort((a, b) => {
        if (!a.fecha) return 1;
        if (!b.fecha) return -1;
        const fechaA = new Date(a.fecha);
        const fechaB = new Date(b.fecha);
        return fechaA.getTime() - fechaB.getTime();
      });
    });

    const numeroClases = computed(() => {
      return sesionesFiltradas.value.length;
    });

    const numeroAsistentes = computed(() => {
      const cursosIds: string[] = sesionesFiltradas.value.map(_sesion => _sesion.id);
      return props.inscripciones.filter(_inscripcion => {
        const cursos = Object.values(_inscripcion.pago.cursos);
        const flagRelator = props.tipoUsuario === "admin" ? true : cursos.some(_curso => !!_curso.relatores[props.usuario.id]);
        const flagCurso = cursos.some(_curso => cursosIds.includes(_curso.id ?? ""));
        return flagCurso && flagRelator;
      }).length;
    });

    const noData = computed(() => {
      if (props.tipoUsuario !== "cliente") {
        return "No hay clases programadas.\nPara programar una clases haga clic en \"PROGRAMAR CLASE\"";
      }
      return "No hay clases programadas.";
    });

    // Methods

    intervalId.value = window.setInterval(() => {
      fechaActual.value = new Date();
    }, 1000);

    function clickFechaPrevia() {
      const fechaNueva = new Date(fecha.value);
      fechaNueva.setDate(fechaNueva.getDate() - 1);
      fecha.value = fechaNueva;
    }

    function clickFechaSiguiente() {
      const fechaNueva = new Date(fecha.value);
      fechaNueva.setDate(fechaNueva.getDate() + 1);
      fecha.value = fechaNueva;
    }

    // Before Unmount

    onBeforeUnmount(() => {
      window.clearInterval(intervalId.value);
    });

    // Emits

    const onClickSesion = (sesion: Sesion) => {
      if (props.tipoUsuario === "cliente") return;
      ctx.emit("click:sesion", sesion);
    };

    const onClickAgregarLink = (sesion: Sesion) => {
      if (props.tipoUsuario === "cliente") return;
      ctx.emit("click:agregar-link", sesion);
    }

    return {
      // Data
      fecha,
      fechaActual,
      // Computed
      noData,
      fechaString,
      numeroClases,
      numeroAsistentes,
      sesionesFiltradas,
      // Methods
      clickFechaPrevia,
      clickFechaSiguiente,
      // Emits
      onClickSesion,
      onClickAgregarLink
    };
  }
});
