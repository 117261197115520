
// Decoradores
import {Component, Emit, Prop, Vue} from "vue-property-decorator";

// Tipos
import {Carrito} from "@/typings/store/plugins/easyFirestore/carritos";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon
  }
})
export default class BotonPagar extends Vue {
  @Prop() readonly cargando!: boolean;
  @Prop() readonly carrito!: Carrito | null;

  get carritoVacio(): boolean {
    return this.carrito === null || Object.values(this.carrito.cursos).length === 0;
  }

  get buttonClasses(): string {
    let classes = "sf-ui-display font-size-25 font-weight-semi-bold white--text text-initial text-center";
    if (this.carritoVacio) {
      classes += " button-inactive button-disabled";
    } else if (this.cargando) {
      classes += "  button-disabled";
    }
    return classes;
  }


  @Emit("click:pagar")
  onClickPagar(): void {
    return;
  }
}
