import {Vue} from "vue-property-decorator";

const fallbacks: { [key: string]: string } = {
  "admin": "usuarios",
  "cliente": "inicio",
  "relator": "cursos"
};

const rutasSinLogin = ["inicio", "relatores", "quienesSomos", "terminosCondiciones", "cursosDisponibles", "informacionCompleta"];

export default async function routeGuard(vm: Vue): Promise<boolean> {
  const usuario = vm.getUsuario;
  const currentRoute = vm.$route.name ?? "";
  const permisos = usuario?.permisos;
  if (permisos && permisos[currentRoute]) return true;
  const fallback = usuario?.tipo ? fallbacks[usuario.tipo] : null;
  if (fallback) {
    if (currentRoute !== fallback) {
      await vm.$router.replace({name: fallback});
    }
    return false;
  }
  if (!rutasSinLogin.includes(currentRoute)) {
    await vm.$router.replace({name: "inicio"});
  }
  return false;
}
