
// Vue
import Vue, {computed, defineComponent, ref} from "vue";

// Tipos
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";
import {SesionPatch} from "@/typings/store/plugins/easyFirestore/sesiones";

// Componetes
import IconButton from "@/components/custom/IconButton.vue";
import TextButton from "@/components/custom/TextButton.vue";
import ConfirmationDialog from "@/components/custom/ConfirmationDialog.vue";
import FormularioSesion from "@/components/cursos/formulario/FormularioSesion.vue";

export default defineComponent({
  name: "PlanEstudio",
  components: {
    FormularioSesion,
    TextButton,
    IconButton,
    ConfirmationDialog
  },
  props: {
    value: {
      required: true,
      type: Array as () => SesionPatch[]
    },
    readonly: {
      type: Boolean,
      default: false,
      required: true
    },
    relatores: {
      required: true,
      type: Array as () => Usuario[]
    }
  },
  emits: [
    "input"
  ],
  setup(props, ctx) {
    // VModel

    const model = computed<SesionPatch[]>({
      get() {
        return props.value;
      },
      set(value) {
        ctx.emit("input", value);
      }
    });

    // Data

    const dialogoSesion = ref(false);
    const dialogoConfirmacion = ref(false);
    const indexSeleccionado = ref<number | null>(null);
    const sesionSeleccionada = ref<SesionPatch | null>(null);

    // Computed

    const mensajeConfirmacion = computed(() => {
      if (!sesionSeleccionada.value || !dialogoConfirmacion.value) return "";
      const nombre = sesionSeleccionada.value.nombre;
      return `¿Estás seguro que quieres eliminar la clase "${nombre}"?`;
    });

    // Methods

    const cerrarDialogoSesion = () => {
      dialogoSesion.value = false;
      sesionSeleccionada.value = null;
      indexSeleccionado.value = null;
    };

    const guardarSesion = (sesion: SesionPatch) => {
      if (sesion.id) {
        const findIndex = model.value.findIndex(_sesion => _sesion.id === sesion.id);
        Vue.set(model.value, findIndex, sesion);
      } else if (indexSeleccionado.value !== null) {
        Vue.set(model.value, indexSeleccionado.value, sesion);
      } else {
        model.value.push(sesion);
      }
      cerrarDialogoSesion();
    };

    const abrirEditarSesion = (sesion: SesionPatch, index: number) => {
      sesionSeleccionada.value = sesion;
      indexSeleccionado.value = index;
      dialogoSesion.value = true;
    };

    const abrirEliminarSesion = (sesion: SesionPatch, index: number) => {
      indexSeleccionado.value = index;
      sesionSeleccionada.value = sesion;
      dialogoConfirmacion.value = true;
    };

    const cerrarDialogoConfirmacion = () => {
      sesionSeleccionada.value = null;
      indexSeleccionado.value = null;
      dialogoConfirmacion.value = false;
    };

    const eliminarSesion = () => {
      if (sesionSeleccionada.value?.id) {
        const index = model.value.findIndex(_sesion => _sesion.id === sesionSeleccionada.value?.id);
        Vue.delete(model.value.sesiones, index);
      } else if (indexSeleccionado.value !== null) {
        Vue.delete(model.value, indexSeleccionado.value);
      }
      cerrarDialogoConfirmacion();
    };

    return {
      // VModel
      model,
      // Data
      dialogoSesion,
      dialogoConfirmacion,
      indexSeleccionado,
      sesionSeleccionada,
      // Computed
      mensajeConfirmacion,
      // Methods
      guardarSesion,
      eliminarSesion,
      abrirEditarSesion,
      cerrarDialogoSesion,
      abrirEliminarSesion,
      cerrarDialogoConfirmacion
    };
  }
});
