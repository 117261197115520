
// Decoradores
import {Component, Emit, Prop, Vue} from "vue-property-decorator";

// Tipos
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";
import {SesionPatch} from "@/typings/store/plugins/easyFirestore/sesiones";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import TextButton from "@/components/custom/TextButton.vue";
import PageSelect from "@/components/custom/PageSelect.vue";
import PageSwitch from "@/components/custom/PageSwitch.vue";
import DialogTitle from "@/components/custom/DialogTitle.vue";
import PageTextArea from "@/components/custom/PageTextArea.vue";
import PageTextField from "@/components/custom/PageTextField.vue";

@Component({
  components: {
    SvgIcon,
    PageSwitch,
    PageSelect,
    TextButton,
    DialogTitle,
    PageTextArea,
    PageTextField
  }
})
export default class FormularioSesion extends Vue {
  @Prop() readonly relatores!: Usuario[];
  @Prop() readonly sesion!: SesionPatch | null;

  created(): void {
    if (!this.sesion) return;
    this.link = this.sesion.link || "";
    this.nombre = this.sesion.nombre || "";
    this.fecha = this.sesion.fecha || null;
    this.duracion = this.sesion.duracion || 0;
    this.tipo = this.sesion.tipo || "grabada";
    this.relator = this.sesion.relator || null;
    this.contenido = this.sesion.contenido || "";
  }

  link = "";
  nombre = "";
  duracion = 0;
  contenido = "";
  fecha: string | null = null;
  relator: Usuario | null = null;
  tipo: "grabada" | "en-vivo" = "en-vivo";

  get switchValue(): boolean {
    return this.tipo === "en-vivo";
  }

  set switchValue(value: boolean) {
    this.tipo = value ? "en-vivo" : "grabada";
  }

  get buttonDisabled(): boolean {
    const flagNoNombre = !this.nombre;
    const flagNoRelator = !this.relator;
    const flagNoDuracion = !this.duracion;
    const flagNoContenido = !this.contenido;
    const flagNoLink = !this.switchValue && !this.link;
    return flagNoNombre || flagNoDuracion || flagNoContenido || flagNoRelator || flagNoLink;
  }

  @Emit("click:cerrar")
  onClickCerrar(): void {
    return;
  }

  @Emit("click:crear")
  onClickCrear(): SesionPatch {
    const sesionPatch: SesionPatch = {
      resubida: false,
      tipo: this.tipo,
      link: this.link,
      fecha: this.fecha,
      nombre: this.nombre,
      relator: this.relator,
      duracion: this.duracion,
      contenido: this.contenido
    };
    if (this.sesion?.id) sesionPatch.id = this.sesion.id;
    if (this.sesion?.curso) sesionPatch.curso = this.sesion.curso;
    if (this.sesion?.numero) sesionPatch.numero = this.sesion.numero;
    return sesionPatch;
  }

}
