
// Decoradores
import {Component, Prop, Vue} from "vue-property-decorator";

// Tipos
import {Curso} from "@/typings/store/plugins/easyFirestore/cursos";

@Component
export default class EstadoCursos extends Vue {
  @Prop() readonly cursos!: Curso[];

  get pendientes(): number {
    return this.cursos.filter(_curso => _curso.estado === "pendiente").length;
  }

  get finalizados(): number {
    return this.cursos.filter(_curso => _curso.estado === "finalizado").length;
  }

  get publicados(): number {
    return this.cursos.filter(_curso => _curso.estado === "publicado").length;
  }

  get items(): { text: string; value: number }[] {
    return [{
      text: "Publicados",
      value: this.publicados
    }, {
      text: "Pendientes",
      value: this.pendientes
    }, {
      text: "Finalizados",
      value: this.finalizados
    }];
  }
}
