
// Decoradores
import {Component, Emit, Prop, VModel, Vue} from "vue-property-decorator";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import ViewTitle from "@/components/custom/ViewTitle.vue";
import TextButton from "@/components/custom/TextButton.vue";
import SearchTextField from "@/components/custom/SearchTextField.vue";

@Component({
  components: {
    SvgIcon,
    ViewTitle,
    TextButton,
    SearchTextField
  }
})
export default class TituloUsuarios extends Vue {
  @VModel() buscar!: string;

  @Prop() readonly titulo!: string;
  @Prop() readonly cargando!: boolean;
  @Prop() readonly textoBoton!: string;
  @Prop() readonly buttonDisabled!: boolean;
  @Prop() readonly mostrarFormulario!: boolean;
  @Prop() readonly mostrarInformacion!: boolean;

  buscando = false;

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get mostrarBuscar(): boolean {
    const formularioInformacion = !this.mostrarFormulario && !this.mostrarInformacion;
    if (this.mobile) return this.buscando && formularioInformacion;
    return formularioInformacion;
  }

  get hideBack(): boolean {
    return !this.buscando && !this.mostrarFormulario && !this.mostrarInformacion;
  }

  get mostrarBotonMovil(): boolean {
    return this.mobile && !this.buscando && !this.mostrarFormulario && !this.mostrarInformacion;
  }

  get mostrarBotonCrear(): boolean {
    return !this.mostrarInformacion && !this.mobile;
  }

  get mostrarBotonCrearMovil(): boolean {
    return !this.mostrarFormulario && !this.mostrarInformacion && this.mobile;
  }

  @Emit("click:filtrar")
  onClickFiltrar(): void {
    return;
  }

  @Emit("click:back")
  onClickBack(): void {
    return;
  }

  @Emit("click:crear")
  onClickCrear(): void {
    this.buscando = false;
  }

  clickBack(): void {
    if (this.buscando) {
      this.buscando = false;
    } else {
      this.onClickBack();
    }
  }
}
