
// Decoradores
import {Component, Emit, Prop, VModel, Vue} from "vue-property-decorator";

// Tipos
import {DataTableHeader} from "vuetify";
import {Pago} from "@/typings/store/plugins/easyFirestore/pagos";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon
  }
})
export default class DialogoDetalleHistorial extends Vue {
  @VModel() model!: boolean;
  @Prop() readonly pago!: Pago;

  metodos = {
    VD: "Débito",
    VN: "Crédito",
    VC: "Crédito",
    SI: "Crédito",
    S2: "Crédito",
    NC: "Crédito",
    VP: "Prepago"
  };

  get transactionDate(): Date | null {
    return this.pago?.resultado?.transaction_date?.toDate() ?? null;
  }

  get fecha(): string {
    return this.transactionDate?.format("DD/MM/YYYY") ?? "";
  }

  get hora(): string {
    return this.transactionDate?.format("HH:mm:ss") ?? "";
  }

  get cursos(): string {
    const array = Object.values(this.pago.cursos);
    return array.map(curso => curso.nombre).join(", ");
  }

  get metodoPago(): string {
    return this.metodos[this.pago.resultado?.payment_type_code ?? "VD"] ?? "";
  }

  get total(): string {
    return this.pago.monto?.toCurrency("$") ?? "";
  }

  get items(): DataTableHeader[] {
    return [{
      text: "Fecha",
      value: this.fecha
    }, {
      text: "Hora",
      value: this.hora
    }, {
      text: "Cursos",
      value: this.cursos
    }, {
      text: "Método de pago",
      value: this.metodoPago
    }, {
      text: "Total",
      value: this.total
    }];
  }

  @Emit("click:cerrar")
  onClickCerrar(): void {
    this.model = false;
  }
}
