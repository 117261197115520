
// Vue
import {computed, defineComponent} from "vue";

// Helpers
import {metodoPago} from "@/helpers/pagos";
import {toCurrency} from "@/helpers/number";

// Vuetify
import vuetify from "@/plugins/vuetify";

// Tipos
import {Pago} from "@/typings/store/plugins/easyFirestore/pagos";

export default defineComponent({
  name: "InfoDetallePago",
  props: {
    pagoSeleccionado: {
      required: true,
      type: Object as () => Pago,
    },
  },
  setup(props) {
    // Computed

    const id = computed(() => props.pagoSeleccionado.id);

    const rut = computed(() => props.pagoSeleccionado.cliente.rut);

    const nombre = computed(() => props.pagoSeleccionado.cliente.nombre);

    const mobile = computed(() => vuetify.framework.breakpoint.mdAndDown);

    const monto = computed(() => toCurrency(props.pagoSeleccionado.monto));

    const hora = computed(() => new Date(props.pagoSeleccionado.fecha).format("HH:mm"));

    const metodoDePago = computed(() => metodoPago(props.pagoSeleccionado).capitalizeFirstLetter());

    const fecha = computed(() => new Date(props.pagoSeleccionado.fecha).format("DD/MM/YYYY"));

    return {
      // Computed
      id,
      rut,
      hora,
      monto,
      fecha,
      mobile,
      nombre,
      metodoDePago,
    };
  },
});
