
// Vue
import {computed, defineComponent, ref} from "vue";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import TextButton from "@/components/custom/TextButton.vue";
import PageTextField from "@/components/custom/PageTextField.vue";

export default defineComponent({
  name: "FormularioActivarCuenta",
  components: {
    SvgIcon,
    TextButton,
    PageTextField
  },
  props: {
    email: {
      type: String,
      required: true
    },
    error: {
      type: String,
      required: true
    },
    cargando: {
      type: Boolean,
      required: true
    },
    mobile: {
      type: Boolean,
      required: true
    }
  },
  emits: [
    "click:cerrar",
    "click:activar"
  ],
  setup(_props, ctx) {
    // Data

    const password = ref("");
    const confirmar = ref("");
    const showingPassword = ref(false);
    const showingPasswordConfirmar = ref(false);

    // Computed

    const buttonDisabled = computed(() => !password.value || !confirmar.value);

    const inputTypePassword = computed(() => showingPassword.value ? "text" : "password");

    const inputTypePasswordConfirmar = computed(() => showingPasswordConfirmar.value ? "text" : "password");

    const appendIconPassword = computed(() => showingPassword.value ? "far-eye-slash" : "far-eye");

    const appendIconPasswordConfirmar = computed(() => showingPasswordConfirmar.value ? "far-eye-slash" : "far-eye");

    // Methods

    const clickShowPassword = () => {
      showingPassword.value = !showingPassword.value;
    };

    const clickShowPasswordConfirmar = () => {
      showingPasswordConfirmar.value = !showingPasswordConfirmar.value;
    };

    const activar = () => {
      onActivar({
        password: password.value,
        confirmar: confirmar.value
      });
    };

    // Emits

    const onClickCerrar = () => {
      ctx.emit("click:cerrar");
    };

    const onActivar = (data: { password: string; confirmar: string }) => {
      ctx.emit("click:activar", data);
    };

    return {
      // Data
      password,
      confirmar,
      showingPassword,
      showingPasswordConfirmar,
      // Computed
      buttonDisabled,
      inputTypePassword,
      appendIconPassword,
      inputTypePasswordConfirmar,
      appendIconPasswordConfirmar,
      // Methods
      activar,
      clickShowPassword,
      clickShowPasswordConfirmar,
      // Emits
      onActivar,
      onClickCerrar
    };
  }
});
