
// Vue
import {computed, defineComponent, ref} from "vue";

// Vuex
import store from "@/store";
import {mapGetters} from "vuex";

// Router
import router from "@/router";

// Tipos
import {Pago} from "@/typings/store/plugins/easyFirestore/pagos";
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import ViewTitle from "@/components/custom/ViewTitle.vue";
import Footer from "@/components/appCorePagina/Footer.vue";
import ResultadoExito from "@/components/resultadosCompra/ResultadoExito.vue";
import ResultadoError from "@/components/resultadosCompra/ResultadoError.vue";

export default defineComponent({
  name: "VistaResultadoCompra",
  components: {
    Footer,
    SvgIcon,
    ViewTitle,
    ResultadoExito,
    ResultadoError,
  },
  setup() {
    // Vuex

    const getters = mapGetters({
      getUsuario: "usuario/get",
      getArrayPagos: "pagos/getArray",
    });

    const actions = {
      fetchAndAddPagos: "pagos/fetchAndAdd",
      fetchAndAddInscripciones: "inscripciones/fetchAndAdd",
    };

    const arrayPagos = computed<Pago[]>(getters.getArrayPagos);
    const usuario = computed<Usuario | null>(getters.getUsuario);

    // Data

    const pago = ref<Pago | null>(null);

    (async () => {
      const id = router.currentRoute.params.id;
      await store.dispatch(actions.fetchAndAddPagos);
      await store.dispatch(actions.fetchAndAddInscripciones);
      const pagoResultado = arrayPagos.value.find(_pago => _pago.id === id && _pago.cliente.id === usuario.value?.id);
      if (!pagoResultado) await router.push({name: "inicio"});
      pago.value = pagoResultado ?? null;
    })();

    // Computed

    const fallido = computed(() => {
      if (!pago.value?.resultado?.status) return true;
      const status = pago.value?.resultado.status;
      return status === "FAILED" || status === "NULLIFIED" || status === "PARTIALLY_NULLIFIED";
    });

    return {
      // Data
      pago,
      // Computed
      fallido,
    };

  },
});
