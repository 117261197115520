
// Vue
import {computed, defineComponent} from "vue";

// Vuetify
import vuetify from "@/plugins/vuetify";

export default defineComponent({
  name: "PageSwitch",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    activeColor: {
      type: String
    },
    color: {
      type: String,
      default: "#ffffff"
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    "input"
  ],
  setup(props, ctx) {
    // VModel

    const model = computed({
      get() {
        return props.value;
      },
      set(value) {
        if (!props.readonly) ctx.emit("input", value);
      }
    });

    // Computed

    const theme = computed(() => vuetify.framework.theme.currentTheme);

    const innerColor = computed(() => {
      const vuetifyColor = theme.value[props.color] as string | null;
      const color = vuetifyColor ?? props.color;
      if (props.activeColor) {
        const vuetifyActiveColor = theme.value[props.activeColor] as string | null;
        const activeColor = vuetifyActiveColor ?? props.activeColor;
        return model.value ? activeColor : color;
      }
      return color;
    });

    return {
      // VModel
      model,
      // Computed
      innerColor
    };
  }
});
