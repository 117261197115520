
// Decoradores
import {Component, Vue, Watch} from "vue-property-decorator";

// Helpers
import routeGuard from "@/helpers/routeGuard";

// Vuex
import {mapActions, mapGetters} from "vuex";

// Tipos
import {Curso} from "@/typings/store/plugins/easyFirestore/cursos";
import {Inscripcion} from "@/typings/store/plugins/easyFirestore/inscripciones";
import {Notificacion} from "@/typings/store/plugins/easyFirestore/notificaciones";
import {Sesion, SesionPatch} from "@/typings/store/plugins/easyFirestore/sesiones";
import {TipoUsuario, Usuario, Usuarios} from "@/typings/store/plugins/easyFirestore/usuarios";

// Componentes
import ViewTitle from "@/components/custom/ViewTitle.vue";
import Footer from "@/components/appCorePagina/Footer.vue";
import TextButton from "@/components/custom/TextButton.vue";
import TituloAgenda from "@/components/agenda/TituloAgenda.vue";
import MessageDialog from "@/components/custom/MessageDialog.vue";
import CalendarioAgenda from "@/components/agenda/CalendarioAgenda.vue";
import FormularioAgenda from "@/components/agenda/FormularioAgenda.vue";
import FormularioAgregarLink from "@/components/agenda/FormularioAgregarLink.vue";
import CalendarioAgendaMovil from "@/components/agenda/CalendarioAgendaMovil.vue";

@Component({
  computed: mapGetters({
    getUsuario: "usuario/get",
    getUsuarios: "usuarios/get",
    getArrayCursos: "cursos/getArray",
    getRutasUsuario: "usuario/getRutas",
    getArraySesiones: "sesiones/getArray",
    getArrayInscripciones: "inscripciones/getArray",
  }),
  beforeRouteEnter(_to, _from, next) {
    next(routeGuard);
  },
  components: {
    Footer,
    ViewTitle,
    TextButton,
    TituloAgenda,
    MessageDialog,
    FormularioAgenda,
    CalendarioAgenda,
    CalendarioAgendaMovil,
    FormularioAgregarLink,
  },
  methods: mapActions({
    setSesion: "sesiones/set",
    setNotificacion: "notificaciones/set",
  }),
})
export default class VistaAgenda extends Vue {
  created(): void {
    if (!routeGuard(this)) return;
  }

  dialogoProgramar = false;
  dialogoAgregarLink = false;
  fechaSeleccionada: string | null = null;
  sesionSeleccionada: Sesion | null = null;
  dialogoMensaje = {
    model: false,
    mensaje: "",
  };

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get usuario(): Usuario | null {
    return this.getUsuario;
  }

  get usuarios(): Usuarios {
    return this.getUsuarios;
  }

  get tipoUsuario(): TipoUsuario | null {
    return this.usuario?.tipo ?? null;
  }

  get isNotClient(): boolean {
    return this.tipoUsuario !== "cliente";
  }

  get titulo(): string {
    if (this.tipoUsuario === "cliente") return "Calendario";
    return "Agenda";
  }

  get cursos(): Curso[] {
    return this.getArrayCursos.filter(_curso => {
      const relatoresCurso = Object.values(_curso.relatores);
      const cursoTieneRelator = this.tipoUsuario === "relator" ? relatoresCurso.some(_relator => _relator.id === this.usuario?.id) : true;
      return _curso.estado !== "finalizado" && cursoTieneRelator;
    });
  }

  get inscripciones(): Inscripcion[] {
    return this.getArrayInscripciones;
  }

  get cursosId(): string[] {
    const cursos: string[] = [];
    if (this.isNotClient) {
      for (const curso of this.cursos) {
        if (curso.id) cursos.push(curso.id);
      }
    } else {
      for (const inscripcion of this.inscripciones) {
        if (inscripcion.cliente.id !== this.usuario?.id) continue;
        cursos.push(...Object.keys(inscripcion.pago.cursos));
      }
    }
    return cursos;
  }

  get sesiones(): Sesion[] {
    return this.getArraySesiones;
  }

  get sesionesCalendario(): Sesion[] {
    return this.sesiones.filter(sesion => {
      if (!sesion.fecha) return false;
      const flagTipo = sesion.tipo === "en-vivo";
      const flagCurso = sesion.curso.id ? this.cursosId.includes(sesion.curso.id) : false;
      const flagRelator = this.tipoUsuario === "relator" ? sesion.relator?.id === this.usuario?.id : true;
      return flagTipo && flagCurso && flagRelator;
    });
  }

  get containerClass(): { [key: string]: boolean } {
    return {
      'vista__container': true,
      'vista__container--cliente': this.tipoUsuario === "cliente",
    };
  }

  @Watch("dialogoProgramar")
  onDialogoProgramarChange(val: boolean): void {
    if (!val) {
      this.sesionSeleccionada = null;
      this.fechaSeleccionada = null;
    }
  }

  onClickDate(fecha: string): void {
    this.dialogoProgramar = true;
    this.fechaSeleccionada = fecha;
  }

  mostrarMensaje(mensaje: string): void {
    this.dialogoMensaje = {
      mensaje,
      model: true,
    };
  }

  clientesSesion(sesion: Sesion): Usuarios {
    if (!sesion.curso.id) return {};
    const usuarios: Usuarios = {};
    for (const inscripcion of this.inscripciones) {
      if (inscripcion.pago.cursos[sesion.curso.id]) {
        const usuario = this.getUsuarios[inscripcion.cliente.id];
        if (usuario) this.$set(usuarios, usuario.id, usuario);
      }
    }
    return usuarios;
  }

  notificar(sesion: Sesion): void {
    if (!sesion.fecha) return;
    const usuarios = this.clientesSesion(sesion);
    if (Object.keys(usuarios).length === 0) return;
    const fecha = sesion.fecha.toDate();
    const hora = fecha.format("HH:mm");
    const dia = fecha.format("DD/MM/YYYY");
    const nombre = sesion.nombre.capitalizeFirstLetter();
    const notificacion: Notificacion = {
      usuarios,
      curso: null,
      topico: null,
      enviada: false,
      autor: this.usuario,
      tipo: "clase-reprogramada",
      titulo: "¡Clase reprogramada!",
      fecha: new Date().toISOString(),
      contenido: `${nombre} se impartirá el día ${dia} a las ${hora}.`,
    };
    this.setNotificacion(notificacion);
  }

  guardarSesion(sesion: SesionPatch): void {
    this.setSesion(sesion);
    this.dialogoProgramar = false;
    const mensaje = this.sesionSeleccionada ? '¡Cambios guardados con éxito!' : '¡Clase programada con éxito!';
    if (this.sesionSeleccionada) {
      this.notificar(this.sesionSeleccionada);
    }
    this.sesionSeleccionada = null;
    this.mostrarMensaje(mensaje);
  }

  guardarLink(sesion: SesionPatch): void {
    this.setSesion(sesion);
    this.cerrarDialogoAgregarLink();
    this.mostrarMensaje("¡Link guardado con éxito!");
  }

  abrirEditarSesion(sesion: Sesion): void {
    if (this.tipoUsuario === "cliente") return;
    this.dialogoProgramar = true;
    this.sesionSeleccionada = sesion;
  }

  abrirAgregarLink(sesion: Sesion): void {
    if (this.tipoUsuario === "cliente") return;
    this.dialogoAgregarLink = true;
    this.sesionSeleccionada = sesion;
  }

  cerrarDialogoAgregarLink(): void {
    this.sesionSeleccionada = null;
    this.dialogoAgregarLink = false;
  }
}
