
// Decoradores
import {Component, Emit, Prop, VModel, Vue} from "vue-property-decorator";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import ViewTitle from "@/components/custom/ViewTitle.vue";
import TextButton from "@/components/custom/TextButton.vue";
import SearchTextField from "@/components/custom/SearchTextField.vue";

@Component({
  components: {
    SvgIcon,
    ViewTitle,
    TextButton,
    SearchTextField,
  },
})
export default class TituloPromociones extends Vue {
  @VModel() buscar!: string;

  @Prop() readonly titulo!: string;
  @Prop() readonly disabled!: boolean;
  @Prop() readonly cargando!: boolean;
  @Prop() readonly textoBoton!: string;
  @Prop() readonly buttonDisabled!: boolean;
  @Prop() readonly mostrarFormulario!: boolean;
  @Prop() readonly mostrarInformacion!: boolean;


  buscando = false;

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get hideBack(): boolean {
    return !this.mostrarFormulario && !this.mostrarInformacion && !this.buscando;
  }

  get mostrarBuscar(): boolean {
    if (this.mobile) return this.buscando;
    return !this.mostrarFormulario && !this.mostrarInformacion;
  }

  get mostrarBoton(): boolean {
    return !this.mostrarInformacion && !this.mobile;
  }

  get mostrarBotonMovil(): boolean {
    return !this.mostrarInformacion && !this.mostrarFormulario && this.mobile;
  }

  get mostrarIconoMovil(): boolean {
    return this.mobile && !this.buscando && !this.mostrarFormulario && !this.mostrarInformacion;
  }

  @Emit("click:agregar")
  onClickAgregar(): void {
    return;
  }

  @Emit("click:back")
  onClickBack(): void {
    return;
  }

  @Emit("click:filtrar")
  onClickFiltrar(): void {
    return;
  }

  clickBack(): void {
    if (this.buscando) {
      this.buscando = false;
    } else {
      this.onClickBack();
    }
  }
}
