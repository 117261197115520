
// Decoradores
import {Component, Emit, Prop, Vue} from "vue-property-decorator";

// Tipos
import {Sesion, SesionPatch} from "@/typings/store/plugins/easyFirestore/sesiones";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import TextButton from "@/components/custom/TextButton.vue";
import DialogTitle from "@/components/custom/DialogTitle.vue";
import PageTextField from "@/components/custom/PageTextField.vue";

@Component({
  components: {
    SvgIcon,
    TextButton,
    DialogTitle,
    PageTextField,
  },
})
export default class FormularioAgregarLink extends Vue {
  @Prop() readonly sesion!: Sesion;

  created(): void {
    if (this.sesion.resubida) {
      this.link = this.sesion.link;
    }
  }

  link = "";
  validando = false;

  get titulo(): string {
    if (this.sesion.resubida) return "Editar link";
    return "Agregar link";
  }

  get buttonDisabled(): boolean {
    return !this.link;
  }

  get erroresLink(): string[] {
    const errores: string[] = [];
    if (!this.validando) return errores;
    if (!this.validarLink(this.link)) errores.push("Link inválido");
    return errores;
  }

  @Emit("click:cerrar")
  onClickCerrar(): void {
    return;
  }

  @Emit("click:guardar")
  onClickGuardar(sesion: SesionPatch): SesionPatch {
    return sesion;
  }

  validarLink(link: string): boolean {
    return link.startsWith("https://drive.google.com/file/d/");
  }

  guardar(): void {
    this.validando = true;
    if (!this.link || !this.validarLink(this.link)) return;
    const sesionPatch: SesionPatch = {
      id: this.sesion.id,
      link: this.link,
      resubida: true,
    };
    this.onClickGuardar(sesionPatch);
  }
}
