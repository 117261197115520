
// Vue
import {defineComponent} from "vue";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "AvatarAccionesAppBar",
  components: {
    SvgIcon,
  },
  props: {
    imagen: {
      type: String,
      default: null,
    },
  },
});
