
// Decoradores
import {Component, Emit, Prop, VModel, Vue} from "vue-property-decorator";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {
    SvgIcon
  }
})
export default class PageTextArea extends Vue {
  @VModel() model!: string;

  @Prop() readonly rows!: number;
  @Prop() readonly label!: string;
  @Prop() readonly placeholder!: string;
  @Prop() readonly errorMessages!: Array<string>;
  @Prop({default: "text"}) readonly type!: string;
  @Prop({type: Boolean}) readonly readonly!: boolean;
  @Prop({type: Boolean}) readonly autoGrow!: boolean;

  get textfieldClass(): string {
    let classes = "page-textarea__textarea font-size-14";
    if (this.readonly) {
      classes += " readonly";
    }
    return classes;
  }

  @Emit("click:append")
  onClickAppend(): void {
    return;
  }
}
