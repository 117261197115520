
// Vue
import {computed, defineComponent} from "vue";

// Vuetify
import vuetify from "@/plugins/vuetify";

// Types
import {Notificacion} from "@/typings/store/plugins/easyFirestore/notificaciones";
import {NotificacionesLeidas} from "@/typings/store/plugins/easyFirestore/notificacionesLeidas";

// Componentes
import TextButton from "@/components/custom/TextButton.vue";

export default defineComponent({
  name: "FiltrosNotificaciones",
  components: {
    TextButton,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    notificacionesLeidas: {
      required: true,
      type: Object as () => NotificacionesLeidas,
    },
    notificaciones: {
      required: true,
      type: Array as () => Notificacion[],
    },
  },
  emits: [
    "input",
    "click:marcar-todas-leidas",
  ],
  setup(props, ctx) {
    // VModel

    const model = computed({
      get() {
        return props.value;
      },
      set(value) {
        ctx.emit("input", value);
      },
    });

    // Computed

    const mobile = computed(() => vuetify.framework.breakpoint.mdAndDown);

    const cantidadNoLeidas = computed(() => {
      return props.notificaciones.filter(_notificacion => {
        if (!_notificacion.id) return true;
        return !props.notificacionesLeidas[_notificacion.id];
      }).length;
    });

    // Emits

    const onClickMarcarTodasLeidas = () => {
      ctx.emit('click:marcar-todas-leidas');
    };

    return {
      // VModel
      model,
      // Computed
      mobile,
      cantidadNoLeidas,
      // Emits
      onClickMarcarTodasLeidas,
    };
  },
});
