
// Vue
import {defineComponent, reactive, toRef} from "vue";

// Router
import router from "@/router";

// componentes
import {Ruta} from "@/typings/store/modules/rutas";

export default defineComponent({
  name: "NavegacionAppBar",
  props: {
    rutas: {
      required: true,
      type: Array as () => Ruta[],
    },
  },
  setup() {
    // Data

    const location = toRef(reactive(router), "currentRoute");

    // Methods

    const getClasses = (ruta: Ruta) => {
      return ({
        'navegacion__route': true,
        'navegacion__route--active': location.value.name === ruta.to?.name,
      });
    };

    return {
      // Methods
      getClasses,
    };
  },
});
