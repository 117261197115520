import { RouteConfig } from "vue-router";
import Contactos from "@/views/VistaContactos.vue";

const contactos: RouteConfig = {
    path: "/contactos",
    name: "contactos",
    component: Contactos,
};

export default contactos;
