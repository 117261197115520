
// Decoradores
import {Component, Emit, Prop, Vue} from "vue-property-decorator";

// Tipos
import {DataTableHeader} from "vuetify";
import {Pago} from "@/typings/store/plugins/easyFirestore/pagos";
import {ItemTablaHistorial} from "@/typings/components/historial/tablaHistorial";

// Componentes
import PageTableItem from "@/components/custom/PageTableItem.vue";
import IconButton from "@/components/custom/IconButton.vue";

@Component({
  components: {IconButton, PageTableItem}
})
export default class TablaHistorial extends Vue {
  @Prop() readonly pagos!: Pago[];

  metodos = {
    VD: "Débito",
    VN: "Crédito",
    VC: "Crédito",
    SI: "Crédito",
    S2: "Crédito",
    NC: "Crédito",
    VP: "Prepago"
  };

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get headers(): DataTableHeader[] {
    const headers: DataTableHeader[] = [{
      text: "Fecha",
      value: "fecha"
    }];
    if (!this.mobile) {
      headers.push({
        text: "Hora",
        value: "hora"
      });
    }
    headers.push({
      text: "Cursos",
      value: "cursos"
    });
    if (!this.mobile) {
      headers.push(...[{
        text: "Met. pago",
        value: "metodoPago"
      }, {
        text: "Total",
        value: "total"
      }]);
    } else {
      headers.push({
        text: "",
        sortable: false,
        value: "opciones"
      });
    }
    return headers;
  }

  get items(): ItemTablaHistorial[] {
    return this.pagos.map(pago => {
      const id = pago.id ?? "";
      const date = pago.resultado?.transaction_date?.toDate() ?? new Date();
      const cursos = this.getCursosPago(pago);
      const hora = date.format("HH:mm");
      const fecha = date.format("DD/MM/YYYY");
      const total = pago.monto?.toCurrency("$") ?? "";
      const metodoPago = this.metodos[pago.resultado?.payment_type_code ?? "VD"] ?? "";
      return {
        id,
        hora,
        fecha,
        total,
        cursos,
        metodoPago,
        data: pago
      };
    });
  }

  @Emit("click:informacion")
  onClickInformacion({data}: ItemTablaHistorial): Pago {
    return data;
  }

  getCursosPago(pago: Pago): string {
    const array = Object.values(pago.cursos);
    return array.map(curso => curso.nombre).join(", ");
  }
}
