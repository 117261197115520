// vuex
import {Store} from "vuex";

// firebase
import {Firebase, initFirebase} from "@/config/firebase";

// tipos
import {State} from "@/typings/store";
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";
import {Carrito} from "@/typings/store/plugins/easyFirestore/carritos";

// helpers
import {idtify} from "@/helpers/string";

// imports
import abrirCanalesBD from "./abrirCanalesBD";
import cerrarCanalesBD from "./cerrarCanalesBD";

const conexionBDSinUsuario = async (store: Store<State>) => {
  const promises: Promise<void>[] = [
    store.dispatch("cupos/openDBChannel"),
    store.dispatch("cursos/openDBChannel"),
    store.dispatch("pagina/openDBChannel"),
    store.dispatch("usuarios/openDBChannel"),
    store.dispatch("sesiones/openDBChannel"),
    store.dispatch("regiones/openDBChannel"),
    store.dispatch("ciudades/openDBChannel"),
    store.dispatch("contactos/openDBChannel"),
    store.dispatch("promociones/openDBChannel"),
    store.dispatch("testimonios/openDBChannel"),
    store.dispatch("categoriasCurso/openDBChannel"),
  ];
  await Promise.all(promises);
};

const validarUser = async (store: Store<State>, user: Firebase.User): Promise<void> => {
  await store.dispatch("usuarios/fetchById", user.uid).catch((error) => {
    console.error(error);
  });
  const usuarioUID = store.getters["usuarios/get"][user.uid] as | Usuario | undefined;
  let id = "";
  if (user.email) {
    id = idtify(user.email);
    await store.dispatch("usuarios/fetchById", id).catch((error) => {
      console.error(error);
    });
  }
  const usuarioEmail = store.getters["usuarios/get"][id] as Usuario | undefined;
  let usuario: Usuario | null = null;
  if (usuarioEmail) {
    usuario = Object.assign({}, usuarioUID, usuarioEmail);
    usuario.id = user.uid;
    usuario.uid = user.uid;
    await store.dispatch("usuarios/set", usuario);
    await store.dispatch("usuarios/delete", id);
  } else if (!usuarioUID) {
    const clienteUsuario: Usuario = {
      id: user.uid,
      uid: user.uid,
      rut: "",
      nombre: user.providerData[0]?.displayName ?? "",
      email: user.providerData[0]?.email ?? "",
      permisos: {
        perfil: true,
        agenda: true,
        inicio: true,
        cuenta: true,
        carrito: true,
        misCursos: true,
        relatores: true,
        historial: true,
        reproductor: true,
        quienesSomos: true,
        notificaciones: true,
        resultadoCompra: true,
        cursosDisponibles: true,
        informacionCompleta: true,
        terminosCondiciones: true
      },
      perfil: {
        imagen: user.providerData[0]?.photoURL ? {
          id: user.uid,
          url: user.providerData[0].photoURL,
          nombre: user.providerData[0].displayName ?? "",
          fecha: new Date().toISOString(),
          ref: "from-provider"
        } : null,
        profesion: "",
        telefono: user.providerData[0]?.phoneNumber ?? "",
        ciudad: null,
        antecedentes: null
      },
      fecha: new Date().toISOString(),
      tipo: "cliente",
      estado: "libre",
      token: null
    };
    await store.dispatch("usuarios/set", clienteUsuario);
  }
};
const cargarCarritoUsuario = async (store: Store<State>, usuario: Usuario) => {
  if (!usuario.uid) return;
  try {
    await store.dispatch("carritos/fetchById", usuario.uid);
  } catch (error) {
    console.error(error);
  }
  let carrito = store.getters["carritos/get"][usuario.uid] as | Carrito | undefined;
  if (!carrito && usuario?.tipo === "cliente") {
    carrito = {
      id: usuario.uid,
      usuario: usuario,
      estado: "revisado",
      cursos: {},
      promocion: null
    };
    await store.dispatch("carritos/set", carrito);
  }
};

const usuarioBloquedo = (usuario: Usuario) => {
  return usuario?.estado === "bloqueado";
};

const noTienePermisos = (usuario: Usuario) => {
  return !usuario?.permisos || Object.values(usuario.permisos).length === 0;
};

const iniciarFirebase = (store: Store<State>): void => {
  initFirebase().then(() => {
    conexionBDSinUsuario(store);
    Firebase.auth().onAuthStateChanged(async (user) => {
      store.commit("setErrorLogin", null);
      store.commit("cargando/set", true);
      if (user) {
        await validarUser(store, user);
        const usuario = store.getters["usuarios/get"][user.uid] ?? null;
        if (noTienePermisos(usuario)) {
          Firebase.auth().signOut();
          store.commit("cargando/set", false);
          return;
        }
        if (usuarioBloquedo(usuario)) {
          store.commit("setErrorLogin", "auth/user-disabled");
          Firebase.auth().signOut();
          return;
        }
        await store.dispatch("usuario/set", usuario);
        await cargarCarritoUsuario(store, usuario);
        const providerId = user.providerData[0]?.providerId ?? null;
        store.commit("usuario/setProviderId", providerId);
        await abrirCanalesBD(store, usuario);
      } else {
        const usuario = store.getters["usuario/get"];
        if (usuario) {
          await cerrarCanalesBD(store, usuario);
        }
        store.commit("usuario/set", null);
      }
      setTimeout(() => {
        store.commit("cargando/set", false);
      }, 1000);
    });
  });
};

export default iniciarFirebase;
