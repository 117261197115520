
// Vue
import {computed, defineComponent, ref, watch} from "vue";

// Vuex
import Store from "@/store";
import {mapGetters} from "vuex";

// Helpers
import routeGuard from "@/helpers/routeGuard";

// Tipos
import {Pago} from "@/typings/store/plugins/easyFirestore/pagos";
import {Curso} from "@/typings/store/plugins/easyFirestore/cursos";
import {Ciudad} from "@/typings/store/plugins/easyFirestore/ciudades";
import {Region} from "@/typings/store/plugins/easyFirestore/regiones";
import {FormularioPerfilType} from "@/typings/components/perfil/formularioPerfil";
import {Archivo, Archivos, BlobArchivo} from "@/typings/store/plugins/easyFirestore/archivos";
import {Usuario, UsuarioPatch} from "@/typings/store/plugins/easyFirestore/usuarios";

// Componentes
import Footer from "@/components/appCorePagina/Footer.vue";
import TituloPerfil from "@/components/perfil/TituloPerfil.vue";
import MessageDialog from "@/components/custom/MessageDialog.vue";
import FormularioPerfil from "@/components/perfil/FormularioPerfil.vue";
import TablaPagosPerfil from "@/components/perfil/TablaPagosPerfil.vue";
import TablaCursosPerfil from "@/components/perfil/TablaCursosPerfil.vue";

export default defineComponent({
  name: "VistaPerfil",
  components: {
    Footer,
    TituloPerfil,
    MessageDialog,
    FormularioPerfil,
    TablaPagosPerfil,
    TablaCursosPerfil,
  },
  computed: mapGetters({
    getUsuario: "usuario/get",
  }),
  beforeRouteEnter(_to, _from, next) {
    next(routeGuard);
  },
  created() {
    if (!routeGuard(this)) return;
  },
  setup() {
    // Vuex

    const getters = mapGetters({
      getUsuario: "usuario/get",
      getArchivos: "archivos/get",
      getArrayPagos: "pagos/getArray",
      getArrayCursos: "cursos/getArray",
      getArrayRegiones: "regiones/getArray",
      getArrayCiudades: "ciudades/getArray",
    });

    const actions = {
      setUsuario: "usuarios/set",
      setArchivo: "archivos/set",
      setBlobArchivo: "archivos/setBlob",
    };

    const archivos = computed<Archivos>(getters.getArchivos);
    const arrayPagos = computed<Pago[]>(getters.getArrayPagos);
    const usuario = computed<Usuario | null>(getters.getUsuario);
    const arrayCursos = computed<Curso[]>(getters.getArrayCursos);
    const arrayRegiones = computed<Region[]>(getters.getArrayRegiones);
    const arrayCiudades = computed<Ciudad[]>(getters.getArrayCiudades);

    // Data

    const editando = ref(false);
    const cargando = ref(false);
    const dialogoMensaje = ref(false);
    const formulario = ref<FormularioPerfilType>({
      file: null,
      rut: usuario.value?.rut ?? "",
      nombre: usuario.value?.nombre ?? "",
      ciudad: usuario.value?.perfil?.ciudad ?? null,
      telefono: usuario.value?.perfil?.telefono ?? "",
      profesion: usuario.value?.perfil?.profesion ?? "",
      region: usuario.value?.perfil?.ciudad?.region ?? null,
      antecedentes: usuario.value?.perfil?.antecedentes ?? "",
    });

    // Computed

    const regionSeleccionada = computed(() => formulario.value.region);

    const tipoUsuario = computed(() => usuario.value?.tipo ?? null);

    const ciudades = computed(() => arrayCiudades.value.filter(ciudad => ciudad.region.id === formulario.value.region?.id));

    const containerClass = computed(() => ({
      'vista__container': true,
      'vista__container--cliente': tipoUsuario.value === "cliente",
    }));

    const buttonDisabled = computed(() => {
      const flags = [
        !formulario.value.rut,
        !formulario.value.nombre,
        !formulario.value.region,
        !formulario.value.ciudad,
        !formulario.value.telefono,
      ];
      if (tipoUsuario.value !== "cliente") {
        flags.push(!formulario.value.profesion);
      }
      if (tipoUsuario.value === "relator") {
        flags.push(!formulario.value.antecedentes);
      }
      return flags.some(flag => flag) && editando.value;
    });

    // Watch

    watch(regionSeleccionada, (_newValue: Region | null, oldValue: Region | null) => {
      if (oldValue) formulario.value.ciudad = null;
    });

    // Methods

    const onClickEditar = () => {
      if (editando.value) {
        editarUsuario();
      } else {
        editando.value = true;
      }
    };

    const subirImagen = async (userId: string) => {
      if (!formulario.value.file) return null;
      const blobArchivo: BlobArchivo = {
        nombre: "perfil",
        file: formulario.value.file,
        fecha: new Date().toDateString(),
        ref: `usuarios/${userId}/imagen/perfil`,
      };
      const archivoId = await Store.dispatch(actions.setBlobArchivo, blobArchivo);
      return archivos.value[archivoId] ?? null;
    };

    const obtenerUsuario = (id: string, archivo: Archivo | null) => {
      const usuarioPatch: UsuarioPatch = {
        id: id,
        rut: formulario.value.rut,
        nombre: formulario.value.nombre,
        perfil: {
          ciudad: formulario.value.ciudad,
          telefono: formulario.value.telefono,
        },
      };
      if (usuarioPatch.perfil) {
        if (archivo) usuarioPatch.perfil.imagen = archivo;
        if (formulario.value.profesion) usuarioPatch.perfil.profesion = formulario.value.profesion;
        if (formulario.value.antecedentes) usuarioPatch.perfil.antecedentes = formulario.value.antecedentes;
      }
      return usuarioPatch;
    };

    const editarUsuario = async () => {
      if (!usuario.value || !formulario.value.rut.validateRUT()) return;
      cargando.value = true;
      const userId = usuario.value.id;
      const archivo = await subirImagen(userId);
      const usuarioPatch = obtenerUsuario(userId, archivo);
      await Store.dispatch(actions.setUsuario, usuarioPatch);
      editando.value = false;
      cargando.value = false;
      dialogoMensaje.value = true;
      formulario.value.file = null;
    };

    return {
      // Vuex
      usuario,
      archivos,
      arrayPagos,
      arrayCursos,
      arrayRegiones,
      arrayCiudades,
      // Data
      editando,
      cargando,
      formulario,
      dialogoMensaje,
      // Computed
      ciudades,
      tipoUsuario,
      containerClass,
      buttonDisabled,
      // Methods
      subirImagen,
      onClickEditar,
      editarUsuario,
    };
  },
});
