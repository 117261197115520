
// Decoradores
import {Component, Vue, Watch} from "vue-property-decorator";

// Helpers
import "@/helpers/extensions";

// Vuex
import {mapActions, mapGetters} from "vuex";

// Tipos
import {Ruta} from "./typings/store/modules/rutas";
import {ProviderId} from "@/typings/store/modules/usuario";
import {Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";
import {Carrito} from "@/typings/store/plugins/easyFirestore/carritos";
import {Notificacion} from "./typings/store/plugins/easyFirestore/notificaciones";

// Componentes
import AppBar from "@/components/appCore/AppBar.vue";
import SvgIcon from "@/components/custom/SvgIcon.vue";
import VistaCargando from "@/views/VistaCargando.vue";
import AppBarPagina from "@/components/appCorePagina/AppBarPagina.vue";
import MessageDialog from "@/components/custom/MessageDialog.vue";

@Component({
  computed: mapGetters({
    getUsuario: "usuario/get",
    getCargando: "cargando/get",
    getErrorLogin: "getErrorLogin",
    getRutasArray: "rutas/getArray",
    getCarrito: "usuario/getCarrito",
    getRutasUsuario: "usuario/getRutas",
    getProviderId: "usuario/getProviderId",
    getNoLeidasNotificaciones: "notificaciones/getNoLeidos"
  }),
  methods: mapActions({
    setCargando: "cargando/set",
    signOutUsuario: "usuario/signOut"
  }),
  components: {
    MessageDialog,
    AppBar,
    SvgIcon,
    AppBarPagina,
    VistaCargando
  }
})
export default class App extends Vue {
  created(): void {
    if (!this.$route.name) {
      this.$router.replace({name: "inicio"});
    }
    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    }
  }

  editando = false;
  dialogoIniciarSesion = false;
  dialogoMensaje = {
    model: false,
    mensaje: "Cambios guardados con éxito"
  };

  get providerId(): ProviderId | null {
    return this.getProviderId;
  }

  get cargando(): boolean {
    return this.getCargando;
  }

  get carrito(): Carrito | null {
    return this.getCarrito;
  }

  get usuario(): Usuario | null {
    return this.getUsuario;
  }

  get rutasUsuario(): Array<Ruta> {
    return this.getRutasUsuario;
  }

  get mostrarAppBar(): boolean {
    const rutasNoMostrar = ["login", "inicio", "relatores", "quienesSomos", "terminosCondiciones", "cuenta", "cursosDisponibles", "reproductor", "informacionCompleta"];
    const currentRoute = this.$route.name ?? "";
    const noCargando = !this.cargando;
    const usuarioValido = this.usuario !== null && this.usuario.tipo !== "cliente";
    const rutaValida = !rutasNoMostrar.includes(currentRoute);
    return noCargando && usuarioValido && rutaValida;
  }

  get mostrarAppBarPagina(): boolean {
    const noLogin = this.$route.name !== "iniciarSesion";
    const tipoUsuario = this.usuario?.tipo;
    const rutaReproductor = this.$route.name === "reproductor";
    const tipoUsuarioValido = tipoUsuario === "cliente" || tipoUsuario === "admin";
    return noLogin && !rutaReproductor && !this.cargando && (this.usuario === null || tipoUsuarioValido);
  }

  get notificacionesPendientes(): Array<Notificacion> {
    return this.getNoLeidasNotificaciones;
  }

  @Watch("usuario")
  onUsuarioChange(val: Usuario | null): void {
    if (val?.tipo === "admin") {
      this.$goTo("usuarios");
    }
  }

  @Watch("getErrorLogin")
  onErrorLoginChange(error: string | null): void {
    if (error) {
      this.dialogoIniciarSesion = true;
    }
  }

  onClickEditar(): void {
    this.editando = !this.editando;
    if (!this.editando) {
      this.dialogoMensaje.model = true;
    }
  }

  clickIniciarSesion(): void {
    this.$router.push({name: "iniciarSesion"});
  }

  signOut(): void {
    this.signOutUsuario();
  }
}
