import { render, staticRenderFns } from "./Controles.vue?vue&type=template&id=cb963f68&scoped=true&"
import script from "./Controles.vue?vue&type=script&lang=ts&"
export * from "./Controles.vue?vue&type=script&lang=ts&"
import style0 from "./Controles.vue?vue&type=style&index=0&id=cb963f68&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb963f68",
  null
  
)

export default component.exports