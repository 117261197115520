
// Vue
import {computed, defineComponent} from "vue";

// Vuetify
import vuetify from "@/plugins/vuetify";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

export default defineComponent({
  name: "MenuButton",
  components: {
    SvgIcon
  },
  props: {
    color: {
      type: String,
      default: "accent"
    },
    activeColor: {
      type: String,
      default: "secondary"
    }
  },
  setup() {
    // Computed

    const mobile = computed(() => vuetify.framework.breakpoint.mdAndDown);

    return {
      // Computed
      mobile
    };
  }
});
