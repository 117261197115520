
// Decoradores
import {Component, Vue} from "vue-property-decorator";

// Helpers
import routeGuard from "@/helpers/routeGuard";
// Vuex
import {mapActions, mapGetters} from "vuex";

// Tipos
import {Cupo} from "@/typings/store/plugins/easyFirestore/cupos";
import {DataFiltrosCursos} from "@/typings/components/cursos/filtros";
import {DataFormularioCurso} from "@/typings/components/cursos/formulario";
import {Curso, CursoPatch} from "@/typings/store/plugins/easyFirestore/cursos";
import {Inscripcion} from "@/typings/store/plugins/easyFirestore/inscripciones";
import {Notificacion} from "@/typings/store/plugins/easyFirestore/notificaciones";
import {Sesion, SesionPatch} from "@/typings/store/plugins/easyFirestore/sesiones";
import {CategoriaCurso} from "@/typings/store/plugins/easyFirestore/categoriasCurso";
import {TipoUsuario, Usuario, Usuarios} from "@/typings/store/plugins/easyFirestore/usuarios";
import {Archivo, Archivos, BlobArchivo} from "@/typings/store/plugins/easyFirestore/archivos";

// Componentes
import NoData from "@/components/custom/NoData.vue";
import ViewTitle from "@/components/custom/ViewTitle.vue";
import TablaCursos from "@/components/cursos/TablaCursos.vue";
import TituloCursos from "@/components/cursos/TituloCursos.vue";
import FiltrosCursos from "@/components/cursos/FiltrosCursos.vue";
import MessageDialog from "@/components/custom/MessageDialog.vue";
import FormularioCurso from "@/components/cursos/FormularioCurso.vue";
import ConfirmationDialog from "@/components/custom/ConfirmationDialog.vue";
import DialogoFiltrosCursos from "@/components/cursos/DialogoFiltrosCursos.vue";

@Component({
  components: {
    NoData,
    ViewTitle,
    TablaCursos,
    TituloCursos,
    MessageDialog,
    FiltrosCursos,
    FormularioCurso,
    ConfirmationDialog,
    DialogoFiltrosCursos,
  },
  beforeRouteEnter(_to, _from, next) {
    next(routeGuard);
  },
  computed: mapGetters({
    getUsuario: "usuario/get",
    getArchivos: "archivos/get",
    getArrayCupos: "cupos/getArray",
    getArrayCursos: "cursos/getArray",
    getArrayUsuarios: "usuarios/getArray",
    getArraySesiones: "sesiones/getArray",
    getArrayInscripciones: "inscripciones/getArray",
    getArrayCategoriasCurso: "categoriasCurso/getArray",
  }),
  methods: mapActions({
    setCupo: "cupos/set",
    setCurso: "cursos/set",
    setSesion: "sesiones/set",
    setArchivo: "archivos/set",
    deleteCupo: "cupos/delete",
    deleteCurso: "cursos/delete",
    deleteSesion: "sesiones/delete",
    deleteArchivo: "archivos/delete",
    setBlobArchivo: "archivos/setBlob",
    setNotificacion: "notificaciones/set",
    setCategoriaCurso: "categoriasCurso/set",
  }),
})
export default class VistaCursos extends Vue {
  created(): void {
    if (!routeGuard(this)) return;
    if (this.$route.params.id) {
      const curso = this.getArrayCursos.find((_course) => _course.id === this.$route.params.id);
      if (curso) {
        this.abrirInformacion(curso);
      }
    }
  }

  buscar = "";
  cargando = false;
  dialogoFiltros = false;
  cargandoPublicar = false;
  mostrarFormulario = false;
  mostrarInformacion = false;
  dialogoConfirmacion = false;
  cursoSeleccionado: Curso | null = null;
  dialogoMensaje = {
    model: false,
    mensaje: "",
  };
  filtros: DataFiltrosCursos = {
    estado: [],
    relator: [],
    categoria: [],
  };
  formulario: DataFormularioCurso = {
    fin: "",
    cupos: 0,
    precio: 0,
    nombre: "",
    inicio: "",
    comision: 0,
    resumen: "",
    copia: false,
    imagen: null,
    sesiones: [],
    descuento: 0,
    relatores: {},
    categoria: null,
    descripcion: "",
    certificado: true,
  };

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get mostrarPublicar(): boolean {
    if (this.cargandoPublicar) return true;
    const isAdmin = this.getUsuario?.tipo === "admin";
    const flagPendiente = this.cursoSeleccionado?.estado === "pendiente" || !this.cursoSeleccionado;
    const flagForm = this.mostrarInformacion || this.mostrarFormulario;
    return isAdmin && flagPendiente && flagForm && !this.cargando;
  }

  get title(): string {
    const deCurso = this.mobile ? '' : ' de curso';
    if (this.mostrarFormulario) {
      if (this.formulario.copia) return `Duplicando curso`;
      return this.cursoSeleccionado ? `Ficha de edición${deCurso}` : `Ficha de inscripción${deCurso}`;
    }
    if (this.mostrarInformacion) return `Ficha de información${deCurso}`;
    return 'Cursos';
  }

  get textoBoton(): string {
    if (this.mostrarFormulario) {
      if (this.formulario.copia) return `DUPLICAR`;
      return this.cursoSeleccionado ? "GUARDAR" : 'CREAR';
    }
    return "CREAR CURSO";
  }

  get textoBotonPublicar(): string {
    if (this.cursoSeleccionado?.estado === "pendiente" && this.mostrarInformacion) return "PUBLICAR";
    return `${this.textoBoton} Y PUBLICAR`;
  }

  get inscripciones(): Inscripcion[] {
    return this.getArrayInscripciones;
  }

  get usuario(): Usuario | null {
    return this.getUsuario;
  }

  get tipoUsuario(): TipoUsuario | null {
    return this.usuario?.tipo ?? null;
  }

  get archivos(): Archivos {
    return this.getArchivos;
  }

  get cursos(): Curso[] {
    const filtroEstado = this.filtros.estado;
    const filtroRelator = this.filtros.relator;
    const filtroCategoria = this.filtros.categoria;
    return this.getArrayCursos.filter(_curso => {
      const flagEstado = filtroEstado.length === 0 || filtroEstado.includes(_curso.estado);
      const flagRelatorFiltro = filtroRelator.length === 0 || filtroRelator.some(relator => !!_curso.relatores[relator]);
      const flagRelator = this.usuario && this.tipoUsuario === "relator" ? !!_curso.relatores[this.usuario.id] : true;
      const flagCategoria = filtroCategoria.length === 0 || filtroCategoria.some(categoria => _curso.categoria.id === categoria);
      return flagEstado && flagRelatorFiltro && flagCategoria && flagRelator;
    });
  }

  get sinCursos(): boolean {
    const flagCursos = this.cursos.length === 0;
    const flagFiltroEstado = this.filtros.estado.length === 0;
    const flagFiltroRelator = this.filtros.relator.length === 0;
    const flagFiltroCategoria = this.filtros.categoria.length === 0;
    return flagCursos && flagFiltroEstado && flagFiltroRelator && flagFiltroCategoria;
  }

  get sesiones(): Sesion[] {
    return this.getArraySesiones;
  }

  get categoriasCurso(): CategoriaCurso[] {
    return this.getArrayCategoriasCurso.slice().sort((a, b) => a.nombre.localeCompare(b.nombre));
  }

  get relatores(): Usuario[] {
    return this.getArrayUsuarios.filter(_usuario => _usuario.tipo === 'relator' && _usuario.uid).sort((a, b) => a.nombre.localeCompare(b.nombre));
  }

  get cupos(): Cupo[] {
    return this.getArrayCupos;
  }

  get buttonDisabled(): boolean {
    const cupos = this.cupos.filter(_cupo => _cupo.curso.id === this.cursoSeleccionado?.id).length;
    const flags = [
      !this.formulario.fin,
      !this.formulario.cupos,
      !this.formulario.nombre,
      !this.formulario.inicio,
      !this.formulario.resumen,
      !this.formulario.categoria,
      !this.formulario.descripcion,
      this.formulario.cupos < cupos,
      this.formulario.sesiones.length === 0,
      Object.values(this.formulario.relatores).length === 0,
    ];
    if (this.tipoUsuario === "admin") {
      flags.push(!this.formulario.precio);
      flags.push(!this.formulario.comision);
    }
    return flags.some(flag => flag) && (this.mostrarFormulario || this.mostrarInformacion);
  }

  mostrarMensaje(mensaje: string): void {
    this.dialogoMensaje = {
      mensaje,
      model: true,
    };
  }

  limpiarFiltros(): void {
    this.buscar = "";
    this.filtros = {
      estado: [],
      relator: [],
      categoria: [],
    };
  }

  limpiar(): void {
    this.cursoSeleccionado = null;
    this.mostrarFormulario = false;
    this.mostrarInformacion = false;
    this.formulario = {
      fin: "",
      cupos: 0,
      precio: 0,
      nombre: "",
      inicio: "",
      comision: 0,
      resumen: "",
      imagen: null,
      copia: false,
      sesiones: [],
      descuento: 0,
      relatores: {},
      categoria: null,
      descripcion: "",
      certificado: true,
    };
  }

  clickCrear(): void {
    this.limpiarFiltros();
    if (!this.mostrarFormulario) {
      this.mostrarFormulario = true;
    } else {
      this.crearCurso();
    }
  }

  clickPublicar(): void {
    this.crearCurso(true);
  }

  abrirInformacion(curso: Curso): void {
    this.limpiarFiltros();
    this.mostrarFormulario = false;
    this.mostrarInformacion = true;
    this.cursoSeleccionado = curso;
  }

  abrirEditar(curso: Curso): void {
    this.limpiarFiltros();
    this.mostrarFormulario = true;
    this.cursoSeleccionado = curso;
    this.mostrarInformacion = false;
  }

  abrirEliminar(curso: Curso): void {
    this.cursoSeleccionado = curso;
    this.dialogoConfirmacion = true;
  }

  cerrarDialogoConfirmacion(): void {
    this.cursoSeleccionado = null;
    this.dialogoConfirmacion = false;
  }

  aplicarFiltros(filtros: DataFiltrosCursos): void {
    this.filtros = {
      estado: filtros.estado.slice(),
      relator: filtros.relator.slice(),
      categoria: filtros.categoria.slice(),
    };
  }

  copiarSesiones(curso: Curso): SesionPatch[] {
    const sesionesCurso = this.sesiones.filter(_sesion => _sesion.curso.id === curso.id);
    const sesionesCopia: SesionPatch[] = [];
    for (const sesionCurso of sesionesCurso) {
      sesionesCopia.push({
        fecha: null,
        resubida: false,
        tipo: sesionCurso.tipo,
        link: "",
        nombre: sesionCurso.nombre,
        numero: sesionCurso.numero,
        relator: sesionCurso.relator,
        duracion: sesionCurso.duracion,
        contenido: sesionCurso.contenido,
      });
    }
    return sesionesCopia;
  }

  copiarRelatores(curso: Curso): Usuarios {
    const relatoresCurso = Object.values(curso.relatores);
    const relatoresCopia: Usuarios = {};
    for (const relatorCurso of relatoresCurso) {
      const relatorReal = this.relatores.find(_relator => _relator.id === relatorCurso.id);
      if (relatorReal) {
        this.$set(relatoresCopia, relatorReal.id, JSON.parse(JSON.stringify(relatorReal)));
      }
    }
    return relatoresCopia;
  }

  copiarImagen(curso: Curso): Archivo | null {
    if (!curso.imagen) return null;
    return {
      ref: curso.imagen.ref || "",
      url: curso.imagen.url || "",
      fecha: curso.imagen.fecha || "",
      nombre: curso.imagen.nombre || "",
    };
  }

  abrirDuplicar(curso: Curso): void {
    this.limpiarFiltros();
    this.formulario = {
      fin: "",
      inicio: "",
      copia: true,
      imagen: null,
      cupos: curso.cupos,
      nombre: curso.nombre,
      resumen: curso.resumen,
      comision: curso.comision,
      categoria: curso.categoria,
      certificado: curso.certificado,
      descripcion: curso.descripcion,
      archivo: this.copiarImagen(curso),
      sesiones: this.copiarSesiones(curso),
      relatores: this.copiarRelatores(curso),
      precio: curso.precioRef ?? curso.precio,
      descuento: this.$calculatePercentage(curso),
    };
    this.mostrarFormulario = true;
  }

  async subirImagen(id: string): Promise<Archivo | null> {
    let archivoId;
    if (this.formulario.imagen) {
      const blobArchivo: BlobArchivo = {
        nombre: "curso",
        file: this.formulario.imagen,
        fecha: new Date().toDateString(),
        ref: `cursos/${id}/imagen/curso`,
      };
      archivoId = await this.setBlobArchivo(blobArchivo);
    } else if (this.formulario.archivo && this.formulario.copia) {
      archivoId = await this.setArchivo(this.formulario.archivo);
    }
    if (!archivoId) return null;
    return this.archivos[archivoId] ?? null;
  }

  calcularPrecio(): number {
    if (!this.formulario.descuento) return this.formulario.precio;
    const descuento = Math.floor((this.formulario.precio * this.formulario.descuento) / 100);
    return this.formulario.precio - descuento;
  }

  obtenerCurso(archivo: Archivo | null, publicar?: boolean): CursoPatch | null {
    if (!this.formulario.categoria) return null;
    const curso: CursoPatch = {
      cupos: this.formulario.cupos,
      precio: this.calcularPrecio(),
      nombre: this.formulario.nombre,
      resumen: this.formulario.resumen,
      comision: this.formulario.comision,
      categoria: this.formulario.categoria,
      relatores: this.formulario.relatores,
      descripcion: this.formulario.descripcion,
      certificado: this.formulario.certificado,
      precioRef: this.formulario.descuento ? this.formulario.precio : null,
      fin:this.cursoSeleccionado ? this.formulario.fin : new Date(`${this.formulario.fin}T23:59:59`).toISOString(),
      inicio:this.cursoSeleccionado ? this.formulario.inicio : new Date(`${this.formulario.inicio}T00:00:00`).toISOString()
    };
    if (this.cursoSeleccionado) {
      curso.id = this.cursoSeleccionado.id;
      if (archivo) curso.imagen = archivo;
      if (publicar) curso.estado = "publicado";
    } else {
      curso.imagen = archivo;
      curso.estado = publicar ? "publicado" : "pendiente";
    }
    return curso;
  }

  async guardarSesiones(id: string): Promise<void> {
    const curso = this.getArrayCursos.find(_curso => _curso.id === id);
    const promises: Promise<void | string>[] = [];
    if (!curso) return;
    const sesionesExistentes = this.sesiones.filter(_sesion => _sesion.curso.id === id);
    for (const sesionExistente of sesionesExistentes) {
      const sesion = this.formulario.sesiones.some(_sesion => _sesion.id === sesionExistente.id);
      if (!sesion && sesionExistente.id) {
        promises.push(this.deleteSesion(sesionExistente.id));
      }
    }
    let numero = 1;
    for (const sesion of this.formulario.sesiones) {
      sesion.curso = curso;
      sesion.numero = numero;
      numero++;
      promises.push(this.setSesion(sesion));
    }
    await Promise.all(promises);
  }

  notificar(curso: Curso, id: string, publicar?: boolean): void {
    if (!curso.id) curso.id = id;
    const body = {
      curso: curso,
      enviada: false,
      autor: this.usuario,
      fecha: new Date().toISOString(),
    };
    if (publicar) {
      const notificacionCliente: Notificacion = {
        ...body,
        usuarios: null,
        topico: "clientes",
        tipo: "curso-publicado",
        titulo: "¡Se ha publicado un curso!",
        contenido: `${curso.nombre.capitalizeFirstLetter()} ya está disponible para ti.`,
      };
      const notificacionRelatores: Notificacion = {
        ...body,
        topico: null,
        tipo: "curso-publicado",
        titulo: "¡Se ha publicado un curso!",
        usuarios: this.copiarRelatores(curso),
        contenido: `${curso.nombre.capitalizeFirstLetter()} ha sido publicado.`,
      };
      const notificacionAdmin: Notificacion = {
        ...body,
        usuarios: null,
        topico: "admins",
        tipo: "curso-publicado",
        titulo: "¡Se ha publicado un curso!",
        contenido: `${curso.nombre.capitalizeFirstLetter()} ha sido publicado.`,
      };
      this.setNotificacion(notificacionAdmin);
      this.setNotificacion(notificacionCliente);
      this.setNotificacion(notificacionRelatores);
    } else if (!this.cursoSeleccionado) {
      const notificacionRelatores: Notificacion = {
        ...body,
        topico: null,
        tipo: "curso-creado",
        titulo: "¡Se ha agregado un curso!",
        usuarios: this.copiarRelatores(curso),
        contenido: `${curso.nombre.capitalizeFirstLetter()} ha sido creado.`,
      };
      const notificacionAdmin: Notificacion = {
        ...body,
        usuarios: null,
        topico: "admins",
        tipo: "curso-creado",
        titulo: "¡Se ha agregado un curso!",
        contenido: `${curso.nombre.capitalizeFirstLetter()} ha sido creado.`,
      };
      this.setNotificacion(notificacionAdmin);
      this.setNotificacion(notificacionRelatores);
    }
  }

  async crearCupos(curso: Curso, id: string): Promise<void> {
    const cuposActuales = this.cupos.filter(_cupo => _cupo.curso.id === id).length;
    const nuevosCupos = curso.cupos - cuposActuales;
    if (nuevosCupos > 0) {
      if (!curso.id) curso.id = id;
      const promises: Promise<string>[] = [];
      for (let i = 0; i < nuevosCupos; i++) {
        const cupo: Cupo = {
          curso: curso,
          usuario: null,
          estado: "libre",
          fecha: new Date().toISOString(),
        };
        promises.push(this.setCupo(cupo));
      }
      await Promise.all(promises);
    }
  }

  async crearCurso(publicar?: boolean): Promise<void> {
    if (publicar) {
      this.cargandoPublicar = true;
    } else {
      this.cargando = true;
    }
    const archivo = await this.subirImagen(this.formulario.nombre);
    const curso = this.obtenerCurso(archivo, publicar);
    console.log(curso)
    if (!curso) return;
    const mensaje = this.obtenerMensaje(publicar);
    const id = await this.setCurso(curso);
    this.notificar(curso as Curso, id, publicar);
    if (publicar || this.cursoSeleccionado?.estado === "publicado") await this.crearCupos(curso as Curso, id);
    await this.guardarSesiones(id);
    this.cargando = false;
    this.cargandoPublicar = false;
    this.mostrarMensaje(mensaje);
    this.limpiar();
  }

  obtenerMensaje(publicar?: boolean): string {
    if (this.cursoSeleccionado && !publicar) return "¡Cambios guardados con éxito!";
    let texto = "creado";
    if (this.formulario.copia) texto = "duplicado";
    if (publicar) texto = "publicado";
    return `¡Curso ${texto} con éxito!`;
  }

  eliminarCurso(): void {
    if (!this.cursoSeleccionado?.id) return;
    const sesiones = this.sesiones.filter(_sesion => _sesion.curso.id === this.cursoSeleccionado?.id);
    for (const sesion of sesiones) {
      if (sesion.id) this.deleteSesion(sesion.id);
    }
    const cupos = this.cupos.filter(_cupo => _cupo.curso.id === this.cursoSeleccionado?.id);
    for (const cupo of cupos) {
      if (cupo.id) this.deleteCupo(cupo.id);
    }
    if (this.cursoSeleccionado.imagen?.id) {
      this.deleteArchivo(this.cursoSeleccionado.imagen.id);
    }
    this.deleteCurso(this.cursoSeleccionado.id);
    this.limpiar();
    this.cerrarDialogoConfirmacion();
  }

  async subirImagenCategoria(id: string, nombre: string, file: File): Promise<Archivo | null> {
    const blobArchivo: BlobArchivo = {
      file,
      nombre: nombre,
      fecha: new Date().toDateString(),
      ref: `categorias/${id}/imagen/${nombre}`,
    };
    const archivoId = await this.setBlobArchivo(blobArchivo);
    if (!archivoId) return null;
    return this.archivos[archivoId] ?? null;
  }

  async guardarCategoria(datos: { categoria: CategoriaCurso; imagen: File | null }): Promise<void> {
    this.mostrarMensaje("¡Categoría guardada con éxito!");
    const id = await this.setCategoriaCurso(datos.categoria);
    if (datos.imagen) {
      const archivo = await this.subirImagenCategoria(id, datos.categoria.nombre, datos.imagen);
      this.setCategoriaCurso({
        id,
        imagen: archivo,
      });
    }
  }
}
