import Firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/messaging";
import env from "@/helpers/env";

function initFirebase(): Promise<void> {
  Firebase.initializeApp({
    apiKey: env.firebaseApiKey,
    authDomain: env.firebaseAuthDomain,
    projectId: env.firebaseProjectId,
    storageBucket: env.firebaseStorageBucket,
    messagingSenderId: env.firebaseMessagingSenderId,
    appId: env.firebaseAppId,
    measurementId: env.firebaseMeasurementId,
  });

  return new Promise((resolve, reject) => {
    Firebase.firestore()
      .enablePersistence()
      .then(resolve)
      .catch((err) => {
        reject(err);
      });
  });
}

export { Firebase, initFirebase };
