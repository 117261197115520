
// Decoradores
import {Component, Emit, Prop, VModel, Vue, Watch} from "vue-property-decorator";

// Tipos
import {FiltroPromocionType} from "@/typings/components/promociones/filtrosPromociones";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import FiltrosPromociones from "@/components/promociones/FiltrosPromociones.vue";
import DialogTitle from "@/components/custom/DialogTitle.vue";

@Component({
  components: {
    DialogTitle,
    SvgIcon,
    FiltrosPromociones
  }
})
export default class DialogoFiltrosPromociones extends Vue {
  @VModel() model!: boolean;

  @Prop() readonly filtro!: FiltroPromocionType;

  filtrosInternos: FiltroPromocionType = null;

  @Watch("model")
  onModelChange(val: boolean): void {
    if (val) {
      this.filtrosInternos = this.filtro;
    }
  }

  @Emit("click:cerrar")
  onClickCerrar(): void {
    this.model = false;
  }

  @Emit("click:aplicar")
  onClickAplicar(): FiltroPromocionType {
    this.model = false;
    return this.filtrosInternos;
  }
}
