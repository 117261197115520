
// Decoradores
import {Component, Emit, Vue} from "vue-property-decorator";

// Validaciones
import {required, email} from "vuelidate/lib/validators";

// Tipos
import {Contacto} from "@/typings/store/plugins/easyFirestore/contactos";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";

@Component({
  components: {SvgIcon},
  validations: {
    nombre: {required},
    asunto: {required},
    mensaje: {required},
    telefono: {required},
    email: {required, email}
  }
})
export default class ContactoInicio extends Vue {
  snackbar = {
    model: false,
    text: ""
  };
  asuntos = [
    {text: "Problemas con mi cuenta", value: "Problemas con mi cuenta"},
    {text: "Proponer nuevo curso", value: "Proponer nuevo curso"}
  ];
  email = "";
  nombre = "";
  asunto = "";
  mensaje = "";
  telefono = "";

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get erroresNombre(): Array<string> {
    const errores: Array<string> = [];
    if (!this.$v.nombre.$dirty) return errores;
    if (!this.$v.nombre.required) errores.push("El nombre es requerido");
    return errores;
  }

  get erroresEmail(): Array<string> {
    const errores: Array<string> = [];
    if (!this.$v.email.$dirty) return errores;
    if (!this.$v.email.required) errores.push("El e-mail es requerido");
    if (!this.$v.email.email) errores.push("El e-mail no es válido");
    return errores;
  }

  get erroresTelefono(): Array<string> {
    const errores: Array<string> = [];
    if (!this.$v.telefono.$dirty) return errores;
    if (!this.$v.telefono.required) errores.push("El teléfono es requerido");
    return errores;
  }

  get erroresAsunto(): Array<string> {
    const errores: Array<string> = [];
    if (!this.$v.asunto.$dirty) return errores;
    if (!this.$v.asunto.required) errores.push("El asunto es requerido");
    return errores;
  }

  get erroresMensaje(): Array<string> {
    const errores: Array<string> = [];
    if (!this.$v.mensaje.$dirty) return errores;
    if (!this.$v.mensaje.required) errores.push("El mensaje es requerido");
    return errores;
  }

  @Emit("click:enviar")
  onClickEnviar(contacto: Contacto): Contacto {
    return contacto;
  }

  enviar(): void {
    this.$v.$touch();
    if (this.$v.$invalid) return;
    const contacto: Contacto = {
      email: this.email,
      nombre: this.nombre,
      asunto: this.asunto,
      mensaje: this.mensaje,
      telefono: this.telefono,
      fecha: new Date().toISOString()
    };
    this.email = "";
    this.nombre = "";
    this.asunto = "";
    this.mensaje = "";
    this.telefono = "";
    this.$v.$reset();
    this.snackbar = {
      model: true,
      text: "Mensaje enviado"
    };
    this.onClickEnviar(contacto);
  }
}
