
// Vue
import {computed, defineComponent, ref, watch} from "vue";

// Vuetify
import vuetify from "@/plugins/vuetify";

// Tipos
import {Curso} from "@/typings/store/plugins/easyFirestore/cursos";
import {Sesion} from "@/typings/store/plugins/easyFirestore/sesiones";
import {DataFormularioCurso} from "@/typings/components/cursos/formulario";
import {Inscripcion} from "@/typings/store/plugins/easyFirestore/inscripciones";
import {Usuario, Usuarios} from "@/typings/store/plugins/easyFirestore/usuarios";
import {CategoriaCurso} from "@/typings/store/plugins/easyFirestore/categoriasCurso";

// Componentes
import Checkbox from "@/components/custom/Checkbox.vue";
import PageSelect from "@/components/custom/PageSelect.vue";
import TextButton from "@/components/custom/TextButton.vue";
import PageTextArea from "@/components/custom/PageTextArea.vue";
import PageTextField from "@/components/custom/PageTextField.vue";
import PageDatePicker from "@/components/custom/PageDatePicker.vue";
import ImagenCurso from "@/components/cursos/formulario/ImagenCurso.vue";
import PlanEstudio from "@/components/cursos/formulario/PlanEstudio.vue";
import FormularioCategoria from "@/components/cursos/formulario/FormularioCategoria.vue";

export default defineComponent({
  name: "FormularioCurso",
  components: {
    Checkbox,
    PageSelect,
    TextButton,
    PlanEstudio,
    ImagenCurso,
    PageTextArea,
    PageTextField,
    PageDatePicker,
    FormularioCategoria
  },
  props: {
    value: {
      required: true,
      type: Object as () => DataFormularioCurso
    },
    cargando: {
      required: true,
      type: Boolean as () => boolean
    },
    sesiones: {
      required: true,
      type: Array as () => Sesion[]
    },
    curso: {
      default: null,
      type: Object as () => Curso | null
    },
    relatores: {
      required: true,
      type: Array as () => Usuario[]
    },
    usuario: {
      default: null,
      type: Object as () => Usuario | null
    },
    buttonDisabled: {
      required: true,
      type: Boolean as () => boolean
    },
    cargandoPublicar: {
      required: true,
      type: Boolean as () => boolean
    },
    inscripciones: {
      required: true,
      type: Array as () => Inscripcion[]
    },
    categorias: {
      required: true,
      type: Array as () => CategoriaCurso[]
    },
    readonly: {
      required: true,
      type: Boolean as () => boolean
    },
    mostrarPublicar: {
      required: true,
      type: Boolean as () => boolean
    }
  },
  emits: [
    "input",
    "click:guardar",
    "click:publicar",
    "click:guardar-categoria"
  ],
  setup(props, ctx) {
    // VModel

    const form = computed<DataFormularioCurso>({
      get() {
        return props.value;
      },
      set(val) {
        ctx.emit("input", val);
      }
    });

    // Data

    const dialogoCategoria = ref(false);
    const categoriaSeleccionada = ref<CategoriaCurso | null>(null);

    // Computed

    const inicio = computed(() => form.value.inicio);

    const mobile = computed(() => vuetify.framework.breakpoint.mdAndDown);

    const tipoUsuario = computed(() => props.usuario?.tipo ?? "relator");

    const precioReadonly = computed(() => props.curso?.estado === "publicado" || props.readonly);

    const relatoresSelect = computed<Usuario[]>({
      get() {
        return Object.values(form.value.relatores);
      },
      set(value: Usuario[]) {
        const relatores: Usuarios = {};
        for (const relator of value) {
          relatores[relator.id] = relator;
        }
        form.value.relatores = relatores;
      }
    });

    const textRelatores = computed(() => {
      const relatores = relatoresSelect.value.length;
      if (relatores === 0) return "";
      if (relatores === 1) return relatoresSelect.value[0].nombre;
      return `${relatores} relatores`;
    });

    const relatoresString = computed(() => relatoresSelect.value.map(relator => relator.nombre).join(", "));

    const mostrarEliminar = computed(() => {
      const id = props.curso?.id;
      if (!id) return true;
      return !props.inscripciones.some(_inscripcion => !!_inscripcion.pago.cursos[id]);
    });

    const cuposClass = computed(() => ({
      'formulario__cupos': true,
      'formulario__cupos--relator': tipoUsuario.value === "relator"
    }));

    // Watchs

    watch(inicio, (value: string) => {
      if (!value || !form.value.fin) return;
      const fechaInicio = new Date(`${value} 00:00:00`);
      const fechaFin = new Date(`${form.value.fin} 00:00:00`);
      if (fechaInicio > fechaFin) form.value.fin = "";
    });

    // Methods

    const onRightClickCategoria = (categoria: CategoriaCurso) => {
      categoriaSeleccionada.value = categoria;
      dialogoCategoria.value = true;
    };

    const allowedDatesInicio = (fecha: string) => {
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      const fechaInicio = new Date(`${fecha} 00:00:00`);
      return fechaInicio.getTime() >= now.getTime();
    };

    const allowedDatesFin = (fecha: string) => {
      if (!form.value.inicio) return false;
      const fechaInicio = new Date(`${form.value.inicio} 00:00:00`);
      const fechaFin = new Date(`${fecha} 00:00:00`);
      return fechaFin.getTime() >= fechaInicio.getTime();
    };

    const calcularPorcentaje = (curso: Curso) => {
      if (!curso.precioRef) return 0;
      return 100 - Math.floor((curso.precio * 100) / curso.precioRef);
    };

    // Emits

    const onClickGuardar = () => {
      ctx.emit("click:guardar");
    };

    const onClickPublicar = () => {
      ctx.emit("click:publicar");
    };

    const onClickGuardarCategoria = (datos: { categoria: CategoriaCurso; imagen: File | null }) => {
      dialogoCategoria.value = false;
      categoriaSeleccionada.value = null;
      ctx.emit("click:guardar-categoria", datos);
    };

    if (props.curso) {
      const sesiones = props.sesiones.filter(sesion => sesion.curso.id === props.curso?.id).sort((a, b) => a.numero - b.numero);
      form.value = {
        sesiones,
        imagen: null,
        copia: false,
        fin: props.curso.fin,
        cupos: props.curso.cupos,
        nombre: props.curso.nombre,
        inicio: props.curso.inicio,
        archivo: props.curso.imagen,
        resumen: props.curso.resumen,
        comision: props.curso.comision,
        categoria: props.curso.categoria,
        relatores: props.curso.relatores,
        descripcion: props.curso.descripcion,
        certificado: props.curso.certificado,
        descuento: calcularPorcentaje(props.curso),
        precio: props.curso.precioRef ?? props.curso.precio
      };
    }

    return {
      // VModel
      form,
      // Data
      dialogoCategoria,
      categoriaSeleccionada,
      // Computed
      mobile,
      cuposClass,
      tipoUsuario,
      textRelatores,
      precioReadonly,
      relatoresSelect,
      relatoresString,
      mostrarEliminar,
      // Methods
      allowedDatesFin,
      allowedDatesInicio,
      onRightClickCategoria,
      // Emits
      onClickGuardar,
      onClickPublicar,
      onClickGuardarCategoria
    };
  }
});
