
// Vue
import {defineComponent} from "vue";

// Tipos
import {Contacto} from "@/typings/store/plugins/easyFirestore/contactos";

export default defineComponent({
  name: "TablaContactos",
  props: {
    contactos: {
      required: true,
      type: Array as () => Contacto[],
    },
  },
  emits: [
    "click:copiar",
  ],
  setup(_props, ctx) {
    // Emits

    const onClickCopiar = (texto: string, nombre: string) => {
      ctx.emit("click:copiar", {texto, nombre});
    };

    return {
      // Emits
      onClickCopiar,
    };
  },
});
