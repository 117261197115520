
// Decoradores
import {Component, Prop, VModel, Vue} from "vue-property-decorator";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import PageTextField from "@/components/custom/PageTextField.vue";

@Component({
  components: {
    SvgIcon,
    PageTextField
  }
})
export default class PageDatePicker extends Vue {
  @VModel() model!: string | Array<string>;

  @Prop() readonly label!: string;
  @Prop() readonly placeholder!: string;
  @Prop() readonly errorMessages?: Array<string>;
  @Prop({type: Boolean}) readonly range!: boolean;
  @Prop() readonly allowedDates!: (date: string) => boolean;
  @Prop({type: Boolean}) readonly readonly!: boolean;

  get date(): string | Array<string> {
    return this.model;
  }

  set date(value: string | Array<string>) {
    this.model = value;
    if (Array.isArray(value)) {
      const first = value[0]?.toDate();
      const last = value[1]?.toDate();
      if (first && last && first.getTime() >= last.getTime()) {
        this.model = [last.format("YYYY-MM-DD"), first.format("YYYY-MM-DD")];
      }
    }
  }

  get stringDate(): string {
    if (!this.model) return "";
    if (Array.isArray(this.model)) {
      return this.model.map((fecha) => fecha.toDate().format("DD/MM/YYYY")).join(" ~ ");
    }
    return this.model.toDate().format("DD/MM/YYYY");
  }
}
