
// Vue
import {computed, defineComponent} from "vue";

// Helpers
import {toCurrency} from "@/helpers/number";

// Tipos
import {Pago} from "@/typings/store/plugins/easyFirestore/pagos";
import {Curso} from "@/typings/store/plugins/easyFirestore/cursos";

export default defineComponent({
  name: "CursosDetallePage",
  props: {
    pagoSeleccionado: {
      required: true,
      type: Object as () => Pago,
    },
  },
  setup(props) {
    // Computed

    const items = computed(() => {
      const arrayCursos = Object.values(props.pagoSeleccionado.cursos);
      return arrayCursos.map(generarItem);
    });

    // Methods

    const generarItem = (item: Curso) => {
      const precio = toCurrency(item.precio);
      return {
        id: item.id ?? "",
        nombre: item.nombre,
        precio: `Total: ${precio}`,
        categoria: item.categoria.nombre,
      };
    };

    return {
      // Computed
      items,
      // Methods
      generarItem,
    };
  },
});
