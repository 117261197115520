
// Decoradores
import {Component, Emit, Prop, VModel, Vue} from "vue-property-decorator";

// Componentes
import TextButton from "@/components/custom/TextButton.vue";

@Component({
  components: {
    TextButton
  }
})
export default class DescuentoCarrito extends Vue {
  @VModel() codigo!: string;
  @Prop() readonly erroresCodigo!: string[];

  @Emit("click:aplicar")
  onClickAplicar(): void {
    return;
  }
}
