
// Decoradores
import {Component, Emit, Prop, VModel, Vue} from "vue-property-decorator";

// Tipos
import {DataFiltrosCursos} from "@/typings/components/cursos/filtros";
import {EstadoCurso} from "@/typings/store/plugins/easyFirestore/cursos";
import {TipoUsuario, Usuario} from "@/typings/store/plugins/easyFirestore/usuarios";
import {CategoriaCurso} from "@/typings/store/plugins/easyFirestore/categoriasCurso";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import TextButton from "@/components/custom/TextButton.vue";
import ResetButton from "@/components/custom/ResetButton.vue";
import FiltersContainer from "@/components/custom/FiltersContainer.vue";
import PageFilter, {PageFilterItem} from "@/components/custom/PageFilter.vue";

@Component({
  components: {
    SvgIcon,
    PageFilter,
    TextButton,
    ResetButton,
    FiltersContainer
  }
})
export default class FiltrosCursos extends Vue {
  @VModel() model!: DataFiltrosCursos;

  @Prop() readonly relatores!: Usuario[];
  @Prop() readonly categorias!: CategoriaCurso[];
  @Prop() readonly tipoUsuario!: TipoUsuario | null;
  @Prop({type: Boolean}) readonly dialog!: boolean;
  @Prop({type: Boolean}) readonly disabled!: boolean;

  created(): void {
    this.filtroEstados = this.model.estado.slice();
    this.filtroRelatores = this.model.relator.slice();
    this.filtroCategorias = this.model.categoria.slice();
  }

  filtroRelatores: string[] = [];
  filtroCategorias: string[] = [];
  filtroEstados: EstadoCurso[] = [];

  itemsEstados = [{
    value: "pendiente",
    text: "Pendiente"
  }, {
    value: "publicado",
    text: "Publicado"
  }, {
    value: "finalizado",
    text: "Finalizado"
  }];

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get itemsCategorias(): PageFilterItem[] {
    return this.categorias.map(categoria => ({
      text: categoria.nombre,
      value: categoria.id ?? ""
    }));
  }

  get itemsRelatores(): PageFilterItem[] {
    return this.relatores.map(relator => ({
      text: relator.nombre,
      value: relator.id ?? ""
    }));
  }

  @Emit("click:aplicar")
  onClickAplicar(): void {
    this.model = {
      estado: this.filtroEstados.slice(),
      relator: this.filtroRelatores.slice(),
      categoria: this.filtroCategorias.slice()
    };
  }

  @Emit("click:resetear")
  onClickResetear(): void {
    this.filtroEstados = [];
    this.filtroRelatores = [];
    this.filtroCategorias = [];
    this.model = {
      estado: [],
      relator: [],
      categoria: []
    };
  }
}
