import { RouteConfig } from "vue-router";
import Promociones from "@/views/VistaPromociones.vue";

const promociones: RouteConfig = {
    path: "/promociones",
    name: "promociones",
    component: Promociones,
};

export default promociones;
