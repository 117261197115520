
// Decoradores
import {Component, Emit, Prop, Vue} from "vue-property-decorator";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import IconButton from "@/components/custom/IconButton.vue";

export interface IControles {
  name: string;
  muted: boolean;
  duration: number;
  playing: boolean;
  currentTime: number;
  fullscreen: boolean;
}

@Component({
  components: {
    SvgIcon,
    IconButton
  }
})
export default class Controles extends Vue {
  @Prop() readonly values!: IControles;

  showingControls = true;
  timeoutId: number | null = null;

  get progressBarStyle(): { [key: string]: string } {
    const percent = this.values.currentTime * 100 / this.values.duration;
    return {
      '--progress-bar-width': `${percent}%`
    };
  }

  get containerStyle(): { [key: string]: string } {
    return {
      'opacity': this.showingControls ? '1' : '0',
      'cursor': this.showingControls ? 'default' : 'none'
    };
  }

  get duration(): string {
    const minutes = Math.floor(this.values.duration / 60).toString();
    const seconds = Math.floor(this.values.duration % 60).toString();
    return `${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`;
  }

  get currentTime(): string {
    const minutes = Math.floor(this.values.currentTime / 60).toString();
    const seconds = Math.floor(this.values.currentTime % 60).toString();
    return `${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`;
  }

  @Emit("click:go-back")
  onClickGoBack(): void {
    return;
  }

  @Emit("click:play-pause")
  onClickPlayPause(): void {
    return;
  }

  @Emit("click:mute-unmute")
  onClickMuteUnmute(): void {
    return;
  }

  @Emit("set:currentTime")
  onSetCurrentTime(value: number): number {
    return value;
  }

  @Emit("click:fullscreen")
  onClickFullscreen(): void {
    return;
  }

  @Emit("click:reload")
  onClickReload(): void {
    return;
  }

  @Emit("click:backward")
  onClickBackward(): void {
    return;
  }

  @Emit("click:forward")
  onClickForward(): void {
    return;
  }

  @Emit("keydown:volume-up")
  onKeyDownVolumeUp(): void {
    return;
  }

  @Emit("keydown:volume-down")
  onKeyDownVolumeDown(): void {
    return;
  }

  onClickPlayPauseHandler(): void {
    this.onClickPlayPause();
    this.toggleControls();
  }

  onMouseMoveHandler(): void {
    this.toggleControls();
  }

  toggleControls(): void {
    if (this.timeoutId) window.clearTimeout(this.timeoutId);
    this.showingControls = true;
    this.timeoutId = window.setTimeout(() => {
      if (!this.values.playing) return;
      this.showingControls = false;
    }, 3000);
  }

  onProgressBarClickHandler({x, target}: MouseEvent): void {
    const element = target as HTMLDivElement;
    const elementX = element.offsetLeft;
    const elementWidth = element.offsetWidth;
    const percent = (x - elementX) / elementWidth;
    this.onSetCurrentTime(this.values.duration * percent);
  }
}
