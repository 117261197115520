
// Vue
import {computed, defineComponent, ref} from "vue";

// Vuetify
import vuetify from "@/plugins/vuetify";

// Tipos
import {Archivo} from "@/typings/store/plugins/easyFirestore/archivos";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import TextButton from "@/components/custom/TextButton.vue";

export default defineComponent({
  name: "ImagenCurso",
  components: {
    SvgIcon,
    TextButton
  },
  props: {
    value: {
      type: File,
      default: null
    },
    archivo: {
      type: Object as () => Archivo | null
    },
    readonly: {
      default: false,
      type: Boolean as () => boolean
    }
  },
  emits: [
    "input"
  ],
  setup(props, ctx) {
    // VModel

    const model = computed({
      get() {
        return props.value;
      },
      set(value) {
        ctx.emit("input", value);
      }
    });

    // Data

    const fileInput = ref<HTMLInputElement | null>(null);

    // Computed

    const url = computed(() => {
      if (model.value) return URL.createObjectURL(model.value);
      return props.archivo?.url ?? "";
    });

    const styleContainer = computed(() => {
      return {
        'background-image': `url(${url.value})`
      };
    });

    const mobile = computed(() => vuetify.framework.breakpoint.mdAndDown);

    // Methods

    const clickInput = () => {
      if(fileInput.value) fileInput.value.click();
    };

    const onFileChange = () => {
      const archivo = fileInput.value?.files?.[0];
      if (archivo) model.value = archivo;
    };

    return {
      // VModel
      model,
      // Data
      fileInput,
      // Computed
      url,
      mobile,
      styleContainer,
      // Methods
      clickInput,
      onFileChange
    }
  }
});
