
// Decoradores
import {Component, Emit, Prop, Vue} from "vue-property-decorator";

// Tipos
import {DataTableHeader} from "vuetify";
import {DataTableItem} from "@/typings/components/carrito/tabla";
import {Carrito, CursoCarrito} from "@/typings/store/plugins/easyFirestore/carritos";

// Componentes
import SvgIcon from "@/components/custom/SvgIcon.vue";
import IconButton from "@/components/custom/IconButton.vue";
import PageTableItem from "@/components/custom/PageTableItem.vue";

@Component({
  components: {
    SvgIcon,
    IconButton,
    PageTableItem,
  }
})
export default class TablaCarrito extends Vue {
  @Prop() readonly carrito!: Carrito | null;
  @Prop() readonly cargando!: boolean;

  get headers(): DataTableHeader[] {
    if (!this.mobile) {
      return [{
        text: "Nombre del curso",
        value: "nombre"
      }, {
        text: "Categoría",
        value: "categoria"
      }, {
        text: "Precio",
        value: "precio"
      }, {
        text: "",
        value: "opciones",
        sortable: false
      }];
    }
    return [{
      text: "Detalles curso",
      value: "detalles"
    }];
  }

  get mobile(): boolean {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get cursos(): Array<CursoCarrito> {
    if (!this.carrito) return [];
    return Object.values(this.carrito.cursos);
  }

  get items(): Array<DataTableItem> {
    return this.cursos.map(cursoCarrito => {
      const data = cursoCarrito;
      const id = cursoCarrito.id ?? "";
      const nombre = cursoCarrito.curso.nombre;
      const precio = cursoCarrito.curso.precio;
      const precioRef = cursoCarrito.curso.precioRef ?? 0;
      const categoria = cursoCarrito.curso.categoria.nombre;
      return {
        id,
        data,
        nombre,
        precio,
        categoria,
        precioRef
      };
    });
  }

  @Emit("click:eliminar-curso")
  onClickEliminarCurso(curso: CursoCarrito): CursoCarrito {
    return curso;
  }
}
