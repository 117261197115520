
// Decoradores
import {Component, Vue} from "vue-property-decorator";

// Validaciones
import {required} from "vuelidate/lib/validators";

// Helpers
import routeGuard from "@/helpers/routeGuard";

// Vuex
import {mapActions, mapGetters} from "vuex";

// Tipos
import {FormularioCuentaType} from "@/typings/components/cuenta/formularioCuenta";

// Componentes
import ViewTitle from "@/components/custom/ViewTitle.vue";
import Footer from "@/components/appCorePagina/Footer.vue";
import MessageDialog from "@/components/custom/MessageDialog.vue";
import FormularioCuenta from "@/components/cuenta/FormularioCuenta.vue";

@Component({
  components: {
    Footer,
    ViewTitle,
    MessageDialog,
    FormularioCuenta
  },
  computed: mapGetters({
    getUsuario: "usuario/get",
    getRutasUsuario: "usuario/getRutas",
    getProviderId: "usuario/getProviderId"
  }),
  methods: mapActions({
    updatePasswordUsuario: "usuario/updatePassword"
  }),
  beforeRouteEnter(_to, _from, next) {
    next(routeGuard);
  },
  validations: {
    formulario: {
      claveNueva: {required},
      claveActual: {required}
    }
  }
})
export default class VistaCuenta extends Vue {
  created(): void {
    if (!routeGuard(this)) return;
    if (this.getProviderId !== "password") {
      this.$router.replace({name: "inicio"});
    }
  }

  errorCode = "";
  cargando = false;
  dialogoMensaje = false;
  formulario: FormularioCuentaType = {
    claveNueva: "",
    claveActual: ""
  };

  get erroresClaveActual(): string[] {
    const errores: string[] = [];
    if (!this.$v.formulario?.claveActual?.$dirty) return errores;
    if (!this.$v.formulario?.claveActual?.required) errores.push("La contraseña actual es requerida.");
    return errores;
  }

  get erroresClaveNueva(): string[] {
    const errores: string[] = [];
    if (!this.$v.formulario?.claveNueva?.$dirty) return errores;
    if (!this.$v.formulario?.claveNueva?.required) errores.push("La contraseña nueva es requerida.");
    return errores;
  }

  get error(): string {
    if (!this.errorCode) return "";
    switch (this.errorCode) {
      case "auth/invalid-email":
        return "Correo inválido";
      case "auth/user-mismatch":
        return "El usuario no coincide";
      case "auth/user-not-found":
        return "No se encontró el usuario";
      case "auth/email-not-found":
        return "No se encontró el correo";
      case "auth/invalid-credential":
        return "Credenciales inválidas";
      case "auth/wrong-password":
        return "Contraseña actual incorrecta";
      case "auth/invalid-verification-id":
        return "ID de verificación inválido";
      case "auth/weak-password":
        return "La contraseña nueva es demasiado débil";
      case "auth/requires-recent-iniciarSesion":
        return "Se requiere una sesión reciente";
      case "auth/invalid-verification-code":
        return "Código de verificación inválido";
      default:
        return "Error desconocido";
    }
  }


  async actualizarClave(): Promise<void> {
    this.$v.$touch();
    if (this.$v.$invalid) return;
    this.cargando = true;
    this.errorCode = "";
    try {
      await this.updatePasswordUsuario({
        newPassword: this.formulario.claveNueva,
        currentPassword: this.formulario.claveActual
      });
      this.$v.$reset();
      this.dialogoMensaje = true;
      this.formulario.claveNueva = "";
      this.formulario.claveActual = "";
    } catch (error) {
      this.errorCode = error.code;
    }
    this.cargando = false;
  }
}
